export * from './Notification.types';

import { Title } from './Title';
import { TitleHighlight } from './TitleHighlight';
import { Subtitle } from './Subtitle';
import { NotificationDate } from './NotificationDate';
import { Notification as BaseNotification } from './Notification';

export const Notification = Object.assign(BaseNotification, {
  Title,
  TitleHighlight,
  Subtitle,
  Date: NotificationDate,
});
