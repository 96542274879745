import { gql } from '@apollo/client';
import { SESSION_AND_NOTE_FRAGMENT } from './fragments/sessionAndNoteFragment';
export const GET_CLINICAL_CASE_SESSIONS_AND_NOTES = gql`
  ${SESSION_AND_NOTE_FRAGMENT}

  query getSessionsAndNotes(
    $clinicalCaseId: ID!
    $statuses: [SessionStatuses]
    $startScheduledAt: String
    $endScheduledAt: String
    $discipline: Disciplines
    $sessionTypes: [SessionTypes]
    $limit: Int
    $offset: Int
  ) {
    sessions(
      clinicalCaseId: $clinicalCaseId
      statuses: $statuses
      discipline: $discipline
      sessionTypes: $sessionTypes
      startScheduledAt: $startScheduledAt
      endScheduledAt: $endScheduledAt
      limit: $limit
      offset: $offset
    ) {
      ...SessionAndNoteFields
    }
  }
`;
