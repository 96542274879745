import { graphql } from 'msw';

export default graphql.mutation('updateSubject', (req, res, ctx) => {
  const {
    subjectInput: { subjectId, text = '', status = 'open' },
  } = req.variables;

  if (text.toLowerCase() === 'error') {
    return res(ctx.delay(1000), ctx.errors([]));
  }

  const statusDescription = status === 'open' ? 'Aberta' : 'Concluída';

  return res(
    ctx.delay(1000),
    ctx.data({
      updateSubject: {
        text,
        status,
        statusDescription,
        id: subjectId,
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
        createdBy: {
          id: 'ad67591b-d9c4-41bc-b413-135b659003f5',
          name: 'Geninho',
        },
        updatedBy: {
          id: 'f23fe3b9-9663-4b41-a6c1-ba9734d8d11e',
          name: 'Genona',
        },
      },
    }),
  );
});
