import { gql } from '@apollo/client';

export const UPDATE_CLINICAL_GUIDANCE_COMMENT = gql`
  mutation updateClinicalGuidanceComment(
    $content: String!
    $commentId: String!
    $clinicalGuidanceId: String!
  ) {
    updateClinicalGuidanceComment(
      content: $content
      commentId: $commentId
      clinicalGuidanceId: $clinicalGuidanceId
    ) {
      id
      content
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
    }
  }
`;
