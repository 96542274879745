import React from 'react';
import { CommunicationChat } from '@genialcare/atipico-react';
import { FamilySupportConversationMessageCreatedNotification } from 'types';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type FamilySupportMultipleMessagesCreatedProps =
  ComposeNotificationProps<FamilySupportConversationMessageCreatedNotification> & {
    total: number;
  };

export const FamilySupportMultipleMessagesCreated: React.FC<
  FamilySupportMultipleMessagesCreatedProps
> = ({ clinicalCaseId, conversationId, createdAt, clinicalCaseName, total, ...props }) => {
  const href = buildURL.toFamilySupportConversation(clinicalCaseId, conversationId);

  return (
    <Notification {...props} date={createdAt} href={href} icon={<CommunicationChat />}>
      <Notification.Title>
        Você tem {total} novas mensagens no chamado aberto do caso {clinicalCaseName}.
      </Notification.Title>
    </Notification>
  );
};
