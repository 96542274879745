import { gql } from '@apollo/client';

export const CUSTOM_TARGET_LIST_BY_ID = gql`
  query getCustomTargetListByID($id: ID!, $clinicalCaseId: ID!) {
    customTargetList(id: $id, clinicalCaseId: $clinicalCaseId) {
      id
      name
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      programs {
        id
        name
        instruction
        stimulusDiscrimination
        consequence
        correctionProcedure
        errorCorrection
        independentResponseReinforcement
        promptedResponseReinforcement
        trialsPerSession
        registerType
        activities {
          name
        }
        targets {
          id
          name
          status
          createdAt
          updatedAt
          data {
            ... on TrialTargetData {
              currentPromptId
              currentPromptName
            }
          }
        }
        targetCategory {
          name
        }
        configuration {
          ... on TrialConfiguration {
            promptSchedule {
              id
              name
              promotion
              demotion
              sessionInARowToConsider
              prompts {
                id
                name
                abbreviation
              }
            }
          }
          ... on TaskAnalysisConfiguration {
            steps {
              id
              name
              level
            }
          }
        }
      }
    }
  }
`;
