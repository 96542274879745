import { gql } from '@apollo/client';

export const PLANNING_FRAGMENT = gql`
  fragment PlanningFields on Planning {
    id
    plannedAt
    clinicalReasoning
    startInterval
    endInterval
    clinicalCase {
      id
      name
    }
    sessions {
      id
      startScheduledAt
      location {
        label
      }
    }
    __typename
  }
`;
