import {
  styled,
  pxToRem,
  Flex,
  Button,
  InterfacePlus as InterfacePlusDefault,
} from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  gap: pxToRem(16),
});

export const Heading = styled(Flex, {
  gap: pxToRem(8),
});

export const Counter = styled(Flex, {
  width: pxToRem(24),
  height: pxToRem(24),
  borderRadius: '100%',
  backgroundColor: '$neutral100',
});

export const InterfacePlus = styled(InterfacePlusDefault, {
  width: pxToRem(25),
  height: pxToRem(25),
});

export const NewTaskButton = styled(Button, {
  minWidth: 'auto',
  width: pxToRem(148),
  height: pxToRem(38),
  fontSize: pxToRem(12),
  paddingLeft: pxToRem(42),
  '& > div': {
    left: pxToRem(6),
  },
});

export const ListContainer = styled(Flex, {
  gap: pxToRem(20),
});
