export enum ClinicianTeamMemberSeniority {
  GENIAL_NETWORK_THERAPIST_1 = 'GENIAL_NETWORK_THERAPIST_1',
  GENIAL_NETWORK_THERAPIST_2 = 'GENIAL_NETWORK_THERAPIST_2',
  GENIAL_NETWORK_THERAPIST_3 = 'GENIAL_NETWORK_THERAPIST_3',
  GENIAL_CLINICIAN_1 = 'GENIAL_CLINICIAN_1',
  GENIAL_CLINICIAN_2 = 'GENIAL_CLINICIAN_2',
  GENIAL_CLINICIAN_3 = 'GENIAL_CLINICIAN_3',
  GENIAL_CLINICIAN_4 = 'GENIAL_CLINICIAN_4',
  GENIAL_CLINICIAN_5 = 'GENIAL_CLINICIAN_5',
}

export enum ClinicianTeamMemberRole {
  ABA_THERAPIST = 'ABA_THERAPIST',
  OCCUPATIONAL_THERAPY_THERAPIST = 'OCCUPATIONAL_THERAPY_THERAPIST',
  SPEECH_THERAPY_THERAPIST = 'SPEECH_THERAPY_THERAPIST',
  CLINICAL_CASE_OWNER = 'CLINICAL_CASE_OWNER',
  CLINICAL_CASE_REFERENCE = 'CLINICAL_CASE_REFERENCE',
  CLINICAL_ASSESSMENT_OWNER = 'CLINICAL_ASSESSMENT_OWNER',
  THERAPY_REFERENCE = 'THERAPY_REFERENCE',
  THERAPY_REFERENCE_SENIORITY = 'THERAPY_REFERENCE_SENIORITY',
  OCCUPATIONAL_THERAPY_SPECIALTY_CONSULTANT = 'OCCUPATIONAL_THERAPY_SPECIALTY_CONSULTANT',
  SPEECH_SPECIALTY_CONSULTANT = 'SPEECH_SPECIALTY_CONSULTANT',
  GCA_MENTOR = 'GCA_MENTOR',
  OTHERS = 'OTHERS',
}

export type ClinicianTeamMember = {
  clinicianId: string;
  name: string;
  legalName?: string;
  role: string;
  seniority?: ClinicianTeamMemberSeniority;
  specialization?: string;
  professionalRegistrationNumber?: string;
  addedAt: string;
  toBeRemovedAt?: string;
};

export type CoverPageDependentInfo = {
  diagnosed: boolean;
  comorbidities: string[];
  medicines: string;
  healthConditions?: string[];
  healthPlan: {
    name?: string;
  };
};

export type CoverPage = {
  clinicianTeam: ClinicianTeamMember[];
  dependentInfo: CoverPageDependentInfo;
};
