import React from 'react';
import { Typography } from '@genialcare/atipico-react';
import * as S from './DeleteView.styles';

type DeleteViewProps = {
  taskId: string;
  title: string;
  isDeleting: boolean;
  onCancel: () => void;
  onDelete: (taskId: string) => Promise<void>;
};

export const DeleteView: React.FC<DeleteViewProps> = ({
  taskId,
  title,
  isDeleting,
  onDelete,
  onCancel,
}) => {
  const handleOnDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    onDelete(taskId);
  };

  const handleOnCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <S.Card data-testid="task-card-delete-view">
      <S.Root direction="column" align="center" justify="center">
        <S.Content direction="column" align="center">
          <Typography variant="body2" color="neutral400">
            Tem certeza que deseja excluir
          </Typography>
          <S.TaskTitle
            variant="body2"
            weight="bold"
            color="purple500"
            data-prefix={`"`}
            data-suffix={`"?`}
          >
            {title}
          </S.TaskTitle>
        </S.Content>

        <S.Actions>
          <S.Button
            colorScheme="red"
            onClick={handleOnDelete}
            isLoading={isDeleting}
            isDisabled={isDeleting}
          >
            Excluir
          </S.Button>
          <S.Button variant="outline" onClick={handleOnCancel}>
            Cancelar
          </S.Button>
        </S.Actions>
      </S.Root>
    </S.Card>
  );
};
