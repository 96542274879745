import { gql } from '@apollo/client';

export const CREATE_CLINICAL_GUIDANCE_COMMENT = gql`
  mutation createClinicalGuidanceComment($clinicalGuidanceId: String!, $content: String!) {
    createClinicalGuidanceComment(clinicalGuidanceId: $clinicalGuidanceId, content: $content) {
      id
      content
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
    }
  }
`;
