import { gql } from '@apollo/client';
import { PENDENCY_FRAGMENT } from '../pendencies/fragments/pendencyFragment';
import { SESSION_CARD__BY_CLINICAL_CASE_FRAGMENT } from './fragments/sessionCardByClinicalCaseFragment';

export const GET_SESSIONS_HISTORY = gql`
  ${PENDENCY_FRAGMENT}
  ${SESSION_CARD__BY_CLINICAL_CASE_FRAGMENT}

  query getSessionsHistory(
    $clinicalCaseId: ID!
    $startScheduledAt: String
    $endScheduledAt: String
    $sessionTypes: [SessionTypes]
    $futureSessionsStatuses: [SessionStatuses]
    $futureSessionsOrderBy: SessionOrderByInput
    $pastSessionsStatuses: [SessionStatuses]
    $pastSessionsOrderBy: SessionOrderByInput
  ) {
    futureSessions: sessions(
      clinicalCaseId: $clinicalCaseId
      sessionTypes: $sessionTypes
      startScheduledAt: $startScheduledAt
      endScheduledAt: $endScheduledAt
      orderBy: $futureSessionsOrderBy
      statuses: $futureSessionsStatuses
    ) {
      ...SessionCardByClinicalCaseFields
      pendencies {
        ...PendencyFields
        __typename
      }
    }
    pastSessions: sessions(
      clinicalCaseId: $clinicalCaseId
      sessionTypes: $sessionTypes
      startScheduledAt: $startScheduledAt
      endScheduledAt: $endScheduledAt
      orderBy: $pastSessionsOrderBy
      statuses: $pastSessionsStatuses
    ) {
      ...SessionCardByClinicalCaseFields
      pendencies {
        ...PendencyFields
        __typename
      }
    }
  }
`;
