import { graphql } from 'msw';

export default graphql.mutation('deleteSubject', (_, res, ctx) =>
  res(
    ctx.delay(3000),
    ctx.data({
      deleteSubject: null,
    }),
  ),
);
