import { graphql } from 'msw';

export default graphql.mutation('selectAvailableHour', (req, res, ctx) => {
  return res(
    ctx.delay(3000),
    ctx.data({
      selectAvailableHour: {
        id: 'cb477afe-c429-11ed-afa1-0242ac120002',
      },
    }),
  );
});
