import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_PEI_OBJECTIVES = gql`
  query getClinicalCasePEIAndObjectives($id: ID!, $objectiveStatuses: [String]) {
    clinicalCase(id: $id) {
      id
      peis(objectiveStatuses: $objectiveStatuses) {
        id
        version
        objectives {
          id
          description
          status
          updatedAt
          programs {
            ... on AbaProgram {
              discipline
              instruction
              stimulusDiscrimination
              targets {
                id
                name
                status
                data {
                  ... on TrialTargetData {
                    currentPromptName
                  }
                }
              }
              configuration {
                ... on TrialConfiguration {
                  promptSchedule {
                    id
                    name
                    prompts {
                      id
                      name
                    }
                  }
                }
              }
            }
            ... on OccupationalTherapyProgram {
              discipline
            }
            ... on SpeechTherapyProgram {
              discipline
            }
          }
          protocolItem {
            domain
            subdomain
            protocolName
          }
        }
      }
    }
  }
`;
