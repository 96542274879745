import { graphql } from 'msw';

export default graphql.mutation('deleteClinicalCaseSupportLink', (_, res, ctx) => {
  return res(
    ctx.delay(3000),
    ctx.data({
      deleteClinicalCaseSupportLink: null,
    }),
  );
});
