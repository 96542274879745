import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { styled } from 'styled-system/jsx';

export const Title = styled(AlertDialog.Title, {
  base: {
    mb: '1.125rem',
    fontSize: '1.125',
    fontWeight: 'bold',
    fontFamily: 'dm-sans',
    lineHeight: '140%',
    color: 'neutral.500',
    textAlign: 'center',
  },
});

export const Text = styled(AlertDialog.Description, {
  base: {
    textAlign: 'center',
    mb: '1.25rem',
    fontSize: '1rem',
    lineHeight: '140%',
    fontFamily: 'open-sans',
    color: 'neutral.400',

    '& > a': {
      color: 'purple.500',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
});

export const Image = styled('img', {
  base: {
    width: { base: '10.625rem', md: '13rem' },
    height: { base: '10.625rem', md: '13rem' },
    margin: '0 auto',
  },
});
