import { gql } from '@apollo/client';

export const GET_USER_AVAILABLE_HOURS = gql`
  query getUserAvailableHours(
    $filterBySelectedAvailableHours: Boolean
    $filters: AvailableHourFiltersInput
  ) {
    user {
      id
      __typename
      availableHours(
        filterBySelectedAvailableHours: $filterBySelectedAvailableHours
        filters: $filters
      ) {
        id
        __typename
        weekday
        startAt
        endAt
        discipline
        status
        statusDescription
        preApprovedFamily
        allocatedInClinicalCase
        createdAt
        updatedAt
        location {
          id
          __typename
          name
          category
        }
        child {
          id
        }
        selectedAvailableHours {
          id
          status
          rejectedReason
          rejectedReasonDescription
          createdAt
          updatedAt
        }
      }
    }
  }
`;
