import { FileFolderBlock, Typography, pxToRem } from '@genialcare/atipico-react';
import React, { useState } from 'react';
import * as S from './Details.styles';
import { ParentalTrainingNote, ParentalTrainingNoteAuthor } from 'types/parentalTrainingNote';
import { convertDateToPtBr } from 'utils/date';
import { useParams } from 'react-router-dom';
import { GET_PARENTAL_TRAINING_NOTE_BY_SESSION_ID } from 'queries/parentalTraining/getParentalTrainingNoteBySessionId';
import useAuthorizedQuery from 'hooks/useAuthorizedQuery';
import { Session } from 'types';
import { Toast } from 'components/Toast';
import { Banner } from 'components/Banner';
import { GET_REQUEST_ERROR_MESSAGE } from 'constants/error-messages';

type Data = {
  session: Session;
};

type Variables = Record<string, unknown>;

export const Details: React.FC = () => {
  const { sessionId } = useParams();
  const [errorMessage, setMessageError] = useState<string>('');

  const { loading, data } = useAuthorizedQuery<Data, Variables>(
    GET_PARENTAL_TRAINING_NOTE_BY_SESSION_ID,
    {
      variables: { sessionId },
      fetchPolicy: 'cache-and-network',
      onError: () => setMessageError(GET_REQUEST_ERROR_MESSAGE),
    },
  );

  const parentalTrainingNote = data?.session?.parentalTrainingNote;
  const sessionOccurrenceDate = data?.session?.finalStartedAt || data?.session.startScheduledAt;

  const formatDate = (date: string) => {
    return convertDateToPtBr(date, { dateStyle: 'short' });
  };

  const formatNoteAuthor = (author: ParentalTrainingNoteAuthor, date: string) => {
    return `Por ${author.clinician.name} (${
      author.clinician.professionalRegistrationNumber || 'XXX'
    }) em ${formatDate(date)}`;
  };

  const renderTemplate = (note: ParentalTrainingNote) => {
    if (note.sessionGoals || note.nextSessionAgreements) {
      return (
        <>
          <S.AttributeTitle variant="h5" weight="bold" css={{ marginTop: pxToRem(40) }}>
            1 - Objetivos da sessão
          </S.AttributeTitle>

          <S.Text variant="body1">{note.sessionGoals}</S.Text>

          <S.AttributeTitle variant="h5" weight="bold">
            2 - Anotações relevantes sobre a sessão
          </S.AttributeTitle>

          <S.Text variant="body1">
            <span dangerouslySetInnerHTML={{ __html: note.body }} />
          </S.Text>

          <S.AttributeTitle variant="h5" weight="bold">
            3 - Combinados para a próxima sessão
          </S.AttributeTitle>

          <S.Text variant="body1" css={{ marginBottom: '48px' }}>
            {parentalTrainingNote?.nextSessionAgreements || 'N/A'}
          </S.Text>
        </>
      );
    } else {
      return <S.TemplateForOldNotes dangerouslySetInnerHTML={{ __html: note.body }} />;
    }
  };

  return (
    <>
      <S.Header
        direction={{ '@initial': 'column', '@md': 'row' }}
        justify="space-between"
        align={{ '@initial': 'start', '@md': 'center' }}
      >
        <Typography variant="h4" weight="bold">
          Registros de Orientação parental
        </Typography>
      </S.Header>

      <S.Wrapper>
        {loading && (
          <>
            <S.SkeletonTitle />
            <S.SkeletonItem />
            <S.SkeletonItem />
            <S.SkeletonItem />
            <S.SkeletonItem />
          </>
        )}

        {!loading && parentalTrainingNote && sessionOccurrenceDate && (
          <>
            <S.NoteAuthor color={'neutral400'}>
              {formatNoteAuthor(parentalTrainingNote?.createdBy, parentalTrainingNote?.createdAt)}
            </S.NoteAuthor>
            <S.NoteTitle variant="h4" weight="bold">
              Sessão de orientação parental - {formatDate(sessionOccurrenceDate)}
            </S.NoteTitle>
            <S.Divider />

            {renderTemplate(parentalTrainingNote)}
          </>
        )}

        <Toast open={!!errorMessage}>
          <Banner
            closable
            type="error"
            Icon={FileFolderBlock}
            handleClose={() => setMessageError('')}
          >
            {errorMessage}
          </Banner>
        </Toast>
      </S.Wrapper>
    </>
  );
};

export default Details;
