import React from 'react';
import {
  CardBody,
  CardFooter,
  CardTitle,
  CardContextualizable,
  CardDescription,
  CardScheduledAt,
} from 'components/Card';
import { type TaskContext } from 'types';
import { TaskCard } from '../TaskCard';
import type { ComposeTaskCardProps } from '../TaskCard.types';
import { buildURL } from 'utils/build-urls';
import { getTaskContextDescription } from 'utils/tasks';

type GenericTaskCardProps = ComposeTaskCardProps<{
  title: string;
  dueDate?: string;
  context: Pick<TaskContext, 'id' | 'type' | 'details' | 'clinicalCase'> | null;
  description?: string;
}>;

export const GenericTaskCard: React.FC<GenericTaskCardProps> = ({
  title,
  context,
  dueDate,
  description,
  ...props
}) => {
  const contextUrl = context ? buildURL.toTaskContextualizable(context) : null;

  return (
    <TaskCard title={title} {...props}>
      <CardBody>
        <CardTitle overflowTextLineValue={3}>{title}</CardTitle>
        <CardContextualizable onClickUrlRedirect={contextUrl}>
          {getTaskContextDescription(context)}
        </CardContextualizable>
        {description && <CardDescription text={description} maxLines={1} />}
      </CardBody>

      <CardFooter>
        <CardScheduledAt date={dueDate} format="short" defaultText="Sem data limite" />
      </CardFooter>
    </TaskCard>
  );
};
