import React, { PropsWithChildren } from 'react';
import * as S from './CardContextualizable.styles';

type CardContextualizableProps = PropsWithChildren & {
  prefix?: string;
  onClickUrlRedirect?: string | null;
};

export const CardContextualizable: React.FC<CardContextualizableProps> = ({
  prefix = 'em',
  onClickUrlRedirect,
  children,
}) => {
  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    return window.open(onClickUrlRedirect || '', '_blank');
  };
  const isLink = !!onClickUrlRedirect;
  const linkProps = isLink ? { onClick: handleCardClick } : {};

  return (
    <S.Root as="span" variant="body2" data-testid="card-contextualizable">
      {prefix}{' '}
      <S.Title as="span" variant="body2" {...linkProps} isLink={isLink}>
        {children}
      </S.Title>
    </S.Root>
  );
};
