import React from 'react';
import { Flex, type CSS, Typography } from '@genialcare/atipico-react';
import { Tooltip } from 'components/Tooltip';
import TooltipIcon from 'assets/images/tooltip-icon.svg';
import * as S from './EmptyList.styles';
import type { IconContainerVariants } from './EmptyList.styles';
import { useDevice } from 'hooks/useDevice';

type EmptyListProps = IconContainerVariants & {
  message: string;
  message2?: string;
  tooltipText?: string;
  Icon?: React.FC;
  css?: CSS;
  direction?: 'column' | 'row';
  align?: 'center' | 'start' | 'end';
};

export const EmptyList: React.FC<EmptyListProps> = ({
  message,
  message2,
  tooltipText,
  Icon,
  css,
  iconSize,
  iconColor,
  iconShape = 'circle',
  direction = 'column',
  align = 'center',
}) => {
  const { isSmallScreen } = useDevice();

  const textAlign = {
    center: 'center',
    start: 'left',
    end: 'right',
  }[align];

  return (
    <S.Root css={css} direction={direction} align={align} data-testid="empty-list">
      {Icon && (
        <S.IconContainer
          iconSize={iconSize}
          iconColor={iconColor}
          iconShape={iconShape}
          align="center"
          justify="center"
          data-testid="empty-list-icon"
        >
          <Icon />
        </S.IconContainer>
      )}
      <Flex direction="column" align={align} css={{ width: '90%', gap: 4 }}>
        <Flex direction="row" css={{ gap: 4 }}>
          <Typography variant="body2" color="neutral400" css={{ textAlign }}>
            {message}
          </Typography>
          {tooltipText && !isSmallScreen && (
            <>
              {' '}
              <Tooltip
                data-testid="empty-list-tooltip"
                triggerIcon={<S.Icon src={TooltipIcon} />}
                colorScheme="purple100"
                contentColorScheme="blue300"
              >
                <Tooltip.Text> {tooltipText} </Tooltip.Text>
              </Tooltip>
            </>
          )}
        </Flex>
        <Typography variant="body2" color="neutral400" css={{ textAlign }}>
          {message2 && message2}
        </Typography>
      </Flex>
    </S.Root>
  );
};
