import { gql } from '@apollo/client';

export const CREATE_AND_ASSOCIATE_ASSESSMENT_NOTE_TO_SESSION = gql`
  mutation createAndAssociateAssessmentNoteToSession(
    $clinicalCaseId: ID!
    $sessionId: ID!
    $request: CreateAndAssociateAssessmentNoteToSessionInput!
  ) {
    createAndAssociateAssessmentNoteToSession(
      clinicalCaseId: $clinicalCaseId
      sessionId: $sessionId
      request: $request
    ) {
      id
    }
  }
`;
