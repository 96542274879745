import { pxToRem, styled, Typography } from '@genialcare/atipico-react';

export const Wrapper = styled('div', {
  width: '100%',
  height: '100%',
  minHeight: '50vh',
  margin: 'auto',
  display: 'grid',
  placeContent: 'center',
  textAlign: 'center',
});

export const Image = styled('img', {
  width: pxToRem(70),
  height: pxToRem(70),
  margin: 'auto',
});

export const Text = styled(Typography, {
  marginTop: pxToRem(16),
});
