import { gql } from '@apollo/client';

export const CREATE_AND_ASSOCIATE_INTERVENTION_NOTE_TO_SESSION_INPUT = gql`
  mutation (
    $clinicalCaseId: ID!
    $sessionId: ID!
    $discipline: String!
    $request: CreateAndAssociateInterventionNoteToSessionInput!
  ) {
    createAndAssociateInterventionNoteToSession(
      clinicalCaseId: $clinicalCaseId
      sessionId: $sessionId
      discipline: $discipline
      request: $request
    ) {
      id
      createdAt
      updatedAt
    }
  }
`;
