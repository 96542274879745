import { graphql } from 'msw';

export default graphql.query('getClinicalCaseGuidances', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        id,
        clinicalGuidances: [
          {
            id: '930f0a46-7354-4281-8f37-d28e3b86d842',
            context: {
              clinicalCase: { id },
            },
            createdBy: {
              id: 'created-by-id',
              name: 'Caio Menezes',
            },
            title: 'Arthur ainda parece estar com piolho.',
            content:
              'Lorem ipsum dolor sit amet consectetur. Nisl morbi aenean nunc arcu adipiscing. Cursus proin magna egestas nec in quam iaculis. Interdum vestibulum non vitae nisl venenatis ridiculus duis pellentesque. Sit aliquam enim augue volutpat porttitor sagittis. Tellus malesuada ac facilisis lorem venenatis sit elementum. Sed facilisi molestie ultrices vivamus lorem eu ipsum eget proin. Nisi a cras ornare a dapibus in euismod semper',
            status: 'OPEN',
            priority: 'HIGH',
            category: 'NOTICE',
            createdAt: '2023-02-08T21:59:51.416Z',
            updatedAt: '2023-02-08T21:59:51.416Z',
            comments: [
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique.',
                createdAt: '2023-02-08T21:59:51.416Z',
                updatedAt: '2023-02-10T21:59:51.416Z',
                createdBy: {
                  name: 'Caroline Batina',
                },
              },
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique, Amet eu adipiscing massa diam arcu ultrices molestie.',
                createdAt: '2023-02-09T21:59:51.416Z',
                updatedAt: '2023-02-09T21:59:51.416Z',
                createdBy: {
                  name: 'Caio Menezes',
                },
              },
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Lorem ipsum dolor sit amet consectetur. Consequat sed faucibus enim suscipit arcu morbi nam. Vitae feugiat fringilla massa sed enim metus. Vehicula blandit adipiscing faucibus et bibendum.',
                createdAt: '2023-02-11T21:59:51.416Z',
                updatedAt: '2023-02-11T21:59:51.416Z',
                createdBy: {
                  name: 'Maria Luiza',
                },
              },
            ],
          },
          {
            id: 'ad222fde-64f6-411a-a9fa-5afdc8a0e8b4',
            context: {
              type: 'INTERVENTION_SESSION',
              id: 'cbd5e480-662e-41c4-9019-fdbf6b386c01',
              details: {
                title: 'Arthur não mandou mto bem hoje não',
                __typename: 'InterventionSessionDetails',
              },
              clinicalCase: { id },
            },
            createdBy: {
              id: 'created-by-id',
              name: 'Isadora Melo',
            },
            title: 'Arthur precisou de mais ajuda do que o normal na sessão.',
            content:
              'Lorem ipsum dolor sit amet consectetur. Nisl morbi aenean nunc arcu adipiscing. Cursus proin magna egestas nec in quam iaculis. Interdum vestibulum non vitae nisl venenatis ridiculus duis pellentesque. Sit aliquam enim augue volutpat porttitor sagittis. Tellus malesuada ac facilisis lorem venenatis sit elementum. Sed facilisi molestie ultrices vivamus lorem eu ipsum eget proin. Nisi a cras ornare a dapibus in euismod semper',
            status: 'OPEN',
            priority: 'HIGH',
            category: 'DISCUSSION',
            createdAt: '2023-02-08T21:59:51.416Z',
            updatedAt: '2023-02-08T21:59:51.416Z',
            comments: [
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique.',
                createdAt: '2023-02-08T21:59:51.416Z',
                updatedAt: '2023-02-10T21:59:51.416Z',
                createdBy: {
                  name: 'Caroline Batina',
                },
              },
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique, Amet eu adipiscing massa diam arcu ultrices molestie.',
                createdAt: '2023-02-09T21:59:51.416Z',
                updatedAt: '2023-02-09T21:59:51.416Z',
                createdBy: {
                  name: 'Caio Menezes',
                },
              },
              {
                id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
                content:
                  'Lorem ipsum dolor sit amet consectetur. Consequat sed faucibus enim suscipit arcu morbi nam. Vitae feugiat fringilla massa sed enim metus. Vehicula blandit adipiscing faucibus et bibendum.',
                createdAt: '2023-02-11T21:59:51.416Z',
                updatedAt: '2023-02-11T21:59:51.416Z',
                createdBy: {
                  name: 'Maria Luiza',
                },
              },
            ],
          },
        ],
      },
    }),
  );
});
