import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationProvider } from 'contexts/notification';
import { Icon } from './Icon';
import { NotificationDate } from './NotificationDate';
import { NotificationProps } from './Notification.types';
import * as S from './Notification.styles';

export const Notification: React.FC<NotificationProps> = ({
  notifications = [],
  showIconBadgeCounter = false,
  id,
  read,
  icon,
  href,
  date,
  onSelect,
  children,
}) => {
  const navigate = useNavigate();

  const handleOnSelect = () => {
    onSelect?.(id);
    navigate(href);
  };

  return (
    <S.Root onSelect={handleOnSelect}>
      <S.Wrapper alignItems="flex-start">
        <Icon
          read={read}
          icon={icon}
          showBadgeCounter={showIconBadgeCounter}
          notificationsCount={notifications.length}
        />

        <S.Content flexDir="column">
          <NotificationProvider read={read}>{children}</NotificationProvider>

          {date && <NotificationDate date={date} />}
        </S.Content>

        {!read && <S.NewBadge data-testid="unread-badge-notification" />}
      </S.Wrapper>
    </S.Root>
  );
};
