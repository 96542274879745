import { gql } from '@apollo/client';
import { PENDENCY_FRAGMENT_WITH_CONTEXT_DETAIL } from './fragments/pendencyFragment';

export const GET_USER_PENDENCIES = gql`
  ${PENDENCY_FRAGMENT_WITH_CONTEXT_DETAIL}

  query getUserPendencies($category: PendencyCategory) {
    user {
      id
      pendencies(category: $category) {
        ...PendencyFieldsWithContextDetail
        __typename
      }
      __typename
    }
  }
`;
