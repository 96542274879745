import { styled, pxToRem, css } from '@genialcare/atipico-react';

export const baseStyle = (colorScheme: string, variant?: 'outline') =>
  css({
    backgroundColor: variant == 'outline' ? 'transparent' : `$${colorScheme}`,
    color: variant == 'outline' ? `$${colorScheme}` : '$neutral0',
    border: variant == 'outline' ? `2px solid $${colorScheme}` : 'none',
    '&:hover': {
      backgroundColor: `$${colorScheme}`,
      border: 'none',
      color: '$neutral0',
      opacity: 0.8,
    },
  })();

export const GhostButton = styled('button', {
  padding: 0,
  margin: 0,
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
});

export const Root = styled('button', {
  display: 'grid',
  borderRadius: '50%',
  placeContent: 'center',
  cursor: 'pointer',
  width: pxToRem(20),
  height: pxToRem(20),
});

export const IconFallbackImage = styled(`span`, {
  fontSize: pxToRem(12),
  textShadow: '0px 1px, 1px 0px, 1px 1px',
});

export const baseIconStyle = (colorScheme: string, variant?: 'outline') =>
  css({
    'path[fill]': {
      fill: variant == 'outline' ? `$${colorScheme}` : 'white',
    },
  })();
