import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Typography } from '@genialcare/atipico-react';
import { ClinicalGuidanceStatus, ClinicalGuidancePriority, ClinicalGuidanceCategory } from 'types';
import { useAuthenticatedUser } from 'contexts/authenticatedUser';
import { useDevice } from 'hooks/useDevice';
import { AlertDialog } from 'components/AlertDialog';
import { Loading } from 'components/Loading';
import { StatusLabel, HighPriorityLabel } from 'components/ClinicalGuidance';
import {
  Heading,
  ResolveButton,
  CommentList,
  CommentForm,
  DropdownMenu,
  TaskList,
} from './components';
import type { ModalHookPropsReturn } from './useModal';
import * as S from './Modal.styles';
import { logError } from 'utils/logger';

type ModalProps = Omit<ModalHookPropsReturn, 'handleGetClinicalGuidance'>;

export const Modal: React.FC<ModalProps> = ({
  open,
  data,
  error,
  loading,
  updateQuery,
  handleOpenModal,
  updateClinicalGuidance,
  updateClinicalGuidanceState,
  createClinicalGuidanceComment,
  createClinicalGuidanceCommentState,
}) => {
  const navigate = useNavigate();
  const authenticatedUser = useAuthenticatedUser();
  const { isSmallScreen } = useDevice();
  const [showConfirmResolve, setShowConfirmResolve] = useState(false);

  const alertDialogProps = {
    open,
    closable: true,
    className: S.rootStyles,
    onOpenChange: (closeState: boolean) => {
      const urlSearchParams = new URLSearchParams(window.location.search);

      if (urlSearchParams.has('clinicalGuidanceId')) {
        urlSearchParams.delete('clinicalGuidanceId');
        navigate(`${window.location.pathname}?${urlSearchParams}`);
      }

      handleOpenModal(closeState);
    },
  };

  if (loading) {
    return (
      <AlertDialog {...alertDialogProps}>
        <Loading text="Carregando discussão clínica..." />
      </AlertDialog>
    );
  }

  if (error) {
    logError(error, {
      component: 'ClinicalGuidanceModal',
      query: 'GET_CLINICAL_GUIDANCE',
    });

    return (
      <AlertDialog {...alertDialogProps}>
        <Typography variant="h5" color="neutral400" css={{ textAlign: 'center' }}>
          Erro ao carregar discussão
        </Typography>
      </AlertDialog>
    );
  }

  if (!open || !data) return null;

  const userId = authenticatedUser.id;
  const {
    id,
    title,
    content,
    status,
    priority,
    category,
    createdBy,
    createdAt,
    updatedAt,
    context,
    tasks = [],
    comments = [],
  } = data.clinicalGuidance;

  const discussionIsOpen = status === ClinicalGuidanceStatus.Open;
  const isHighPriority = priority === ClinicalGuidancePriority.High;
  const isDiscussion = category === ClinicalGuidanceCategory.Discussion;

  const showLabelsOnMobile = showConfirmResolve && isSmallScreen;
  const showDiscussionActions = discussionIsOpen;
  const showStatus = !showConfirmResolve || showLabelsOnMobile;
  const showPriority =
    (!showConfirmResolve && isHighPriority && isDiscussion) || showLabelsOnMobile;

  const handleResolveClinicalGuidance = () => {
    const clinicalGuidanceInput = {
      status: ClinicalGuidanceStatus.Resolved,
    };

    updateClinicalGuidance(id, clinicalGuidanceInput);
  };

  const redirectToClinicalGuidanceEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const path = isDiscussion ? 'discussion' : 'notice';
    const url = `/panel/clinical-cases/${context.clinicalCase.id}/clinical-guidances/${id}/${path}/edit`;

    navigate(url);
  };

  return (
    <AlertDialog {...alertDialogProps}>
      <Heading category={category} />

      <S.Content direction="column" data-testid="clinical-guidance-modal-content">
        <S.ContentSection
          justify="space-between"
          align={{
            '@initial': 'start',
            '@md': 'center',
          }}
          direction={{ '@initial': 'column', '@md': 'row' }}
        >
          <S.ContentInfo direction="column" data-confirm-resolve-opened={showConfirmResolve}>
            <Flex
              direction={{
                '@initial': 'column-reverse',
                '@md': 'row',
              }}
              align={{
                '@initial': 'start',
                '@md': 'center',
              }}
              css={{ gap: 12 }}
            >
              <Typography variant="h5" weight="bold" color="neutral500">
                {title}
              </Typography>

              <Flex css={{ gap: 12, marginRight: 12 }}>
                {showStatus && (
                  <StatusLabel status={status} data-testid="clinical-guidance-label" />
                )}

                {showPriority && <HighPriorityLabel />}
              </Flex>
            </Flex>

            <Flex css={{ gap: 6 }}>
              <Typography variant="subtitle" color="neutral400">
                Criado por
              </Typography>
              <Typography variant="subtitle" color="purple500" weight="bold">
                {createdBy.name}
              </Typography>
              {createdAt !== updatedAt && (
                <Typography
                  variant="subtitle"
                  color="neutral400"
                  data-testid="clinical-guidance-edited-label"
                >
                  (Editado)
                </Typography>
              )}
            </Flex>
          </S.ContentInfo>

          {showDiscussionActions && (
            <S.Actions align="center" css={{ gap: 12 }}>
              <ResolveButton
                data-testid="clinical-guidance-resolve-button"
                opened={showConfirmResolve}
                isLoading={updateClinicalGuidanceState.loading}
                handleOpenConfirm={() => setShowConfirmResolve(true)}
                handleCloseConfirm={() => setShowConfirmResolve(false)}
                handleResolveClinicalGuidance={handleResolveClinicalGuidance}
              />
              {!showConfirmResolve && (
                <DropdownMenu
                  handleToArchive={() => null}
                  handleOnEdit={redirectToClinicalGuidanceEdit}
                />
              )}
            </S.Actions>
          )}
        </S.ContentSection>

        <Typography
          variant="body2"
          color="neutral400"
          css={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}
        >
          {content}
        </Typography>
      </S.Content>

      <S.ContentItems
        direction={{
          '@initial': 'column',
          '@md': 'row',
        }}
      >
        <S.CommentsContainer direction="column">
          {Boolean(comments?.length) && (
            <CommentList userId={userId} clinicalGuidanceId={id} data={comments} />
          )}

          {discussionIsOpen && (
            <CommentForm
              clinicalGuidanceId={id}
              isCreating={createClinicalGuidanceCommentState.loading}
              handleCreateComment={createClinicalGuidanceComment}
            />
          )}
        </S.CommentsContainer>

        <TaskList
          userId={userId}
          tasks={tasks}
          clinicalGuidanceId={id}
          clinicalCaseId={context.clinicalCase.id}
          onDeleted={(taskId) => {
            updateQuery(({ clinicalGuidance }) => {
              const currentTasks = clinicalGuidance.tasks ?? [];
              const filteredTasks = currentTasks.filter((task) => task.id !== taskId);

              return {
                clinicalGuidance: {
                  ...clinicalGuidance,
                  tasks: filteredTasks,
                },
              };
            });
          }}
        />
      </S.ContentItems>
    </AlertDialog>
  );
};
