import { useEffect } from 'react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import { useFirebase } from 'contexts/firebase';
import { useParams } from 'react-router-dom';
import { useUserData } from 'contexts/user';

type EventConfig = {
  withUserEmail?: boolean;
};

type AnalyticsEvents = {
  name: string;
  data?: Record<string, unknown>;
  eventConfig?: EventConfig;
};

export const useAnalyticsEvents = () => {
  const { id = '' } = useParams();
  const { analytics } = useFirebase();
  const user = useUserData();

  useEffect(() => {
    if (!user.email) return;

    setUserProperties(analytics, {
      user_email: user?.email,
    });
  }, [analytics, user]);

  const sendEvent = (params: AnalyticsEvents) => {
    const { name, data = {}, eventConfig = {} } = params;

    sendAnalyticEvent(name, data, eventConfig);
  };

  const sendAnalyticEvent = (
    eventName: string,
    eventParams?: Record<string, unknown>,
    eventConfig?: EventConfig,
  ) => {
    const { withUserEmail = true } = eventConfig || {};
    const defaultEventData = {
      clinical_case_id: id,
      ...eventParams,
    };

    const event = withUserEmail
      ? { ...defaultEventData, user_email: user.email }
      : defaultEventData;

    return logEvent(analytics, eventName, event);
  };

  return {
    sendAnalyticEvent,
    sendEvent,
  };
};
