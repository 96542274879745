import { useContext, createContext } from 'react';

type CardMenuContextType = {
  dropdownOpened: boolean;
  toggleDropdown: () => void;
  closeDropdown: () => void;
};

export const CardMenuContext = createContext<CardMenuContextType | null>(null);

export const useCardMenu = () => {
  const context = useContext(CardMenuContext);

  if (!context) {
    throw new Error('useCardMenu must be used within an CardMenuContext');
  }

  return context;
};
