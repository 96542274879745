import { gql } from '@apollo/client';

export const CLINICAL_GUIDANCE_TASK_FRAGMENT = gql`
  fragment ClinicalGuidanceTaskFields on ClinicalGuidanceTask {
    id
    status
    overdue
    title
    description
    dueDate
    assignee {
      id
      name
    }
    createdBy {
      id
      name
    }
    context {
      id
      type
      clinicalCase {
        id
        name
      }
    }
    __typename
  }
`;
