import React from 'react';
import { FooterItem, IconLogout } from './MenuFooter.styles';
import { MenuItem } from './MenuItem';
import { useAuth0 } from '@auth0/auth0-react';

export const MenuFooter = ({ isOpen }: { isOpen: boolean }) => {
  const { logout } = useAuth0();

  return (
    <FooterItem isOpen={isOpen}>
      <MenuItem
        to="/"
        Icon={IconLogout}
        text="Sair"
        isOpen={isOpen}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      />
    </FooterItem>
  );
};
