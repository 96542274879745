import React, { createContext, useEffect, useContext, PropsWithChildren } from 'react';
import { UserCredential } from 'firebase/auth';
import { deleteApp, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getMessaging } from 'firebase/messaging';
import { useFirebaseAuth } from 'hooks/useFirebaseAuth';
import { getFirestore, initializeFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export type FirebaseContextType = {
  firebaseApp: ReturnType<typeof initializeApp>;
  performance: ReturnType<typeof getPerformance>;
  analytics: ReturnType<typeof getAnalytics>;
  messaging: ReturnType<typeof getMessaging>;
  firestore: ReturnType<typeof getFirestore>;
  familySupportCenterFirestore: ReturnType<typeof getFirestore>;
  userCredential: UserCredential | null | undefined;
};

const FirebaseContext = createContext<FirebaseContextType | null>(null);

const FAMILY_SUPPORT_CENTER_DATABASE_ID = 'family-support-center';

export const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const firebaseApp = initializeApp(firebaseConfig);
  const [firestore, setFirestore] = React.useState<ReturnType<typeof getFirestore>>(
    initializeFirestore(firebaseApp, {
      experimentalAutoDetectLongPolling: true,
      ignoreUndefinedProperties: true,
    }),
  );
  const [familySupportCenterFirestore, setFamilySupportCenterFirestore] = React.useState<
    ReturnType<typeof getFirestore>
  >(
    initializeFirestore(
      firebaseApp,
      {
        experimentalAutoDetectLongPolling: true,
        ignoreUndefinedProperties: true,
      },
      FAMILY_SUPPORT_CENTER_DATABASE_ID,
    ),
  );
  const { userCredential } = useFirebaseAuth(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  const performance = getPerformance(firebaseApp);
  const messaging = getMessaging(firebaseApp);

  useEffect(() => {
    setFirestore(getFirestore(firebaseApp));
    setFamilySupportCenterFirestore(getFirestore(FAMILY_SUPPORT_CENTER_DATABASE_ID));
  }, [firebaseApp]);

  useEffect(() => {
    logEvent(analytics, 'app_view');
  }, [analytics, performance]);

  useEffect(() => {
    const unloadCallback = () => async () => {
      try {
        await deleteApp(firebaseApp);
      } catch {}
    };
    window.addEventListener('beforeunload', unloadCallback);

    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [firebaseApp]);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        performance,
        analytics,
        firestore,
        familySupportCenterFirestore,
        userCredential,
        messaging,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

function useFirebase(): FirebaseContextType {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error('useFirebase must be used within an FirebaseProvider');
  }

  return context;
}

export { FirebaseContext, useFirebase };
