import React from 'react';
import * as S from './CardScheduledAt.styles';
import { convertDateToPtBr, dayjs } from 'utils/date';

type CardScheduledAtProps = {
  date?: string;
  format?: 'short' | 'long';
  defaultText?: string;
};

const formatDueDateTextShort = (date?: string, defaultText = '-') => {
  if (date) {
    return `${convertDateToPtBr(date, { dateStyle: 'short' })}`;
  }

  return defaultText;
};

const formatDueDateTextLong = (date?: string, defaultText = '-') => {
  return date
    ? dayjs
        .utc(date.replace('Z', '-0300'))
        .tz('America/Sao_Paulo')
        .format('DD/MM/YYYY [às] HH[h]mm')
    : defaultText;
};

export const CardScheduledAt: React.FC<CardScheduledAtProps> = ({
  date,
  format = 'long',
  defaultText,
}) => {
  const scheduledAtText =
    format === 'short'
      ? formatDueDateTextShort(date, defaultText)
      : formatDueDateTextLong(date, defaultText);

  return (
    <S.Root justify="center" align="center">
      <S.Icon />
      <S.Text variant="body2" color="purple500">
        {scheduledAtText}
      </S.Text>
    </S.Root>
  );
};
