import React from 'react';
import * as S from './TextArea.styles';

type TextAreaErrorMessageProps = {
  children: string | React.ReactNode | React.ReactNode[];
};

export const TextAreaErrorMessage = React.forwardRef<HTMLSpanElement, TextAreaErrorMessageProps>(
  ({ children, ...props }, ref) => {
    return (
      <S.TextErrorMessageRoot {...props} ref={ref}>
        {children}
      </S.TextErrorMessageRoot>
    );
  },
);
