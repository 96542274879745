import { gql } from '@apollo/client';

export const GET_INTERVENTION_SESSION = gql`
  query getInterventionSession($id: ID!, $clinicalCaseId: ID!) {
    interventionSession(id: $id, clinicalCaseId: $clinicalCaseId) {
      id
      discipline
      skillAcquisitionNotes {
        id
        body
        createdAt
        clinician {
          name
          professionalRegistrationNumber
        }
        targetGroup {
          name
        }
      }
    }
  }
`;
