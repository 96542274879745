import { gql } from '@apollo/client';

export const UPDATE_PEI_TARGET = gql`
  mutation updatePeiTarget($target: UpdateTarget!, $clinicalCaseId: ID!) {
    updatePeiTarget(target: $target, clinicalCaseId: $clinicalCaseId) {
      id
      name
      status
      data {
        ... on TrialTargetData {
          currentPromptId
        }
      }
    }
  }
`;
