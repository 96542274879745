import React, { PropsWithChildren } from 'react';
import * as S from './Link.styles';

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  PropsWithChildren &
  S.LinkPropsVariants;

export const Link: React.FC<LinkProps> = ({ color = 'neutral0', children, ...props }) => (
  <S.Root {...props} color={color}>
    {children}
  </S.Root>
);
