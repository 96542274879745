import {
  pxToRem,
  styled,
  Typography,
  Button as ButtonDefault,
  Flex as FlexDefault,
} from '@genialcare/atipico-react';
import { TextArea as TextAreaDefault } from 'components/Form/TextArea';

export const FieldGroup = styled('div', {
  marginBottom: pxToRem(40),
});

export const HelperText = styled(Typography, {
  marginTop: pxToRem(8),
});

export const TextArea = styled(TextAreaDefault, {
  marginTop: pxToRem(16),
  height: pxToRem(140),

  '@xs': {
    height: pxToRem(150),
  },
});

export const Button = styled(ButtonDefault, {
  '@xs': {
    width: '100%',
  },
  '@md': {
    width: '50%',
  },
  '@lg': {
    width: 'inherit',
  },
});

export const Flex = styled(FlexDefault, {
  gap: 16,
  '@xs': {
    marginTop: 32,
  },
  '@lg': {
    marginTop: 64,
  },
});
