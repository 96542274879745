import React, { useState } from 'react';
import { css } from 'styled-system/css';
import { AlertDialog, AlertDialogBody } from 'components/AlertDialog';
import { Wrapper } from 'components/Wrapper';
import * as S from './Form.styles';
import { FileFolderBlock, Flex, Typography, pxToRem } from '@genialcare/atipico-react';
import { useForm } from 'react-hook-form';
import { ParentalTrainingForm } from 'components/SessionForms/ParentalTrainingForm';
import Thinking from 'assets/images/illustrations/thinking.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthorizedMutation from 'hooks/useAuthorizedMutation';
import { CREATE_PARENTAL_TRAINING_NOTE } from 'queries/parentalTraining/createParentalTrainingNote';
import { logError } from 'utils/logger';
import { Toast } from '@radix-ui/react-toast';
import { Banner } from 'components/Banner';
import { Form as DefaultForm } from 'components/Form/Form';
import { useFormAutoSave } from 'hooks/useFormAutoSave';
import { Loading } from 'components/Loading';
import { DraftStatus } from 'components/Form/DraftStatus';
import { GET_SESSION_BY_ID } from 'queries';
import useAuthorizedQuery from 'hooks/useAuthorizedQuery';
import { convertDateStrToFormat } from 'utils/date';
import { Session } from 'types';
import { useClinicalCase } from 'contexts/clinicalCase';
import { AutoSaveStatuses } from 'types/hooks';

type GetSessionResult = {
  session: Session;
};

type GetSessionVariable = {
  sessionId: string;
};

export type ParentalTrainingNoteFormValues = {
  sessionGoals: string;
  body?: string;
  nextSessionAgreements: string;
};

type MutateResponse = {
  createParentalTrainingNote: {
    id: string;
    sessionId: string;
  };
};

const SUCCESS_MESSAGE =
  'Seu registro da Orientação Parental foi salvo com sucesso. Você pode visualizá-lo ao clicar no link disponível na página da sessão.';

export const Form: React.FC = () => {
  const [openCancelAlert, setOpenCancelAlert] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { id = '', sessionId = '' } = useParams();
  const navigate = useNavigate();
  const { name: clinicalCaseName } = useClinicalCase();

  const { data: sessionData, loading: sessionLoading } = useAuthorizedQuery<
    GetSessionResult,
    GetSessionVariable
  >(GET_SESSION_BY_ID, {
    variables: {
      sessionId,
    },
    onError: (err) => {
      logError(err, {
        component: 'Session/Note',
        query: 'GET_SESSION_BY_ID',
      });
    },
  });

  const { mutate, loading, error } = useAuthorizedMutation(CREATE_PARENTAL_TRAINING_NOTE);

  const methods = useForm<ParentalTrainingNoteFormValues>({
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { autoSaveStatus } = useFormAutoSave<ParentalTrainingNoteFormValues>(methods, {
    clinicalCaseId: id,
    sessionId,
    collectionPrefix: 'parental-training-session-note',
  });

  const handleSave = async () => {
    const { sessionGoals, body, nextSessionAgreements }: ParentalTrainingNoteFormValues =
      methods.getValues();

    try {
      const { data } = await mutate({
        variables: {
          clinicalCaseId: id,
          parentalTrainingNote: {
            sessionId,
            sessionGoals,
            body,
            nextSessionAgreements,
          },
        },
        options: {
          onCompleted: () =>
            navigate(`/panel/clinical-cases/${id}/sessions/${sessionId}/details`, {
              state: { showSuccess: true, successMessage: SUCCESS_MESSAGE },
            }),
        },
      });

      return (data as MutateResponse)?.createParentalTrainingNote;
    } catch (err) {
      setShowError(true);
      logError(err as Error, {
        component: 'Sessions/ParentalTrainingNote',
        mutation: 'CREATE_PARENTAL_TRAINING_NOTE',
      });
    }
  };

  const sessionDate = convertDateStrToFormat(
    'DD/MM/YYYY [às] HH:mm',
    sessionData?.session.finalStartedAt || '',
  );
  const confirmationAlertText = `Tem certeza que deseja salvar esse registro da sessão de ${sessionDate} referente ao caso ${clinicalCaseName}?`;

  if (sessionLoading) return <Loading />;

  return (
    <>
      <Toast open={showError} duration={1000}>
        <Banner
          type="error"
          Icon={FileFolderBlock}
          closable
          handleClose={() => setShowError(false)}
        >
          Ocorreu um erro ao salvar o registro. Por favor, tente novamente.
          <br />
          Erro: {error?.message}
        </Banner>
      </Toast>
      <AlertDialog
        open={openCancelAlert}
        onOpenChange={setOpenCancelAlert}
        className={css({ maxW: '25rem' })}
      >
        <AlertDialogBody>
          <S.Image src={Thinking} alt="Pai não surpreso" />
          <Typography variant="h4" weight="bold" css={{ textAlign: 'center' }}>
            Tem certeza que deseja sair dessa página?
          </Typography>
          <Typography variant="body2" css={{ textAlign: 'center' }}>
            Verifique se os registros da sessão estão salvos no rascunho, os dados não salvos serão
            perdidos.
          </Typography>
        </AlertDialogBody>
        <Flex justify="center" css={{ gap: pxToRem(12), padding: pxToRem(10) }}>
          <S.CancelButton>Voltar</S.CancelButton>
          <S.ConfirmButton
            onClick={() => navigate(`/panel/clinical-cases/${id}/sessions/${sessionId}/details`)}
          >
            Sair
          </S.ConfirmButton>
        </Flex>
      </AlertDialog>

      <S.Title variant="h4" weight="bold">
        Criar nova anotação da sessão - {sessionDate}
      </S.Title>
      <Wrapper>
        {autoSaveStatus === AutoSaveStatuses.LOADING ? (
          <Loading />
        ) : (
          <>
            <DraftStatus status={autoSaveStatus} />
            <DefaultForm
              name={{
                subject: 'parental_training_note',
              }}
              titleText={confirmationAlertText}
              onSave={handleSave}
              formMethods={methods}
              showConfirmation
            >
              <ParentalTrainingForm />
              <S.Flex justify="end" wrap={{ '@xs': 'wrap-reverse', '@md': 'no-wrap' }}>
                <S.Button
                  colorScheme="purple"
                  variant="outline"
                  isDisabled={loading}
                  data-testid="cancel-form-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenCancelAlert(true);
                  }}
                >
                  Cancelar
                </S.Button>
                <S.Button
                  colorScheme="purple"
                  isDisabled={loading}
                  isLoading={loading}
                  data-testid="save-form-button"
                >
                  Salvar
                </S.Button>
              </S.Flex>
            </DefaultForm>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Form;
