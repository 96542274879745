import React, { useState, useEffect } from 'react';
import { Flex } from 'styled-system/jsx';
import { Button, Typography } from '@genialcare/atipico-react';
import { Toast } from 'components/Toast';
import { Banner } from 'components/Banner';
import { register } from '../../serviceWorkerRegistration';

type ServiceWorkerRegistrationProps = {
  defaultOpen?: boolean;
};

export const ServiceWorkerRegistration: React.FC<ServiceWorkerRegistrationProps> = ({
  defaultOpen = false,
}) => {
  const [show, setShow] = useState(defaultOpen);
  const [serviceWorker, setServiceWorker] = useState<ServiceWorker | null>(null);

  const onUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    serviceWorker?.postMessage({ type: 'SKIP_WAITING' });
  };

  useEffect(() => {
    register({
      onUpdate: (registration) => {
        setShow(true);
        setServiceWorker(registration.waiting);

        registration.waiting?.addEventListener('statechange', (e) => {
          if ((e.target as ServiceWorker).state === 'activated') {
            window.location.reload();
          }
        });
      },
    });
  }, []);

  return (
    <Toast open={show}>
      <Banner type="info">
        <Flex gap="24px" alignItems="center">
          <Typography variant="body2" weight="bold" color="purple500">
            Uma nova versão do Painel Clínico está disponível
          </Typography>

          <Button css={{ minWidth: 80 }} onClick={onUpdate}>
            Atualizar
          </Button>
        </Flex>
      </Banner>
    </Toast>
  );
};
