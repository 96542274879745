import { graphql } from 'msw';

export default graphql.query('getClinicalGuidanceRegistries', (req, res, ctx) => {
  const { clinicalCaseId = '' } = req.variables;

  if (clinicalCaseId === 'error') {
    return res(
      ctx.delay(3000),
      ctx.errors([
        {
          message: 'Erro ao buscar registros de orientações clínicas.',
        },
      ]),
    );
  }

  return res(
    ctx.delay(3000),
    ctx.data({
      clinicalGuidanceRegistries: [
        {
          id: '8221414a-d0a7-4be7-a648-91ff2546a0dd',
          status: 'OPEN',
          statusDescription: 'Aberto',
          createdAt: '2023-02-08T21:59:51.416Z',
          updatedAt: '2023-02-08T21:59:51.416Z',
          subjects: [
            {
              id: '3f66c2b0-543f-4832-bd0e-1d1d973eeedc',
              text: 'Acrescentar novos alvos para estimular brincadeiras motoras em conjunto com alvos que estimulem a identificação para reforço de fala.',
              status: 'OPEN',
              statusDescription: 'Aberto',
              createdAt: '2023-02-08T22:33:51.416Z',
              updatedAt: '2023-02-08T22:33:51.416Z',
              clinicalDecision: null,
              createdBy: {
                id: '359f4800-8684-412a-a38c-38fdbcfacb79',
                name: 'Felipe Moraes',
              },
              updatedBy: {
                id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                name: 'Joana Dark',
              },
            },
            {
              id: '6701ca6c-04a9-4ad6-8aa5-1f1d2fd7ba64',
              text: 'Diminuir dica de falar o nome do terapeuta.',
              status: 'DONE',
              statusDescription: 'Terminado',
              createdAt: '2023-02-10T21:59:51.416Z',
              updatedAt: '2023-02-12T21:59:51.416Z',
              clinicalDecision: {
                id: '6701ca6c-04a9-4ad6-8aa5-1f1d2fd7ba64',
                text: 'Conseguimos pensar em novas formas de estimular a fala e a motricidade.',
                createdAt: '2023-02-10T21:59:51.416Z',
                updatedAt: '2023-02-12T21:59:51.416Z',
                createdBy: {
                  id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                  name: 'Joana Dark',
                },
                updatedBy: {
                  id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                  name: 'Joana Dark',
                },
              },
              createdBy: {
                id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                name: 'Joana Dark',
              },
              updatedBy: {
                id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                name: 'Joana Dark',
              },
            },
          ],
          clinicalCase: {
            id: '506e07be-b6af-4585-8081-90681e3a957d',
          },
          createdBy: {
            id: '359f4800-8684-412a-a38c-38fdbcfacb79',
            name: 'Felipe Moraes',
          },
          updatedBy: {
            id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
            name: 'Joana Dark',
          },
        },
        {
          id: '8999390e-5bf1-4bf4-9e2f-04605adef08a',
          status: 'FINISHED',
          statusDescription: 'Finalizado',
          createdAt: '2023-02-08T21:59:51.416Z',
          updatedAt: '2023-02-08T21:59:51.416Z',
          subjects: [
            {
              id: 'f734c919-7d10-4cc1-84ee-0f0a65d35718',
              text: 'Tem engajado nas brincadeiras físicas e tem gostado. Tem dias que fica mais cansado e não engaja.',
              status: 'OPEN',
              statusDescription: 'Aberto',
              clinicalDecision: null,
              createdAt: '2023-02-08T22:33:51.416Z',
              updatedAt: '2023-02-08T22:33:51.416Z',
              createdBy: {
                id: '41500e24-342f-4f25-a873-622705c927af',
                name: 'Loius Lane',
              },
              updatedBy: {
                id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                name: 'Joana Dark',
              },
            },
            {
              id: '95e9c087-ca40-4b84-a4db-f33e478b05c2',
              text: 'Está fazendo conexões maiores.',
              status: 'DONE',
              statusDescription: 'Terminado',
              clinicalDecision: {
                id: '6701ca6c-04a9-4ad6-8aa5-1f1d2fd7ba64',
                text: 'Estimular conexões.',
                createdAt: '2023-02-10T21:59:51.416Z',
                updatedAt: '2023-02-12T21:59:51.416Z',
                createdBy: {
                  id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                  name: 'Joana Dark',
                },
                updatedBy: {
                  id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
                  name: 'Joana Dark',
                },
              },
              createdAt: '2023-02-10T21:59:51.416Z',
              updatedAt: '2023-02-12T21:59:51.416Z',
              createdBy: {
                id: 'baaeea8b-67a2-49c7-b24a-eef54ff05df1',
                name: 'Juliana Maria',
              },
              updatedBy: {
                id: '41500e24-342f-4f25-a873-622705c927af',
                name: 'Loius Lane',
              },
            },
          ],
          clinicalCase: {
            id: '506e07be-b6af-4585-8081-90681e3a957d',
          },
          createdBy: {
            id: 'fae56eef-9fc4-45d6-81f4-e2b574ddb601',
            name: 'Joana Dark',
          },
          updatedBy: {
            id: '359f4800-8684-412a-a38c-38fdbcfacb79',
            name: 'Felipe Moraes',
          },
        },
      ],
    }),
  );
});
