import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ThinkingSvg from 'assets/images/illustrations/thinking.svg';
import * as S from './EmptySkills.styles';

export const TweetsEmptySkills: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNavigate = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/panel/clinical-cases/${id}/overview`);
  };

  return (
    <S.Wrapper>
      <S.Image src={ThinkingSvg} />
      <S.Title variant="h4" color="purple500" weight="bold">
        Ops, não foi possível continuar!
      </S.Title>
      <S.Text variant="body2" color="neutral400">
        A criança ainda não tem nenhuma habilidade registrada. Por favor, aguarde o cadastro das
        habilidades para criar os tweets.
      </S.Text>
      <S.Button colorScheme="purple" onClick={handleNavigate}>
        Voltar para a visão geral
      </S.Button>
    </S.Wrapper>
  );
};

export default TweetsEmptySkills;
