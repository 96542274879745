import { DefaultContext, MutationHookOptions } from '@apollo/client';
import { Task, TaskStatus, UpdateTaskRequest } from 'types';
import { UPDATE_TASK } from 'queries';
import { logError } from 'utils/logger';
import { useAuthorizedMutation } from './useAuthorizedMutation';

type Data = {
  updateTask: Task;
};

type Variables = {
  taskId: string;
  task: UpdateTaskRequest;
};

export const useUpdateTask = (options?: MutationHookOptions<Data, Variables, DefaultContext>) => {
  const {
    mutate,
    data,
    error,
    loading: isUpdating,
  } = useAuthorizedMutation<Data, Variables>(UPDATE_TASK, options);

  const updateTask = async (taskId: string, task: UpdateTaskRequest) => {
    try {
      const response = await mutate({
        variables: {
          taskId,
          task,
        },
      });

      return response.data?.updateTask;
    } catch (e) {
      logError(e as Error, {
        hook: 'useUpdateTask',
        mutation: 'UPDATE_TASK',
      });
    }
  };

  const resolveTask = (taskId: string) => {
    return updateTask(taskId, {
      status: TaskStatus.Resolved,
    });
  };

  const reopenTask = (taskId: string) => {
    return updateTask(taskId, {
      status: TaskStatus.Open,
    });
  };

  const updatedTask = data?.updateTask;

  return {
    error,
    updatedTask,
    updateTask,
    resolveTask,
    reopenTask,
    isUpdating,
  };
};
