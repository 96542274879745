export enum FeedbackableTypes {
  InterventionNotesSummaries = 'INTERVENTION_NOTES_SUMMARIES',
}

export interface Feedback {
  id: string;
  feedbackableId: string;
  feedbackableType: FeedbackableTypes;
  liked: boolean;
  likedReason?: string;
  createdAt: string;
  updatedAt: string;
}
