import { css } from 'styled-system/css';
import { styled, pxToRem, Flex, CommunicationComment } from '@genialcare/atipico-react';

export const rootStyles = css({
  maxW: '1200px',
  borderRadius: '16px',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollBehavior: 'smooth',
});

export const Header = styled(Flex, {
  paddingBottom: pxToRem(14),
  marginBottom: pxToRem(14),
  borderBottom: '1px solid $neutral100',
});

export const CommentIcon = styled(CommunicationComment, {
  width: 30,
  height: 30,
  fill: '$purple500',
});

export const Content = styled(Flex, {
  gap: pxToRem(24),
  paddingBottom: pxToRem(34),
  borderBottom: '1px solid $neutral100',
});

export const ContentSection = styled(Flex, {
  gap: pxToRem(12),
});

export const ContentInfo = styled(Flex, {
  width: '100%',
  '@md': {
    '&[data-confirm-resolve-opened=true]': {
      width: '35%',
    },
    '&[data-confirm-resolve-opened=false]': {
      width: '70%',
    },
  },
});

export const ContentItems = styled(Flex, {
  width: '100%',
  gap: pxToRem(32),
  marginTop: pxToRem(36),
});

export const CommentsContainer = styled(Flex, {
  flex: '1 1 auto',
});

export const Actions = styled(Flex, {
  width: '100%',
  height: 'auto',
  margin: `${pxToRem(12)} 0`,
  '@md': {
    margin: 0,
    width: 'auto',
    height: pxToRem(72),
  },
});
