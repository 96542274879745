import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';

export const queueLink = new QueueLink();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BFF_API_URL,
});
const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => error?.networkError?.message === 'Failed to fetch',
  },
});
const serializingLink = new SerializingLink();

export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_BFF_API_URL,
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
    },
  }),
  link: ApolloLink.from([
    queueLink as unknown as ApolloLink,
    serializingLink as unknown as ApolloLink,
    retryLink,
    httpLink,
  ]),
});
