import React from 'react';
import {
  CardBody,
  CardFooter,
  CardTitle,
  CardDueDate,
  CardDescription,
  CardAssignee,
} from 'components/Card';
import { TaskCard } from '../TaskCard';
import type { ComposeTaskCardProps } from '../TaskCard.types';

type ClinicalGuidanceTaskCardProps = ComposeTaskCardProps<{
  title: string;
  dueDate?: string;
  description?: string;
}>;

export const ClinicalGuidanceTaskCard: React.FC<ClinicalGuidanceTaskCardProps> = ({
  title,
  dueDate,
  assignee,
  description,
  ...props
}) => (
  <TaskCard {...props} assignee={assignee} title={title}>
    <CardBody>
      <CardTitle overflowTextLineValue={3}>{title}</CardTitle>
      <CardDueDate date={dueDate} />

      {description && <CardDescription text={description} />}
    </CardBody>

    <CardFooter>
      <CardAssignee name={assignee.name} />
    </CardFooter>
  </TaskCard>
);
