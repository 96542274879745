import { styled, pxToRem, Button } from '@genialcare/atipico-react';

export const ClinicalGuidanceButton = styled(Button, {
  marginTop: pxToRem(8),
  fontWeight: '$semibold',
  textDecoration: 'underline',
});

export const DetailContent = styled('p', {
  fontSize: pxToRem(14),
  lineHeight: pxToRem(19),
  color: '$neutral400',
  marginBottom: pxToRem(24),
});
