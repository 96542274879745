import React, { useState, useEffect } from 'react';
import { TaskStatus } from 'types';
import { getTaskCardLabelData } from 'utils/get-task-card-label-data';
import { useUpdateTask } from 'hooks/useUpdateTask';
import { useDeleteTask } from 'hooks/useDeleteTask';
import { Card, CardHeader, CardLabel, CardMenu, CardMenuItem } from 'components/Card';
import { DeleteView } from './components/DeleteView';
import type { CardLabelProps } from 'components/Card';
import type { TaskCardProps } from './TaskCard.types';
import * as S from './TaskCard.styles';
import { pxToRem } from '@genialcare/atipico-react';

export const TaskCard: React.FC<TaskCardProps> = ({
  userId,
  id,
  title,
  status,
  createdBy,
  assignee,
  onDeleted,
  onTaskUpdated,
  children,
}) => {
  const { updatedTask, resolveTask, reopenTask, isUpdating } = useUpdateTask({
    fetchPolicy: 'no-cache',
    onCompleted: () => onTaskUpdated?.(),
  });
  const { deleteTask, isDeleting } = useDeleteTask({
    onCompleted: () => onDeleted?.(id),
  });
  const [deleteMode, setDeleteMode] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const { text, bg } = getTaskCardLabelData(currentStatus);
  const labelText = isUpdating ? 'Atualizando...' : text;

  const showCardMenu = userId === createdBy.id || userId === assignee.id;

  useEffect(() => {
    const updatedStatus = updatedTask?.status;

    if (updatedStatus) {
      setCurrentStatus(updatedStatus);
    }
  }, [updatedTask?.status]);

  if (deleteMode) {
    return (
      <DeleteView
        taskId={id}
        title={title}
        isDeleting={isDeleting}
        onDelete={deleteTask}
        onCancel={() => setDeleteMode(false)}
      />
    );
  }

  return (
    <Card css={{ minHeight: pxToRem(200) }}>
      <CardHeader>
        <CardLabel text={labelText} bg={bg as CardLabelProps['bg']} />

        {showCardMenu && (
          <CardMenu>
            {[TaskStatus.Open, TaskStatus.Overdue, TaskStatus.ToBeOverdue].includes(
              currentStatus,
            ) && (
              <CardMenuItem onClick={() => resolveTask(id)} icon={<S.StatusCheckedBox />}>
                Concluir
              </CardMenuItem>
            )}
            {currentStatus === TaskStatus.Resolved && (
              <CardMenuItem onClick={() => reopenTask(id)} icon={<S.InterfaceRefresh />}>
                Reabrir
              </CardMenuItem>
            )}
            <CardMenuItem
              colorScheme="red"
              icon={<S.InterfaceTrash />}
              onClick={() => setDeleteMode(true)}
            >
              Excluir
            </CardMenuItem>
          </CardMenu>
        )}
      </CardHeader>

      {children}
    </Card>
  );
};
