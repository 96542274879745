import { graphql } from 'msw';

export default graphql.mutation('createTask', (req, res, ctx) => {
  const {
    task: { title, description, dueDate, assignee, context },
  } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      createTask: {
        title,
        description,
        dueDate,
        status: 'OPEN',
        id: 'c9fc9033-eae5-4d7e-9a13-550903898064',
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
        assignee: {
          id: assignee.id,
          name: 'Genona',
        },
        context: {
          ...context,
          clinicalCase: {
            id: 'bae88bff-f90f-4e3d-94d9-5664df62b3d8',
          },
        },
        createdBy: {
          id: 'ad67591b-d9c4-41bc-b413-135b659003f5',
          name: 'Geninho',
        },
      },
    }),
  );
});
