import { gql } from '@apollo/client';
import { DEMAND_FIELDS } from './fragments/demandFragments';

export const GET_DEMANDS = gql`
  ${DEMAND_FIELDS}

  query getDemands($clinicalCaseId: ID!) {
    demands(clinicalCaseId: $clinicalCaseId) {
      ...DemandFields
    }
  }
`;
