import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_TASKS = gql`
  query getClinicalCaseTasks($id: ID!) {
    clinicalCase(id: $id) {
      id
      tasks {
        id
        status
        title
        description
        dueDate
        assignee {
          id
          name
        }
        createdBy {
          id
          name
        }
        context {
          id
          type
          clinicalCase {
            id
            name
          }
          details {
            ... on ClinicalGuidance {
              title
            }
            ... on ClinicalGuidanceRegistry {
              createdAt
              updatedAt
              status
              session {
                startScheduledAt
              }
            }
          }
        }
      }
    }
  }
`;
