import { graphql } from 'msw';

export default graphql.mutation('deleteTask', (_, res, ctx) =>
  res(
    ctx.delay(2000),
    ctx.data({
      deleteTask: null,
    }),
  ),
);
