import { useState } from 'react';

type UseToggleHookReturnProps = [boolean, () => void];

export const useToggle = (defaultValue = false): UseToggleHookReturnProps => {
  const [bool, setBool] = useState(defaultValue);

  const handleToggle = () => {
    setBool((current) => !current);
  };

  return [bool, handleToggle];
};
