import { styled, pxToRem, Flex, Button } from '@genialcare/atipico-react';

export const Form = styled(Flex, {
  gap: pxToRem(16),
  marginTop: pxToRem(24),
});

export const SubmitButton = styled(Button, {
  alignSelf: 'flex-end',
  minWidth: '100%',
  height: pxToRem(60),
  '@md': {
    minWidth: pxToRem(157),
    height: pxToRem(40),
  },
});
