import { DefaultContext, MutationHookOptions } from '@apollo/client';
import { ClinicalGuidanceComment } from 'types';
import { CREATE_CLINICAL_GUIDANCE_COMMENT } from 'queries';
import { useAuthorizedMutation } from './useAuthorizedMutation';

type Data = {
  createClinicalGuidanceComment: ClinicalGuidanceComment;
};

type Variables = {
  clinicalGuidanceId: string;
  content: string;
};

export const useCreateClinicalGuidanceComment = (
  options?: MutationHookOptions<Data, Variables, DefaultContext>,
) => {
  const { mutate, data, error, loading } = useAuthorizedMutation(
    CREATE_CLINICAL_GUIDANCE_COMMENT,
    options,
  );

  const mutation = (clinicalGuidanceId: string, content: string) => {
    return mutate({
      variables: {
        content,
        clinicalGuidanceId,
      },
    });
  };

  return {
    data: data?.createClinicalGuidanceComment,
    mutation,
    error,
    loading,
  };
};
