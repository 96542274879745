import React from 'react';
import { useNotifications } from 'hooks/useNotifications';
import { Notifications } from 'components/Notifications';

export const NotificationsContainer = () => {
  const { notifications, handleReadNotification, ...notificationsProps } = useNotifications();

  const handleOnSelectNotification = async (notificationId: string) => {
    await handleReadNotification(notificationId);
  };

  return (
    <Notifications
      {...notificationsProps}
      data={notifications}
      handleOnSelect={handleOnSelectNotification}
    />
  );
};
