import { styled, pxToRem } from '@genialcare/atipico-react';
import type { CSS } from '@genialcare/atipico-react';

export const Root = styled('li', {
  listStyle: 'inherit',

  '& li': {
    marginTop: pxToRem(12),
  },

  '& > ul': {
    marginTop: pxToRem(16),
    paddingInlineStart: pxToRem(8),
  },
});

export const descriptionStyle: CSS = {
  marginTop: pxToRem(8),
};
