import { useForm } from 'react-hook-form';

export type FormValues = {
  content: string;
};

type ClinicalGuidanceCommentFormHookProps = {
  value?: string;
  onSubmit: (data: FormValues) => void;
};

const MIN_COMMENT_SIZE = 5;

export const useClinicalGuidanceCommentForm = ({
  value,
  onSubmit,
}: ClinicalGuidanceCommentFormHookProps) => {
  const methods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      content: value,
    },
  });
  const { register, ...form } = methods;

  const getContentInputProps = () =>
    register('content', {
      required: true,
      minLength: MIN_COMMENT_SIZE,
    });

  return {
    ...form,
    getContentInputProps,
    handleSubmit: onSubmit,
    formMethods: methods,
  };
};
