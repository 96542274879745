import { gql } from '@apollo/client';
export const CLONE_PEI_OBJECTIVE = gql`
  mutation clonePEIObjective(
    $objective: ObjectiveInput!
    $clinicalCaseId: String!
    $originalObjectiveId: String!
  ) {
    clonePEIObjective(
      objective: $objective
      clinicalCaseId: $clinicalCaseId
      originalObjectiveId: $originalObjectiveId
    )
  }
`;
