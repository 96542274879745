import React, { PropsWithChildren, useEffect } from 'react';
import { Header } from '../Header';
import { useUserData } from '../../contexts/user';
import { useAuthenticatedUser } from '../../contexts/authenticatedUser';
import { SideMenu } from 'components/SideMenu';
import * as S from './Layout.styles';
import { Loading } from 'components/Loading';
import { EeselWidget } from 'components/EeselWidget';
import { FileCloudOff } from '@genialcare/atipico-react';
import { useNetworkAvailability } from 'contexts/networkAvailability';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { EESEL_WIDGET } from 'constants/flags';
import { useLocation } from 'react-router-dom';
import { useToast } from 'contexts/toast';

export type LayoutProps = PropsWithChildren & {
  loading?: boolean;
};

const excludedEeselWidgetPaths = (pathname: string) => {
  const pattern = /performance-measurement|sessions\/.*\/details/i;
  return pattern.test(pathname);
};

export const Layout: React.FC<LayoutProps> = ({ loading = false, children }) => {
  const toast = useToast();
  const { isOffline } = useNetworkAvailability();
  const eeselWidgetEnabled = useFeatureFlag(EESEL_WIDGET);
  const user = useUserData();
  const authenticatedUser = useAuthenticatedUser();
  const { pathname } = useLocation();
  const addEeselWidget = eeselWidgetEnabled && !excludedEeselWidgetPaths(pathname);

  useEffect(() => {
    const eeselWidget = document.getElementById('eesel-oracle-widget') as HTMLElement;
    if (eeselWidget && !addEeselWidget) {
      document.body.removeChild(eeselWidget);
      window.EeselAdded = false;
    }
  }, [addEeselWidget]);

  useEffect(() => {
    if (isOffline) {
      toast.error(
        'Parece que você está sem conexão à internet. Lembre-se de conectar novamente sua internet antes de enviar dados.',
        {
          duration: 3000,
          Icon: FileCloudOff,
        },
      );
    }
  }, [isOffline]);

  return (
    <S.Root>
      <SideMenu isClinicalCaseOwner={authenticatedUser.isClinicalCaseOwner} />

      <Header user={user} partnerInfo={authenticatedUser.partnerInfo} shadow />

      {loading ? <Loading /> : children}

      {addEeselWidget && <EeselWidget />}
    </S.Root>
  );
};
