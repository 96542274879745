import { styled, pxToRem, Typography, Button } from '@genialcare/atipico-react';

export const Root = styled(Typography, {
  marginTop: pxToRem(8),
  color: '$neutral400',
  '& a': {
    color: '$purple500',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const ToggleTextButton = styled(Button, {
  minWidth: 'auto',
  height: 0,
  padding: 0,
  border: 'none',
  marginTop: pxToRem(-3),
  marginLeft: pxToRem(4),
  fontSize: pxToRem(14),
});

export const Text = styled('span', {});
