import React from 'react';
import { SessionClinicalGuidance } from 'types';
import { pxToRem, Box, Typography } from '@genialcare/atipico-react';
import * as S from './ClinicalQuestions.styles';

type ClinicalQuestionsProps = {
  handleGetClinicalGuidance: (clinicalGuidanceId: string) => void;
  guidances?: SessionClinicalGuidance[];
  question?: string;
};

const Title = () => (
  <Typography weight="bold" variant="h5" css={{ fontSize: pxToRem(16) }}>
    Discussões clínicas
  </Typography>
);

export const ClinicalQuestions: React.FC<ClinicalQuestionsProps> = ({
  guidances = [],
  question,
  handleGetClinicalGuidance,
}) => {
  if (guidances.length) {
    return (
      <>
        <Title />
        {guidances.map(({ id, title }) => (
          <Box key={`clinical-guidance-${id}`}>
            <S.ClinicalGuidanceButton
              variant="link"
              css={{ fontSize: pxToRem(14) }}
              onClick={() => handleGetClinicalGuidance(id)}
            >
              {title}
            </S.ClinicalGuidanceButton>
          </Box>
        ))}
      </>
    );
  }

  if (question) {
    return (
      <>
        <Title />
        <S.DetailContent>{question}</S.DetailContent>
      </>
    );
  }

  return null;
};
