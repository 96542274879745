import { pxToRem, styled } from '@genialcare/atipico-react';
import { List } from '../../components';

export const Sublist = styled(List, {
  '&:not(:last-of-type)': {
    borderBottom: '1px solid $neutral100',
    paddingBottom: pxToRem(20),
    marginBottom: pxToRem(20),
  },
});

Sublist.defaultProps = {
  sublist: true,
};
