import {
  pxToRem,
  styled,
  Typography,
  Button as ButtonDefault,
  Flex as FlexDefault,
  Card as CardDefault,
} from '@genialcare/atipico-react';
import { TextArea as TextAreaDefault } from 'components/Form/TextArea';
import { Select as SelectDefault } from 'components/Form/Select';
import { Wrapper as WrapperDefault } from 'components/Wrapper';

export const Wrapper = styled(WrapperDefault, {
  borderRadius: pxToRem(16),
});

export const Title = styled(Typography, {
  marginTop: pxToRem(56),
  marginBottom: pxToRem(16),
});

export const FieldGroup = styled('div', {
  marginBottom: pxToRem(24),
});

export const HelperText = styled(Typography, {
  marginTop: pxToRem(8),
});

export const Select = styled(SelectDefault, {
  marginTop: pxToRem(16),
});

export const TextArea = styled(TextAreaDefault, {
  marginTop: pxToRem(16),
});

export const Button = styled(ButtonDefault, {
  '@xs': {
    width: '100%',
  },
  '@md': {
    width: '50%',
  },
  '@lg': {
    width: 'inherit',
  },
});

export const Flex = styled(FlexDefault, {
  gap: 16,
  '@xs': {
    marginTop: 32,
  },
  '@lg': {
    marginTop: 64,
  },
});

export const Tip = styled(FlexDefault, {
  gap: pxToRem(12),
});

export const Tweet = styled(CardDefault, {
  marginBottom: pxToRem(16),
  borderRadius: pxToRem(16),
});

export const TweetTitle = styled(Typography, {
  marginBottom: pxToRem(8),
});

export const TweetBody = styled(Typography, {
  marginBottom: pxToRem(16),
});

export const TweetAuthor = styled('p', {
  fontSize: pxToRem(12),
  lineHeight: '140%',
  fontStyle: 'italic',
});
