import { Session, SessionStatuses, SessionTypes, TaskContext, TaskContextType } from 'types';
import { URLSearchParams } from 'url';

const toClinicalSession = (
  clinicalCaseId: string,
  sessionId: string,
  preparedSession?: boolean,
) => {
  const queryParams = preparedSession !== undefined ? `?preparedSession=${preparedSession}` : '';

  return `/panel/clinical-cases/${clinicalCaseId}/sessions/${sessionId}/details${queryParams}`;
};

const toClinicalGuidancePlanningEdit = (clinicalGuidancePlanningId: string, register?: boolean) => {
  const queryParams = register ? `?register=true` : '';
  return `/panel/users/clinical-guidance-planning/${clinicalGuidancePlanningId}/edit${queryParams}`;
};

export const buildURL = {
  toCreateDemand: (clinicalCaseId: string) => `/panel/clinical-cases/${clinicalCaseId}/demands/new`,
  toDemands: (clinicalCaseId: string) => `/panel/clinical-cases/${clinicalCaseId}/demands`,
  toClinicalGuidance: (clinicalCaseId: string, clinicalGuidanceId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/clinical-guidances?clinicalGuidanceId=${clinicalGuidanceId}`,
  toClinicalCase: (clinicalCaseId: string) => `/panel/clinical-cases/${clinicalCaseId}/overview`,
  toClinicalGuidanceRegistry: (clinicalCaseId: string, registryId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/overview/registries/${registryId}`,
  toClinicalGuidancePlanning: () => `/panel/users/clinical-guidance-planning`,
  toClinicalGuidancePlanningEdit,
  toObservableLightForm: (clinicalCaseId: string, registryId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/overview/registries/${registryId}/observable-light-form`,
  toClinicalCaseDetails: (clinicalCaseId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/details`,
  toCoverPage: (clinicalCaseId: string) => `/panel/clinical-cases/${clinicalCaseId}/cover-page`,
  toClinicalCasePreferencesEdit: (clinicalCaseId: string, preferencesId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/preferences/${preferencesId}/edit`,
  toClinicalCaseWorkloadsEdit: (clinicalCaseId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/workloads/edit`,
  toPei: (clinicalCaseId: string) => `/panel/clinical-cases/${clinicalCaseId}/pei`,
  toClinicalSession,
  toClinicalSessionByStatus: (clinicalCaseId: string, session: Session) => {
    const sessionPrepared = ![SessionStatuses.Scheduled, SessionStatuses.Rescheduled].includes(
      session.status,
    );

    return toClinicalSession(clinicalCaseId, session.id, sessionPrepared);
  },
  toSessionPreparation: (clinicalCaseId: string, sessionId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/sessions/${sessionId}/preparation`,

  toCompleteSession: (clinicalCaseId: string, sessionId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/sessions/${sessionId}/complete`,
  toNoteSession: (clinicalCaseId: string, sessionId: string, sessionType: SessionTypes) => {
    const url: Record<string, string> = {
      INDIRECT_ASSESSMENT: `indirect-note`,
      DIRECT_ASSESSMENT: `direct-note`,
      FEEDBACK_ASSESSMENT: `feedback-note`,
      PARENTAL_TRAINING: `parental-training-note`,
    };

    const path = `/panel/clinical-cases/${clinicalCaseId}/sessions/${sessionId}`;
    if (url[sessionType]) {
      return `${path}/${url[sessionType]}`;
    }
    return `${path}/note`;
  },
  toSessionPlanning: (sessionId: string) => `/panel/users/session-planning/${sessionId}`,
  toPlannings: ({ queryParams }: { queryParams?: URLSearchParams } = {}) => {
    if (queryParams) {
      return `/panel/users/planning?${queryParams.toString()}`;
    }

    return `/panel/users/planning`;
  },
  toPlanning: (clinicalCaseId: string, planningId: string) =>
    `/panel/users/clinical-case/${clinicalCaseId}/planning/${planningId}`,
  toTasksByClinicalCase: (clinicalCaseId: string) =>
    `/panel/users/tasks?clinicalCaseId=${clinicalCaseId}`,

  toTaskContextualizable: (context: TaskContext) => {
    const contextType = context?.type;
    switch (contextType) {
      case TaskContextType.ClinicalCase:
        return buildURL.toClinicalCase(context?.id || '');
      case TaskContextType.ClinicalGuidance:
        return buildURL.toClinicalGuidance(context?.clinicalCase?.id || '', context?.id || '');
      case TaskContextType.Registry:
        return buildURL.toClinicalGuidanceRegistry(
          context?.clinicalCase?.id || '',
          context?.id || '',
        );
      default:
        if (context?.clinicalCase?.id && !context?.id) {
          return buildURL.toClinicalCase(context?.clinicalCase?.id);
        }
        return '';
    }
  },

  toFamilySupportConversation: (clinicalCaseId: string, conversationId: string) =>
    `/panel/users/family-support-center/${conversationId}/${clinicalCaseId}`,

  toVinelandReports: (clinicalCaseId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/assessments/vineland-reports`,
  toItemScores: (clinicalCaseId: string, reportId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/assessments/vineland-reports/${reportId}/item-scores`,
  toItemScoresRevision: (clinicalCaseId: string, reportId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/assessments/vineland-reports/${reportId}/item-scores-revision`,
  toItemScoresSuccess: (clinicalCaseId: string, reportId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/assessments/vineland-reports/${reportId}/item-scores-success`,
  toSupportLinks: (clinicalCaseId: string) =>
    `/panel/clinical-cases/${clinicalCaseId}/support-links`,
};
