import { styled, pxToRem, Flex } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  position: 'relative',
  flex: '0 0 auto',
  width: pxToRem(56),
  height: pxToRem(56),
  borderRadius: '100%',
  backgroundColor: '$neutral100',
  '& > svg': {
    width: pxToRem(32),
    height: pxToRem(32),
  },
  svg: {
    fill: '$purple500',
  },
  variants: {
    read: {
      true: {
        opacity: 0.6,
        svg: {
          fill: '$neutral400',
        },
      },
    },
  },
});
