import { gql } from '@apollo/client';

export const GET_CREATE_OBJECTIVE_FORM_FIELDS = gql`
  query GetCreateObjectiveFormFields {
    protocols {
      id
      name
      items {
        id
        description
        code
        domain
        subdomain
      }
    }
    skills {
      name
      id
    }
  }
`;
