import { styled, Flex } from 'styled-system/jsx';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';

export const Root = styled(DropdownMenuRoot.Item, {
  base: {
    p: '12px',
    borderRadius: '12px',

    '&[data-highlighted]': {
      bg: 'neutral.100',
      outlineColor: 'purple.100',
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&:hover [data-notification-icon]': {
      bg: 'neutral.200',
    },
  },
});

export const Wrapper = styled(Flex, {
  base: {
    gap: '16px',
  },
});

export const Content = styled(Flex, {
  base: {
    gap: '12px',
    flex: '1 1 auto',
  },
});

export const NewBadge = styled(Flex, {
  base: {
    flex: '0 0 auto',
    alignSelf: 'center',
    w: '8px',
    h: '8px',
    bg: 'purple.500',
    borderRadius: '100%',
  },
});
