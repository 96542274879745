import { styled, pxToRem, Flex } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  gap: pxToRem(8),
  width: '100%',
  maxWidth: '100%',
  padding: `${pxToRem(20)} ${pxToRem(16)}`,
  borderRadius: pxToRem(16),
  backgroundColor: '$neutral0',
  boxShadow: `0px ${pxToRem(2)} ${pxToRem(8)} rgba(0, 0, 0, 0.1)`,
  '@md': {
    width: pxToRem(406),
  },
});
