import React from 'react';
import { GeneralAlarm } from '@genialcare/atipico-react';
import { Notification, ComposeNotificationProps } from '../Notification';
import { getTaskContext, getTaskHref } from './common';
import { TaskDueNotification } from 'types/notification';
import { useTranslation } from 'react-i18next';

type TaskDueProps = ComposeNotificationProps<TaskDueNotification>;

export const TaskDue: React.FC<TaskDueProps> = ({
  clinicalCaseId,
  clinicalCaseName,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  registryId,
  sessionDate,
  dueDays,
  taskTitle,
  sentAt,
  ...props
}) => {
  const { t } = useTranslation('common');
  const href = getTaskHref({ clinicalCaseId, clinicalGuidanceId, registryId });
  const titleComplement = getTaskContext({
    clinicalCaseName,
    clinicalGuidanceTitle,
    registryId,
    sessionDate,
  });

  return (
    <Notification {...props} date={sentAt} href={href} icon={<GeneralAlarm />}>
      <Notification.Title>
        Você tem <Notification.TitleHighlight>uma tarefa a vencer </Notification.TitleHighlight>
        {titleComplement}
      </Notification.Title>
      <Notification.Subtitle>
        Vence em {t('days.daysText', { count: dueDays })}: '{taskTitle}'
      </Notification.Subtitle>
    </Notification>
  );
};
