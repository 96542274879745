import React, { useState, useRef } from 'react';
import { GeneralFilter } from '@genialcare/atipico-react';
import { Flex } from 'styled-system/jsx';
import { NotificationType } from 'types';
import { UseNotificationHookProps } from 'hooks/useNotifications';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { SelectDropdownItem } from 'components/Form/SelectDropdownItem';
import * as S from './Filter.styles';

type FilterProps = Pick<
  UseNotificationHookProps,
  'selectedNotificationTypes' | 'handleSetNotificationTypes'
>;

const filterOptions = [
  {
    text: 'Comunicações',
    values: [
      NotificationType.ClinicalGuidanceCreated,
      NotificationType.HighPriorityClinicalGuidanceCreated,
      NotificationType.NoticeClinicalGuidanceCreated,
      NotificationType.ClinicalGuidanceCommentAdded,
      NotificationType.HighPriorityClinicalGuidanceCommentAdded,
      NotificationType.NoticeClinicalGuidanceCommentAdded,
    ],
  },
  {
    text: 'Tarefas',
    values: [NotificationType.TaskCreated, NotificationType.TaskResolved, NotificationType.TaskDue],
  },
  {
    text: 'Anotação clínica',
    values: [NotificationType.UncompletedExpiredSession],
  },
  {
    text: 'Sessões',
    values: [
      NotificationType.SessionCancelled,
      NotificationType.SessionScheduleMadeOfficial,
      NotificationType.OfficialSessionScheduleDeleted,
    ],
  },
  {
    text: 'Genial junto',
    values: [
      NotificationType.FamilySupportConversationMessageCreated,
      NotificationType.HighPriorityFamilySupportConversationMessageCreated,
    ],
  },
];

export const Filter: React.FC<FilterProps> = ({
  selectedNotificationTypes,
  handleSetNotificationTypes,
}) => {
  const [showContent, setShowContent] = useState(false);
  const filterContentRef = useRef<HTMLDivElement>(null);

  const handleToggleContent = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowContent((current) => !current);
  };

  useOnClickOutside(filterContentRef, () => {
    setShowContent(false);
  });

  return (
    <Flex ref={filterContentRef} position="relative">
      <S.FilterButton
        data-testid="notification-filter-trigger"
        variant="outline"
        open={showContent}
        active={Boolean(selectedNotificationTypes.length)}
        icon={<GeneralFilter />}
        onClick={handleToggleContent}
        css={S.filterButtonBaseStyle}
      />

      {showContent && (
        <S.FilterContent>
          {filterOptions.map(({ text, values }) => (
            <SelectDropdownItem
              key={`filter-option-${text}`}
              text={text}
              value={values.join(',')}
              checked={values.some((value) => selectedNotificationTypes.includes(value))}
              onSelect={() => {
                handleSetNotificationTypes(values);
              }}
            />
          ))}
        </S.FilterContent>
      )}
    </Flex>
  );
};
