import React from 'react';
import { logError } from 'utils/logger';
import { AccessDenied, GenericError, NotFound as NotFoundComponent } from 'components/Errors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export enum ErrorType {
  NotFound = 'notFound',
  Permission = 'permission',
  Generic = 'generic',
}

type RouterProps = {
  router: {
    location: ReturnType<typeof useLocation>;
    navigate: ReturnType<typeof useNavigate>;
    params: ReturnType<typeof useParams>;
  };
};

type ErrorBoundaryProps = React.PropsWithChildren<RouterProps>;
type ErrorBoundaryState = {
  error: ErrorType | false;
};

function withRouter(Component: React.ComponentType<ErrorBoundaryProps>) {
  function ComponentWithRouterProp(props: React.PropsWithChildren) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const Errors = {
  [ErrorType.NotFound]: NotFoundComponent,
  [ErrorType.Permission]: AccessDenied,
  [ErrorType.Generic]: GenericError,
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    if (error.message.includes('access denied')) return { error: ErrorType.Permission };
    if (error.message.includes('resource not found')) {
      logError(error, { component: 'ErrorBoundary' });
      return { error: ErrorType.NotFound };
    }
    return { error: ErrorType.NotFound };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    logError(error, { component: 'ErrorBoundary', info });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({
        error: false,
      });
    }
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    const ErrorComponent = Errors[this.state.error] || GenericError;

    return <ErrorComponent />;
  }
}

export default withRouter(ErrorBoundary);
