import React from 'react';
import * as S from './Select.styles';

type SelectItemProps = {
  children: string | React.ReactNode | React.ReactNode[];
  isActive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SelectItem: React.FC<SelectItemProps> = ({ onClick, isActive, children, ...rest }) => (
  <S.Item onClick={onClick} isActive={isActive} {...rest}>
    {children}
  </S.Item>
);
