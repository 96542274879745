import React from 'react';
import { FileClipboardAlt } from '@genialcare/atipico-react';
import { TaskCreatedNotification } from 'types';
import { Notification, ComposeNotificationProps } from '../Notification';
import { getTaskContext, getTaskHref } from './common';

type TaskCreatedProps = ComposeNotificationProps<TaskCreatedNotification>;

export const TaskCreated: React.FC<TaskCreatedProps> = ({
  clinicalCaseId,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  registryId,
  clinicalCaseName,
  createdByName,
  taskTitle,
  createdAt,
  sessionDate,
  ...props
}) => {
  const href = getTaskHref({ clinicalCaseId, clinicalGuidanceId, registryId });
  const titleComplement = getTaskContext({
    clinicalCaseName,
    clinicalGuidanceTitle,
    registryId,
    sessionDate,
  });

  return (
    <Notification {...props} date={createdAt} href={href} icon={<FileClipboardAlt />}>
      <Notification.Title>
        {createdByName} <Notification.TitleHighlight>criou uma tarefa</Notification.TitleHighlight>{' '}
        para você {titleComplement}
      </Notification.Title>
      <Notification.Subtitle>'{taskTitle}'</Notification.Subtitle>
    </Notification>
  );
};
