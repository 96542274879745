import { graphql } from 'msw';

export default graphql.mutation('updateClinicalGuidance', (req, res, ctx) => {
  const {
    clinicalGuidanceId,
    clinicalGuidance: { title, content, status, priority, context },
  } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      updateClinicalGuidance: {
        id: clinicalGuidanceId,
        title,
        content,
        status,
        priority,
        context,
        category: 'DISCUSSION',
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
        createdBy: {
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
        },
      },
    }),
  );
});
