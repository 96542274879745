import { graphql } from 'msw';

export default graphql.query('getClinicalCaseSupportLinks', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        id,
        supportLinks: [
          {
            id: '730fc6e4-2e58-45d4-92b4-d94daf81e893',
            category: 'PEI',
            url: 'https://drive.google.com/drive',
            createdBy: {
              id: 'aa457ad8-c44a-11ed-afa1-0242ac120002',
              name: 'Lívia Maria',
            },
            createdAt: '2023-03-14T20:51:07.234Z',
          },
          {
            id: '0aa8bcb5-53f3-4ce0-9d77-5a22320b6854',
            category: 'Vídeo',
            url: 'https://www.youtube.com/',
            createdBy: {
              id: 'b3f9f7de-c44a-11ed-afa1-0242ac120002',
              name: 'Jéssica Silva',
            },
            createdAt: '2023-03-15T13:46:03.345Z',
          },
        ],
      },
    }),
  );
});
