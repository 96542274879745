import React from 'react';
import { Input } from '@genialcare/atipico-react';
import { MaskedRange } from 'imask';
import { useIMask } from 'react-imask';
import { dayjs } from 'utils/date';
import { useMergeRefs } from 'hooks/useMergeRefs';

type InputDate = React.ComponentProps<typeof Input> & {
  type: 'date' | 'datetime' | 'time';
  onAccept?: (value: Date) => void;
  onComplete?: (value: Date) => void;
};

const formats = {
  date: {
    pattern: 'DD/MM/YYYY',
    placeholder: 'dd/mm/aaaa',
  },
  datetime: {
    pattern: 'DD/MM/YYYY HH:mm',
    placeholder: 'dd/mm/aaaa 00:00',
  },
  time: {
    pattern: 'HH:mm',
    placeholder: '00:00',
  },
};

export const InputDate = React.forwardRef<HTMLSpanElement, InputDate>((props, forwardRef) => {
  const { type, onAccept, onComplete, ...rest } = props;
  const { ref: iMaskRef } = useIMask(
    {
      mask: Date,
      pattern: formats[type].pattern,
      format: function (date) {
        let day = String(date.getDate());
        let month = String(date.getMonth() + 1);
        const year = String(date.getFullYear());
        let hours = String(date.getHours());
        let minutes = String(date.getMinutes());

        if (Number(day) < 10) day = '0' + day;
        if (Number(month) < 10) month = '0' + month;
        if (Number(hours) < 10) hours = '0' + hours;
        if (Number(minutes) < 10) minutes = '0' + minutes;

        let formattedDate = [day, month, year].join('/');
        if (type === 'datetime') formattedDate += ` ${hours}:${minutes}`;
        if (type === 'time') formattedDate = `${hours}:${minutes}`;

        return formattedDate;
      },
      parse: (str) => dayjs(str, formats[type].pattern).toDate(),
      blocks: {
        YYYY: {
          mask: MaskedRange,
          from: 1990,
          to: 2100,
          maxLength: 4,
        },
        MM: {
          mask: MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        DD: {
          mask: MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        HH: {
          mask: MaskedRange,
          from: 0,
          to: 23,
          maxLength: 2,
        },
        mm: {
          mask: MaskedRange,
          from: 0,
          to: 59,
          maxLength: 2,
        },
      },
      min: new Date(1990, 0, 1),
      max: new Date(2100, 0, 1),
    },
    {
      onAccept: (value) => {
        onAccept?.(dayjs(value, formats[type].pattern).toDate());
        return value;
      },
      onComplete: (value) => {
        onComplete?.(dayjs(value, formats[type].pattern).toDate());
        return value;
      },
    },
  );
  const ref = useMergeRefs(iMaskRef, forwardRef);

  return <Input type="tel" ref={ref} placeholder={formats[type].placeholder} {...rest} />;
});
