import { gql } from '@apollo/client';

export const CREATE_CLINICAL_GUIDANCE = gql`
  mutation createClinicalGuidance($clinicalGuidance: CreateClinicalGuidanceInput!) {
    createClinicalGuidance(clinicalGuidance: $clinicalGuidance) {
      id
      title
      content
      status
      priority
      category
      createdAt
      updatedAt
      context {
        id
        type
        clinicalCase {
          id
        }
      }
      createdBy {
        id
        name
      }
    }
  }
`;
