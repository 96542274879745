import { styled, pxToRem, Flex, CommunicationComment } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  paddingBottom: pxToRem(14),
  marginBottom: pxToRem(14),
  borderBottom: '1px solid $neutral100',
});

export const CommentIcon = styled(CommunicationComment, {
  width: 30,
  height: 30,
  fill: '$purple500',
});
