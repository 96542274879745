import { gql } from '@apollo/client';

export const MARK_ITEM_SCORES_AS_SELECTED = gql`
  mutation markItemScoresAsSelected(
    $vinelandReportId: ID!
    $input: SelectItemsScoresRequestInput!
  ) {
    markItemScoresAsSelected(vinelandReportId: $vinelandReportId, input: $input) {
      id
      status
    }
  }
`;
