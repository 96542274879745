import { Flex } from '@genialcare/atipico-react';
import CalendarWithCheck from 'assets/images/calendar-with-check.svg';
import React from 'react';
import { SessionScheduleMadeOfficialNotification } from 'types';
import { ComposeNotificationProps, Notification } from '../Notification';
import { getSimplifiedWeekdayTranslation } from 'utils/weekdays';
import { convertStrFormatToPtBrDate } from 'utils/date';

type SessionScheduleMadeOfficialProps =
  ComposeNotificationProps<SessionScheduleMadeOfficialNotification>;

export const SessionScheduleMadeOfficial: React.FC<SessionScheduleMadeOfficialProps> = ({
  clinicalCaseName,
  startScheduledAt,
  endScheduledAt,
  coreSessionInitialDate,
  weekday,
  sentAt,
  ...props
}) => {
  return (
    <Notification href={`/panel/users/sessions`} icon={<img src={CalendarWithCheck} />} {...props}>
      <Notification.Title>
        Você tem novos <Notification.TitleHighlight>agendamentos</Notification.TitleHighlight> no
        caso {clinicalCaseName}, a partir do dia{' '}
        {convertStrFormatToPtBrDate(coreSessionInitialDate, 'DD.MM.YYYY', 'DD/MM')}.
      </Notification.Title>

      <Notification.Subtitle>
        'As sessões desse caso acontecerão às {getSimplifiedWeekdayTranslation(weekday)}s, das{' '}
        {startScheduledAt} até às {endScheduledAt} horas.'
      </Notification.Subtitle>

      <Flex>
        <Notification.Date date={sentAt} />
      </Flex>
    </Notification>
  );
};
