import { gql } from '@apollo/client';

export const DELETE_ATTACHMENT_FROM_REGISTRY_SUBJECT_MUTATION = gql`
  mutation ($registryId: ID!, $subjectId: ID!, $attachmentKey: String!) {
    deleteAttachmentFromSubject(
      registryId: $registryId
      subjectId: $subjectId
      attachmentKey: $attachmentKey
    )
  }
`;
