import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_GUIDANCE_REGISTRY = gql`
  query getClinicalGuidanceRegistry($id: ID!) {
    clinicalGuidanceRegistry(id: $id) {
      __typename
      id
      status
      content
      statusDescription
      tasks {
        id
        title
        description
        dueDate
        status
        createdAt
        updatedAt
        assignee {
          id
          name
        }
        createdBy {
          id
          name
        }
      }
      session {
        id
        status
        discipline
        startScheduledAt
        finalStartedAt
        sessionType
        clinicians {
          id
          name
        }
      }
      clinicalCase {
        id
      }
      subjects {
        id
        text
        status
        statusDescription
        createdAt
        updatedAt
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        clinicalDecision {
          id
          text
          createdAt
          updatedAt
          createdBy {
            id
            name
          }
          updatedBy {
            id
            name
          }
        }
        attachments {
          key
          filename
          contentType
          checksum
          serviceName
          byteSize
          url
          createdAt
        }
      }
      observableLightRegistrations {
        id
      }
      attachments {
        key
        filename
        contentType
        checksum
        serviceName
        byteSize
        url
        createdAt
      }
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
