import React from 'react';
import { IconButton } from '@genialcare/atipico-react';
import { useCardMenu } from 'contexts/cardMenu';
import * as S from './Trigger.styles';

export const Trigger = () => {
  const { toggleDropdown } = useCardMenu();

  const handleToggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleDropdown();
  };

  return (
    <IconButton
      data-testid="card-menu-trigger"
      css={S.buttonStyle}
      icon={<S.ButtonIcon />}
      onClick={handleToggleDropdown}
    />
  );
};
