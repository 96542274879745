import {
  pxToRem,
  styled,
  Typography,
  Button as ButtonDefault,
  Flex as FlexDefault,
} from '@genialcare/atipico-react';
import { AlertDialogCancelButton, AlertDialogConfirmButton } from 'components/AlertDialog';

export const Title = styled(Typography, {
  marginTop: pxToRem(42),
  marginBottom: pxToRem(16),
});

export const Button = styled(ButtonDefault, {
  '@xs': {
    width: '100%',
  },
  '@md': {
    width: '50%',
  },
  '@lg': {
    width: 'inherit',
  },
});

export const Flex = styled(FlexDefault, {
  gap: 16,
  '@xs': {
    marginTop: 32,
  },
  '@lg': {
    marginTop: 64,
  },
});

export const Image = styled('img', {
  width: '100%',
  height: pxToRem(160),
});

export const CancelButton = styled(AlertDialogCancelButton, {
  width: '100%',
  minWidth: pxToRem(170),
});

export const ConfirmButton = styled(AlertDialogConfirmButton, {
  width: '100%',
  minWidth: pxToRem(170),
});
