import React, { PropsWithChildren } from 'react';
import type { BoxProps } from 'styled-system/jsx';
import * as S from './Text.styles';
import type { TextVariants } from './Text.styles';

type BaseProps = BoxProps &
  TextVariants & {
    as?: React.ElementType;
    className?: string;
  };

type TextProps = PropsWithChildren<BaseProps>;

export const Text: React.FC<TextProps> = ({
  weight,
  className,
  as = 'p',
  variant = 'body1',
  color = 'neutral.500',
  children,
  ...props
}) => (
  <S.Root
    {...{ ...props, as }}
    weight={weight}
    variant={variant}
    color={color}
    className={className}
  >
    {children}
  </S.Root>
);
