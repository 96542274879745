import { gql } from '@apollo/client';

export const CREATE_SKILL_ACQUISITION_NOTE = gql`
  mutation createSkillAcquisitionNote(
    $skillAcquisitionNote: SkillAcquisitionNoteInput!
    $clinicalCaseId: String!
  ) {
    createSkillAcquisitionNote(
      skillAcquisitionNote: $skillAcquisitionNote
      clinicalCaseId: $clinicalCaseId
    )
  }
`;
