import React from 'react';
import { Flex, Typography } from '@genialcare/atipico-react';
import { ClinicalGuidanceCategory } from 'types';
import { useDevice } from 'hooks/useDevice';
import * as S from './Heading.styles';

type HeadingProps = {
  category: ClinicalGuidanceCategory;
};

const getHeadingText = (category: ClinicalGuidanceCategory, isSmallScreen: boolean) => {
  if (category === ClinicalGuidanceCategory.Notice) {
    return 'Aviso';
  }

  return isSmallScreen ? 'Discussão' : 'Discussão Clínica';
};

export const Heading: React.FC<HeadingProps> = ({ category }) => {
  const { isSmallScreen } = useDevice();
  const text = getHeadingText(category, isSmallScreen);

  return (
    <S.Root justify="space-between">
      <Flex align="center" css={{ gap: 8 }}>
        <S.CommentIcon data-testid="heading-icon" />

        <Typography variant="h5" color="purple500" weight="bold">
          {text}
        </Typography>
      </Flex>
    </S.Root>
  );
};
