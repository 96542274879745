import { useEffect, useState } from 'react';
import { useMutation, DefaultContext, MutationHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useAuth0 } from '@auth0/auth0-react';
import { logError } from 'utils/logger';

export const useAuthorizedMutation = <TData, TVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, DefaultContext>,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('');

  useEffect(() => {
    const authorizedMutation = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (e) {
        if ((e as Error).message !== 'Login required') {
          logError(e as Error);
        }
      }
    };

    authorizedMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentMutation, { data, loading, error, reset }] = useMutation(mutation, options);

  const mutate = async ({
    variables,
    options: mutateOptions,
  }: {
    variables: TVariables;
    options?: MutationHookOptions<TData, TVariables, DefaultContext>;
  }) => {
    let accessToken = token;

    if (!accessToken) {
      accessToken = await getAccessTokenSilently();
      setToken(accessToken);
    }

    return currentMutation({
      variables,
      ...mutateOptions,
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });
  };

  return {
    mutate,
    data,
    loading,
    error,
    token,
    reset,
  };
};

export default useAuthorizedMutation;
