import { gql } from '@apollo/client';

export const GET_FIRST_VINELAND_REPORT = gql`
  query getFirstVinelandReport($clinicalCaseId: ID!) {
    firstVinelandReport(clinicalCaseId: $clinicalCaseId) {
      id
      interviewRespondentName
      assessedAt
    }
  }
`;
