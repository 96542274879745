import { gql } from '@apollo/client';

export const GET_DISCIPLINE_FORM_DATA = gql`
  query getDisciplineFormData {
    strategies {
      id
      name
    }
    sensorialFunctions {
      id
      name
    }
    skills {
      id
      name
    }
  }
`;
