import { gql } from '@apollo/client';

export const GET_CLINICIANS_FROM_CLINICAL_CASE = gql`
  query getCliniciansFromClinicalCase($clinicalCaseId: ID!) {
    clinicalCase(id: $clinicalCaseId) {
      clinicians {
        id
        name
        user {
          id
        }
      }
    }
  }
`;
