import React, { useRef, useState, PropsWithChildren } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { CardMenuContext } from 'contexts/cardMenu';
import { Trigger } from './Trigger';
import * as S from './CardMenu.styles';
import { CSS } from '@genialcare/atipico-react';

type CardMenuProps = PropsWithChildren & {
  defaultOpen?: boolean;
  css?: CSS;
};

export const CardMenu: React.FC<CardMenuProps> = ({ defaultOpen = false, children, css }) => {
  const [dropdownOpened, setShowDropdown] = useState(defaultOpen);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setShowDropdown((current) => !current);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const contextProps = {
    dropdownOpened,
    toggleDropdown,
    closeDropdown,
  };

  useOnClickOutside(containerRef, closeDropdown);

  return (
    <S.Root ref={containerRef} css={css}>
      <CardMenuContext.Provider value={contextProps}>
        <Trigger />

        {dropdownOpened && <S.Content direction="column">{children}</S.Content>}
      </CardMenuContext.Provider>
    </S.Root>
  );
};
