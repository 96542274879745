import { datadogRum } from '@datadog/browser-rum';

if (['staging', 'production'].includes(process.env.REACT_APP_ENVIRONMENT || '')) {
  datadogRum.init({
    applicationId: 'f5443c26-0b27-44c8-8f42-cf71414db108',
    clientToken: 'pub0e4557f1d525f4fa8728cab646afbb36',
    site: 'datadoghq.com',
    service: 'clinical-panel',
    env: process.env.REACT_APP_ENVIRONMENT || 'staging',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version: process.env.REACT_APP_DEPLOY_VERSION,
    allowedTracingUrls: [/https:\/\/clinical-panel-bff.*\.genialcare\.com\.br/],
    enableExperimentalFeatures: ['feature_flags'],
    beforeSend: (event) => {
      const ignoredErrorsMessages = [
        'required to use the Firebase SDK. (messaging/unsupported-browser)',
        'Subscription failed - no active Service Worker',
        '401: Unauthorized',
        'Fetch is aborted',
      ];
      const ignoredErrors = ignoredErrorsMessages.some(
        (message) => event.type === 'error' && event.error.message.includes(message),
      );

      if (ignoredErrors) {
        return false;
      }
    },
  });
}

export const datadog = datadogRum;
