import { gql } from '@apollo/client';

export const PARENTAL_TRAINING_SESSION_FIELDS = gql`
  fragment ParentalTrainingSessionFields on Session {
    id
    clinicalCaseId
    startScheduledAt
    endScheduledAt
    finalStartedAt
    finalEndedAt
    status
    statusDescription
    sessionType
    sessionTypeDescription
    observation
    cancellationReason
    clinicians {
      id
      name
      professionalRegistrationNumber
    }
    cancellation {
      reason
      reasonDescription
      comment
      requestedByRoleDescription
      inAdvance
    }
    createdAt
    updatedAt
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
  }
`;
