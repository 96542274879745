import React, { useRef } from 'react';
import { pxToRem } from '@genialcare/atipico-react';
import * as S from './CardDescription.styles';
import DOMPurify from 'dompurify';
import { useLineClamp } from 'hooks/useLineClamp';

type CardDescriptionProps = {
  text: string;
  maxLines?: number;
};

export const CardDescription: React.FC<CardDescriptionProps> = ({ ...props }) => {
  const { text, maxLines = 3, ...otherProps } = props;
  const ref = useRef<HTMLSpanElement>(null);

  const { clamps, toggle, handleToggle } = useLineClamp(ref, maxLines);

  DOMPurify.addHook('uponSanitizeAttribute', (node) => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });

  const descriptionSanitized = DOMPurify.sanitize(text);

  return (
    <>
      <S.Root css={{ fontSize: pxToRem(12) }} {...otherProps}>
        <S.Text dangerouslySetInnerHTML={{ __html: descriptionSanitized }} ref={ref} />

        {clamps && (
          <S.ToggleTextButton variant="outline" onClick={handleToggle}>
            {`Ver ${toggle ? 'mais' : 'menos'}`}
          </S.ToggleTextButton>
        )}
      </S.Root>
    </>
  );
};
