import React from 'react';
import { match } from 'ts-pattern';
import { Flex, Typography, InterfaceEditAlt } from '@genialcare/atipico-react';
import { SessionTypes, UncompletedExpiredSessionNotification } from 'types';
import { formatPtBrDate } from 'utils/date';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type UncompletedExpiredSessionProps =
  ComposeNotificationProps<UncompletedExpiredSessionNotification>;

const sessionNameWithPrefix = (sessionName: string) => (sessionName ? `de ${sessionName}` : '');

const getSessionTypeName = (sessionType: SessionTypes) => {
  const type = sessionType.toUpperCase();
  const sessionName = match(type)
    .with(SessionTypes.Intervention, () => 'intervenção')
    .otherwise(() => '');

  return sessionNameWithPrefix(sessionName);
};

export const UncompletedExpiredSession: React.FC<UncompletedExpiredSessionProps> = ({
  sentAt,
  sessionId,
  sessionType,
  clinicalCaseId,
  clinicalCaseName,
  startScheduledAt,
  ...props
}) => {
  const notificationsCounter = (props.notifications ?? []).length;
  const hasNotifications = notificationsCounter > 1;
  const sessionName = getSessionTypeName(sessionType);
  const href = buildURL.toClinicalSession(clinicalCaseId, sessionId, true);
  const formatedScheduledAt = formatPtBrDate(startScheduledAt.toDate(), { dateStyle: 'short' });

  return (
    <Notification {...props} showIconBadgeCounter href={href} icon={<InterfaceEditAlt />}>
      <Notification.Title>
        Existe{' '}
        <Notification.TitleHighlight>
          uma sessão {sessionName} incompleta
        </Notification.TitleHighlight>{' '}
        no {clinicalCaseName}:
      </Notification.Title>

      <Notification.Subtitle>
        'Por favor conclua a sessão do dia {formatedScheduledAt}.'
      </Notification.Subtitle>

      <Flex>
        {hasNotifications && (
          <Typography
            as="h6"
            variant="subtitle"
            weight="bold"
            color="neutral300"
            data-testid="notification-counter-label"
          >
            {notificationsCounter} notificações -&nbsp;
          </Typography>
        )}

        <Notification.Date date={sentAt} />
      </Flex>
    </Notification>
  );
};
