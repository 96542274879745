import React from 'react';
import type { ThemeColors } from '@genialcare/atipico-react';
import { ClinicalGuidanceCategory } from 'types';
import { Label } from '../Label';

type CategoryLabelProps = {
  category: ClinicalGuidanceCategory;
};

const labelData = {
  [ClinicalGuidanceCategory.Notice]: {
    text: 'Aviso',
    bg: 'orange300',
  },
  [ClinicalGuidanceCategory.Discussion]: {
    text: 'Discussão',
    bg: 'blue300',
  },
};

export const CategoryLabel: React.FC<CategoryLabelProps> = ({ category, ...props }) => {
  const { text, bg } = labelData[category];

  return (
    <Label {...props} color="neutral0" bg={bg as ThemeColors}>
      {text}
    </Label>
  );
};
