import React, { useEffect } from 'react';
import { Typography } from '@genialcare/atipico-react';
import { TextArea } from 'components/Form/TextArea';
import * as S from './CommentForm.styles';
import { Form } from 'components/Form/Form';
import { useClinicalGuidanceCommentForm } from 'hooks/useClinicalGuidanceCommentForm';

type CommentFormProps = {
  isCreating: boolean;
  clinicalGuidanceId: string;
  handleCreateComment: (clinicalGuidanceId: string, content: string) => void;
};

export const CommentForm: React.FC<CommentFormProps> = ({
  handleCreateComment,
  clinicalGuidanceId,
  isCreating,
}) => {
  const {
    getContentInputProps,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    formMethods,
  } = useClinicalGuidanceCommentForm({
    onSubmit: ({ content }) => {
      return handleCreateComment(clinicalGuidanceId, content);
    },
  });

  const isLoading = isSubmitting || isCreating;
  const isDisabled = !isValid || isLoading;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  return (
    <Form
      formMethods={formMethods}
      onSave={handleSubmit}
      name={{
        subject: 'clinical_guidance',
      }}
    >
      <S.Form direction="column" data-testid="clinical-guidance-comment-form">
        <Typography variant="h5" weight="bold" color="neutral500">
          Escreva o seu comentário
        </Typography>

        <TextArea {...getContentInputProps()} rows={6} placeholder="Descreva aqui" />

        <S.SubmitButton isDisabled={isDisabled} isLoading={isLoading}>
          Enviar
        </S.SubmitButton>
      </S.Form>
    </Form>
  );
};
