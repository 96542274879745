import { gql } from '@apollo/client';
import { PENDENCY_FRAGMENT } from '../pendencies/fragments/pendencyFragment';

export const GET_SESSION_BY_ID = gql`
  ${PENDENCY_FRAGMENT}

  query getSessionById($sessionId: ID!) {
    session(id: $sessionId) {
      id
      clinicalCaseId
      discipline
      startScheduledAt
      endScheduledAt
      finished
      finalStartedAt
      finalEndedAt
      status
      statusDescription
      sessionType
      sessionTypeDescription
      observation
      performanceRegistry {
        status
        completedById
        completedAt
      }
      cancellationReason
      cancellation {
        reason
        reasonDescription
        comment
        requestedByRoleDescription
        inAdvance
      }
      createdAt
      updatedAt
      sessionable {
        ... on ClinicalGuidanceSessionable {
          registry {
            id
            status
          }
        }
        ... on ParentalTrainingSessionable {
          noteId
        }
        ... on AssessmentSessionable {
          noteId
        }
        ... on InterventionSessionable {
          id
          suggestedNote {
            healthChecks
            usedReinforcers
            challengingBehaviors
            summary
            observation
          }
          suggestedChildProgressionNote {
            body
          }
          evolutionCheck {
            id
            assessedAt
            assessedBy {
              id
              name
            }
          }
        }
      }
      location {
        label
        labelDescription
      }
      clinicians {
        id
        name
      }
      createdBy {
        id
        name
        email
      }
      updatedBy {
        id
        name
      }
      pendencies {
        ...PendencyFields
        __typename
      }
    }
  }
`;
