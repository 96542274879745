import React, { useContext, createContext, PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ROLES_NAMESPACE = 'https://genialcare.com.br/roles';

type UserContextType = {
  name: string;
  email: string;
  image: string;
  roles: string[];
};

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth0();
  const props = {
    name: user?.name ?? '',
    email: user?.email ?? '',
    image: user?.picture ?? '',
    roles: user?.[ROLES_NAMESPACE] || [],
  };

  return <UserContext.Provider value={props}>{children}</UserContext.Provider>;
};

export const useUserData = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserData must be used within an UserProvider');
  }

  return context;
};
