import { Box, styled, pxToRem } from '@genialcare/atipico-react';

export const Loading = styled(Box, {
  paddingTop: pxToRem(54),
  paddingBottom: pxToRem(54),
  alignItems: 'center',
  width: '100%',

  '@lg': {
    paddingTop: pxToRem(56),
    paddingBottom: pxToRem(56),
  },
});
