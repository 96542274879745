import { gql } from '@apollo/client';
import { ITEM_SCORE_FRAGMENT_WITH_PROTOCOL } from './fragments/itemScoreFragment';

export const GET_VINELAND_REPORT_BY_ID_WITH_ITEM_SCORES = gql`
  ${ITEM_SCORE_FRAGMENT_WITH_PROTOCOL}

  query getVinelandReportById(
    $clinicalCaseId: ID!
    $vinelandReportId: ID!
    $itemScoreStatuses: [ItemScoreStatuses]
  ) {
    vinelandReport(clinicalCaseId: $clinicalCaseId, vinelandReportId: $vinelandReportId) {
      id
      interviewRespondentName
      assessedAt
      peiSuggestions
      itemScores(itemScoreStatuses: $itemScoreStatuses) {
        ...ItemScoreWithProtocolFields
      }
      domainScores {
        id
        domain
        standardScore
        subdomainScores {
          id
          subdomain
          ageEquivalent
          vScaleScore
          gsv
        }
      }
    }
  }
`;
