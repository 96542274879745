import React, { useState } from 'react';
import { Menu } from './SideMenu.styles';
import { MenuHeader } from './components/MenuHeader';
import { MenuContent } from './components/MenuContent';
import { MenuFooter } from './components/MenuFooter';

type SideMenuProps = {
  isClinicalCaseOwner: boolean;
};

export const SideMenu: React.FC<SideMenuProps> = ({ isClinicalCaseOwner }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as="nav" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} data-testid="side-menu">
      <MenuHeader isOpen={isOpen} />
      <MenuContent isOpen={isOpen} isClinicalCaseOwner={isClinicalCaseOwner} />
      <MenuFooter isOpen={isOpen} />
    </Menu>
  );
};
