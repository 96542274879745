import { gql } from '@apollo/client';

export const DELETE_SUBJECT_CLINICAL_DECISION = gql`
  mutation deleteSubjectClinicalDecision(
    $registryId: ID!
    $subjectId: ID!
    $clinicalDecisionId: ID!
  ) {
    deleteSubjectClinicalDecision(
      registryId: $registryId
      subjectId: $subjectId
      clinicalDecisionId: $clinicalDecisionId
    )
  }
`;
