import { gql } from '@apollo/client';
import { CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT } from 'queries/clinicalGuidancePlanning/fragments/clinicalGuidancePlanningByTaskFields';
import { CLINICAL_GUIDANCE_TASK_FRAGMENT } from 'queries/clinicalGuidanceTask/fragments/taskFragment';

export const GET_USER_CLINICAL_GUIDANCE_TASKS_WITH_PLANNING = gql`
  ${CLINICAL_GUIDANCE_TASK_FRAGMENT}
  ${CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT}

  query getUserTasksWithClinicalGuidancePlanning(
    $statuses: [ClinicalGuidanceTaskStatus]
    $clinicalGuidancePlanningStatuses: [PlanningStatus]
    $orderBy: TaskOrderByInput
    $limit: Int
    $offset: Int
    $overdue: Boolean
  ) {
    user {
      id
      clinicalGuidanceTasks(
        statuses: $statuses
        clinicalGuidancePlanningStatuses: $clinicalGuidancePlanningStatuses
        orderBy: $orderBy
        limit: $limit
        offset: $offset
        overdue: $overdue
      ) {
        ...ClinicalGuidanceTaskFields
        ...ClinicalGuidancePlanningByTaskFields
        __typename
      }
    }
  }
`;
