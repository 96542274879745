import React, { PropsWithChildren, createContext, useContext } from 'react';

type NotificationContextProps = {
  read: boolean;
};

type NotificationProviderProps = PropsWithChildren & NotificationContextProps;

const NotificationContext = createContext<NotificationContextProps | null>(null);

export const useNotificationData = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotificationData must be used within an NotificationProvider');
  }

  return context;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ read, children }) => (
  <NotificationContext.Provider value={{ read }}>{children}</NotificationContext.Provider>
);
