import { gql } from '@apollo/client';

export const OBSERVATION_FIELDS = gql`
  fragment ObservationFields on Observation {
    id
    body
    demandId
    createdAt
    updatedAt
    createdBy {
      id
      clinician {
        id
        name
        professionalRegistrationNumber
      }
    }
  }
`;
