import { useState, useEffect, useCallback } from 'react';
import { UserCredential } from 'firebase/auth';
import { useFirebase } from 'contexts/firebase';
import {
  DocumentData,
  doc,
  updateDoc,
  query,
  collection,
  onSnapshot,
  getDocs,
  where,
  orderBy,
  documentId,
  limit as limitFirestore,
} from 'firebase/firestore';
import { logError } from 'utils/logger';
import { NotificationData, NotificationType } from 'types';

const NOTIFICATIONS_LIMIT_SIZE = 40;
const NOTIFICATION_COLLECTION_NAME = 'notifications';

const buildUserNotificationsCollection = (credential: UserCredential) => {
  const { user } = credential;
  return `user-notifications-${user.uid}`;
};

export const useNotifications = ({ limit = NOTIFICATIONS_LIMIT_SIZE } = {}) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [selectedNotificationTypes, setSelectedNotificationTypes] = useState<NotificationType[]>(
    [],
  );
  const { firestore, userCredential } = useFirebase();

  useEffect(() => {
    if (userCredential) {
      const userNotificationsCollection = collection(
        firestore,
        buildUserNotificationsCollection(userCredential),
      );
      const userNotificationsQuery = query(
        userNotificationsCollection,
        orderBy('sentAt', 'desc'),
        limitFirestore(limit),
      );

      const unsubscribe = onSnapshot(userNotificationsQuery, async (snapshot) => {
        const notificationIds = new Set();
        const userNotificationsFromFirestore: DocumentData[] = [];

        if (!snapshot.empty) {
          snapshot.forEach((userNotificationDoc) => {
            const data = userNotificationDoc.data();
            const notificationId = data.notification.id;

            if (!data.notifications?.length) {
              notificationIds.add(notificationId);
            }

            userNotificationsFromFirestore.push({
              ...data,
              notificationId,
              id: userNotificationDoc.id,
            });
          });
        }

        const batchIds = Array.from(notificationIds);
        const notificationsPayload: NotificationData[] = [];
        const notificationsFromFirestore: Record<string, DocumentData> = {};

        while (batchIds.length) {
          const userNotificationIds = batchIds.splice(0, 10);

          const notificationsCollection = collection(firestore, NOTIFICATION_COLLECTION_NAME);
          const notificationsQuery = query(
            notificationsCollection,
            where(documentId(), 'in', userNotificationIds),
          );

          const notificationDocs = await getDocs(notificationsQuery);

          notificationDocs.docs.forEach((notificationDoc) => {
            notificationsFromFirestore[notificationDoc.id] = notificationDoc.data();
          });
        }

        userNotificationsFromFirestore.forEach((userNotification) => {
          const {
            type,
            read = false,
            notifications: groupedNotifications = [],
            ...notification
          } = {
            ...userNotification,
            ...(notificationsFromFirestore[userNotification.notificationId] ?? {}),
          } as NotificationData;

          const firstNotification = groupedNotifications?.[0] ?? {};
          const notificationType = type ?? firstNotification?.type;

          notificationsPayload.push({
            ...notification,
            ...firstNotification,
            read,
            type: notificationType,
            notifications: groupedNotifications,
          });
        });

        setNotifications(notificationsPayload);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [userCredential, firestore, limit]);

  const handleReadNotification = async (notificationId: string) => {
    if (userCredential) {
      try {
        const userNotificationsPath = buildUserNotificationsCollection(userCredential);
        const notificationDoc = doc(firestore, userNotificationsPath, notificationId);

        await updateDoc(notificationDoc, { read: true });
      } catch (e) {
        logError(e as Error, {
          component: 'useNotifications',
        });
      }
    }
  };

  const handleSetNotificationTypes = (notificationTypes: NotificationType[]) => {
    const currentTypes = new Set(selectedNotificationTypes);

    notificationTypes.forEach((type) => {
      currentTypes.has(type) ? currentTypes.delete(type) : currentTypes.add(type);
    });

    setSelectedNotificationTypes(Array.from(currentTypes));
  };

  const handleGetNotifications = useCallback(() => {
    if (selectedNotificationTypes.length) {
      return notifications.filter(({ type }) => selectedNotificationTypes.includes(type));
    }

    return notifications;
  }, [notifications, selectedNotificationTypes]);

  return {
    notifications: handleGetNotifications(),
    selectedNotificationTypes,
    handleSetNotificationTypes,
    handleReadNotification,
  };
};

export type UseNotificationHookProps = ReturnType<typeof useNotifications>;
