import { graphql } from 'msw';

export default graphql.mutation('updateTask', (req, res, ctx) => {
  const { taskId, task } = req.variables;
  const {
    status,
    assignee = {},
    title = 'Task title',
    description = 'Task description',
    dueDate = '2023-04-01T03:00:00.000Z',
  } = task;

  return res(
    ctx.delay(2000),
    ctx.data({
      updateTask: {
        id: taskId,
        title,
        description,
        dueDate,
        status,
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
        assignee: {
          id: '6f2750cc-7f82-4feb-9f31-1e79d3efd100',
          name: 'Genona',
          ...assignee,
        },
        context: {
          id: 'f332c154-d076-4cb1-9cba-5b0dac245364',
          type: 'CLINICAL_GUIDANCE',
          clinicalCase: {
            id: 'bae88bff-f90f-4e3d-94d9-5664df62b3d8',
          },
        },
        createdBy: {
          id: 'ad67591b-d9c4-41bc-b413-135b659003f5',
          name: 'Geninho',
        },
      },
    }),
  );
});
