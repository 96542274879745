import { styled, pxToRem, Box, Flex } from '@genialcare/atipico-react';

export const Root = styled(Box, {
  position: 'relative',
});

export const Content = styled(Flex, {
  position: 'absolute',
  right: 0,
  top: pxToRem(42),
  zIndex: 2,
  gap: pxToRem(8),
  padding: pxToRem(12),
  minWidth: pxToRem(200),
  borderRadius: pxToRem(16),
  backgroundColor: '$neutral0',
  boxShadow: `0px ${pxToRem(10)} ${pxToRem(30)} rgba(0, 0, 0, 0.1)`,
});
