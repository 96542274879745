import { graphql } from 'msw';

export default graphql.query('getUserClinicalCaseHoursDetails', (_req, res, ctx) => {
  return res(
    ctx.delay(1500),
    ctx.data({
      user: {
        __typename: 'User',
        clinicalCaseHoursDetails: {
          __typename: 'ClinicalCaseHourDetails',
          scheduledHours: 2,
          childYearsOld: 3,
          firstInterventionDate: '2024-02-01',
          childRemainWorkload: [
            {
              discipline: 'ABA',
              hours: 18,
            },
            {
              discipline: 'FONO',
              hours: 5,
            },
            {
              discipline: 'TO',
              hours: 5,
            },
          ],
        },
      },
    }),
  );
});
