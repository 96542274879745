import {
  pxToRem,
  styled,
  InterfaceRefresh,
  InterfaceCheck,
  FileDocument,
  keyframes,
  InterfaceTrash,
} from '@genialcare/atipico-react';

const antiClockwiseSpin = keyframes({
  from: {
    transform: 'rotate(360deg)',
  },
  to: {
    transform: 'rotate(0deg)',
  },
});

export const Draft = styled('div', {
  marginTop: pxToRem(-16),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textAlign: 'right',
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  fontWeight: '$semibold',
});

export const AutoSaveDraft = styled('p', {
  color: '$purple200',
});

export const DraftSavingIcon = styled(InterfaceRefresh, {
  fill: '$purple200',
  marginRight: pxToRem(8),
  width: pxToRem(20),
  animation: `${antiClockwiseSpin} 1s linear infinite`,
});

export const DraftSavedIcon = styled(InterfaceCheck, {
  width: pxToRem(25),
  marginRight: pxToRem(8),
  fill: '$purple200',
});

export const DraftIcon = styled(FileDocument, {
  width: pxToRem(25),
  marginRight: pxToRem(8),
  fill: '$purple200',
});

export const RemoveDraft = styled(InterfaceTrash, {
  width: pxToRem(25),
  marginRight: pxToRem(8),
  fill: '$purple200',
});
