import React from 'react';
import * as S from './AlertDialog.styles';

type AlertDialogBodyProps = {
  children: string | React.ReactNode | React.ReactNode[];
};

export const AlertDialogBody: React.FC<AlertDialogBodyProps> = ({ children, ...rest }) => (
  <S.Body {...rest}>{children}</S.Body>
);
