import { DefaultContext, MutationHookOptions } from '@apollo/client';
import { ClinicalGuidanceComment } from 'types';
import { UPDATE_CLINICAL_GUIDANCE_COMMENT } from 'queries';
import { useAuthorizedMutation } from './useAuthorizedMutation';

type Data = {
  updateClinicalGuidanceComment: ClinicalGuidanceComment;
};

type Variables = {
  content: string;
  commentId: string;
  clinicalGuidanceId: string;
};

export const useUpdateClinicalGuidanceComment = (
  options?: MutationHookOptions<Data, Variables, DefaultContext>,
) => {
  const { mutate, data, error, loading } = useAuthorizedMutation<Data, Variables>(
    UPDATE_CLINICAL_GUIDANCE_COMMENT,
    options,
  );

  const mutation = (input: Variables) => {
    const { content, commentId, clinicalGuidanceId } = input;

    return mutate({
      variables: {
        content,
        commentId,
        clinicalGuidanceId,
      },
    });
  };

  return {
    data: data?.updateClinicalGuidanceComment,
    mutation,
    error,
    loading,
  };
};
