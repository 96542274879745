import React, { PropsWithChildren } from 'react';
import { Typography } from '@genialcare/atipico-react';
import { useNotificationData } from 'contexts/notification';
import * as S from './TitleHighlight.styles';

export const TitleHighlight: React.FC<PropsWithChildren> = ({ children }) => {
  const { read } = useNotificationData();

  return (
    <Typography
      as="span"
      variant="subtitle"
      weight="bold"
      color={read ? 'neutral400' : 'purple500'}
      css={S.baseStyle}
    >
      {children}
    </Typography>
  );
};
