import React from 'react';
import { ToastProps } from '@radix-ui/react-toast';
import { useToastData } from 'contexts/toast';
import { Banner } from 'components/Banner';
import * as S from './Toast.styles';

export const Toast: React.FC<ToastProps> = ({ open: defaultOpen, children, ...rest }) => {
  const {
    variant,
    duration,
    content,
    Icon,
    onClose,
    open = defaultOpen || false,
  } = useToastData() ?? {};

  return (
    <S.Root open={open} duration={duration} {...rest}>
      <Banner closable type={variant!} Icon={Icon} handleClose={onClose}>
        {content || children}
      </Banner>
    </S.Root>
  );
};
