import { gql } from '@apollo/client';

import { SESSION_AND_NOTE_FRAGMENT } from './fragments/sessionAndNoteFragment';

export const GET_CLINICAL_CASE_SESSION_AND_NOTE_BY_ID = gql`
  ${SESSION_AND_NOTE_FRAGMENT}

  query getSessionAndNoteById($id: ID!) {
    session(id: $id) {
      ...SessionAndNoteFields
    }
  }
`;
