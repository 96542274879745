import { TaskContext, TaskParticipant, TaskSubject } from 'types/task';
import { ClinicalGuidancesPlanningStatus } from './clinicalGuidancePlanning';

export enum ClinicalGuidanceTaskStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type ClinicalGuidanceTaskFiltersType = {
  statuses?: ClinicalGuidanceTaskStatus[] | null;
  clinicalGuidancePlanningStatuses?: ClinicalGuidancesPlanningStatus[] | null;
  overdue?: boolean | null;
};

export type ClinicalGuidanceTask = {
  id: string;
  title: string;
  description?: string;
  status: ClinicalGuidanceTaskStatus;
  overdue: boolean;
  context: TaskContext;
  assignee: TaskParticipant;
  createdBy: TaskParticipant;
  dueDate?: string;
  subject?: TaskSubject;
  createdAt: string;
  updatedAt: string;
};
