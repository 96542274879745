import { gql } from '@apollo/client';

export const UPDATE_PEI_OBJECTIVE = gql`
  mutation ($objective: UpdateObjectiveInput!, $clinicalCaseId: String!) {
    updatePEIObjective(objective: $objective, clinicalCaseId: $clinicalCaseId) {
      id
      description
    }
  }
`;
