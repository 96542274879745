import { gql } from '@apollo/client';

export const CREATE_TASK = gql`
  mutation createTask($task: CreateTaskInput!) {
    createTask(task: $task) {
      id
      title
      description
      dueDate
      status
      createdAt
      updatedAt
      assignee {
        id
        name
      }
      createdBy {
        id
        name
      }
      context {
        id
        type
        clinicalCase {
          id
        }
      }
    }
  }
`;
