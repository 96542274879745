import React from 'react';
import { Layout } from 'components/Layout';
import * as S from './BaseError.styles';
import { useUserData } from 'contexts/user';

export type BaseErrorProps = {
  imageSrc: string;
  title: string;
  subtitle?: string;
  description?: string;
};

export const BaseError: React.FC<BaseErrorProps> = ({ imageSrc, title, subtitle, description }) => {
  const user = useUserData();
  const RootTag = user.name ? Layout : React.Fragment;

  return (
    <RootTag>
      <S.Container direction="column" align="center" justify="center">
        <S.Image src={imageSrc} />
        <S.Title color="neutral500" variant="h1" weight="bold">
          {title}
        </S.Title>
        {subtitle && (
          <S.Subtitle color="neutral500" variant="h2">
            {subtitle}
          </S.Subtitle>
        )}
        {description && (
          <S.Description color="neutral400" variant="body1">
            {description}
          </S.Description>
        )}
      </S.Container>
    </RootTag>
  );
};
