import {
  pxToRem,
  styled,
  Flex,
  Typography,
  generateColorPaletteVariants,
} from '@genialcare/atipico-react';
import { Wrapper as DefaultWrapper } from 'components/Wrapper';
import Skeleton from 'react-loading-skeleton';
import type { CSS } from '@genialcare/atipico-react';

const buildSkeletonStyle = (style: CSS) => ({
  '&.react-loading-skeleton': style,
});

export const Header = styled(Flex, {
  gap: pxToRem(16),
  marginTop: pxToRem(56),
});

export const Wrapper = styled(DefaultWrapper, {
  marginTop: pxToRem(20),
  borderRadius: '16px',
  minHeight: pxToRem(480),
  padding: `${pxToRem(8)} ${pxToRem(16)}`,

  '@md': {
    padding: `${pxToRem(32)} ${pxToRem(64)}`,
  },
});

export const NoteAuthor = styled('p', {
  maxWidth: '66%',
  fontStyle: 'italic',
  fontSize: '10px',
  lineHeight: pxToRem(14),
  variants: {
    color: generateColorPaletteVariants(['color']),
  },
  marginTop: pxToRem(12),

  '@md': {
    fontSize: '12px',
  },
});

export const NoteTitle = styled(Typography, {
  marginTop: pxToRem(20),
});

export const Divider = styled('hr', {
  marginTop: pxToRem(8),
  borderTop: '1px solid #F1F1F1',
});

export const AttributeTitle = styled(Typography, {
  marginTop: pxToRem(32),
});

export const Text = styled(Typography, {
  margin: `${pxToRem(8)} ${pxToRem(8)} 0 ${pxToRem(8)}`,
  variants: {
    variant: {
      body1: {
        fontSize: pxToRem(16),
      },
    },
  },
});

export const TemplateForOldNotes = styled('div', {
  padding: `${pxToRem(32)} ${pxToRem(0)} `,
  fontSize: pxToRem(20),
});

export const SkeletonTitle = styled(
  Skeleton,
  buildSkeletonStyle({
    width: '60%',
    height: pxToRem(40),
    marginTop: pxToRem(12),
  }),
);

export const SkeletonItem = styled(
  Skeleton,
  buildSkeletonStyle({
    height: pxToRem(56),
    borderRadius: pxToRem(8),
    '@md': {
      height: pxToRem(54),
    },
    marginTop: pxToRem(24),
  }),
);
