import React from 'react';
import NotFoundSvg from 'assets/images/illustrations/not-found.svg';
import { BaseError } from '../BaseError';

export const NotFound = () => {
  return (
    <BaseError
      imageSrc={NotFoundSvg}
      title="Ops..."
      subtitle="Página não encontrada!"
      description="A página que você tentou acessar não existe ou ocorreu algum erro inesperado. Por favor, tente novamente mais tarde."
    />
  );
};
