import { gql } from '@apollo/client';
import { INTERVENTION_NOTES_SUMMARY_FRAGMENT } from './fragments/interventionNotesSummaryFragment';

export const CREATE_INTERVENTION_NOTES_SUMMARY = gql`
  ${INTERVENTION_NOTES_SUMMARY_FRAGMENT}

  mutation ($clinicalCaseId: ID!, $startDate: String, $endDate: String, $key: String) {
    createInterventionNotesSummary(
      clinicalCaseId: $clinicalCaseId
      startDate: $startDate
      endDate: $endDate
      key: $key
    ) {
      ...InterventionNotesSummaryFields
    }
  }
`;
