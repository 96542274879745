import { useMediaQuery } from 'react-responsive';
import tokens from '@genialcare/atipico-tokens';

export const useDevice = (): {
  isSmallScreen: boolean;
  isBigScreen: boolean;
  isMobileScreen: boolean;
} => {
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${tokens.screen.breakpoint.lg.value}px)`,
  });
  const isBigScreen = !isSmallScreen;
  const isMobileScreen = useMediaQuery({
    query: `(max-width: ${tokens.screen.breakpoint.sm.value}px)`,
  });

  return {
    isSmallScreen,
    isMobileScreen,
    isBigScreen,
  };
};
