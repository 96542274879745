import { gql } from '@apollo/client';
import { PLANNING_FRAGMENT } from 'queries/planning';

export const GET_USER_PLANNINGS = gql`
  ${PLANNING_FRAGMENT}

  query getUserPlannings($filters: PlanningInputFilters) {
    user {
      id
      firstName
      lastName
      plannings(filters: $filters) {
        ...PlanningFields
        __typename
      }
    }
  }
`;
