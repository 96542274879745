import { graphql } from 'msw';

export default graphql.mutation('createClinicalGuidance', (req, res, ctx) => {
  const {
    clinicalGuidance: { title, content, category, priority = 'HIGH' },
  } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      createClinicalGuidance: {
        title,
        content,
        priority,
        category,
        status: 'OPEN',
        id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
        context: {
          type: 'INTERVENTION_SESSION',
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          clinicalCase: {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          },
        },
        createdBy: {
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
        },
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
      },
    }),
  );
});
