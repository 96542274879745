import { gql } from '@apollo/client';
import { DEMAND_FIELDS } from './fragments/demandFragments';

export const UPDATE_DEMAND = gql`
  ${DEMAND_FIELDS}

  mutation updateDemand($clinicalCaseId: ID!, $demandId: ID!, $request: UpdateDemandInput!) {
    updateDemand(clinicalCaseId: $clinicalCaseId, demandId: $demandId, request: $request) {
      ...DemandFields
    }
  }
`;
