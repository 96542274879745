import { gql } from '@apollo/client';

export const UPDATE_CUSTOM_TARGET_LIST = gql`
  mutation updateCustomTargetList(
    $id: String!
    $clinicalCaseId: String!
    $customTargetList: CustomTargetListInput!
  ) {
    updateCustomTargetList(
      id: $id
      clinicalCaseId: $clinicalCaseId
      customTargetList: $customTargetList
    ) {
      id
    }
  }
`;
