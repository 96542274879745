import React from 'react';
import { pxToRem } from '@genialcare/atipico-react';
import { AssessmentNote, DirectNoteDetail, FeedbackNoteDetail, IndirectNoteDetail } from 'types';
import { List } from '../components';
import { useTranslation } from 'react-i18next';
import { convertDateToPtBr } from 'utils/date';

type AssessmentNotesProps = {
  note: AssessmentNote;
};

export const AssessmentDetail: React.FC<AssessmentNotesProps> = ({ note }) => {
  const { t } = useTranslation('assessment');
  const detail = note.detail;
  let itemList = {};

  if (detail.type === 'indirect_assessment') {
    const { protocol, observation, participants } = detail as IndirectNoteDetail;
    itemList = { protocol, participants, observation };
  }

  if (detail.type === 'direct_assessment') {
    const {
      protocol,
      observation,
      usedReinforcers,
      challengingBehaviors,
      healthChecks: healthCheckList,
    } = detail as DirectNoteDetail;
    const healthChecks = healthCheckList.join(', ');
    itemList = {
      protocol,
      usedReinforcers,
      challengingBehaviors,
      healthChecks,
      observation,
    };
  }

  if (detail.type === 'feedback_assessment') {
    const {
      participants,
      keyDevelopmentMilestones,
      expectedReassessmentDate: expectedReassessmentDateRaw,
      observation,
      reportDelivered,
    } = detail as FeedbackNoteDetail;

    const expectedReassessmentDate = convertDateToPtBr(expectedReassessmentDateRaw, {
      dateStyle: 'long',
    });
    itemList = {
      participants,
      keyDevelopmentMilestones,
      expectedReassessmentDate,
      observation,
      reportDelivered: reportDelivered ? 'Sim' : 'Não',
    };
  }

  const items = Object.entries(itemList).map(([key, value]) => {
    return (
      <List.Item key={key} name={t(`attributes.${key}`)}>
        {value as string}
      </List.Item>
    );
  });

  return <List css={{ marginBottom: pxToRem(24) }}>{items}</List>;
};
