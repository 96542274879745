import { gql } from '@apollo/client';

export const CREATE_CLINICAL_GUIDANCE_REGISTRY = gql`
  mutation createClinicalGuidanceRegistry(
    $clinicalGuidanceRegistry: CreateClinicalGuidanceRegistryInput!
  ) {
    createClinicalGuidanceRegistry(clinicalGuidanceRegistry: $clinicalGuidanceRegistry) {
      id
    }
  }
`;
