import React, { PropsWithChildren } from 'react';
import * as S from './Label.styles';
import type { LabelVariants } from './Label.styles';

type LabelProps = LabelVariants & PropsWithChildren;

export const Label: React.FC<LabelProps> = ({ bg, color, children, ...props }) => (
  <S.Root {...props} bg={bg} color={color}>
    {children}
  </S.Root>
);
