import React, { useContext } from 'react';

type ListContextProps = {
  sublist: boolean;
};

type ListProviderProps = ListContextProps & React.PropsWithChildren;

const ListContext = React.createContext<ListContextProps | null>(null);

export const ListProvider: React.FC<ListProviderProps> = ({ sublist, children }) => (
  <ListContext.Provider value={{ sublist }}>{children}</ListContext.Provider>
);

export const useList = () => {
  const context = useContext(ListContext);

  if (!context) {
    throw new Error('useList must be used within a ListProvider');
  }

  return context;
};
