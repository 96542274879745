import {
  styled,
  pxToRem,
  Button,
  Flex,
  Card as CardDefault,
  CardBody as CardBodyDefault,
} from '@genialcare/atipico-react';
import { TextArea as TextAreaDefault } from 'components/Form/TextArea';

export const Card = styled(CardDefault, {
  gap: pxToRem(12),
  padding: pxToRem(16),
  borderRadius: pxToRem(16),
  boxShadow: 'unset',
});

export const CardBody = styled(CardBodyDefault, {
  gap: pxToRem(8),
  padding: 0,
});

export const TextArea = styled(TextAreaDefault, {
  padding: pxToRem(8),
  margin: `${pxToRem(4)} 0`,
  fontSize: pxToRem(14),
});

export const FormActions = styled(Flex, {
  flex: 1,
  gap: pxToRem(16),
  width: '100%',
  '@md': {
    width: 'auto',
  },
});

export const FormActionButton = styled(Button, {
  minWidth: pxToRem(90),
  height: pxToRem(48),
  '@md': {
    height: pxToRem(40),
  },
});
