import { gql } from '@apollo/client';

export const CLINICAL_GUIDANCE_PLANNING_BY_TASK_FRAGMENT = gql`
  fragment ClinicalGuidancePlanningByTaskFields on ClinicalGuidanceTask {
    subject {
      id
      type
      ... on ClinicalGuidancePlanningSubject {
        planning {
          id
          status
          plannedAt
          note
          createdAt
          updatedAt
          mentor {
            id
            firstName
            lastName
          }
          mentee {
            id
            firstName
            lastName
            clinician {
              id
              name
              legalName
            }
          }
          clinicalCase {
            id
            name
          }
          registry {
            id
          }
        }
      }
    }
  }
`;
