import { gql } from '@apollo/client';

export const GET_USER_CLINICAL_CASE_HOURS_DETAILS = gql`
  query getUserClinicalCaseHoursDetails($childId: ID!) {
    user {
      clinicalCaseHoursDetails(childId: $childId) {
        __typename
        scheduledHours
        childYearsOld
        firstInterventionDate
        childRemainWorkload {
          discipline
          hours
        }
      }
    }
  }
`;
