import React from 'react';
import * as S from './Banner.styles';
import { InterfaceCross } from '@genialcare/atipico-react';

type BannerProps = {
  type: 'warning' | 'error' | 'success' | 'info';
  children: React.ReactNode;
  closable?: boolean;
  Icon?: React.FC;
  handleClose?: () => void;
};

export const Banner: React.FC<BannerProps> = ({
  children,
  type,
  Icon,
  closable,
  handleClose = () => null,
  ...rest
}) => {
  return (
    <S.Root align="center" type={type} {...rest}>
      {Icon && (
        <S.Icon data-testid="banner-icon">
          <Icon />
        </S.Icon>
      )}
      <S.Text as="span" variant="body2">
        {children}
      </S.Text>
      {closable && (
        <S.CloseButton data-testid="banner-close-button" onClick={handleClose}>
          <InterfaceCross color="#FFF" />
        </S.CloseButton>
      )}
    </S.Root>
  );
};
