import React from 'react';
import { FamilySupportConversationMessageCreatedNotification } from 'types';
import { ComposeNotificationProps } from '../Notification';
import { FamilySupportMultipleMessagesCreated } from './FamilySupportMultipleMessagesCreated';
import { FamilySupportSingleMessageCreated } from './FamilySupportSingleMessageCreated';

type FamilySupportMessageCreatedProps =
  ComposeNotificationProps<FamilySupportConversationMessageCreatedNotification>;

type FamilySupportMessagesCreatedProps = FamilySupportMessageCreatedProps & {
  notifications?: FamilySupportMessageCreatedProps[];
};

export const FamilySupportMessagesCreated: React.FC<FamilySupportMessagesCreatedProps> = ({
  notifications,
  createdAt,
  ...props
}) => {
  const totalMessages = notifications?.length || 1;
  const lastNotification = notifications?.sort(
    (a: FamilySupportMessageCreatedProps, b: FamilySupportMessageCreatedProps) =>
      a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime(),
  )?.[totalMessages - 1];

  return totalMessages > 1 ? (
    <FamilySupportMultipleMessagesCreated
      {...props}
      createdAt={lastNotification?.createdAt || createdAt}
      total={totalMessages}
    />
  ) : (
    <FamilySupportSingleMessageCreated createdAt={createdAt} {...props} />
  );
};
