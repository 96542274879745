import {
  CSS,
  styled,
  pxToRem,
  IconButton,
  StatusNotification as NotificationIcon,
} from '@genialcare/atipico-react';

export const Icon = styled(NotificationIcon, {
  width: '100%',
  height: '100%',
  fill: '$purple100',
});

export const Triggerbutton = styled(IconButton, {
  width: pxToRem(32),
  height: pxToRem(32),
});

export const triggerToggleStatus: CSS = {
  transition: 'backgroundColor 300ms ease',
  '&[data-state="closed"]': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '$neutral100',
    },
  },
  '&[data-state="open"]': {
    backgroundColor: '$purple100',
    '+ div': {
      transition: 'opacity 200ms ease',
      opacity: 0,
      visibility: 'visible',
    },
    [`${Icon}`]: {
      fill: '$neutral0',
    },
  },
};
