import React from 'react';
import { pxToRem } from '@genialcare/atipico-react';
import { SessionCancellation } from 'types';
import { List } from '../components';

type CancellationProps = {
  observation?: string;
  cancellation: SessionCancellation;
};

export const Cancellation: React.FC<CancellationProps> = ({ observation, cancellation }) => {
  return (
    <List css={{ marginBottom: pxToRem(24) }} data-testid="cancellation-template">
      <List.Item name="Informações operacionais">
        <List sublist>
          <List.Item name={observation || 'Não há informações operacionais nesta sessão.'} />
        </List>
      </List.Item>

      <List.Item name="Motivo do cancelamento">
        {cancellation.reasonDescription ||
          'Não há informações de motivos de cancelamento nesta sessão.'}
      </List.Item>

      <List.Item name="Solicitante do cancelamento">
        {cancellation.requestedByRoleDescription}
      </List.Item>

      <List.Item name="Aviso com antecedência">{cancellation.inAdvance ? 'Sim' : 'Não'}</List.Item>

      <List.Item name="Observações sobre o cancelamento">
        {cancellation.comment || 'Não há observações sobre o cancelamento nesta sessão.'}
      </List.Item>
    </List>
  );
};
