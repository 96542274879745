import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_INFOS_WITH_CHILD = gql`
  query getClinicalCaseInfos($id: ID!) {
    clinicalCase(id: $id) {
      name
      id
      caregivers {
        id
        name
        cellphoneNumber
      }
      dependents {
        id
        name
        avatarImage
        birthDate
        age
      }
      currentComplexityScore {
        id
        score
        createdAt
      }
      children {
        id
        fullName
        downgraded
        schedulingScore {
          score
          consecutiveLowSchedulingRateDays
        }
      }
    }
  }
`;
