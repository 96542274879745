import { gql } from '@apollo/client';

export const GET_OBJECTIVES_WITH_PROTOCOL = gql`
  query getObjectivesWithProtocol($clinicalCaseId: ID!) {
    clinicalCase(id: $clinicalCaseId) {
      id
      name
      peis {
        id
        objectives {
          id
          status
          protocolItem {
            id
            protocolId
            protocolName
            description
            code
            domain
            subdomain
          }
        }
      }
    }
  }
`;
