import { gql } from '@apollo/client';

export const ADD_VINELAND_REPORT_TO_CLINICAL_CASE = gql`
  mutation addVinelandReportToClinicalCase($input: AddVinelandReportInput!) {
    addVinelandReportToClinicalCase(input: $input) {
      id
      interviewRespondentName
      assessedAt
    }
  }
`;
