import React, { PropsWithChildren } from 'react';
import { CSS } from '@genialcare/atipico-react';
import * as S from './Badge.styles';

type BadgeProps = PropsWithChildren &
  S.BadgeVariants & {
    css?: CSS;
  };

export const Badge: React.FC<BadgeProps> = ({
  bg,
  color,
  fixed,
  extended,
  css,
  children,
  ...props
}) => (
  <S.Root {...props} bg={bg} color={color} fixed={fixed} extended={extended} css={css}>
    {children}
  </S.Root>
);
