import React from 'react';
import { pxToRem, Typography } from '@genialcare/atipico-react';
import { convertDateToPtBr } from 'utils/date';

type CardDueDateProps = {
  date?: string;
};

const formatDueDateText = (date?: string) => {
  if (date) {
    return `Até ${convertDateToPtBr(date, { dateStyle: 'short' })}`;
  }

  return 'Sem data limite';
};

export const CardDueDate: React.FC<CardDueDateProps> = ({ date }) => {
  const dueDateText = formatDueDateText(date);

  return (
    <Typography variant="body2" color="neutral400" css={{ fontSize: pxToRem(14) }}>
      {dueDateText}
    </Typography>
  );
};
