type DemandClinician = {
  id: string;
  name: string;
  profissionalRegistrationNumber?: string;
};
type DemandUser = {
  id: string;
  clinician: DemandClinician;
};

export type Observation = {
  id: string;
  demandId: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  createdBy: DemandUser;
};

export enum DemandStatuses {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
}

export type Demand = {
  id: string;
  status: DemandStatuses;
  statusDescription: string;
  title: string;
  description: string;
  requestedAt: string;
  observations: Observation[];
  createdAt: string;
  updatedAt: string;
  createdBy: DemandUser;
  updatedBy: DemandUser;
};
