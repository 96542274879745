import { gql } from '@apollo/client';

export const FEEDBACK_FRAGMENT = gql`
  fragment FeedbackFields on Feedback {
    id
    feedbackableId
    feedbackableType
    liked
    likedReason
    createdAt
    updatedAt
  }
`;
