import { graphql } from 'msw';

export default graphql.mutation('createClinicalCaseSupportLink', (req, res, ctx) => {
  const { category, url } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      createClinicalCaseSupportLink: {
        id: 'cb477afe-c429-11ed-afa1-0242ac120002',
        category,
        url,
        createdAt: '2023-01-01T17:24:13',
        createdBy: {
          id: '31a06995-89a7-49dd-809c-4d7eecaa9731',
          name: 'Maria Luiza',
        },
      },
    }),
  );
});
