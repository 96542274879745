import { gql } from '@apollo/client';

const basicPendencyFields = (name: string, contextDetail = '') => gql`
  fragment ${name} on Pendency {
    id
    pendencyType
    pendencyTypeDescription
    category
    categoryDescription
    dueDate
    createdAt
    updatedAt
    context {
      id
      type
      clinicalCase {
        id
        name
        number
      }
      ${contextDetail}
    }
    __typename
  }
`;

export const PENDENCY_FRAGMENT = basicPendencyFields('PendencyFields');

export const PENDENCY_FRAGMENT_WITH_CONTEXT_DETAIL = basicPendencyFields(
  'PendencyFieldsWithContextDetail',
  `
    details {
      ... on Session {
        id
        discipline
        startScheduledAt
        endScheduledAt
        finalStartedAt
        finalEndedAt
        status
        statusDescription
        sessionType
        sessionTypeDescription
      }
      ... on Task {
        id
        title
        context {
          id
          type
          clinicalCase {
            id
            name
          }
        }
        subject {
          id
          type
        }
      }
    }
  `,
);
