import { gql } from '@apollo/client';
import { PLANNING_FRAGMENT } from 'queries/planning';

export const GET_PLANNING_AND_OBJECTIVES = gql`
  fragment RegistryFields on ClinicalGuidanceRegistry {
    subjects {
      clinicalDecision {
        id
        text
        createdAt
        createdBy {
          name
        }
      }
    }
  }

  ${PLANNING_FRAGMENT}

  query getPlanningAndObjectives(
    $planningId: ID!
    $clinicalCaseId: ID!
    $objectiveStatuses: [String]
    $evolutionCheckOrder: EvolutionCheckOrder
    $evolutionCheckLimit: Int
    $lastWeekStart: String
    $lastWeekEnd: String
  ) {
    user: user {
      id
      planning(id: $planningId) {
        ...PlanningFields
        __typename
      }
    }

    clinicalCase: clinicalCase(id: $clinicalCaseId) {
      id
      dependents {
        id
        name
        age
        birthDate
      }
      peis(objectiveStatuses: $objectiveStatuses) {
        id
        objectives {
          id
          description
          status
          updatedAt
          evolutionChecks(order: $evolutionCheckOrder, limit: $evolutionCheckLimit) {
            pros
            cons
            createdAt
            wasAssessed
          }
          strategies {
            id
            name
            definition
            useFor
            itsGoodFor
            abbreviation
          }
          programs {
            ... on AbaProgram {
              discipline
              instruction
              activities {
                name
              }
            }
            ... on OccupationalTherapyProgram {
              discipline
            }
            ... on SpeechTherapyProgram {
              discipline
            }
          }
        }
      }
    }
    lastWeekSessions: sessions(
      clinicalCaseId: $clinicalCaseId
      startScheduledAt: $lastWeekStart
      endScheduledAt: $lastWeekEnd
      sessionTypes: [INTERVENTION, CLINICAL_GUIDANCE]
    ) {
      id
      sessionType
      sessionable {
        ... on ClinicalGuidanceSessionable {
          registry {
            ...RegistryFields
          }
        }
        ... on InterventionSessionable {
          clinicalGuidanceRegistry {
            ...RegistryFields
          }
        }
      }
    }
  }
`;
