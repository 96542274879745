import getClinicalCaseSessions from './get-clinical-case-sessions';
import getUserClinicalCaseAvailableHours from './get-user-clinical-case-available-hours';
import getClinicalGuidance from './get-clinical-guidance';
import getClinicalCasePEIs from './get-clinical-case-peis';
import getClinicalCaseGuidances from './get-clinical-case-guidances';
import getClinicalCaseSupportLinks from './get-clinical-case-support-links';
import getTaskFormData from './get-task-form-data';
import getClinicalCaseTasks from './get-clinical-case-tasks';
import getClinicalGuidanceRegistries from './get-clinical-guidance-registries';
import getUserAvailableHours from './get-user-available-hours';
import getUserClinicalCaseHoursDetails from './get-user-clinical-case-hours-details';
import getClinicalCasePreferences from './get-clinical-case-preferences';
import getObservableLightForm from './get-observable-light-form';

export const queries = [
  getUserClinicalCaseAvailableHours,
  getUserClinicalCaseHoursDetails,
  getClinicalCasePreferences,
  getClinicalCaseSessions,
  getClinicalGuidance,
  getClinicalCasePEIs,
  getClinicalCaseGuidances,
  getClinicalCaseSupportLinks,
  getTaskFormData,
  getClinicalCaseTasks,
  getClinicalGuidanceRegistries,
  getUserAvailableHours,
  getObservableLightForm,
];
