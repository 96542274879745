import React from 'react';
import { Flex } from 'styled-system/jsx';
import * as RadioGroupDefault from '@radix-ui/react-radio-group';

type RadioGroupProps = {
  children: React.ReactNode | React.ReactNode[];
  name?: string;
  containerClassName?: string;
  className?: string;
  defaultValue?: string;
  value?: string;
  ariaLabel?: string;
  flexCss?: Record<string, unknown>;
  onValueChange?: (value: string) => void;
};

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>((props, ref) => {
  const { children, defaultValue, ariaLabel, value, containerClassName, className, ...rest } =
    props;

  return (
    <RadioGroupDefault.Root
      value={value ?? (defaultValue || undefined)}
      aria-label={ariaLabel || ''}
      className={containerClassName}
      ref={ref}
      {...rest}
    >
      <Flex
        alignItems="center"
        gap={{ xs: '8px', md: '28px' }}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        className={className}
      >
        {children}
      </Flex>
    </RadioGroupDefault.Root>
  );
});
