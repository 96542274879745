import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  pxToRem,
  InterfacePlus,
} from '@genialcare/atipico-react';
import { SkillAcquisitionNotes as SkillAcquisitionNotesType } from 'types';
import { useSkillAcquisitionNoteFlags } from 'hooks/useSkillAcquisitionNoteFlags';
import * as S from './SkillAcquisitionNotes.styles';
import { convertDateToPtBr } from 'utils/date';

type SkillAcquisitionNotesProps = {
  interventionSessionId: string;
  notes: SkillAcquisitionNotesType[];
};

export const SkillAcquisitionNotes: React.FC<SkillAcquisitionNotesProps> = ({
  notes,
  interventionSessionId,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showFields } = useSkillAcquisitionNoteFlags();

  const handleCreateTweet = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(`/panel/clinical-cases/${id}/interventions/${interventionSessionId}/tweets`);
  };

  return (
    <S.Root direction="column" round bg="neutral0">
      <CardHeader
        align="center"
        justify="space-between"
        direction="column"
        css={{ '@lg': { flexDirection: 'row' } }}
      >
        <Typography weight="bold" variant="h4">
          Relatos sobre a evolução da criança
        </Typography>

        {!showFields && (
          <S.CreateNoteButton
            leftIcon={<InterfacePlus width={30} height={30} color="white" />}
            onClick={handleCreateTweet}
          >
            Criar novo tweet
          </S.CreateNoteButton>
        )}
      </CardHeader>

      <CardBody>
        {notes.length ? (
          notes.map((note) => {
            const formattedDate = convertDateToPtBr(note.createdAt);
            const footerText = `Por ${note.clinician.name} (${note.clinician.professionalRegistrationNumber}) em ${formattedDate}`;

            return (
              <S.Note direction="column" key={note.id} round bg="neutral0">
                {note.targetGroup && (
                  <S.NoteHeader>
                    <Typography weight="bold" variant="h5" css={{ fontSize: pxToRem(16) }}>
                      {note.targetGroup.name}
                    </Typography>
                  </S.NoteHeader>
                )}

                <S.NoteBody>
                  <Typography
                    variant="body2"
                    color="neutral400"
                    css={{ fontSize: pxToRem(14), width: '100%' }}
                  >
                    {note.body}
                  </Typography>
                </S.NoteBody>
                <CardFooter>
                  <Typography variant="quote" css={{ fontSize: pxToRem(10), color: '$neutral400' }}>
                    {footerText}
                  </Typography>
                </CardFooter>
              </S.Note>
            );
          })
        ) : (
          <Typography variant="body2" css={{ fontSize: pxToRem(14), color: '$neutral400' }}>
            Nenhum tweet foi adicionado.
          </Typography>
        )}
      </CardBody>
    </S.Root>
  );
};
