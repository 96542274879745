import { gql } from '@apollo/client';
import { PARENTAL_TRAINING_NOTE_FIELDS } from './fragments/parentalTrainingNoteFragments';

export const CREATE_PARENTAL_TRAINING_NOTE = gql`
  ${PARENTAL_TRAINING_NOTE_FIELDS}

  mutation ($clinicalCaseId: String!, $parentalTrainingNote: CreateParentalTrainingNoteInput!) {
    createParentalTrainingNote(
      clinicalCaseId: $clinicalCaseId
      parentalTrainingNote: $parentalTrainingNote
    ) {
      ...ParentalTrainingNoteFields
    }
  }
`;
