import { gql } from '@apollo/client';

export const CREATE_CLINICAL_CASE_SUPPORT_LINK = gql`
  mutation createClinicalCaseSupportLink($id: ID!, $url: String!, $category: String!) {
    createClinicalCaseSupportLink(clinicalCaseId: $id, url: $url, category: $category) {
      id
      category
      url
      createdBy {
        id
        name
      }
      createdAt
    }
  }
`;
