import { Typography, pxToRem, styled, Flex } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  borderRadius: pxToRem(8),
  padding: `${pxToRem(12)} ${pxToRem(12)} ${pxToRem(12)} ${pxToRem(18)}`,
  maxWidth: pxToRem(534),
  variants: {
    type: {
      warning: {
        backgroundColor: '$orange300',
      },
      error: {
        backgroundColor: '$red500',
      },
      success: {
        backgroundColor: '$green500',
      },
      info: {
        backgroundColor: '$yellow200',
      },
    },
  },
});

export const Icon = styled('div', {
  svg: {
    width: pxToRem(29),
    height: pxToRem(29),
    fill: '$neutral0',
  },
  '@md': {
    svg: {
      width: pxToRem(40),
      height: pxToRem(37),
    },
  },
});

export const CloseButton = styled('span', {
  height: '100%',
  border: 'none',
  cursor: 'pointer',
  svg: {
    width: pxToRem(20),
    height: pxToRem(20),
    fill: '$neutral0',
  },
  '@md': {
    svg: {
      width: pxToRem(24),
      height: pxToRem(24),
    },
  },
});

export const Text = styled(Typography, {
  margin: `0 ${pxToRem(10)} 0 ${pxToRem(19)}`,
  color: '$neutral0',
  '& a': {
    textDecoration: 'underline',
  },
  variants: {
    variant: {
      body2: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
      },
    },
  },
});
