import React, { PropsWithChildren } from 'react';
import type { CSS } from '@genialcare/atipico-react';
import { ListProvider } from './List.context';
import * as S from './List.styles';

type ListProps = PropsWithChildren & {
  css?: CSS;
  sublist?: boolean;
  className?: string;
};

export const List: React.FC<ListProps> = ({
  sublist = false,
  css,
  className,
  children,
  ...props
}) => (
  <S.Root {...props} sublist={sublist} css={css} className={className}>
    <ListProvider sublist={sublist}>{children}</ListProvider>
  </S.Root>
);
