import React, { PropsWithChildren, useEffect } from 'react';
import { Typography } from '@genialcare/atipico-react';
import { AUTHENTICATED_USER } from 'queries';
import { logError } from 'utils/logger';
import { AuthenticatedUserContext } from 'contexts/authenticatedUser';
import { useAuthorizedQuery } from 'hooks/useAuthorizedQuery';
import { useUserData } from 'contexts/user';
import { Auth0Role } from 'types';
import { datadogRum } from '@datadog/browser-rum';
import { Loading } from 'components/Loading';
import * as S from './AuthenticatedUserContainer.styles';
import initHelpHero from 'helphero';

type Data = {
  user: {
    id: string;
    roles: string[];
    partner: PartnerInfo | null;
  };
};

type PartnerInfo = {
  legalName: string;
};

export const AuthenticatedUserContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { error, loading, data } = useAuthorizedQuery<Data, unknown>(AUTHENTICATED_USER, {
    fetchPolicy: 'cache-first',
  });
  const { roles, email, name } = useUserData();
  const helpHero = initHelpHero(process.env.REACT_APP_HELP_HERO_KEY as string);

  useEffect(() => {
    const user = data?.user;

    if (!user) return;

    datadogRum.setUser({
      id: user.id,
      name,
      email,
      roles,
    });
    helpHero.identify(user.id, { name, email });
  }, [data, email, name, roles]);

  if (loading) {
    return (
      <S.Loading>
        <Loading />
      </S.Loading>
    );
  }

  if (error) {
    logError(error, {
      component: 'AuthenticatedUserContainer',
      query: 'AUTHENTICATED_USER',
    });

    return <Typography variant="body2">Erro ao carregar informações do usuário.</Typography>;
  }

  const user = data?.user;

  const userContextData = {
    id: user?.id || '',
    roles,
    isClinicalCaseOwner: roles.includes(Auth0Role.ClinicalCaseOwner),
    isClinicalCaseReference: roles.includes(Auth0Role.ClinicalCaseReference),
    partnerInfo: user?.partner || undefined,
  };

  return (
    <AuthenticatedUserContext.Provider value={userContextData}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
