import { gql } from '@apollo/client';
import { DEMAND_FIELDS } from './fragments/demandFragments';

export const CREATE_DEMAND = gql`
  ${DEMAND_FIELDS}

  mutation createDemand($clinicalCaseId: ID!, $request: CreateDemandInput!) {
    createDemand(clinicalCaseId: $clinicalCaseId, request: $request) {
      ...DemandFields
    }
  }
`;
