import { gql } from '@apollo/client';

export const GET_USER_CLINICAL_CASE_AVAILABLE_HOURS = gql`
  query getUserClinicalCaseAvailableHours($filters: AvailableHourFiltersInput) {
    user {
      clinicalCaseAvailableHours(filters: $filters) {
        __typename
        allocatedInClinicalCase
        location {
          __typename
          id
          name
        }
        preApprovedFamily
        availableHourChild {
          id
          contractingModel
        }
        availableHours {
          __typename
          weekday
          hours {
            __typename
            id
            startAt
            endAt
            preApprovedFamily
          }
        }
      }
    }
  }
`;
