import { gql } from '@apollo/client';

export const GET_OBJECTIVES_FOR_EVOLUTION_CHECK = gql`
  query getClinicalCasePEIAndObjectives($id: ID!, $objectiveStatuses: [String]) {
    clinicalCase(id: $id) {
      id
      peis(objectiveStatuses: $objectiveStatuses) {
        objectives {
          id
          description
          programs {
            ... on AbaProgram {
              discipline
              targets {
                id
                name
                status
                data {
                  ... on TrialTargetData {
                    currentPromptName
                  }
                }
              }
              configuration {
                ... on TrialConfiguration {
                  promptSchedule {
                    id
                    name
                    prompts {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
