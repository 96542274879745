import { ValidationValue, ValidationValueMessage } from 'react-hook-form';

export const buildValidationValueMessage = <T extends ValidationValue>(
  value: T,
  message: string,
): ValidationValueMessage<T> => ({
  value,
  message,
});

export const buildMinLengthValidationMessage = (value: number, message?: string) => {
  return buildValidationValueMessage(
    value,
    message ?? `O texto deve ser maior que ${value} caracteres`,
  );
};

export const buildMaxLengthValidationMessage = (value: number, message?: string) => {
  return buildValidationValueMessage(
    value,
    message ?? `O texto deve ser menor que ${value} caracteres`,
  );
};
