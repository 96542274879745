import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastProvider as ToastRadixProvider } from '@radix-ui/react-toast';
import './index.styles';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { datadog } from './services/datadog';
import { GlobalStyles } from './index.styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { NetworkAvailabilityProvider } from './contexts/networkAvailability';
import { ToastProvider } from 'contexts/toast';
import { ToastViewport } from 'components/Toast';
import { ServiceWorkerRegistration } from 'components/ServiceWorkerRegistration';
import { apolloClient } from './api/apollo-client';
import { ApolloProvider } from '@apollo/client';

import 'react-loading-skeleton/dist/skeleton.css';
import 'styled-system/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <GlobalStyles />

    <ToastRadixProvider>
      <ServiceWorkerRegistration />

      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <NetworkAvailabilityProvider>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUD as string,
              }}
              cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : 'memory'}
            >
              <ToastProvider>
                <App />
              </ToastProvider>

              <ToastViewport />
            </Auth0Provider>
          </NetworkAvailabilityProvider>
        </ApolloProvider>
      </BrowserRouter>
    </ToastRadixProvider>
  </React.StrictMode>,
);

if (['staging', 'production'].includes(process.env.REACT_APP_ENVIRONMENT || ''))
  datadog.startSessionReplayRecording();

reportWebVitals();
