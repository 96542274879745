import { styled } from 'styled-system/jsx';
import { Root as ToastRoot, ToastViewport as ToastViewportDefault } from '@radix-ui/react-toast';

export const Root = styled(ToastRoot, {
  base: {
    animationDuration: '400ms',
    willChange: 'transform, opacity',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',

    '&[data-state="open"]': {
      animationName: 'slideUpToast',
    },

    '&[data-state="closed"]': {
      animationName: 'slideDownToast',
    },
  },
});

export const ToastViewport = styled(ToastViewportDefault, {
  base: {
    position: 'fixed',
    bottom: '15px',
    right: '0px',
    zIndex: 10,
    m: '0 12px',
    outline: 'none',
    listStyle: 'none',

    md: {
      right: '30vw',
    },
  },
});
