import React from 'react';
import { CommunicationChat } from '@genialcare/atipico-react';
import { FamilySupportConversationMessageCreatedNotification } from 'types';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type FamilySupportSingleMessageCreatedProps =
  ComposeNotificationProps<FamilySupportConversationMessageCreatedNotification>;

export const FamilySupportSingleMessageCreated: React.FC<
  FamilySupportSingleMessageCreatedProps
> = ({ clinicalCaseId, conversationId, user, clinicalCaseName, createdAt, text, ...props }) => {
  const href = buildURL.toFamilySupportConversation(clinicalCaseId, conversationId);

  return (
    <Notification {...props} date={createdAt} href={href} icon={<CommunicationChat />}>
      <Notification.Title>
        Você tem uma <Notification.TitleHighlight>nova mensagem</Notification.TitleHighlight> no
        chamado do caso{' '}
        <Notification.TitleHighlight>{clinicalCaseName}:</Notification.TitleHighlight>
      </Notification.Title>
      <Notification.Subtitle>
        {user.name}: {text}
      </Notification.Subtitle>
    </Notification>
  );
};
