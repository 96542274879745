import React, { PropsWithChildren, ReactElement } from 'react';
import { useCardMenu } from 'contexts/cardMenu';
import * as S from './CardMenuItem.styles';
import type { ButtonVariants } from './CardMenuItem.styles';

type CardMenuItemProps = PropsWithChildren &
  ButtonVariants & {
    icon?: ReactElement;
    onClick?: (e: React.MouseEvent) => void;
  };

export const CardMenuItem: React.FC<CardMenuItemProps> = ({
  colorScheme,
  icon,
  onClick,
  children,
}) => {
  const { closeDropdown } = useCardMenu();

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();

    onClick?.(e);
    closeDropdown();
  };

  return (
    <S.Root colorScheme={colorScheme} leftIcon={icon} onClick={handleOnClick}>
      {children}
    </S.Root>
  );
};
