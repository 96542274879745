import { graphql } from 'msw';
import { v4 as uuidv4 } from 'uuid';

export default graphql.query('observableLightForm', (_, res, ctx) => {
  return res(
    ctx.delay(1500),
    ctx.data({
      observableLightForm: {
        id: uuidv4(),
        title: 'LiGHT Observável',
        shortTitle: 'LiGHT Observável',
        name: 'observable_light',
        formGroupQuestions: [
          {
            id: uuidv4(),
            title: 'Grupo de perguntas',
            description: 'Grupo de perguntas',
            formQuestions: [
              {
                id: uuidv4(),
                number: '1',
                label: 'Pergunta 1',
                description: 'Pergunta 1',
                required: true,
                formAnswers: [
                  {
                    id: uuidv4(),
                    label: 'Resposta 1',
                    weight: '1',
                  },
                ],
              },
            ],
          },
        ],
      },
    }),
  );
});
