import { gql } from '@apollo/client';

export const GET_CLINICAL_GUIDANCE_TASK_FORM_DATA = gql`
  query getClinicalGuidanceTaskFormData($clinicalCaseId: ID!, $clinicalGuidanceId: ID!) {
    clinicalCase(id: $clinicalCaseId) {
      clinicians {
        id
        name
        user {
          id
        }
      }
    }
    clinicalGuidance(id: $clinicalGuidanceId) {
      title
      status
      category
      createdAt
      createdBy {
        id
        name
      }
    }
  }
`;
