import {
  Typography,
  Button as ButtonDefault,
  pxToRem,
  styled,
  keyframes,
  InterfaceSettingsAdjust,
} from '@genialcare/atipico-react';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, scale: 0.9 },
  '100%': { opacity: 1, scale: 1 },
});

export const Wrapper = styled('div', {
  position: 'relative',
  maxWidth: '100%',
  zIndex: 1,
  variants: {
    isOpen: {
      true: {
        zIndex: 1,
      },
    },
  },
});

export const Background = styled('div', {
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  variants: {
    isOpen: {
      true: {
        backgroundColor: 'rgba(109, 104, 173, 0.4)',
        position: 'fixed',
        backdropFilter: 'blur(2.5px)',
        zIndex: 1,
        inset: 0,
        '@md': {
          position: 'initial',
        },
      },
    },
  },
});

export const Box = styled('div', {
  minWidth: pxToRem(320),
  borderRadius: pxToRem(16),
  backgroundColor: '$neutral0',
  boxShadow: `0 ${pxToRem(10)} ${pxToRem(30)} rgba(0, 0, 0, 0.1)`,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  height: 'fit-content',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  '@md': {
    position: 'absolute',
    top: pxToRem(8),
    right: 0,
    left: 'initial',
    margin: 'initial',
  },
});

export const Trigger = styled('button', {
  width: pxToRem(48),
  height: pxToRem(48),
  backgroundColor: '$neutral0',
  boxShadow: `0 ${pxToRem(10)} ${pxToRem(30)} rgba(0, 0, 0, 0.1)`,
  borderRadius: pxToRem(8),
  border: 'none',
  cursor: 'pointer',
  display: 'grid',
  placeItems: 'center',
  transition: 'all .3s ease-in-out',
  ' > svg': {
    fill: '$purple500',
    position: 'initial',
    transition: 'all .2s ease-in-out',
    width: pxToRem(24),
    height: pxToRem(24),
  },
  variants: {
    isOpen: {
      true: {
        backgroundColor: '$purple100',
        ' > svg': {
          fill: '$neutral0',
        },
      },
      false: {
        '&:hover': {
          ' > svg': {
            fill: '$purple100',
          },
        },
      },
    },
  },
});

export const Header = styled('div', {
  width: '100%',
  backgroundColor: '$purple100',
  padding: `${pxToRem(24)} ${pxToRem(20)} ${pxToRem(24)} ${pxToRem(56)}`,
  color: '$neutral0',
  position: 'relative',
  borderTopLeftRadius: pxToRem(16),
  borderTopRightRadius: pxToRem(16),
});

export const FilterIcon = styled(InterfaceSettingsAdjust, {
  width: pxToRem(23, 12),
  height: '100%',
  fill: '$neutral0',
  position: 'absolute',
  top: 0,
  left: 20,
});

export const CloseButton = styled('span', {
  width: pxToRem(20),
  height: pxToRem(20),
  position: 'absolute',
  cursor: 'pointer',
  top: pxToRem(24),
  right: pxToRem(20),
  '&::before': {
    content: '',
    width: '100%',
    height: '2px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    backgroundColor: '$neutral0',
    rotate: '45deg',
  },
  '&::after': {
    content: '',
    width: '100%',
    height: '2px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    backgroundColor: '$neutral0',
    rotate: '-45deg',
  },
});

export const Title = styled(Typography, {
  fontFamily: '$dm-sans !important',
});

export const Body = styled('div', {
  width: '100%',
  padding: `${pxToRem(8)} ${pxToRem(24)}`,
});

export const Footer = styled('div', {
  width: '100%',
  padding: pxToRem(32),
});

export const Button = styled(ButtonDefault, {
  marginTop: pxToRem(8),
  width: '100%',
  minWidth: '100%',
});
