import React, { ReactNode } from 'react';
import type { ThemeColors } from '@genialcare/atipico-react';
import * as S from './Trigger.styles';

type TriggerProps = React.HTMLAttributes<HTMLButtonElement> & {
  icon?: ReactNode;
  render?: ReactNode;
  variant?: 'outline';
  colorScheme: ThemeColors;
};

export const Trigger: React.FC<TriggerProps> = ({
  icon,
  render,
  colorScheme,
  variant,
  ...props
}) => {
  const triggerProps = {
    ...props,
    'aria-label': 'Ver mais',
  };

  if (render) {
    return <S.GhostButton {...triggerProps}>{render}</S.GhostButton>;
  }

  return (
    <S.Root {...triggerProps} className={S.baseStyle(colorScheme, variant)}>
      {icon ?? <S.IconFallbackImage>?</S.IconFallbackImage>}
    </S.Root>
  );
};
