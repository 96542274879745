import { gql } from '@apollo/client';
import { FEEDBACK_FRAGMENT } from './fragments/feedbackFragment';

export const CREATE_FEEDBACK = gql`
  ${FEEDBACK_FRAGMENT}

  mutation ($request: CreateFeedbackInput!) {
    createFeedback(request: $request) {
      ...FeedbackFields
    }
  }
`;
