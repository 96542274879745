import { gql } from '@apollo/client';

export const CREATE_CLINICAL_CASE_COMPLEXITY_SCORE = gql`
  mutation ($clinicalCaseId: ID!, $complexityScore: CreateComplexityScoreInput!) {
    createComplexityScore(clinicalCaseId: $clinicalCaseId, complexityScore: $complexityScore) {
      id
      score
      reason
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
