import { gql } from '@apollo/client';

export const SESSION_AND_NOTE_FRAGMENT = gql`
  fragment SessionAndNoteFields on Session {
    id
    clinicalCaseId
    discipline
    participants
    startScheduledAt
    endScheduledAt
    finished
    finalStartedAt
    finalEndedAt
    status
    statusDescription
    sessionType
    sessionTypeDescription
    observation
    cancellationReason
    clinicalGuidances {
      id
      title
    }
    skillAcquisitionNotes {
      id
      body
      createdAt
      updatedAt
      clinician {
        id
        name
        professionalRegistrationNumber
      }
      targetGroup {
        id
        name
        technicalName
      }
    }
    cancellation {
      reason
      reasonDescription
      comment
      requestedByRoleDescription
      inAdvance
    }
    note {
      id
      body
      healthChecks
      healthCheck
      createdBy {
        id
        clinician {
          id
          name
          professionalRegistrationNumber
        }
      }
      detail {
        ... on AbaNoteDetail {
          discipline
          usedReinforcers
          challengingBehaviors
        }
        ... on OccupationalTherapyNoteDetail {
          discipline
          usedReinforcers
          challengingBehaviors
          observation
          measurements {
            strategy
            response
          }
        }
        ... on SpeechTherapyNoteDetail {
          discipline
          usedReinforcers
          challengingBehaviors
          observation
          measurements {
            skill {
              id
              name
            }
            prompt
            response
          }
        }
      }
    }
    assessmentNote {
      id
      detail {
        ... on IndirectNoteDetail {
          type
          protocol
          observation
          participants
        }
        ... on DirectNoteDetail {
          type
          protocol
          observation
          usedReinforcers
          challengingBehaviors
          healthChecks
        }
        ... on FeedbackNoteDetail {
          type
          participants
          keyDevelopmentMilestones
          expectedReassessmentDate
          observation
          reportDelivered
        }
      }
      createdBy {
        id
        clinician {
          id
          name
          professionalRegistrationNumber
        }
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    location {
      label
      labelDescription
    }
    clinicians {
      id
      name
      professionalRegistrationNumber
    }
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
  }
`;
