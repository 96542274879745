import { useFirebase } from 'contexts/firebase';
import debounce from 'lodash/debounce';
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { UseFormReturn, useWatch, useFormState } from 'react-hook-form';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { logError } from 'utils/logger';
import { useAnalyticsEvents } from './useAnalyticsEvents';
import pickBy from 'lodash/pickBy';
import { AutoSaveStatus, AutoSaveStatuses } from 'types/hooks/formAutoSaveTypes';

export function useFormAutoSave<T extends Record<string, unknown>>(
  formMethods: UseFormReturn<T>,
  options: {
    formatDataBeforeReload?: (data: T) => T;
    clinicalCaseId: string;
    sessionId: string;
    collectionPrefix: string;
  },
) {
  const { firestore, userCredential } = useFirebase();
  const { isDirty, isSubmitSuccessful } = useFormState({
    control: formMethods.control,
  });
  const { sendAnalyticEvent } = useAnalyticsEvents();
  const [startRegister] = useState<string>(new Date().toISOString());
  const {
    clinicalCaseId,
    sessionId,
    collectionPrefix,
    formatDataBeforeReload = (data) => data,
  } = options;
  const [autoSaveStatus, setAutoSaveStatus] = useState<AutoSaveStatus>(AutoSaveStatuses.LOADING);
  const collectionDocName = `${collectionPrefix}-${sessionId}`;
  const watchedData = useWatch({
    control: formMethods.control,
  });
  const sendAnalyticsEvent = useRef<boolean>(false);

  const getDocumentReference = useCallback(() => {
    if (!userCredential?.user) return;

    const userCollection = collection(firestore, `user-form-draft-${userCredential.user.uid}`);
    return doc(userCollection, collectionDocName);
  }, [userCredential, firestore, collectionDocName]);

  const autoSaveFormData = useMemo(
    () =>
      debounce(async () => {
        try {
          const docReference = getDocumentReference();
          if (!docReference) return;

          setAutoSaveStatus(AutoSaveStatuses.SAVING);
          await updateDoc(docReference, {
            ...(pickBy(formMethods.getValues()) as T),
          });
          sendAnalyticsEvent.current = true;
          setAutoSaveStatus(AutoSaveStatuses.SAVED);
        } catch (error) {
          logError(error as Error, {
            component: 'useFormAutoSave',
            action: 'autoSaveFormData',
          });
          setAutoSaveStatus(AutoSaveStatuses.IDLE);
        }
      }, 2000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCredential, collectionDocName],
  );

  const removeData = async () => {
    try {
      const docReference = getDocumentReference();
      if (!docReference) return;

      await deleteDoc(docReference);
      sendAnalyticsEvent.current = false;
    } catch (err) {
      logError(err as Error, {
        component: 'useFormAutoSave',
        action: 'removeData',
      });
    }
  };

  useEffect(() => {
    const createOrUpdateDoc = async () => {
      const docReference = getDocumentReference();
      if (!docReference) return;

      try {
        const storedDoc = await getDoc(docReference);

        if (storedDoc.exists()) {
          const data = storedDoc.data() as T;
          formMethods.reset(formatDataBeforeReload(data));
        } else {
          await setDoc(docReference, {
            clinicalCaseId,
            sessionId,
          });
        }
      } catch (error) {
        logError(error as Error, {
          component: 'useFormAutoSave',
          action: 'createOrUpdateDoc',
        });
      } finally {
        setAutoSaveStatus(AutoSaveStatuses.IDLE);
      }
    };

    createOrUpdateDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalCaseId, sessionId, userCredential]);

  useEffect(() => {
    if (!isDirty) return;

    setAutoSaveStatus(AutoSaveStatuses.IDLE);
    autoSaveFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchedData), isDirty]);

  useEffect(() => {
    if (!isSubmitSuccessful) return;

    removeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    const sendEvent = sendAnalyticsEvent.current;

    return () => {
      if (sendEvent) {
        sendAnalyticEvent(`${collectionPrefix}-draft`, {
          clinical_case_id: clinicalCaseId,
          session_id: sessionId,
          start_register: startRegister,
          end_register: new Date().toISOString(),
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent.current]);

  return {
    autoSaveStatus,
    removeData,
  };
}
