import { ClinicalCase } from './clinicalCase';
import { Firebase } from './firebase';
import { Pendency } from './pendency';
import { Task, TotalTasks } from './task';
import {
  AvailableHour,
  ClinicalCaseAvailableHours,
  UserClinicalCaseHoursDetails,
} from './marketplace';
import { Planning } from './planning';
import { ClinicalGuidanceTask } from './clinicalGuidance';

export enum Auth0Role {
  ClinicalCaseOwner = 'clinical_case_owner',
  ClinicalCaseReference = 'clinical_case_reference',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  clinicalCases: ClinicalCase[];
  firebase: Firebase;
  tasks?: Task[];
  clinicalGuidanceTasks?: ClinicalGuidanceTask[];
  totalTasks?: TotalTasks;
  pendencies?: Pendency[];
  availableHours?: AvailableHour[];
  clinicalCaseAvailableHours: ClinicalCaseAvailableHours[];
  clinicalCaseHoursDetails?: UserClinicalCaseHoursDetails;
  plannings?: Planning[];
  planning?: Planning;
}
