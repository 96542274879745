import type { Task } from '../task';

export type ClinicalGuidanceContextType = 'INTERVENTION_SESSION';

type ClinicalGuidanceParticipant = {
  id: string;
  name: string;
};

type ClinicalGuidanceContext = {
  id?: string;
  type?: ClinicalGuidanceContextType;
  details?: {
    title: string;
  };
  clinicalCase: {
    id: string;
  };
};

export type ClinicalGuidanceComment = {
  id: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  createdBy: ClinicalGuidanceParticipant;
};

export enum ClinicalGuidanceStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export enum ClinicalGuidancePriority {
  High = 'HIGH',
  Low = 'LOW',
}

export enum ClinicalGuidanceCategory {
  Notice = 'NOTICE',
  Discussion = 'DISCUSSION',
}

export type ClinicalGuidance = {
  id: string;
  title: string;
  content: string;
  context: ClinicalGuidanceContext;
  status: ClinicalGuidanceStatus;
  priority: ClinicalGuidancePriority;
  category: ClinicalGuidanceCategory;
  createdBy: ClinicalGuidanceParticipant;
  comments?: ClinicalGuidanceComment[];
  tasks?: Task[];
  createdAt: string;
  updatedAt: string;
};

export type UpdateClinicalGuidanceInput = Partial<
  Pick<ClinicalGuidance, 'status' | 'priority' | 'title' | 'content' | 'context'>
>;

export type ClinicalGuidanceTaskCard = Pick<
  ClinicalGuidance,
  'title' | 'status' | 'category' | 'createdBy' | 'createdAt'
>;
