import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_CLINICIANS = gql`
  query getClinicalCaseClinicians($clinicalCaseId: ID!) {
    clinicalCase(id: $clinicalCaseId) {
      clinicians {
        id
        name
        user {
          id
        }
      }
    }
  }
`;
