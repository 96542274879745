import { styled, pxToRem, Flex, Button } from '@genialcare/atipico-react';

export const ResolveButton = styled(Button, {
  flex: 1,
  minWidth: 'auto',
  '@md': {
    minWidth: pxToRem(217),
    height: pxToRem(48),
  },
});

export const ConfirmSection = styled(Flex, {
  gap: pxToRem(24),
  flex: 1,
  padding: pxToRem(12),
  marginBottom: pxToRem(12),
  borderRadius: pxToRem(12),
  backgroundColor: '$neutral100',
  '@md': {
    flex: 'auto',
    marginBottom: pxToRem(0),
  },
});

export const ConfirmButton = styled(Button, {
  minWidth: '100%',
  '@md': {
    minWidth: pxToRem(100),
    height: pxToRem(48),
  },
});
