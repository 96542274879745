import React, { useState, useEffect, createRef, useCallback } from 'react';
import { Flex, Typography } from '@genialcare/atipico-react';
import { useLocation, useParams } from 'react-router-dom';
import { Session, SessionStatuses } from 'types';
import { SessionCard } from 'components/SessionCard';
import {
  Modal as ClinicalGuidanceModal,
  useModal as useClinicalGuidanceModal,
} from 'components/ClinicalGuidance';
import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import { useDevice } from 'hooks/useDevice';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import { DateFilter } from './components/DateFilter';
import { DisciplineFilter } from './components/DisciplineFilter';
import * as S from './InterventionSessionList.styles';

export interface SessionNoteListProps {
  sessions: Session[];
  session?: Session;
  fetchMoreSessions: (params: Record<string, unknown>) => void;
  limit: number;
}

type Data = { sessions: Session[] };
const LAST_UUID_REGEX = /\/([0-9a-fA-F-]+)$/i;

export const SessionNoteList: React.FC<SessionNoteListProps> = ({
  sessions: rawSessions = [],
  session: selectedSession,
  fetchMoreSessions,
  limit,
}) => {
  const sessions = [selectedSession, ...rawSessions].filter(
    (session) =>
      !!session &&
      ((session.status === SessionStatuses.Completed && (session.note || session.assessmentNote)) ||
        session.status === SessionStatuses.Cancelled),
  ) as Session[];

  const [sessionDetail, setSessionDetail] = useState<Session>(sessions[0]);
  const [hasMore, setHasMore] = useState(true);
  const { isSmallScreen } = useDevice();
  const { id = '' } = useParams();
  const { pathname = '' } = useLocation();
  const sessionsListRef = createRef<HTMLDivElement>();
  const { sendAnalyticEvent } = useAnalyticsEvents();

  useEffect(() => {
    if (sessions[0]) {
      handleSetSessionDetail(sessions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchMoreSessions = useCallback(() => {
    sendAnalyticEvent('intervention_sessions_end_page');

    if (!hasMore) return;

    fetchMoreSessions({
      variables: { id, limit, offset: rawSessions.length },
      updateQuery: (prev: Data, result: { fetchMoreResult: Data }) => {
        const { fetchMoreResult } = result;
        if (!fetchMoreResult) return prev;
        const { sessions: newSessions = [] } = fetchMoreResult || {};
        const currentSessions: Session[] = prev?.sessions || [];

        if (newSessions.length < limit) {
          setHasMore(false);
        }

        return {
          sessions: [...currentSessions, ...newSessions],
        };
      },
    });
  }, [hasMore, fetchMoreSessions, id, limit, rawSessions, sendAnalyticEvent]);

  useIntersectionObserver(handleFetchMoreSessions, sessionsListRef);

  const { handleGetClinicalGuidance, ...clinicalGuidanceModalProps } = useClinicalGuidanceModal();

  const handleSetSessionDetail = (session: Session) => {
    window.history.replaceState('', '', `${pathname.replace(LAST_UUID_REGEX, '')}/${session.id}`);

    setSessionDetail(session);
  };

  return (
    <S.Root direction="column" align={{ '@initial': 'center', '@lg': 'start' }}>
      <ClinicalGuidanceModal {...clinicalGuidanceModalProps} />

      <S.SessionsHeader>
        <S.SessionsPageTitle variant="h4" weight="bold">
          Anotações das sessões com a criança
        </S.SessionsPageTitle>
        <S.Actions>
          <DateFilter />
          <DisciplineFilter />
        </S.Actions>
      </S.SessionsHeader>

      <Flex css={{ width: '100%' }}>
        {sessions.length ? (
          <S.SessionsContainer>
            <S.SessionsList>
              {sessions.map((session, index) => (
                <SessionCard
                  data-testid={`session-card-detail-closed-${sessionDetail.id}`}
                  {...session}
                  key={`${session.id}--${index}`}
                  onClick={() => handleSetSessionDetail(session)}
                  handleGetClinicalGuidance={handleGetClinicalGuidance}
                  isOpen={isSmallScreen && session.id === sessionDetail?.id}
                  isSelected={!isSmallScreen && session.id === sessionDetail?.id}
                />
              ))}
              {hasMore && (
                <div ref={sessionsListRef}>
                  <Typography variant="subtitle">Carregando mais sessões...</Typography>
                </div>
              )}
            </S.SessionsList>
          </S.SessionsContainer>
        ) : (
          <S.EmptySessions variant="body1">Nenhuma sessão registrada</S.EmptySessions>
        )}
        {sessionDetail && (
          <S.SessionsDetails>
            <SessionCard
              {...sessionDetail}
              isOpen
              key={sessionDetail.id}
              data-testId={`session-card-detail-opened-${sessionDetail.id}`}
              handleGetClinicalGuidance={handleGetClinicalGuidance}
            />
          </S.SessionsDetails>
        )}
      </Flex>
    </S.Root>
  );
};
