import React from 'react';
import { Typography } from '@genialcare/atipico-react';
import { ClinicalGuidanceComment } from 'types';
import { CommentItem } from '../CommentItem';
import * as S from './CommentList.styles';

type CommentListProps = {
  userId: string;
  clinicalGuidanceId: string;
  data: ClinicalGuidanceComment[];
};

export const CommentList: React.FC<CommentListProps> = ({ userId, clinicalGuidanceId, data }) => (
  <S.Comments direction="column">
    <Typography variant="h5" weight="bold" color="neutral500">
      Comentários do time
    </Typography>

    <S.CommentsContent direction="column">
      {data.map((comment) => (
        <CommentItem
          key={`comment-${comment.id}`}
          data={comment}
          userId={userId}
          clinicalGuidanceId={clinicalGuidanceId}
        />
      ))}
    </S.CommentsContent>
  </S.Comments>
);
