import React, { ReactElement } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { datadogRum } from '@datadog/browser-rum';

type SplitIOProviderProps = {
  children: ReactElement;
};

const sdkConfig: SplitIO.IBrowserSettings = {
  scheduler: {
    featuresRefreshRate: 1,
  },
  core: {
    key: String(process.env.REACT_APP_SPLIT_IO_CUSTOMER_ID),
    authorizationKey: String(process.env.REACT_APP_SPLIT_IO_AUTHORIZATION_KEY),
  },
  impressionListener: {
    logImpression(impressionData) {
      datadogRum.addFeatureFlagEvaluation(
        impressionData.impression.feature,
        impressionData.impression.treatment,
      );
    },
  },
};

export const SplitIOProvider: React.FC<SplitIOProviderProps> = ({ children }) => (
  <SplitFactory config={sdkConfig}>{children}</SplitFactory>
);
