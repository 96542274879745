import React from 'react';
import ErrorSvg from 'assets/images/illustrations/error-list.svg';
import { BaseError } from '../BaseError';

export const GenericError = () => {
  return (
    <BaseError
      imageSrc={ErrorSvg}
      title="Ops..."
      subtitle="Ocorreu um erro!"
      description="Tivemos um erro inesperado na página que você tentou acessar. Por favor, tente novamente mais tarde."
    />
  );
};
