import React, { useRef, forwardRef, ReactNode, PropsWithChildren } from 'react';
import type { CSS, ThemeColors } from '@genialcare/atipico-react';
import { useToggle } from 'hooks/useToggle';
import { useMergeRefs } from 'hooks/useMergeRefs';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Trigger } from './Trigger';
import { Content } from './Content';
import * as S from './Tooltip.styles';

export type TooltipProps = PropsWithChildren & {
  css?: CSS;
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  colorScheme?: ThemeColors;
  contentColorScheme?: ThemeColors;
  trigger?: ReactNode;
  triggerIcon?: ReactNode;
  variant?: 'outline';
  onOpenChange?: (open: boolean) => void;
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      defaultIsOpen = false,
      colorScheme = 'purple500',
      contentColorScheme = colorScheme,
      css,
      isOpen,
      trigger,
      triggerIcon,
      onOpenChange,
      children,
      variant,
      ...props
    },
    ref,
  ) => {
    const controlled = isOpen !== undefined;
    const innerRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useMergeRefs(ref, innerRef);
    const [tooltipInternalState, toggleTooltipState] = useToggle(defaultIsOpen);

    const open = controlled ? isOpen! : tooltipInternalState;

    const toogleTooltip = () => {
      if (!controlled) {
        toggleTooltipState();
      }

      onOpenChange?.(!open);
    };

    const handleOnTrigger = (e: React.MouseEvent) => {
      e.preventDefault();
      toogleTooltip();
    };

    useOnClickOutside(innerRef, () => {
      if (open) toogleTooltip();
    });

    return (
      <S.Root {...props} ref={rootRef} css={css}>
        <Trigger
          render={trigger}
          icon={triggerIcon}
          colorScheme={colorScheme}
          onClick={handleOnTrigger}
          variant={variant}
        />

        <Content open={open} rootRef={innerRef} colorScheme={contentColorScheme}>
          {children}
        </Content>
      </S.Root>
    );
  },
);
