export type Child = {
  id: string;
  fullName: string;
  createdAt: string;
  updatedAt: string;
  calendar: ChildCalendarSlot[];
  calculatedOfficialScheduledHoursByDiscipline?: CalculatedOfficialScheduledHoursByDiscipline;
  downgraded?: boolean;
  schedulingScore?: SchedulingScore;
};

export type SchedulingScore = {
  score: number;
  consecutiveLowSchedulingRateDays: number;
};

export type ChildCalendarSlot = {
  weekday: string;
  startAt: string;
  endAt: string;
  schedules: Schedule[];
};

export type Schedule = {
  id: string;
  status: ScheduleStatus;
  collaborators: Collaborator[];
};

export enum ScheduleStatus {
  proposal = 'proposal',
  pre_allocated = 'pre_allocated',
  official = 'official',
}

export type Collaborator = {
  id: string;
  name: string;
};

export type CalculatedOfficialScheduledHoursByDiscipline = {
  aba: number;
  speechTherapy: number;
  occupationalTherapy: number;
};
