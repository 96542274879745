import { Box, Button, styled, pxToRem, Flex, Typography } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  marginTop: pxToRem(30, 12),

  '@lg': {
    marginTop: pxToRem(60),
  },
});
export const SessionsHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: pxToRem(24, 12),

  '@lg': {
    marginBottom: pxToRem(21),
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const SessionsPageTitle = styled(Typography, {
  marginBottom: pxToRem(12, 12),

  '@lg': {
    marginBottom: pxToRem(0),
  },
});

export const NewSessionButton = styled(Button, {
  width: '100%',
});

export const Actions = styled('div', {
  display: 'flex',
  gap: pxToRem(12),
});

export const SessionsContainer = styled(Box, {
  maxHeight: `calc(100vh - ${pxToRem(182, 12)})`,
  overflowY: 'scroll',

  '@lg': {
    maxHeight: `calc(99vh + ${pxToRem(370)})`,

    '&::-webkit-scrollbar': {
      width: pxToRem(6),
    },
    '&::-webkit-scrollbar-thumb': {
      background: '$neutral300',
      borderRadius: pxToRem(3),
    },
  },
});

export const EmptySessions = styled(Typography, {
  width: '100%',
  textAlign: 'center',

  '@lg': {
    textAlign: 'left',
  },
});

export const SessionsList = styled(Box, {
  width: 'inherit',

  '@lg': {
    maxWidth: pxToRem(460),
    paddingRight: pxToRem(15),
  },

  '@xxl': {
    minWidth: pxToRem(460),
  },
});

export const SessionsDetails = styled(Box, {
  width: '58%',
  marginLeft: pxToRem(28),
  display: 'none',
  '@lg': {
    display: 'block',
  },
});
