import React, { useMemo, ElementType, PropsWithChildren } from 'react';
import { Typography } from '@genialcare/atipico-react';
import type { ThemeColors } from '@genialcare/atipico-react';

export type TextVariant = 'title' | 'body';

type TextProps = PropsWithChildren & {
  as?: ElementType;
  color?: ThemeColors;
  variant?: TextVariant;
};

const variantMap: Record<TextVariant, Record<string, string>> = {
  title: {
    variant: 'subtitle',
    weight: 'bold',
  },
  body: {
    variant: 'subtitle',
  },
};

export const Text: React.FC<TextProps> = ({
  color = 'neutral0',
  variant = 'body',
  as,
  children,
  ...props
}) => {
  const variantProps = useMemo(() => variantMap[variant], [variant]);

  return (
    <Typography as={as} {...variantProps} {...props} color={color}>
      {children}
    </Typography>
  );
};
