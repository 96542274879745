import React from 'react';
import * as S from './DraftStatus.styles';
import { CSS } from '@genialcare/atipico-react';
import { AutoSaveStatus, AutoSaveStatuses } from 'types/hooks/formAutoSaveTypes';

type DraftStatusProps = {
  status: AutoSaveStatus;
  css?: CSS;
};

const draftStatus = {
  [AutoSaveStatuses.SAVING]: {
    icon: S.DraftSavingIcon,
    text: 'Salvando...',
  },
  [AutoSaveStatuses.SAVED]: {
    icon: S.DraftSavedIcon,
    text: 'Rascunho salvo',
  },
  [AutoSaveStatuses.IDLE]: {
    icon: S.DraftIcon,
    text: 'Rascunho',
  },
  [AutoSaveStatuses.LOADING]: {
    icon: S.DraftIcon,
    text: 'Carregando...',
  },
};

export const DraftStatus: React.FC<DraftStatusProps> = ({ status, ...otherProps }) => {
  const draftProps = draftStatus[status] || draftStatus[AutoSaveStatuses.IDLE];

  return (
    <S.Draft {...otherProps}>
      <draftProps.icon />
      <S.AutoSaveDraft>{draftProps.text}</S.AutoSaveDraft>
    </S.Draft>
  );
};
