import React from 'react';
import { CommunicationChat } from '@genialcare/atipico-react';
import { ClinicalGuidanceCommentAddedNotification } from 'types';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type ClinicalGuidanceCommentAddedProps =
  ComposeNotificationProps<ClinicalGuidanceCommentAddedNotification> & {
    total: number;
  };

export const ClinicalGuidanceMultipleCommentsAdded: React.FC<ClinicalGuidanceCommentAddedProps> = ({
  clinicalCaseId,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  createdAt,
  total,
  ...props
}) => {
  const href = buildURL.toClinicalGuidance(clinicalCaseId, clinicalGuidanceId);

  return (
    <Notification {...props} date={createdAt} href={href} icon={<CommunicationChat />}>
      <Notification.Title>
        {total} novos comentários na discussão '{clinicalGuidanceTitle}'.
      </Notification.Title>
    </Notification>
  );
};
