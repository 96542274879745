import React from 'react';
import type { CSS } from '@genialcare/atipico-react';
import Loader from 'assets/images/loader.svg';
import * as S from './Loading.styles';

type LoadingProps = {
  text?: string;
  css?: CSS;
};

const DEFAULT_LOADING_TEXT = 'Carregando...';

export const Loading: React.FC<LoadingProps> = ({ text = DEFAULT_LOADING_TEXT, css, ...rest }) => (
  <S.Wrapper {...rest} css={css}>
    <S.Image src={Loader} alt={text} />
    <S.Text variant="body2">{text}</S.Text>
  </S.Wrapper>
);

export default Loading;
