import { gql } from '@apollo/client';
import { DEMAND_FIELDS, OBSERVATION_FIELDS } from './fragments';

export const GET_DEMAND_BY_ID = gql`
  ${DEMAND_FIELDS}
  ${OBSERVATION_FIELDS}

  query getDemandById($clinicalCaseId: ID!, $demandId: ID!) {
    demand(clinicalCaseId: $clinicalCaseId, demandId: $demandId) {
      ...DemandFields
      observations {
        ...ObservationFields
      }
    }
  }
`;
