/* eslint-disable react-hooks/exhaustive-deps */
import { getToken, onMessage, MessagePayload, isSupported } from 'firebase/messaging';
import { useFirebase } from 'contexts/firebase';
import { useCallback, useEffect, useRef, useState } from 'react';
import { logError } from 'utils/logger';
import { REGISTER_USER_DEVICE } from 'queries';
import useAuthorizedMutation from './useAuthorizedMutation';
import { v4 as uuidV4 } from 'uuid';
import { useLocalStorage } from './useLocalStorage';

type RegisterDeviceData = {
  deviceId: string;
  deviceToken: string;
};

type Variables = Record<string, unknown>;

export type pushNotification = {
  isNotificationsEnabled: boolean;
  enableNotifications: () => Promise<boolean>;
  userDeviceToken: string | null;
  receivedMessages: MessagePayload[];
};

export const usePushNotifications = () => {
  const Notification = window.Notification || ({} as typeof window.Notification);
  const [receivedMessages, setReceivedMessages] = useState<Array<MessagePayload>>([]);
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState<boolean>(false);
  const [userDeviceToken, setUserDeviceToken] = useState<string | null>(null);
  const { messaging } = useFirebase();
  const [deviceId, setDeviceId] = useLocalStorage('userDeviceId', '');
  const initialized = useRef(false);

  const { mutate: registerUserDevice } = useAuthorizedMutation<RegisterDeviceData, Variables>(
    REGISTER_USER_DEVICE,
  );

  const persistUserDeviceToken = useCallback(
    (deviceToken: string) => {
      return registerUserDevice({
        variables: {
          deviceId,
          deviceToken,
        },
      });
    },
    [deviceId],
  );

  const generateDeviceToken = useCallback(async () => {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
      });
      await persistUserDeviceToken(currentToken);
      return currentToken;
    } catch (err) {
      if ((err as Error).message !== 'Login required') {
        logError(err as Error, { component: 'usePushNotifications' });
      }
      return null;
    }
  }, [deviceId, userDeviceToken]);

  useEffect(
    () => setIsNotificationsEnabled(Notification.permission === 'granted'),
    [Notification.permission],
  );

  useEffect(() => {
    if (!isNotificationsEnabled) return;
    if (deviceId) return;

    setDeviceId(uuidV4());
  }, [isNotificationsEnabled, deviceId]);

  const enableNotifications = async () => {
    try {
      const isSupportedBrowser = await isSupported();
      if (!isSupportedBrowser) return;
      if (initialized.current) return;

      initialized.current = true;
      if (!isNotificationsEnabled) {
        const permission = await Notification.requestPermission?.();
        if (permission !== 'granted') return false;
      }

      if (!userDeviceToken) {
        const firebaseToken = await generateDeviceToken();
        setUserDeviceToken(firebaseToken);
      }
    } catch (err) {
      logError(err as Error, { component: 'usePushNotifications/enableNotifications' });
    }

    return true;
  };

  onMessage(messaging, (payload) => {
    setReceivedMessages([...receivedMessages, payload]);
  });

  return {
    isNotificationsEnabled,
    enableNotifications,
    userDeviceToken,
    receivedMessages,
    deviceId,
  };
};
