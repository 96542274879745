import { TaskStatus } from 'types';

const data = {
  [TaskStatus.Open]: {
    text: 'Em aberto',
    bg: 'yellow300',
  },
  [TaskStatus.Resolved]: {
    text: 'Concluída',
    bg: 'green200',
  },
  [TaskStatus.Overdue]: {
    text: 'Atrasada',
    bg: 'orange200',
  },
  [TaskStatus.ToBeOverdue]: {
    text: 'A vencer',
    bg: 'pink300',
  },
};

export const getTaskCardLabelData = (status: TaskStatus) => data[status];
