import { styled, pxToRem } from '@genialcare/atipico-react';
import { VariantProps } from '@stitches/react';

export const Root = styled('ul', {
  paddingInlineStart: pxToRem(20),
  '& li': {
    marginTop: pxToRem(40),

    '&:first-child': {
      marginTop: 0,
    },
  },
  variants: {
    sublist: {
      true: {
        listStyleType: 'disc',
      },
      false: {
        listStyleType: 'decimal',

        '& > li::marker': {
          color: '$neutral500',
          fontWeight: '$bold',
          fontSize: pxToRem(18),
        },
      },
    },
  },
});

export type ListVariantProps = VariantProps<typeof Root>;
