import React, { ReactNode } from 'react';
import { pxToRem } from '@genialcare/atipico-react';
import { Badge } from '../../Badge';
import * as S from './Icon.styles';

type IconProps = {
  read: boolean;
  icon: ReactNode;
  notificationsCount: number;
  showBadgeCounter: boolean;
};

const NOTIFICATION_LIMIT_SIZE = 9;

export const Icon: React.FC<IconProps> = ({ read, icon, notificationsCount, showBadgeCounter }) => {
  const showCounter = showBadgeCounter && notificationsCount > 1;
  const counter = notificationsCount > NOTIFICATION_LIMIT_SIZE ? '+9' : notificationsCount;

  return (
    <S.Root align="center" justify="center" read={read} data-notification-icon>
      {icon}

      {showCounter && (
        <Badge
          fixed
          bg="red500"
          color="neutral0"
          data-testid="notification-icon-badge"
          css={{
            top: pxToRem(-5),
            right: pxToRem(-5),
          }}
        >
          {counter}
        </Badge>
      )}
    </S.Root>
  );
};
