import React from 'react';
import { ClinicalGuidanceCommentAddedNotification } from 'types';
import { ComposeNotificationProps } from '../Notification';
import { ClinicalGuidanceSingleCommentAdded } from './ClinicalGuidanceSingleCommentAdded';
import { ClinicalGuidanceMultipleCommentsAdded } from './ClinicalGuidanceMultipleCommentsAdded';

type ClinicalGuidanceCommentAddedProps =
  ComposeNotificationProps<ClinicalGuidanceCommentAddedNotification>;

type ClinicalGuidanceCommentsAddedProps = ClinicalGuidanceCommentAddedProps & {
  notifications?: ClinicalGuidanceCommentAddedProps[];
};

export const ClinicalGuidanceCommentsAdded: React.FC<ClinicalGuidanceCommentsAddedProps> = ({
  notifications,
  createdAt,
  ...props
}) => {
  const totalComments = notifications?.length || 1;
  const lastNotification = notifications?.sort(
    (a: ClinicalGuidanceCommentAddedProps, b: ClinicalGuidanceCommentAddedProps) =>
      a.createdAt.toDate().getTime() - b.createdAt.toDate().getTime(),
  )?.[totalComments - 1];

  return totalComments > 1 ? (
    <ClinicalGuidanceMultipleCommentsAdded
      {...props}
      createdAt={lastNotification?.createdAt || createdAt}
      total={totalComments}
    />
  ) : (
    <ClinicalGuidanceSingleCommentAdded createdAt={createdAt} {...props} />
  );
};
