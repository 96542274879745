import { gql } from '@apollo/client';
export const UPDATE_REGISTRY = gql`
  mutation ($clinicalGuidanceRegistry: UpdateClinicalGuidanceRegistryInput!) {
    updateClinicalGuidanceRegistry(clinicalGuidanceRegistry: $clinicalGuidanceRegistry) {
      id
      content
      status
      statusDescription
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
