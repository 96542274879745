import { pxToRem } from '@genialcare/atipico-react';
import React from 'react';
import * as S from './NoteTemplate.styles';
import { ParentalTrainingNote } from 'types/parentalTrainingNote';

export type TemplateData = {
  parentalTrainingNote: ParentalTrainingNote;
};

export const NoteTemplate: React.FC<TemplateData> = ({ parentalTrainingNote }) => {
  const renderTemplate = (note: ParentalTrainingNote) => {
    if (note.sessionGoals || note.nextSessionAgreements) {
      return (
        <>
          <S.AttributeTitle variant="h5" weight="bold" css={{ marginTop: pxToRem(40) }}>
            1 - Objetivos da sessão
          </S.AttributeTitle>

          <S.Text variant="body1">{note.sessionGoals}</S.Text>

          <S.AttributeTitle variant="h5" weight="bold">
            2 - Anotações relevantes sobre a sessão
          </S.AttributeTitle>

          <S.Text variant="body1">
            <span dangerouslySetInnerHTML={{ __html: note.body }} />
          </S.Text>

          <S.AttributeTitle variant="h5" weight="bold">
            3 - Combinados para a próxima sessão
          </S.AttributeTitle>

          <S.Text variant="body1" css={{ marginBottom: '48px' }}>
            {parentalTrainingNote?.nextSessionAgreements || 'N/A'}
          </S.Text>
        </>
      );
    } else {
      return <S.TemplateForOldNotes dangerouslySetInnerHTML={{ __html: note.body }} />;
    }
  };

  return (
    <>
      <S.Root>{parentalTrainingNote && renderTemplate(parentalTrainingNote)}</S.Root>
    </>
  );
};

export default NoteTemplate;
