import { pxToRem, styled } from '@genialcare/atipico-react';
import { InterfaceLogout as IconLogoutSvg } from '@genialcare/atipico-react';
import { menuIconStyles } from '../SideMenu.styles';
import { List } from './MenuContent.styles';

export const FooterItem = styled(List, {
  padding: `${pxToRem(32, 12)} ${pxToRem(6, 12)}`,
  marginTop: 'auto',
  borderTop: '1px solid $neutral100',
  transition: '0.5s',

  '@lg': {
    padding: `${pxToRem(32)} 0 ${pxToRem(14)} 0`,
  },
});
export const IconLogout = styled(IconLogoutSvg, {
  ...menuIconStyles,
});
