import { CSS, pxToRem } from '@genialcare/atipico-react';

export const baseStyle: CSS = {
  fontSize: pxToRem(14),
  flex: '1 1 auto',
};

export const opacityStyle = (read: boolean): CSS => ({
  opacity: read ? 0.6 : 1,
});

export const overflowTextStyle: CSS = {
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
