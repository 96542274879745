import { DefaultContext, MutationHookOptions } from '@apollo/client';
import { DELETE_TASK } from 'queries';
import { logError } from 'utils/logger';
import { useAuthorizedMutation } from './useAuthorizedMutation';

type Variables = {
  taskId: string;
};

export const useDeleteTask = (
  options?: MutationHookOptions<unknown, Variables, DefaultContext>,
) => {
  const {
    mutate,
    error,
    loading: isDeleting,
  } = useAuthorizedMutation<unknown, Variables>(DELETE_TASK, options);

  const deleteTask = async (taskId: string) => {
    try {
      await mutate({
        variables: {
          taskId,
        },
      });
    } catch (e) {
      logError(e as Error, {
        hook: 'useDeleteTask',
        mutation: 'DELETE_TASK',
      });
    }
  };

  return {
    error,
    deleteTask,
    isDeleting,
  };
};
