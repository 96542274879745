import { styled, pxToRem, css, Flex } from '@genialcare/atipico-react';

type BaseStyleProps = {
  colorScheme: string;
  height: number;
};

export const baseStyle = ({ colorScheme, height }: BaseStyleProps) =>
  css({
    bottom: `calc(${pxToRem(height)} + 16px)`,
    backgroundColor: `$${colorScheme}`,

    '&::before': {
      backgroundColor: `$${colorScheme}`,
    },
  })();

export const Root = styled(Flex, {
  gap: pxToRem(8),
  borderRadius: pxToRem(8),
  width: 'max-content',
  maxWidth: pxToRem(310),
  padding: `${pxToRem(12)} ${pxToRem(16)}`,
  visibility: 'hidden',
  position: 'absolute',
  left: pxToRem(-10),
  zIndex: 10,
  '&::before': {
    content: '',
    width: pxToRem(20),
    height: pxToRem(20),
    borderRadius: pxToRem(4),
    rotate: '45deg',
    position: 'absolute',
    bottom: -6,
    left: 12,
    zIndex: -1,
  },
  variants: {
    show: {
      true: {
        visibility: 'visible',
      },
    },
  },
});
