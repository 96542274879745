import { styled, pxToRem, Typography } from '@genialcare/atipico-react';
import type { CSS } from '@genialcare/atipico-react';

const baseStyle: CSS = {
  fontSize: pxToRem(14),
};

export const Root = styled(Typography, {
  ...baseStyle,
  color: '$neutral400',
});

export const Title = styled(Typography, {
  ...baseStyle,
  color: '$purple500',
  fontWeight: '$bold',
  variants: {
    isLink: {
      true: {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      false: {
        color: '$neutral400',
      },
    },
  },
});
