import debounce from 'lodash/debounce';
import { RefObject, useEffect, useState } from 'react';
import { useToggle } from './useToggle';

type webkitParams = {
  webkitLineClamp: string;
  display: string;
  webkitBoxOrient: string;
  overflow: string;
  textOverflow: string;
};

export const useLineClamp = <T extends HTMLElement>(ref: RefObject<T>, lines = 3) => {
  const [clamps, setClamps] = useState(true);
  const [toggle, handleToggle] = useToggle(true);

  useEffect(() => {
    const checkClamps = () => {
      if (!ref.current) return;

      const { webkitLineClamp, display, webkitBoxOrient, overflow, textOverflow } =
        ref.current.style;

      setWebKitStyle(ref, lines);

      setClamps(ref.current.clientHeight !== ref.current.scrollHeight);

      updateWebKitStyle(ref, {
        webkitLineClamp,
        display,
        webkitBoxOrient,
        overflow,
        textOverflow,
      });
    };

    const debouncedCheck = debounce(checkClamps, 50);

    checkClamps();

    window.addEventListener('resize', debouncedCheck);
    return () => window.removeEventListener('resize', debouncedCheck);
  }, [ref]);

  useEffect(() => {
    if (!ref.current) return;

    toggle ? setWebKitStyle(ref, lines) : removeWebKitStyle(ref);
  }, [toggle]);

  return { clamps, toggle, handleToggle };
};

const removeWebKitStyle = (ref: RefObject<HTMLElement>) => {
  if (!ref.current) return;

  updateWebKitStyle(ref, {
    webkitLineClamp: 'unset',
    display: 'block',
    webkitBoxOrient: 'unset',
    overflow: 'unset',
    textOverflow: 'unset',
  });
};

const setWebKitStyle = (ref: RefObject<HTMLElement>, lines: number) => {
  if (!ref.current) return;

  updateWebKitStyle(ref, {
    webkitLineClamp: lines.toString(),
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  });
};

const updateWebKitStyle = (ref: RefObject<HTMLElement>, params: webkitParams) => {
  if (!ref.current) return;

  ref.current.style.webkitLineClamp = params.webkitLineClamp;
  ref.current.style.display = params.display;
  ref.current.style.webkitBoxOrient = params.webkitBoxOrient;
  ref.current.style.overflow = params.overflow;
  ref.current.style.textOverflow = params.textOverflow;
};
