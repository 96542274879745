import React from 'react';
import {
  CardBody,
  CardFooter,
  CardTitle,
  CardContextualizable,
  CardDescription,
  CardAssignee,
  CardDueDate,
} from 'components/Card';
import { TaskCard } from '../TaskCard';
import { TaskContextType, type TaskContext } from 'types';
import type { ComposeTaskCardProps } from '../TaskCard.types';
import { buildURL } from 'utils/build-urls';

type ClinicalCaseTaskCardProps = ComposeTaskCardProps<{
  title: string;
  dueDate?: string;
  description?: string;
  context: Pick<TaskContext, 'clinicalCase' | 'details' | 'id' | 'type'>;
}>;

export const ClinicalCaseTaskCard: React.FC<ClinicalCaseTaskCardProps> = ({
  title,
  description,
  context,
  dueDate,
  assignee,
  ...props
}) => {
  const registryDetailsTitle =
    context?.type === TaskContextType.Registry ? 'em Orientação Clínica' : null;
  const contextDetailsTitle = context?.details?.title || registryDetailsTitle;
  const contextualizablePrefix = context?.details?.title ? 'em' : 'Nesse caso';
  const contextualizableTitle = contextDetailsTitle ? contextDetailsTitle : '';

  const contextUrl =
    context && [TaskContextType.ClinicalGuidance, TaskContextType.Registry].includes(context.type)
      ? buildURL.toTaskContextualizable(context)
      : null;

  return (
    <TaskCard title={title} assignee={assignee} {...props}>
      <CardBody>
        <CardTitle overflowTextLineValue={3}>{title}</CardTitle>
        <CardContextualizable prefix={contextualizablePrefix} onClickUrlRedirect={contextUrl}>
          {contextualizableTitle}
        </CardContextualizable>

        {description && <CardDescription text={description} />}
      </CardBody>

      <CardFooter>
        <CardAssignee name={assignee.name} />
        <CardDueDate date={dueDate} />
      </CardFooter>
    </TaskCard>
  );
};
