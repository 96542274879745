import React, { useState } from 'react';
import { GeneralCalendar, InputErrorMessage } from '@genialcare/atipico-react';
import { useSessionFilters } from 'contexts/filterSessions';
import * as S from './DateFilter.styles';

export const DateFilter = () => {
  const { setFilters, filters } = useSessionFilters();
  const [startDate, setStartedDate] = useState<string>();
  const [endDate, setEndedDate] = useState<string>();
  const [error, setError] = useState<string>();

  const revertDate = (date: string) => date.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1');

  const convertDate = (date: Date) =>
    date.toLocaleDateString('pt-BR').replace(/(\d{2})\/(\d{2})\/(\d{4}).*/, '$3-$2-$1');

  const addStartedDate = (date: Date) => {
    if (!date) return;
    setStartedDate(convertDate(date));
  };

  const addEndedDate = (date: Date) => {
    if (!date) return;
    setEndedDate(convertDate(date));
  };

  const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!startDate) {
      setError('Faltou você preencher esse campo');
      return false;
    }

    setFilters({
      startDate,
      endDate,
    });
  };

  const initialDates = {
    startDate: filters?.startDate ? revertDate(filters.startDate) : '',
    endDate: filters?.endDate ? revertDate(filters.endDate) : '',
  };

  return (
    <S.Filter
      date-testid="date-filter-button"
      icon={<GeneralCalendar data-testid="date-filter-icon" />}
      title="Ver por data"
      onFilter={handleFilter}
      onClear={() => setFilters({ startDate: null, endDate: null })}
    >
      <S.Label variant="body2">Data inicial*:</S.Label>
      <S.Input
        type="date"
        data-testid="start-date-filter-input"
        isInvalid={!!error}
        onComplete={addStartedDate}
        defaultValue={initialDates.startDate}
      />
      {error && <InputErrorMessage>{error}</InputErrorMessage>}
      <S.Label variant="body2">Data final:</S.Label>
      <S.Input
        type="date"
        data-testid="end-date-filter-input"
        onComplete={addEndedDate}
        defaultValue={initialDates.endDate}
      />
    </S.Filter>
  );
};
