import React from 'react';
import type { ThemeColors } from '@genialcare/atipico-react';
import { ClinicalGuidanceStatus } from 'types';
import { Label } from '../Label';

type StatusLabelProps = {
  status: ClinicalGuidanceStatus;
};

const labelData = {
  [ClinicalGuidanceStatus.Open]: {
    text: 'Em andamento',
    bg: 'yellow300',
  },
  [ClinicalGuidanceStatus.Resolved]: {
    text: 'Resolvida',
    bg: 'green200',
  },
};

export const StatusLabel: React.FC<StatusLabelProps> = ({ status, ...props }) => {
  const { text, bg } = labelData[status];

  return (
    <Label {...props} color="purple500" bg={bg as ThemeColors}>
      {text}
    </Label>
  );
};
