import { styled, pxToRem, css } from '@genialcare/atipico-react';

export const colorSchemeStyle = (colorScheme: string) =>
  css({
    '&:checked': {
      borderColor: 'transparent',
      backgroundColor: `$${colorScheme}`,
    },
    '&:checked:disabled': {
      borderColor: 'transparent',
    },
  })();

export const Root = styled('input', {
  appearance: 'none',
  display: 'grid',
  placeContent: 'center',
  width: pxToRem(26),
  height: pxToRem(24),
  borderRadius: pxToRem(4),
  border: '1px solid $neutral300',
  '&:disabled': {
    opacity: 0.9,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: '$neutral100',
  },
  '&:checked::before': {
    transform: 'scale(1)',
  },
  '&::before': {
    content: '',
    width: pxToRem(12),
    height: pxToRem(12),
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
    backgroundColor: '$neutral0',
    clipPath: 'polygon(6.25% 54%, 0% 64.5%, 36.25% 100%, 100% 7.5%, 89.75% 0%, 36.25% 82.75%)',
  },
});
