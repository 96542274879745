import React, { PropsWithChildren } from 'react';
import { Typography } from '@genialcare/atipico-react';
import { useNotificationData } from 'contexts/notification';
import * as S from './Title.styles';

export const Title: React.FC<PropsWithChildren> = ({ children }) => {
  const { read } = useNotificationData();

  return (
    <Typography
      as="h1"
      variant="subtitle"
      color="neutral500"
      data-testid="notification-title"
      css={{
        ...S.baseStyle,
        ...S.opacityStyle(read),
        ...S.overflowTextStyle,
      }}
    >
      {children}
    </Typography>
  );
};
