import { graphql } from 'msw';
import {
  Session,
  SessionStatuses,
  SessionTypes,
  SessionLocation,
  GeneralSessionClinician,
  SessionCancellation,
  Sessionable,
  Pendency,
} from 'types';

const MOCKED_CLINICAL_CASES = [
  'ed62af10-5272-41a6-b530-1f0de21e251d', // Geninho
  'd217af73-36b5-410b-bd21-a07686c1aef4', // Hanna Montana
];

const createdBy = {
  __typename: 'SessionUser',
  id: 'fake-created-by-id',
  name: 'Geninho',
};

const updatedBy = {
  __typename: 'SessionUser',
  id: 'fake-updated-by-id',
  name: 'Genona',
};

export const mockClinicalGuidanceSessions: Session[] = [
  {
    __typename: 'Session',
    id: 'fake-session-id',
    clinicalCaseId: 'fake-clinical-case-id',
    clinicalCase: {
      __typename: 'ClinicalCase',
      id: `fake-clinical-case-id`,
      name: 'Geninho',
    },
    discipline: 'ABA',
    participants: ['Outros'],
    startScheduledAt: '2023-07-11T14:00:00.000Z',
    endScheduledAt: '2023-07-11T15:00:00.000Z',
    finalStartedAt: '2023-07-12T16:00:00.000Z',
    finalEndedAt: '2023-07-12T17:00:00.000Z',
    finished: false,
    status: SessionStatuses.Scheduled,
    statusDescription: 'Agendada',
    sessionType: SessionTypes.ClinicalGuidance,
    sessionTypeDescription: 'Orientação clínica',
    observation: '',
    cancellationReason: '',
    cancellation: {
      __typename: 'SessionCancellation',
      reason: 'without_justification',
      reasonDescription: '',
      comment: '',
      requestedByRoleDescription: '',
      inAdvance: false,
    } as SessionCancellation,
    createdAt: '2023-06-20T18:44:59.084Z',
    updatedAt: '2023-06-26T19:18:38.064Z',
    location: {
      __typename: 'SessionLocation',
      label: 'online',
      labelDescription: 'Online',
    } as SessionLocation,
    clinicians: [
      {
        __typename: 'GeneralSessionClinician',
        id: '91cc3301-083f-404d-817a-c51eb51a974e',
        name: 'Dev',
      } as GeneralSessionClinician,
    ],
    sessionable: {} as Sessionable,
    createdBy,
    updatedBy,
    pendencies: [] as Pendency[],
  } as Session,
];

export default graphql.query('getClinicalCaseSessions', (req, res, ctx) => {
  const { id } = req.variables;

  if (!MOCKED_CLINICAL_CASES.includes(id)) return;

  return res(
    ctx.delay(3000),
    ctx.data({
      clinicalCase: {
        id,
        interventionSessions: [
          {
            id: 'cbd5e480-662e-41c4-9019-fdbf6b386c01',
            discipline: 'Fono',
            isCancelled: false,
            cancellationReason: null,
            createdAt: '2023-02-08T21:59:51.416Z',
            startedAt: '2023-02-08T21:59:51.416Z',
            title: 'A cuidadora desmarcou esta sessão',
            healthCheck: 'Congestão nasal',
            participants: ['Cuidador', 'Outro familiar'],
            usedReinforcers: 'Brincadeiras lúdicas, aviões, geleca',
            challengingBehaviors: 'Inflexibilidade',
            clinicalGuidances: [
              {
                id: 'ffe6a9d4-c217-4978-8b94-0156f3bacf59',
                title: 'Arthur ainda parece estar com piolho',
              },
              {
                id: 'ffe6a9d4-c217-4978-8b94-0156f3bacf50',
                title: 'Arthur ainda parece estar com piolho',
              },
            ],
            clinician: {
              id: 'ffab1de3-6732-463b-89b2-ea737bbd769e',
              name: 'Clinician',
              professionalRegistrationNumber: 'CRFa 2-19681',
            },
            note: {
              id: '051a1942-209e-4c5c-ba25-8805fd860cb8',
              body: 'A cuidadora Kelly entrou em contato via WhatsApp informando que o Arthur não estava se sentindo muito bem hoje e precisou levar ele ao médico no horário da sessão que estava agendada. Vamos realizar o reagendamento da sessão de TO com o time de OPS para quando ele estiver se sentido melhor.',
              healthInsuranceReport:
                'Atendimento em (Psicologia ABA, Fonoaudiologia, Terapia Ocupacional) realizado com referido paciente no dia (XX/XX/XXXX) com duração de (X) horas. Paciente tem diagnóstico de Transtorno do Espectro Autista e na sessão desta data foram utilizados (copiar e colar tópico 4) para ensino e desenvolvimento das habilidades programadas no seu PEI, tendo a criança apresentado desempenho (bom, razoável, excelente, médio, péssimo) e dificuldades em (copiar e colar tópico 5). Sugere-se manutenção dos atendimentos terapêuticos na frequência e carga horária em vigor.',
              questionsForSupervision: 'Arthur ainda parece estar com piolho',
            },
            skillAcquisitionNotes: [],
          },
          {
            id: 'cbd5e480-662e-41c4-9019-fdbf6b386c01',
            discipline: 'Fono',
            isCancelled: false,
            cancellationReason: null,
            createdAt: '2023-02-08T21:59:51.416Z',
            startedAt: '2023-02-08T21:59:51.416Z',
            title: 'A cuidadora desmarcou esta sessão',
            healthCheck: 'Congestão nasal',
            participants: ['Cuidador', 'Outro familiar'],
            usedReinforcers: 'Brincadeiras lúdicas, aviões, geleca',
            challengingBehaviors: 'Inflexibilidade',
            clinician: {
              id: 'ffab1de3-6732-463b-89b2-ea737bbd769e',
              name: 'Clinician',
              professionalRegistrationNumber: 'CRFa 2-19681',
            },
            note: {
              id: '051a1942-209e-4c5c-ba25-8805fd860cb8',
              body: 'A cuidadora Kelly entrou em contato via WhatsApp informando que o Arthur não estava se sentindo muito bem hoje e precisou levar ele ao médico no horário da sessão que estava agendada. Vamos realizar o reagendamento da sessão de TO com o time de OPS para quando ele estiver se sentido melhor.',
              healthInsuranceReport:
                'Atendimento em (Psicologia ABA, Fonoaudiologia, Terapia Ocupacional) realizado com referido paciente no dia (XX/XX/XXXX) com duração de (X) horas. Paciente tem diagnóstico de Transtorno do Espectro Autista e na sessão desta data foram utilizados (copiar e colar tópico 4) para ensino e desenvolvimento das habilidades programadas no seu PEI, tendo a criança apresentado desempenho (bom, razoável, excelente, médio, péssimo) e dificuldades em (copiar e colar tópico 5). Sugere-se manutenção dos atendimentos terapêuticos na frequência e carga horária em vigor.',
              questionsForSupervision: 'Arthur ainda parece estar com piolho',
            },
            skillAcquisitionNotes: [],
          },
          {
            id: 'cbd5e480-662e-41c4-9019-fdbf6b386c01',
            discipline: 'Fono',
            isCancelled: false,
            cancellationReason: null,
            createdAt: '2023-02-08T21:59:51.416Z',
            title: 'A cuidadora desmarcou esta sessão',
            healthCheck: 'Congestão nasal',
            participants: ['Cuidador', 'Outro familiar'],
            usedReinforcers: 'Brincadeiras lúdicas, aviões, geleca',
            challengingBehaviors: 'Inflexibilidade',
            clinician: {
              id: 'ffab1de3-6732-463b-89b2-ea737bbd769e',
              name: 'Clinician',
              professionalRegistrationNumber: 'CRFa 2-19681',
            },
            note: {
              id: '051a1942-209e-4c5c-ba25-8805fd860cb8',
              body: 'A cuidadora Kelly entrou em contato via WhatsApp informando que o Arthur não estava se sentindo muito bem hoje e precisou levar ele ao médico no horário da sessão que estava agendada. Vamos realizar o reagendamento da sessão de TO com o time de OPS para quando ele estiver se sentido melhor.',
              healthInsuranceReport:
                'Atendimento em (Psicologia ABA, Fonoaudiologia, Terapia Ocupacional) realizado com referido paciente no dia (XX/XX/XXXX) com duração de (X) horas. Paciente tem diagnóstico de Transtorno do Espectro Autista e na sessão desta data foram utilizados (copiar e colar tópico 4) para ensino e desenvolvimento das habilidades programadas no seu PEI, tendo a criança apresentado desempenho (bom, razoável, excelente, médio, péssimo) e dificuldades em (copiar e colar tópico 5). Sugere-se manutenção dos atendimentos terapêuticos na frequência e carga horária em vigor.',
              questionsForSupervision: '',
            },
            skillAcquisitionNotes: [],
          },
        ],
      },
    }),
  );
});
