import { CSS, pxToRem } from '@genialcare/atipico-react';

export const baseStyle: CSS = {
  opacity: 0.6,
  fontSize: pxToRem(14),
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
