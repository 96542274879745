import { gql } from '@apollo/client';

export const GET_FIREBASE_CUSTOM_TOKEN = gql`
  query getFirebaseCustomToken {
    user {
      firebase {
        token
      }
    }
  }
`;
