import { VariantProps } from '@stitches/react';
import { styled, pxToRem, Flex } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  width: '100%',
  gap: pxToRem(16),
  margin: `${pxToRem(54)} auto`,
  '@md': {
    width: pxToRem(320),
  },
});

export const IconContainer = styled(Flex, {
  borderRadius: '100%',

  variants: {
    iconSize: {
      sm: {
        width: pxToRem(56),
        height: pxToRem(56),
        svg: {
          width: pxToRem(40),
          height: pxToRem(40),
        },
      },
      md: {
        width: pxToRem(72),
        height: pxToRem(72),
        svg: {
          width: pxToRem(32),
          height: pxToRem(32),
        },
      },
    },
    iconColor: {
      light: {
        backgroundColor: '$neutral100',
        svg: {
          fill: '$neutral200',
        },
      },
      dark: {
        backgroundColor: '$neutral200',
        svg: {
          fill: '$neutral300',
        },
      },
    },
    iconShape: {
      circle: {
        borderRadius: '100%',
      },
      square: {
        borderRadius: pxToRem(16),
      },
    },
  },
  defaultVariants: {
    iconSize: 'sm',
    iconColor: 'dark',
    iconShape: 'circle',
  },
});
export type IconContainerVariants = VariantProps<typeof IconContainer>;

export const Icon = styled('img', {
  width: pxToRem(10),
  height: pxToRem(10),
  objectFit: 'contain',
  fill: '$neutral200',
});
