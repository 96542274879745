import { gql } from '@apollo/client';

export const UPDATE_SUBJECT_CLINICAL_DECISION = gql`
  mutation updateSubjectClinicalDecision($decisionInput: UpdateSubjectClinicalDecisionInput!) {
    updateSubjectClinicalDecision(subjectClinicalDecisionInput: $decisionInput) {
      id
      text
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
