import { graphql } from 'msw';

export default graphql.mutation('createSubject', (req, res, ctx) => {
  const {
    subjectInput: { text },
  } = req.variables;

  if (text.toLowerCase() === 'error') {
    return res(ctx.delay(3000), ctx.errors([]));
  }

  return res(
    ctx.delay(3000),
    ctx.data({
      createSubject: {
        text,
        id: 'cc897d35-ed79-46bd-9472-de3191286822',
        status: 'open',
        statusDescription: 'Aberta',
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-01T00:00:00',
        createdBy: {
          id: 'ad67591b-d9c4-41bc-b413-135b659003f5',
          name: 'Geninho',
        },
        updatedBy: {
          id: 'f23fe3b9-9663-4b41-a6c1-ba9734d8d11e',
          name: 'Genona',
        },
      },
    }),
  );
});
