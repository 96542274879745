import React from 'react';
import { useParams } from 'react-router-dom';
import { SessionNoteList } from 'components/SessionNoteList';
import useAuthorizedQuery from 'hooks/useAuthorizedQuery';
import { Loading } from 'components/Loading';
import { GET_CLINICAL_CASE_SESSIONS_AND_NOTES } from 'queries';
import { useSessionFilters } from 'contexts/filterSessions';
import { logError } from 'utils/logger';
import { Session, SessionStatuses, SessionTypes } from 'types';
import { GET_CLINICAL_CASE_SESSION_AND_NOTE_BY_ID } from 'queries';

export const PAGE_SESSIONS_LIMIT = 5;

type GetSessionsResponse = {
  sessions: Session[];
  startDate?: string | null;
  endDate?: string | null;
  disciplines?: string[] | null;
  user?: string | null;
};

type Variables = Record<string, unknown>;

type GetSessionByIdResponse = {
  session: Session;
};

type GetSessionByIdVariable = {
  id: string;
};

type SessionNoteProps = {
  sessionTypes?: SessionTypes[];
};

const DEFAULT_SESSION_TYPES = [SessionTypes.Intervention];

export const SessionNotes: React.FC<SessionNoteProps> = ({
  sessionTypes = DEFAULT_SESSION_TYPES,
}) => {
  const { id = '', sessionId = '' } = useParams();
  const { filters } = useSessionFilters();
  const { startDate: startScheduledAt, endDate: endScheduledAt, discipline } = filters ?? {};

  const { loading, error, data, fetchMore } = useAuthorizedQuery<GetSessionsResponse, Variables>(
    GET_CLINICAL_CASE_SESSIONS_AND_NOTES,
    {
      variables: {
        clinicalCaseId: id,
        statuses: [SessionStatuses.Completed, SessionStatuses.Cancelled],
        sessionTypes: sessionTypes,
        limit: PAGE_SESSIONS_LIMIT,
        offset: 0,
        startScheduledAt,
        endScheduledAt,
        discipline,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: sessionByIdLoading, data: sessionByIdData } = useAuthorizedQuery<
    GetSessionByIdResponse,
    GetSessionByIdVariable
  >(
    GET_CLINICAL_CASE_SESSION_AND_NOTE_BY_ID,
    {
      variables: {
        id: sessionId,
      },
      fetchPolicy: 'cache-and-network',
      onError: (e) => {
        logError(e, {
          component: 'SessionNotes',
          query: 'GET_CLINICAL_CASE_SESSION_AND_NOTE_BY_ID',
        });
      },
    },
    { executeQueryIf: () => !!sessionId },
  );

  if (error) {
    logError(error, {
      component: 'SessionNotes',
      query: 'GET_CLINICAL_CASE_SESSIONS_AND_NOTES',
    });

    return <p>erro ao carregar.</p>;
  }

  if (loading || sessionByIdLoading) return <Loading />;

  const sessions = data?.sessions ?? [];

  return (
    <SessionNoteList
      sessions={sessions}
      session={sessionByIdData?.session}
      fetchMoreSessions={fetchMore}
      limit={PAGE_SESSIONS_LIMIT}
    />
  );
};

export default SessionNotes;
