import {
  styled,
  pxToRem,
  Flex,
  Typography,
  Button as ButtonDefault,
} from '@genialcare/atipico-react';
import { Card as CardDefault } from 'components/Card';

export const Card = styled(CardDefault, {
  padding: pxToRem(8),
});

export const Root = styled(Flex, {
  flex: '1 1 auto',
  gap: pxToRem(24),
  padding: `${pxToRem(42)} ${pxToRem(16)}`,
  borderRadius: pxToRem(12),
  backgroundColor: '$neutral100',
});

export const Content = styled(Flex, {
  gap: pxToRem(4),
  textAlign: 'center',
});

export const TaskTitle = styled(Typography, {
  '&::before, &::after': {
    color: '$neutral400',
  },
  '&::before': {
    content: 'attr(data-prefix)',
  },
  '&::after': {
    content: 'attr(data-suffix)',
  },
});

export const Actions = styled(Flex, {
  gap: pxToRem(8),
});

export const Button = styled(ButtonDefault, {
  minWidth: 'auto',
  width: pxToRem(116),
  height: pxToRem(40),
  fontSize: pxToRem(12),
  variants: {
    colorScheme: {
      red: {
        color: '$neutral0',
        backgroundColor: '$red500',
        '&:hover, &:disabled': {
          backgroundColor: '$red500',
        },
      },
    },
  },
});
