import React, { useEffect, useRef } from 'react';
import { Typography } from '@genialcare/atipico-react';
import { Checkbox } from 'components/Checkbox';
import * as S from './SelectDropdownItem.styles';

type SelectDropdownItemProps = {
  text: string;
  value: string;
  checked?: boolean;
  onSelect: (value: string, checked: boolean) => void;
};

export const SelectDropdownItem: React.FC<SelectDropdownItemProps> = ({
  text,
  value,
  checked,
  onSelect,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleAvoidCloseSelect = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(value, e.target.checked);
  };

  useEffect(() => {
    if (!checked && checkboxRef.current) {
      checkboxRef.current.checked = false;
    }
  }, [checked]);

  return (
    <S.Root onClick={handleAvoidCloseSelect}>
      <S.Label htmlFor={value}>
        <Checkbox
          data-testid={`select-dropdown-item-${value}`}
          ref={checkboxRef}
          id={value}
          onChange={handleOnChange}
          defaultChecked={checked}
          css={{ flex: '0 0 auto' }}
        />
        <Typography variant="body2" color="neutral400">
          {text}
        </Typography>
      </S.Label>
    </S.Root>
  );
};
