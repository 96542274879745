import React from 'react';
import { useParams } from 'react-router-dom';
import useAuthorizedQuery from 'hooks/useAuthorizedQuery';
import { Loading } from 'components/Loading';
import { useSessionFilters } from 'contexts/filterSessions';
import { logError } from 'utils/logger';
import { Session, SessionStatuses, SessionTypes } from 'types';
import { GET_PARENTAL_TRAINING_SESSIONS_AND_NOTES_BY_CLINICAL_CASE } from 'queries/parentalTraining/getParentalTrainingSessionsAndNotesByClinicalCaseId';
import { ParentalTrainingSessionList } from 'components/ParentalTraining/SessionList';
import { GET_PARENTAL_TRAINING_SESSION_AND_NOTE_BY_SESSION_ID } from 'queries/parentalTraining/getParentalTrainingSessionAndNoteBySessionId';

export const PAGE_SESSIONS_LIMIT = 5;

type SessionsResponse = {
  sessions: Session[];
  startDate?: string | null;
  endDate?: string | null;
  user?: string | null;
};

type SessionByIdResponse = {
  session: Session;
};

type Variables = Record<string, unknown>;

export const List = () => {
  const { id = '', sessionId = '' } = useParams();
  const { filters } = useSessionFilters();
  const { startDate: startScheduledAt, endDate: endScheduledAt } = filters ?? {};

  const { loading, error, data, fetchMore } = useAuthorizedQuery<SessionsResponse, Variables>(
    GET_PARENTAL_TRAINING_SESSIONS_AND_NOTES_BY_CLINICAL_CASE,
    {
      variables: {
        clinicalCaseId: id,
        statuses: [SessionStatuses.Completed, SessionStatuses.Cancelled],
        sessionTypes: [SessionTypes.ParentalTraining],
        limit: PAGE_SESSIONS_LIMIT,
        offset: 0,
        startScheduledAt,
        endScheduledAt,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: sessionByIdLoading, data: sessionByIdData } = useAuthorizedQuery<
    SessionByIdResponse,
    Variables
  >(
    GET_PARENTAL_TRAINING_SESSION_AND_NOTE_BY_SESSION_ID,
    {
      variables: {
        id: sessionId,
      },
      fetchPolicy: 'cache-and-network',
      onError: (e) => {
        logError(e, {
          component: 'ParentalTraining/Note/List',
          query: 'GET_PARENTAL_TRAINING_SESSION_AND_NOTE_BY_SESSION_ID',
        });
      },
    },
    {
      executeQueryIf: () => !!sessionId,
    },
  );

  if (error) {
    logError(error, {
      component: 'ParentalTraining/Note/List',
      query: 'GET_PARENTAL_TRAINING_SESSIONS_AND_NOTES_BY_CLINICAL_CASE',
    });

    return <p>erro ao carregar.</p>;
  }

  if (loading || sessionByIdLoading) return <Loading />;

  const sessions = data?.sessions ?? [];

  return (
    <>
      <ParentalTrainingSessionList
        sessions={sessions}
        session={sessionByIdData?.session}
        fetchMoreSessions={fetchMore}
        limit={PAGE_SESSIONS_LIMIT}
      />
    </>
  );
};

export default List;
