import React from 'react';
import type { Timestamp } from 'firebase/firestore';
import { Typography } from '@genialcare/atipico-react';
import { formatPtBrDate } from 'utils/date';

type DateProps = {
  date: Timestamp;
};

export const NotificationDate: React.FC<DateProps> = ({ date }) => (
  <Typography as="h6" variant="subtitle" color="neutral300" data-testid="notification-date">
    {formatPtBrDate(date.toDate(), { dateStyle: 'long' })}
  </Typography>
);
