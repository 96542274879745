import { pxToRem, styled, CSS } from '@genialcare/atipico-react';
import {
  Box,
  InterfaceMenu as IconMenuSvg,
  InterfaceCross as IconMenuCloseSvg,
} from '@genialcare/atipico-react';

const displayIconStyle: CSS = {
  display: 'block',
  '@lg': {
    display: 'none',
  },
};

export const IconMenu = styled(IconMenuSvg, {
  width: pxToRem(42, 12),
  height: pxToRem(50, 12),
  fill: '$purple100',
  ...displayIconStyle,
});

export const IconMenuClose = styled(IconMenuCloseSvg, {
  width: pxToRem(50, 12),
  height: pxToRem(50, 12),
  fill: '$purple100',
  ...displayIconStyle,
});

export const Logo = styled(Box, {
  transition: '0.5s',
  display: 'none',
  width: pxToRem(36),
  height: pxToRem(75),
  alignSelf: 'center',
  '@lg': {
    display: 'block',
  },

  variants: {
    isOpen: {
      true: {
        width: pxToRem(137),
      },
    },
  },
});
