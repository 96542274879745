import { useUserData } from 'contexts/user';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import {
  SKILL_ACQUISITION_NOTE,
  SKILL_ACQUISITION_NOTE_REQUIRED_FIELD,
  SHOW_SUGGESTED_CHILD_PROGRESSION_NOTE,
} from 'constants/flags';

export const useSkillAcquisitionNoteFlags = () => {
  const { email } = useUserData();
  const showFields = useFeatureFlag(SKILL_ACQUISITION_NOTE);
  const bodyFieldRequired = useFeatureFlag(SKILL_ACQUISITION_NOTE_REQUIRED_FIELD);
  const showSuggestedChildProgressionNote = useFeatureFlag(SHOW_SUGGESTED_CHILD_PROGRESSION_NOTE, {
    attributes: {
      user_email: email,
    },
  });

  return {
    showFields,
    bodyFieldRequired,
    showSuggestedChildProgressionNote,
  };
};
