export type ClinicalGuidancesPlanning = {
  id: string;
  status: ClinicalGuidancesPlanningStatus;
  plannedAt: string;
  clinicalCase: ClinicalCasePlanning;
  note: string;
  mentor: MentorPlanning;
  mentee: MenteePlanning;
  task: TaskPlanning;
  registry: RegistryPlanning;
  createdAt: string;
  updatedAt: string;
};

export enum ClinicalGuidancesPlanningStatus {
  Planned = 'PLANNED',
  NotPlanned = 'NOT_PLANNED',
}

export type MenteePlanning = {
  id: string;
  firstName: string;
  lastName: string;
  clinician: ClinicianPlanning;
};

export type MentorPlanning = {
  id: string;
  firstName: string;
  lastName: string;
  clinician?: ClinicianPlanning;
};

export type ClinicianPlanning = {
  id: string;
  name: string;
  legalName: string;
};

export type ClinicalCasePlanning = {
  id: string;
  name: string;
};

type TaskPlanning = {
  id: string;
};

type RegistryPlanning = {
  id?: string;
};
