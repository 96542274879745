import { DefaultContext, MutationHookOptions } from '@apollo/client';
import useAuthorizedMutation from './useAuthorizedMutation';
import { CREATE_SKILL_ACQUISITION_NOTE, GET_INTERVENTION_SESSION } from 'queries';

export type FormValues = {
  targetGroupId?: string;
  body: string;
};

type Data = {
  createSkillAcquisitionNote: FormValues & {
    id: string;
    clinicalCaseId: string;
    interventionId: string;
  };
};

type Variables = Record<string, unknown>;

export const useCreateSkillAcquisitionNote = (
  options?: MutationHookOptions<Data, Variables, DefaultContext>,
) => {
  const {
    mutate: executeMutate,
    error,
    loading,
    token,
  } = useAuthorizedMutation<Data, Variables>(CREATE_SKILL_ACQUISITION_NOTE, options);

  const mutate = ({
    onSuccess,
    values,
  }: {
    onSuccess?: () => void;
    values: FormValues & {
      clinicalCaseId: string;
      interventionId: string;
    };
  }) => {
    const { body, clinicalCaseId, interventionId, targetGroupId } = values;

    return executeMutate({
      variables: {
        clinicalCaseId,
        skillAcquisitionNote: {
          interventionSessionId: interventionId,
          targetGroupId,
          body,
        },
      },
      options: {
        onCompleted: () => onSuccess?.(),
        refetchQueries: [
          {
            query: GET_INTERVENTION_SESSION,
            context: {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
            variables: {
              id: interventionId,
              clinicalCaseId,
            },
          },
        ],
      },
    });
  };

  return { mutate, error, loading };
};
