import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useIntersectionObserver = (
  callback: () => void,
  containerRef: RefObject<HTMLElement>,
  reset?: unknown,
) => {
  useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          callback();
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.05,
    });
    if (containerRef?.current) {
      observer.observe(containerRef.current);
    }
    return () => observer?.disconnect();
  }, [reset, containerRef, callback, reset]);
};
