import { CSS, styled, pxToRem, InterfaceOther2 } from '@genialcare/atipico-react';

export const buttonStyle: CSS = {
  width: pxToRem(30),
  height: pxToRem(30),
  zIndex: 1,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const ButtonIcon = styled(InterfaceOther2, {
  width: pxToRem(35),
  height: pxToRem(35),
  '& path': {
    stroke: '$neutral300',
  },
});
