import React from 'react';
import * as S from './CardLocation.styles';

type CardLocationProps = {
  location?: string;
};

export const CardLocation: React.FC<CardLocationProps> = ({ location = '-' }) => {
  return (
    <S.Root justify="center" align="center">
      <S.Icon />
      <S.Text variant="body2" color="purple500">
        {location}
      </S.Text>
    </S.Root>
  );
};
