import { graphql } from 'msw';

export default graphql.query('getClinicalCaseTasks', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        id,
        tasks: [
          {
            id: '57584f27-0c7d-4123-b08d-ff24a1d6ad0c',
            status: 'OPEN',
            title: 'Primeira tarefa',
            description:
              'Lorem ipsum dolor sit amet consectetur. Nisl morbi aenean nunc arcu adipiscing. Cursus proin magna egestas nec in quam iaculis. Interdum vestibulum non vitae nisl venenatis ridiculus duis pellentesque. Sit aliquam enim augue volutpat porttitor sagittis. Tellus malesuada ac facilisis lorem venenatis sit elementum. Sed facilisi molestie ultrices vivamus lorem eu ipsum eget proin. Nisi a cras ornare a dapibus in euismod semper',
            dueDate: '2023-05-17T03:00:00.000Z',
            assignee: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            createdBy: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            context: {
              clinicalCase: {
                name: '04. Geninho (Genona)',
              },
              details: {
                title: null,
              },
            },
          },
          {
            id: 'd77145e2-1cbb-4e06-be09-55c38f8e4c20',
            status: 'OPEN',
            title: 'Segunda tarefa',
            description:
              'Lorem ipsum dolor sit amet consectetur. Nisl morbi aenean nunc arcu adipiscing. Cursus proin magna egestas nec in quam iaculis. Interdum vestibulum non vitae nisl venenatis ridiculus duis pellentesque. Sit aliquam enim augue volutpat porttitor sagittis. Tellus malesuada ac facilisis lorem venenatis sit elementum. Sed facilisi molestie ultrices vivamus lorem eu ipsum eget proin. Nisi a cras ornare a dapibus in euismod semper',
            dueDate: '2023-05-17T03:00:00.000Z',
            assignee: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            createdBy: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            context: {
              clinicalCase: {
                name: '04. Geninho (Genona)',
              },
              details: {
                title: 'Primeira discussão',
                __typename: 'ClinicalGuidance',
              },
            },
          },
          {
            id: '7490f00b-307e-4979-8173-55be07478aa6',
            status: 'RESOLVED',
            title: 'Terceira tarefa',
            description:
              'Lorem ipsum dolor sit amet consectetur. Nisl morbi aenean nunc arcu adipiscing. Cursus proin magna egestas nec in quam iaculis. Interdum vestibulum non vitae nisl venenatis ridiculus duis pellentesque. Sit aliquam enim augue volutpat porttitor sagittis. Tellus malesuada ac facilisis lorem venenatis sit elementum. Sed facilisi molestie ultrices vivamus lorem eu ipsum eget proin. Nisi a cras ornare a dapibus in euismod semper',
            dueDate: '2023-05-17T03:00:00.000Z',
            assignee: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            createdBy: {
              id: 'c6d48c33-a5c9-45ac-b547-dbfe94b43e3',
              name: 'Dev2',
            },
            context: {
              clinicalCase: {
                name: '04. Geninho (Genona)',
              },
              details: {
                title: 'Segunda discussão',
                __typename: 'ClinicalGuidance',
              },
            },
          },
        ],
      },
    }),
  );
});
