import { datadogRum } from '@datadog/browser-rum';
import { ApolloError } from '@apollo/client';

export const logError = (
  error: string | ErrorEvent | ApolloError | Error,
  context?: Record<string, unknown>,
) => {
  const environment = process.env.REACT_APP_ENVIRONMENT || '';
  if (!['staging', 'production'].includes(environment)) {
    console.error(error);
  }

  datadogRum.addError(error, { ...context, graphQLErrors: (error as ApolloError)?.graphQLErrors });
};
