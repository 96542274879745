import { gql } from '@apollo/client';
import {
  PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS,
  PARENTAL_TRAINING_NOTE_FIELDS,
} from './fragments/parentalTrainingNoteFragments';
import { PARENTAL_TRAINING_SESSION_FIELDS } from './fragments/parentalTrainingSessionFragments';

export const GET_PARENTAL_TRAINING_SESSIONS_AND_NOTES_BY_CLINICAL_CASE = gql`
  ${PARENTAL_TRAINING_NOTE_FIELDS}
  ${PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS}
  ${PARENTAL_TRAINING_SESSION_FIELDS}

  query getParentalTrainingSessionsAndNotes(
    $clinicalCaseId: ID!
    $statuses: [SessionStatuses]
    $startScheduledAt: String
    $endScheduledAt: String
    $sessionTypes: [SessionTypes]
    $limit: Int
    $offset: Int
  ) {
    sessions(
      clinicalCaseId: $clinicalCaseId
      statuses: $statuses
      sessionTypes: $sessionTypes
      startScheduledAt: $startScheduledAt
      endScheduledAt: $endScheduledAt
      limit: $limit
      offset: $offset
    ) {
      ...ParentalTrainingSessionFields
      parentalTrainingNote {
        ...ParentalTrainingNoteFields
        ...ParentalTrainingNoteAuthorFields
      }
    }
  }
`;
