import { Box, pxToRem, styled, generateColorPaletteVariants } from '@genialcare/atipico-react';

export const Badge = styled(Box, {
  height: pxToRem(16),
  minWidth: pxToRem(44),
  maxWidth: 'max-content',
  padding: `${pxToRem(1)} ${pxToRem(5)}`,
  marginLeft: pxToRem(2),
  fontSize: pxToRem(10),
  lineHeight: pxToRem(14),
  fontWeight: '$bold',
  borderRadius: pxToRem(8),
  textAlign: 'center',

  variants: {
    color: generateColorPaletteVariants(['color']),
    bg: generateColorPaletteVariants(['backgroundColor']),
  },

  defaultVariants: {
    color: 'neutral100',
    bg: 'purple100',
  },
});
