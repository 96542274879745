import { Box, styled, pxToRem } from '@genialcare/atipico-react';

export const Root = styled(Box, {
  position: 'relative',
  paddingTop: pxToRem(54),
  paddingBottom: pxToRem(54),

  '@lg': {
    paddingTop: pxToRem(56),
    paddingBottom: pxToRem(56),
  },
});
