import React from 'react';
import TherapistsSvg from 'assets/images/illustrations/therapists.svg';
import { BaseError } from '../BaseError';

export const AccessDenied = () => {
  return (
    <BaseError
      imageSrc={TherapistsSvg}
      title="Ops..."
      subtitle="Acesso negado!"
      description="Você não tem permissão para acessar esta página. Solicite o acesso para a administração do sistema."
    />
  );
};
