import React from 'react';
import * as S from './CardLabel.styles';
import type { LabelVariants } from './CardLabel.styles';

export type CardLabelProps = LabelVariants & {
  text: string;
};

export const CardLabel: React.FC<CardLabelProps> = ({ text, bg, color, ...props }) => (
  <S.Root {...props} bg={bg} color={color}>
    {text}
  </S.Root>
);
