import { gql } from '@apollo/client';

export const GET_EDIT_CUSTOM_TARGET_LIST_DATA = gql`
  query getEditCustomTargetListData($clinicalCaseId: ID!, $customTargetListId: ID!) {
    clinicalCase(id: $clinicalCaseId) {
      peis(status: "in_treatment") {
        objectives {
          program {
            id
            name
            targets {
              id
              name
            }
          }
        }
      }
    }
    customTargetList(id: $customTargetListId, clinicalCaseId: $clinicalCaseId) {
      id
      name
      programs {
        id
        targets {
          id
          name
        }
      }
    }
  }
`;
