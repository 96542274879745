const verboseWeekdayTranslation: Record<string, string> = {
  sunday: 'domingo',
  monday: 'segunda-feira',
  tuesday: 'terça-feira',
  wednesday: 'quarta-feira',
  thursday: 'quinta-feira',
  friday: 'sexta-feira',
  saturday: 'sábado',
};

const simplifiedWeekdayTranslation: Record<string, string> = {
  sunday: 'domingo',
  monday: 'segunda',
  tuesday: 'terça',
  wednesday: 'quarta',
  thursday: 'quinta',
  friday: 'sexta',
  saturday: 'sábado',
};

export const getVerboseWeekdayTranslation = (weekday: string): string | undefined =>
  verboseWeekdayTranslation[weekday];

export const getSimplifiedWeekdayTranslation = (weekday: string): string | undefined =>
  simplifiedWeekdayTranslation[weekday];
