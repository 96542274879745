import React from 'react';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';
import * as S from './DropdownMenu.styles';

type DropdownMenuProps = {
  handleOnEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleToArchive: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const SHOW_ARCHIVED_BUTTON_FLAG = false;

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ handleOnEdit, handleToArchive }) => {
  return (
    <DropdownMenuRoot.Root modal={false}>
      <DropdownMenuRoot.Trigger asChild>
        <S.TriggerMenuButton
          variant="outline"
          data-testid="dropdown-menu-trigger"
          css={S.triggerMenuButtonOpenStyle}
          icon={<S.TriggerMenuButtonIcon />}
        />
      </DropdownMenuRoot.Trigger>

      <S.Content align="end" sideOffset={12}>
        <DropdownMenuRoot.Item>
          <S.MenuItemButton onClick={handleOnEdit} leftIcon={<S.EditIcon />}>
            Editar
          </S.MenuItemButton>
        </DropdownMenuRoot.Item>

        {SHOW_ARCHIVED_BUTTON_FLAG && (
          <DropdownMenuRoot.Item>
            <S.MenuItemButton onClick={handleToArchive} leftIcon={<S.FolderIcon />}>
              Arquivar
            </S.MenuItemButton>
          </DropdownMenuRoot.Item>
        )}
      </S.Content>
    </DropdownMenuRoot.Root>
  );
};
