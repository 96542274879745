import { graphql } from 'msw';

export default graphql.mutation('updateClinicalGuidanceComment', (req, res, ctx) => {
  const { content, commentId } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      updateClinicalGuidanceComment: {
        content,
        id: commentId,
        createdAt: '2023-01-01T00:00:00',
        updatedAt: '2023-01-02T05:20:10',
        createdBy: {
          id: '31a06995-89a7-49dd-809c-4d7eecaa9731',
          name: 'Maria Luiza',
        },
      },
    }),
  );
});
