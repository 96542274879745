import { styled, Flex } from 'styled-system/jsx';

export const Root = styled(Flex, {
  base: {
    alignItems: 'center',

    _hover: {
      cursor: 'pointer',
      bg: 'neutral.100',
    },
  },
});

export const Label = styled('label', {
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    p: '12px',
    w: '100%',
  },
});
