import { graphql } from 'msw';

export default graphql.mutation('registerUserDevice', (req, res, ctx) => {
  const { deviceId, deviceToken } = req.variables;

  return res(
    ctx.delay(1200),
    ctx.data({
      registerUserDevice: {
        deviceId,
        deviceToken,
      },
    }),
  );
});
