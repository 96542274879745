import React from 'react';
import * as S from './Avatar.styles';
import type { AvatarVariants } from './Avatar.styles';
import { CSS } from '@genialcare/atipico-react';
import ImageDefault from 'assets/images/default-avatar.png';

type AvatarProps = AvatarVariants & {
  src: string;
  alt?: string;
  css?: CSS;
};

export const Avatar: React.FC<AvatarProps> = ({ src, size, alt, css }) => (
  <S.Root size={size} css={css}>
    <S.Image src={src} alt={alt} onError={(e) => (e.currentTarget.src = ImageDefault)} />
  </S.Root>
);
