import { useState } from 'react';
import { logError } from 'utils/logger';

type Variables = string | Record<string, unknown> | Array<unknown>;

export const useLocalStorage = (key: string, initialValue: Variables = {}) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage?.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      logError(`Error on get ${key} to localStorage: ${JSON.stringify(error)}`);
      return initialValue;
    }
  });

  const setValue = (value: Variables) => {
    try {
      setStoredValue(value);
      window.localStorage?.setItem(key, JSON.stringify(value));
    } catch (error) {
      logError(`Error on set ${key} to localStorage: ${JSON.stringify(error)}`);
    }
  };

  return [storedValue, setValue];
};
