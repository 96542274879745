import { gql } from '@apollo/client';
import {
  PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS,
  PARENTAL_TRAINING_NOTE_FIELDS,
} from './fragments/parentalTrainingNoteFragments';

export const GET_PARENTAL_TRAINING_NOTE_BY_SESSION_ID = gql`
  ${PARENTAL_TRAINING_NOTE_FIELDS}
  ${PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS}

  query getParentalTrainingNoteBySessionId($sessionId: ID!) {
    session(id: $sessionId) {
      id
      startScheduledAt
      finalStartedAt
      parentalTrainingNote {
        ...ParentalTrainingNoteFields
        ...ParentalTrainingNoteAuthorFields
      }
    }
  }
`;
