import { gql } from '@apollo/client';

export const GET_PROMPT_SCHEDULES = gql`
  query GetPromptSchedules {
    promptSchedules {
      id
      name
      prompts {
        id
        name
        abbreviation
        level
      }
    }
  }
`;
