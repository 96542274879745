import React from 'react';
import * as AlertDialogDefault from '@radix-ui/react-alert-dialog';
import * as S from './AlertDialog.styles';

type AlertDialogConfirmButtonProps = {
  children: string | React.ReactNode | React.ReactNode[];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const AlertDialogConfirmButton: React.FC<AlertDialogConfirmButtonProps> = ({
  children,
  onClick,
  isDisabled,
  isLoading,
  ...rest
}) => (
  <AlertDialogDefault.Action asChild>
    <S.Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={onClick}
      css={{ '&[type="button"]': { backgroundColor: '$purple500' } }}
      {...rest}
    >
      {children}
    </S.Button>
  </AlertDialogDefault.Action>
);
