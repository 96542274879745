import { gql } from '@apollo/client';

export const GET_SKILL_ACQUISITIONS = gql`
  query getSkillAcquisitions($id: ID!) {
    clinicalCase(id: $id) {
      id
      skillAcquisitions {
        id
        skillName
        name
        technicalName
      }
    }
  }
`;
