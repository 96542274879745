import { VariantProps } from '@stitches/react';
import { styled } from '@genialcare/atipico-react';

export const Root = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  borderRadius: '100%',
  variants: {
    size: {
      sm: {
        width: 40,
        height: 40,
      },
      md: {
        width: 64,
        height: 64,
      },
      lg: {
        width: 124,
        height: 124,
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const Image = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

export type AvatarVariants = VariantProps<typeof Root>;
