import { styled as atStyled, Button as ButtonDefault } from '@genialcare/atipico-react';
import { styled } from 'styled-system/jsx';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

export const Overlay = styled(AlertDialog.Overlay, {
  base: {
    position: 'fixed',
    backdropFilter: 'blur(2.5px)',
    inset: 0,
    zIndex: 9998,
    bg: 'rgba(109, 104, 173, 0.4)',
    animation: 'fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
  },
});

export const Content = styled(AlertDialog.Content, {
  base: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 9999,
    p: '24px',
    w: '90vw',
    maxW: '32rem',
    maxH: '85vh',
    bg: 'neutral.0',
    borderRadius: '12px',
    boxShadow: '0 8.86364px 26.5909px rgba(0, 0, 0, 0.1)',
    transform: 'translate(-50%, -50%)',
    animation: 'contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)',

    '&:focus': {
      outline: 'none',
    },
  },
});

export const Title = styled(AlertDialog.Title, {
  base: {
    mb: '18px',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'dm-sans',
    lineHeight: '140%',
    color: '$neutral500',
  },
});

export const Body = styled(AlertDialog.Description, {
  base: {
    mb: '20px',
    fontSize: '16px',
    lineHeight: '140%',
    fontFamily: 'open-sans',
    color: 'neutral.400',

    '& > a': {
      color: 'purple.500',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
});

export const Button = atStyled(ButtonDefault, {
  maxWidth: '100%',
  minWidth: 'auto',
});

export const CloseButton = styled('span', {
  base: {
    position: 'absolute',
    top: '1.25rem',
    right: '1.25rem',
    w: '1.25rem',
    h: '1.25rem',
    cursor: 'pointer',

    _before: {
      content: '""',
      position: 'absolute',
      m: 'auto',
      w: '100%',
      h: '2px',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      bg: 'purple.500',
      rotate: '45deg',
    },

    _after: {
      content: '""',
      position: 'absolute',
      m: 'auto',
      w: '100%',
      h: '2px',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      bg: 'purple.500',
      rotate: '-45deg',
    },
  },
});
