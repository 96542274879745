import React, { PropsWithChildren } from 'react';
import { CSS } from '@genialcare/atipico-react';
import * as S from './Card.styles';

type CardProps = PropsWithChildren & {
  css?: CSS;
  className?: string;
};

export const Card: React.FC<CardProps> = ({ css, className, children, ...props }) => (
  <S.Root {...props} direction="column" css={css} className={className}>
    {children}
  </S.Root>
);
