import React, { useState, useRef, PropsWithChildren } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { SelectItem } from './SelectItem';
import * as S from './Select.styles';
import { CSS } from '@genialcare/atipico-react';

export type Option = {
  value: string;
  children: string | React.ReactNode | React.ReactNode[];
};

export type SelectProps = PropsWithChildren & {
  placeholder?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  options?: Option[];
  'data-testid'?: string;
  onSelect?: (event: string) => void;
  css?: CSS;
};

export const Select: React.FC<SelectProps> = React.forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const {
      placeholder,
      options,
      defaultValue,
      isDisabled,
      isReadOnly,
      onSelect,
      children,
      'data-testid': dataTestId,
      ...rest
    } = props;

    const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue);
    const [open, setOpen] = useState<boolean>(false);
    const [cursorOption, setCursorOption] = useState<number>(
      () => options?.findIndex(({ value }) => value === defaultValue) || 0,
    );

    const rootRef = useRef<HTMLDetailsElement>(null);

    const handleSelect = (value: string, index: number) => {
      setSelectedValue(value);
      setCursorOption(index);
      onSelect?.(value);
      setOpen(false);
    };

    const handleToggle = (e: React.MouseEvent<HTMLDetailsElement>) => {
      e.preventDefault();
      setOpen(!open);
    };

    const selectedElement = options?.find(({ value }) => value === selectedValue)?.children;

    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === 'Tab') return setOpen(!open);

      if (e.key === 'Enter') {
        return setSelectedValue(options?.[cursorOption]?.value);
      }

      if (e.key === 'ArrowDown') {
        return setCursorOption((currentOption) => {
          const nextCursor = currentOption + 1;
          if (nextCursor === options?.length) return currentOption;
          return nextCursor;
        });
      }

      if (e.key === 'ArrowUp') {
        return setCursorOption((currentOption) => {
          const prevCursor = currentOption - 1;
          if (prevCursor < 0) return 0;
          return prevCursor;
        });
      }
    };

    useOnClickOutside(rootRef, () => {
      if (open) {
        setOpen(false);
      }
    });

    return (
      <S.Root
        role="combobox"
        open={open}
        ref={rootRef}
        onClick={handleToggle}
        onKeyDown={handleKeyPress}
        data-testid={dataTestId}
        aria-disabled={isDisabled}
        data-readonly={isReadOnly}
        {...rest}
      >
        <input
          type="hidden"
          role="textbox"
          aria-hidden="true"
          defaultValue={selectedValue}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          readOnly={isReadOnly}
          data-readonly={isReadOnly}
          ref={ref}
          {...rest}
        />
        <S.Trigger data-testid="trigger" aria-disabled={isDisabled}>
          <S.TriggerText>{selectedElement || placeholder || 'Selecione aqui'}</S.TriggerText>
          <S.Icon aria-disabled={isDisabled} />
        </S.Trigger>

        {!isReadOnly && !isDisabled && (
          <S.Content>
            {children ||
              options?.map(({ value, children: optionChildren }, index) => (
                <SelectItem
                  key={index}
                  onClick={() => handleSelect(value, index)}
                  isActive={cursorOption === index}
                >
                  {optionChildren}
                </SelectItem>
              ))}
          </S.Content>
        )}
      </S.Root>
    );
  },
);
