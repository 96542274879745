import { Typography, styled, Flex } from '@genialcare/atipico-react';

export const Image = styled('img', {
  marginTop: '5rem',
  width: '20rem',
  borderRadius: '25%',
});

export const Title = styled(Typography, {
  marginTop: '2rem',
  textAlign: 'center',
});

export const Subtitle = styled(Typography, {
  textAlign: 'center',
});

export const Description = styled(Typography, {
  textAlign: 'center',
  maxWidth: '30rem',
});

export const Container = styled(Flex, {
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 1rem',
});
