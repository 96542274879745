import React from 'react';
import { useLocation } from 'react-router-dom';
import { ListItem, ItemText, NavLink } from './MenuItem.styles';
import { Flex } from '@genialcare/atipico-react';
import { Badge } from 'components/Badge';
import { useDevice } from '../../../hooks/useDevice';

export interface MenuItemProps {
  Icon: React.ElementType;
  showBadge?: boolean;
  text: string;
  to: string;
  isOpen: boolean;
  onClick?: () => void;
}

export const MenuItem = ({
  to,
  Icon,
  text,
  isOpen,
  showBadge,
  onClick,
  ...props
}: MenuItemProps) => {
  const location = useLocation();
  const { isBigScreen } = useDevice();
  const menuItemFontVariant = isBigScreen ? 'menu' : 'h4';
  const isActive = location.pathname === to;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    return onClick?.();
  };

  return (
    <NavLink {...props} to={to} onClick={handleClick}>
      <ListItem as="li" active={isActive} isOpen={isOpen}>
        <Flex align="start" justify="center">
          <Icon active={isActive} />
          <Flex align="center" css={{ gap: 4 }}>
            <ItemText isOpen={isOpen} variant={menuItemFontVariant} active={isActive}>
              {text}
            </ItemText>

            {showBadge && isOpen && (
              <Badge bg={isActive ? 'neutral100' : 'purple300'} color="purple500">
                Novo!
              </Badge>
            )}
          </Flex>
        </Flex>
      </ListItem>
    </NavLink>
  );
};
