import {
  generateColorPaletteVariants,
  Card,
  CardHeader,
  Flex,
  pxToRem,
  styled,
  CardBody,
  Box,
} from '@genialcare/atipico-react';

export const Root = styled(Card, {
  margin: `${pxToRem(12)} ${pxToRem(0)}`,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  width: '100%',
  border: `${pxToRem(1)} solid transparent`,

  variants: {
    isOpen: {
      true: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        height: 'fit-content',
        minHeight: pxToRem(365),
        margin: `${pxToRem(12)} ${pxToRem(0)} ${pxToRem(0)}`,
      },
      false: {
        cursor: 'pointer',
        '&:hover, &:focus': {
          borderColor: '$purple300',
        },
      },
    },
    isSelected: {
      true: {
        borderColor: '$purple300',
      },
    },
  },
});

export const HeaderInfo = styled(CardHeader, {
  gap: pxToRem(16),
  paddingBottom: 0,
  marginTop: pxToRem(8),
});

export const HeaderTitle = styled(CardHeader, {
  paddingBottom: pxToRem(12),
});

export const BadgeContainer = styled(Flex, {
  marginRight: pxToRem(6),
});

export const SessionAuthor = styled('p', {
  maxWidth: '66%',
  fontStyle: 'italic',
  fontSize: '10px',
  lineHeight: pxToRem(24),
  variants: {
    color: generateColorPaletteVariants(['color']),
  },

  '@md': {
    fontSize: '12px',
  },
});

export const BodyContainer = styled(CardBody, {
  fontSize: pxToRem(12),
  marginBottom: pxToRem(24),
  paddingTop: '0',
});

export const ResumeContainer = styled(Flex, {
  gap: pxToRem(8),
  flex: '1 1 auto',
});

export const SessionResume = styled('p', {
  fontSize: pxToRem(12),
  lineHeight: pxToRem(17),
  display: '-webkit-box',
  '-webkit-line-clamp': 7,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

export const SessionDetail = styled(Box, {
  width: '100%',
  fontSize: pxToRem(14),
  lineHeight: pxToRem(19),

  '@md': {
    padding: `0 ${pxToRem(16)}`,
  },
});

export const DetailContent = styled('p', {
  fontSize: pxToRem(14),
  lineHeight: pxToRem(19),
  color: '$neutral400',
  marginBottom: pxToRem(24),
});

export const Divider = styled('hr', {
  marginTop: pxToRem(8),
  borderTop: '1px solid #F1F1F1',
});

export const CancellationTemplate = styled('div', {
  marginTop: pxToRem(40),
});
