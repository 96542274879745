import { gql } from '@apollo/client';

export const UPDATE_CLINICAL_GUIDANCE = gql`
  mutation updateClinicalGuidance(
    $clinicalGuidance: UpdateClinicalGuidanceInput!
    $clinicalGuidanceId: String!
  ) {
    updateClinicalGuidance(
      clinicalGuidance: $clinicalGuidance
      clinicalGuidanceId: $clinicalGuidanceId
    ) {
      id
      title
      content
      status
      priority
      category
      createdAt
      updatedAt
      createdBy {
        name
      }
      context {
        id
        type
        clinicalCase {
          id
        }
      }
      comments {
        id
        content
        createdAt
        updatedAt
        createdBy {
          name
        }
      }
    }
  }
`;
