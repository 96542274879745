import React from 'react';
import { useParams } from 'react-router-dom';
import { GET_INTERVENTION_SESSION } from 'queries';
import { ClinicalCase, SkillAcquisition, InterventionSession } from 'types';
import useAuthorizedQuery from 'hooks/useAuthorizedQuery';
import { Loading } from 'components/Loading';
import { TweetsEmptySkills } from './EmptySkills';
import { TweetsForm } from './Form';
import { logError } from 'utils/logger';
import { GET_SKILL_ACQUISITIONS } from 'queries/getSkillAcquisitions';

interface ClinicalCaseProps extends ClinicalCase {
  skillAcquisitions: SkillAcquisition[];
}

type ClinicalCaseData = {
  clinicalCase: ClinicalCaseProps;
};

type SkillAcquisitionNotesData = {
  interventionSession: InterventionSession;
};

type Variables = Record<string, unknown>;

export const Tweets: React.FC = () => {
  const { id, interventionId } = useParams();

  const {
    loading: clinicalCaseLoading,
    error: clinicalCaseError,
    data: clinicalCaseData,
  } = useAuthorizedQuery<ClinicalCaseData, Variables>(GET_SKILL_ACQUISITIONS, {
    variables: { id },
  });

  const {
    loading: interventionSessionLoading,
    error: interventionSessionError,
    data: interventionSessionData,
  } = useAuthorizedQuery<SkillAcquisitionNotesData, Variables>(GET_INTERVENTION_SESSION, {
    variables: { id: interventionId, clinicalCaseId: id },
  });

  const skills = clinicalCaseData?.clinicalCase?.skillAcquisitions || [];
  const interventionSession = interventionSessionData?.interventionSession || undefined;

  if (clinicalCaseError || interventionSessionError) {
    if (clinicalCaseError)
      logError(clinicalCaseError, {
        component: 'Interventions/Tweets',
        query: GET_SKILL_ACQUISITIONS,
      });
    if (interventionSessionError)
      logError(interventionSessionError, {
        component: 'Interventions/Tweets',
        query: GET_INTERVENTION_SESSION,
      });

    return <p>Erro ao carregar tweets...</p>;
  }
  if (clinicalCaseLoading || interventionSessionLoading) return <Loading />;

  return skills?.length === 0 ? (
    <TweetsEmptySkills />
  ) : (
    <TweetsForm skillAcquisitions={skills} interventionSession={interventionSession} />
  );
};

export default Tweets;
