import React from 'react';
import { StatusCheckedBox } from '@genialcare/atipico-react';
import { TaskResolvedNotification } from 'types';
import { Notification, ComposeNotificationProps } from '../Notification';
import { getTaskContext, getTaskHref } from './common';

type TaskResolvedProps = ComposeNotificationProps<TaskResolvedNotification>;

export const TaskResolved: React.FC<TaskResolvedProps> = ({
  clinicalCaseId,
  clinicalCaseName,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  registryId,
  taskTitle,
  assigneeId,
  resolvedById,
  resolvedByName,
  resolvedAt,
  sessionDate,
  ...props
}) => {
  const href = getTaskHref({ clinicalCaseId, clinicalGuidanceId, registryId });
  const titleComplement = getTaskContext({
    clinicalCaseName,
    clinicalGuidanceTitle,
    registryId,
    sessionDate,
  });

  const resolvedByAssignee = assigneeId === resolvedById;

  return (
    <Notification {...props} date={resolvedAt} href={href} icon={<StatusCheckedBox />}>
      <Notification.Title>
        {resolvedByName}{' '}
        <Notification.TitleHighlight>concluiu uma tarefa</Notification.TitleHighlight>{' '}
        {resolvedByAssignee ? '' : 'para você '}
        {titleComplement}
      </Notification.Title>
      <Notification.Subtitle>'{taskTitle}'</Notification.Subtitle>
    </Notification>
  );
};
