import { styled, Box, pxToRem } from '@genialcare/atipico-react';

export const Wrapper = styled(Box, {
  width: '100%',
  position: 'relative',
  overflowWrap: 'break-word',
  boxShadow: `0px ${pxToRem(2)} ${pxToRem(8)} rgba(0, 0, 0, 0.1)`,
  backgroundColor: '$neutral0',
  padding: `${pxToRem(12)} ${pxToRem(10)}`,
  borderRadius: pxToRem(8),

  '@md': {
    padding: pxToRem(32),
  },
});
