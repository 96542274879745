import type { Timestamp } from 'firebase/firestore';
import { TaskContextType } from './task';
import { SessionCancellation, SessionTypes } from './session';

export enum NotificationType {
  ClinicalGuidanceCreated = 'clinical_guidance_created',
  HighPriorityClinicalGuidanceCreated = 'high_priority_clinical_guidance_created',
  NoticeClinicalGuidanceCreated = 'notice_clinical_guidance_created',
  ClinicalGuidanceCommentAdded = 'clinical_guidance_comment_added',
  HighPriorityClinicalGuidanceCommentAdded = 'high_priority_clinical_guidance_comment_added',
  NoticeClinicalGuidanceCommentAdded = 'notice_clinical_guidance_comment_added',
  TaskCreated = 'task_created',
  TaskResolved = 'task_resolved',
  TaskDue = 'task_due',
  UncompletedExpiredSession = 'uncompleted_expired_session',
  SessionCancelled = 'session_cancelled',
  SessionScheduleMadeOfficial = 'session_schedule_made_official',
  OfficialSessionScheduleDeleted = 'official_session_schedule_deleted',
  FamilySupportConversationMessageCreated = 'family_support_conversation_message_created',
  HighPriorityFamilySupportConversationMessageCreated = 'high_priority_family_support_conversation_message_created',
}

type BaseNotificationData = {
  id: string;
  read: boolean;
  type: NotificationType;
  notifications?: NotificationData[];
};

type CreatedData = {
  createdAt: Timestamp;
  createdByName: string;
};

type ClinicalCaseData = {
  clinicalCaseId: string;
  clinicalCaseName: string;
};

type ClinicalGuidanceData = {
  clinicalGuidanceId: string;
  clinicalGuidanceTitle: string;
};

type ClinicalGuidanceCommentData = {
  commentId: string;
  commentContent: string;
};

type RegistryData = {
  registryId?: string;
  sessionDate?: Timestamp;
};

type TaskData = {
  taskId: string;
  taskTitle: string;
  assigneeId: string;
};

type TaskResolvedData = {
  resolvedById: string;
  resolvedByName: string;
  resolvedAt: Timestamp;
};

type TaskDueData = {
  dueDays: number;
  dueDate: Timestamp;
  sentAt: Timestamp;
};

type SessionData = {
  sessionId: string;
  startScheduledAt: Timestamp;
  sessionType: SessionTypes;
  sentAt: Timestamp;
  cancellation?: SessionCancellation;
};

type SessionScheduleMadeOfficialData = {
  clinicalCaseName: string;
  startScheduledAt: string;
  endScheduledAt: string;
  coreSessionInitialDate: string;
  weekday: string;
  sentAt: Timestamp;
};

type OfficialSessionScheduleDeletedData = {
  clinicalCaseName: string;
  startScheduledAt: string;
  endScheduledAt: string;
  weekday: string;
  cancellationStartDate: string;
  sentAt: Timestamp;
};

type FamilySupportConversationMessageCreatedData = {
  messageId: string;
  messageDocumentId: string;
  conversationId: string;
  clinicalCaseId: string;
  clinicalCaseName: string;
  participantsIds: string[];
  text: string;
  user: {
    userId: string;
    name: string;
    email: string;
  };
  sentAt: Timestamp;
  createdAt: Timestamp;
};

export type TaskContextData = RegistryData &
  Partial<ClinicalGuidanceData> & {
    clinicalCaseId: string;
    contextType?: TaskContextType;
  };

export type ClinicalGuidanceCreatedNotification = BaseNotificationData &
  ClinicalCaseData &
  ClinicalGuidanceData &
  CreatedData;

export type ClinicalGuidanceCommentAddedNotification = BaseNotificationData &
  ClinicalCaseData &
  ClinicalGuidanceData &
  ClinicalGuidanceCommentData &
  CreatedData;

export type NoticeClinicalGuidanceCreatedNotification = BaseNotificationData &
  ClinicalCaseData &
  ClinicalGuidanceData &
  CreatedData;

export type NoticeClinicalGuidanceCommentAddedNotification = BaseNotificationData &
  ClinicalCaseData &
  ClinicalGuidanceData &
  ClinicalGuidanceCommentData &
  CreatedData;

export type TaskCreatedNotification = BaseNotificationData &
  TaskData &
  ClinicalCaseData &
  Partial<ClinicalGuidanceData> &
  CreatedData &
  RegistryData &
  TaskContextData;

export type TaskResolvedNotification = BaseNotificationData &
  TaskData &
  TaskResolvedData &
  ClinicalCaseData &
  Partial<ClinicalGuidanceData> &
  RegistryData &
  TaskContextData;

export type TaskDueNotification = BaseNotificationData &
  TaskData &
  TaskDueData &
  Partial<ClinicalCaseData> &
  Partial<ClinicalGuidanceData> &
  RegistryData &
  Partial<TaskContextData>;

export type UncompletedExpiredSessionNotification = BaseNotificationData &
  ClinicalCaseData &
  SessionData;

export type SessionScheduleMadeOfficialNotification = BaseNotificationData &
  SessionScheduleMadeOfficialData;

export type OfficialSessionScheduleDeletedNotification = BaseNotificationData &
  OfficialSessionScheduleDeletedData;

export type SessionCancelledNotification = BaseNotificationData & ClinicalCaseData & SessionData;

export type FamilySupportConversationMessageCreatedNotification = BaseNotificationData &
  FamilySupportConversationMessageCreatedData;

export type NotificationData =
  | ClinicalGuidanceCreatedNotification
  | ClinicalGuidanceCommentAddedNotification
  | NoticeClinicalGuidanceCreatedNotification
  | NoticeClinicalGuidanceCommentAddedNotification
  | TaskCreatedNotification
  | TaskResolvedNotification
  | TaskDueNotification
  | UncompletedExpiredSessionNotification
  | SessionCancelledNotification
  | SessionScheduleMadeOfficialNotification
  | OfficialSessionScheduleDeletedNotification
  | FamilySupportConversationMessageCreatedNotification;

export type TaskNotificationData = Pick<ClinicalCaseData, 'clinicalCaseName'> &
  Partial<ClinicalGuidanceData> &
  Partial<RegistryData>;
