import React, { PropsWithChildren } from 'react';
import { Typography } from '@genialcare/atipico-react';
import * as S from './Subtitle.styles';

export const Subtitle: React.FC<PropsWithChildren> = ({ children }) => (
  <Typography
    variant="subtitle"
    color="neutral500"
    data-testid="notification-subtitle"
    css={S.baseStyle}
  >
    {children}
  </Typography>
);
