import { pxToRem, styled, Typography } from '@genialcare/atipico-react';
import { Flex } from '@genialcare/atipico-react';
import { NavLink as RouteNavLink } from 'react-router-dom';

export const ListItem = styled(Flex, {
  transition: '0.5s',
  borderRadius: pxToRem(10),
  marginBottom: pxToRem(10),
  padding: pxToRem(14),
  alignItems: 'center',

  '@lg': {
    width: pxToRem(46),
    height: pxToRem(46),
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$purple100',
      },
    },
    isOpen: {
      true: {
        textAlign: 'left',
        width: '100%',

        '@lg': {
          alignSelf: 'center',
          alignItems: 'center',
          width: pxToRem(205),
        },
      },
    },
  },

  defaultVariants: {
    active: false,
  },
});

export const ItemText = styled(Typography, {
  transition: 'display 0s linear 150ms, opacity 100ms;',
  marginLeft: pxToRem(11),
  opacity: 0,
  display: 'none',
  color: '$purple100',
  lineHeight: 'initial',

  variants: {
    active: {
      true: {
        color: '$neutral0',
        lineHeight: 'initial',
      },
    },
    isOpen: {
      true: {
        opacity: 1,
        display: 'block',
        transition: 'display 0s linear 150ms, opacity 100ms',
        lineHeight: 'initial',
      },
    },
  },
});

export const NavLink = styled(RouteNavLink, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  whiteSpace: 'nowrap',

  '@lg': {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
