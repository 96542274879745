import { SessionTypes } from './session';
import { TaskContext, TaskSubject } from './task';

export enum PendencyContextType {
  Session = 'SESSION',
  Task = 'TASK',
}

export enum PendencyType {
  SessionPending = 'SESSION_PENDING',
  SessionMissingNote = 'SESSION_MISSING_NOTE',
  TaskOverdue = 'TASK_OVERDUE',
  TaskToBeOverdue = 'TASK_TO_BE_OVERDUE',
}

export enum PendencyCategories {
  Urgent = 'URGENT',
  Overdue = 'OVERDUE',
  ToBeOverdue = 'TO_BE_OVERDUE',
}

export type PendencyDetailsSession = {
  id?: string;
  sessionType?: SessionTypes;
  sessionTypeDescription?: string;
};

export type PendencyDetailsTask = {
  id?: string;
  title?: string;
  context?: TaskContext;
  subject?: TaskSubject;
};

export type PendencyDetailsEmpty = {
  _?: never;
};

export type PendencyDetails = PendencyDetailsSession & PendencyDetailsEmpty & PendencyDetailsTask;

export type PendencyContext = {
  id: string;
  type: PendencyContextType;
  clinicalCase?: {
    id: string;
    name: string;
    number?: number;
  };
  details: PendencyDetails;
};

export type Pendency = {
  id: string;
  pendencyType: PendencyType;
  pendencyTypeDescription: string;
  category?: PendencyCategories;
  categoryDescription?: string;
  dueDate: string;
  context: PendencyContext;
  createdAt: string;
  updatedAt: string;
};
