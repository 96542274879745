import { styled, Flex } from 'styled-system/jsx';
import * as RadioGroup from '@radix-ui/react-radio-group';

export const FieldGroup = styled(Flex, {
  base: {
    w: {
      xs: '100%',
      md: '-webkit-fill-available',
      lg: '345px',
    },
  },
});

export const Item = styled(RadioGroup.Item, {
  base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    w: '100%',
    p: '16px 16px 16px 48px',
    bg: 'neutral.0',
    color: 'neutral.400',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'neutral.300',
    borderRadius: 'base',

    '&[aria-invalid=true]': {
      borderColor: 'red.500',

      _before: {
        borderColor: 'red.500',
      },
    },

    '&[aria-checked=true]': {
      borderColor: 'purple.500',
      color: 'purple.500',

      _before: {
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: 'purple.500',
      },
    },

    _before: {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '16px',
      m: 'auto',
      w: '20px',
      h: '20px',
      bg: 'neutral.0',
      borderRadius: '40px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.300',
    },
  },
});

export const Label = styled('label', {
  base: {
    w: '100%',
  },
  variants: {
    disabled: {
      true: { opacity: 0.5 },
    },
  },
});

export const LabelText = styled('span', {
  base: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const LabelValue = styled('span', {
  base: {
    pr: '10px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});

export const TextErrorMessageRoot = styled('span', {
  base: {
    mt: '4px',
    fontSize: '13px',
    fontFamily: 'open-sans',
    color: 'red.500',
  },
});
