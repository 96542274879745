import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import React, { useCallback, useEffect } from 'react';

const widgetElements = () => {
  const container = document.getElementById('eesel-oracle-widget');
  const chatTrigger = container?.querySelector('div > button');
  const chatIcon = chatTrigger?.querySelector('svg');
  const form = container?.querySelector('form');
  const textarea = form?.querySelector('textarea');

  return {
    form,
    textarea,
    chatIcon,
    chatTrigger,
  };
};

export const EeselWidget: React.FC = () => {
  const { sendAnalyticEvent } = useAnalyticsEvents();

  useEffect(() => {
    window.__EESEL_CUSTOM_ERROR = process.env.REACT_APP_EESEL_CUSTOM_ERROR;
    window.__EESEL_WELCOME_MSG = process.env.REACT_APP_EESEL_WELCOME_MSG;
    window.__EESEL_BOT_NAME = process.env.REACT_APP_EESEL_BOT_NAME;
    window.__EESEL_NAMESPACE = process.env.REACT_APP_EESEL_NAMESPACE;
    const script = document.createElement('script');

    if (window.EeselAdded || document.getElementById('eesel-oracle-widget')) return;

    script.src = process.env.REACT_APP_EESEL_URL || '';
    script.onload = () => {
      if (window.ChatWidget) {
        window.ChatWidget.default({
          EESEL_LOGO_URL: '',
          EESEL_COLOR_PALETTE: { primary: '#6d68ad' },
          EESEL_DEFAULT_OPEN: false,
        });
      }
    };
    document.body.appendChild(script);

    window.EeselAdded = true;
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openChatEvent = useCallback(() => {
    const { chatIcon } = widgetElements();
    const isChatClosed = chatIcon?.getAttribute('viewBox') === '0 0 48 54';

    if (!isChatClosed) return;

    sendAnalyticEvent('eesel_widget_open', {
      event_category: 'eesel_widget',
      event_label: 'eesel_widget_click',
    });
  }, [sendAnalyticEvent]);

  const sendMessageEvent = useCallback(() => {
    sendAnalyticEvent('eesel_widget_send_message', {
      event_category: 'eesel_widget',
      event_label: 'eesel_widget_send_message',
    });
  }, [sendAnalyticEvent]);

  useEffect(() => {
    if (!window.EeselAdded) return;

    const { chatTrigger, form, textarea } = widgetElements();

    const sendMessageWithEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        sendMessageEvent();
      }
    };

    chatTrigger?.addEventListener('click', openChatEvent);
    form?.addEventListener('submit', sendMessageEvent);
    textarea?.addEventListener('keydown', sendMessageWithEnter);

    return () => {
      chatTrigger?.removeEventListener('click', openChatEvent);
      form?.removeEventListener('submit', sendMessageEvent);
      textarea?.removeEventListener('keydown', sendMessageWithEnter);
    };
  }, [openChatEvent, sendMessageEvent]);

  return null;
};
