import { gql } from '@apollo/client';

export const CREATE_SUBJECT_CLINICAL_DECISION = gql`
  mutation createSubjectClinicalDecision($decisionInput: CreateSubjectClinicalDecisionInput!) {
    createSubjectClinicalDecision(subjectClinicalDecisionInput: $decisionInput) {
      id
      text
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
