export const PERSIST_REQUEST_ERROR_MESSAGE =
  'Ocorreu um erro inesperado ao salvar as informações. Salve as informações que você preencheu em um outro local, recarregue a página e tente salvar novamente.';

export const DELETE_REQUEST_ERROR_MESSAGE =
  'Ocorreu um erro inesperado ao excluir os dados. Por favor, recarregue a página e tente novamente.';

export const GET_REQUEST_ERROR_MESSAGE =
  'Ocorreu um erro inesperado ao buscar os dados. Por favor, recarregue a página e tente novamente.';

export const FINISH_REQUEST_ERROR_MESSAGE =
  'Ocorreu um erro inesperado ao finalizar. Por favor, recarregue a página e tente novamente.';

export const GET_LAST_REGISTRY_ERROR_MESSAGE =
  'Ocorreu um erro ao buscar a última orientação clínica. Por favor, atualize a página e tente novamente.';

export const CREATE_REGISTRY_ERROR =
  'Ocorreu um erro ao criar a orientação clínica. Por favor, atualize a página e tente novamente.';
