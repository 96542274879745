import { Typography, pxToRem, styled } from '@genialcare/atipico-react';
import { InputDate } from 'components/Form/InputDate';
import { Filter as FilterDefault } from 'components/Filter';

export const Filter = styled(FilterDefault, {
  width: pxToRem(320),
});

export const Label = styled(Typography, {
  marginTop: pxToRem(12),
});

export const Input = styled(InputDate, {
  marginTop: pxToRem(12),
});
