import {
  CSS,
  styled,
  pxToRem,
  StatusCheckedBox as StatusCheckedBoxDefault,
  InterfaceRefresh as InterfaceRefreshDefault,
  InterfaceTrash as InterfaceTrashDefault,
} from '@genialcare/atipico-react';

const baseIconStyle: CSS = {
  width: pxToRem(28),
  height: pxToRem(28),
  fill: '$purple500',
};

export const StatusCheckedBox = styled(StatusCheckedBoxDefault, baseIconStyle);

export const InterfaceRefresh = styled(InterfaceRefreshDefault, baseIconStyle);

export const InterfaceTrash = styled(InterfaceTrashDefault, {
  ...baseIconStyle,
  fill: '$red500',
});
