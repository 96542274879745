import React from 'react';
import { Flex, Typography } from '@genialcare/atipico-react';
import { Badge } from 'components/Badge';
import {
  Session,
  SessionStatuses,
  GeneralSessionClinician,
  OccupationalTherapyNoteDetail,
  SpeechTherapyNoteDetail,
  SessionTypes,
} from 'types';
import { SkillAcquisitionNotes } from 'components/SkillAcquisitionNotes';
import { convertDateToPtBr } from 'utils/date';
import * as S from './SessionCard.styles';
import { ClinicalQuestions } from './components';
import { ABA as ABADiscipline, TO as TODiscipline, Fono as FonoDiscipline } from './Disciplines';
import { Cancellation } from './Cancellation';
import { AssessmentDetail } from './AssessmentDetail/AssessmentDetail';

type WithoutAbaDetail = OccupationalTherapyNoteDetail | SpeechTherapyNoteDetail;

export interface SessionCard extends Session {
  isOpen?: boolean;
  isSelected?: boolean;
  showResume?: boolean;
  onClick?: () => void;
  handleGetClinicalGuidance: (clinicalGuidanceId: string) => void;
}

const CanceledBadge = () => (
  <Badge bg="orange200" color="purple500">
    Cancelada
  </Badge>
);

const formatSessionDate = (date?: string, onlyDate = true) => {
  if (!date) return '-';

  const replacedDate = date.replace('Z', '-0300');

  return convertDateToPtBr(replacedDate, onlyDate ? { dateStyle: 'short' } : {});
};

const formatSessionTitle = (clinician: GeneralSessionClinician, date?: string) => {
  const formattedDate = formatSessionDate(date, false);

  return `Por ${clinician.name} (${
    clinician.professionalRegistrationNumber || 'XXX'
  }) em ${formattedDate}`;
};

const disciplineTemplates = {
  ABA: ABADiscipline,
  Fono: FonoDiscipline,
  TO: TODiscipline,
};

export const SessionCard: React.FC<SessionCard> = ({
  id,
  clinicians,
  status,
  discipline,
  startScheduledAt,
  sessionType,
  sessionTypeDescription,
  skillAcquisitionNotes = [],
  clinicalGuidances = [],
  note,
  assessmentNote,
  cancellation,
  observation,
  isOpen,
  isSelected,
  onClick,
  handleGetClinicalGuidance,
}) => {
  let DisciplineTemplate = disciplineTemplates[discipline];

  const clinician = note?.createdBy?.clinician || clinicians[0];
  const isCancelled = status === SessionStatuses.Cancelled;
  const isIntervention = sessionType === SessionTypes.Intervention;
  const sessionTitle = formatSessionTitle(clinician, startScheduledAt);
  const { usedReinforcers, challengingBehaviors, ...noteDetailProps } = note?.detail || {};
  const noteBody = note?.body || (noteDetailProps as WithoutAbaDetail).observation || '-';
  const cardResume = () => {
    if (isCancelled) return observation || 'Sem observações operacionais';
    if (assessmentNote) return assessmentNote.detail.observation;
    return noteBody;
  };

  if (['TO', 'Fono'].includes(discipline) && usedReinforcers && challengingBehaviors) {
    DisciplineTemplate = ABADiscipline;
  }

  if (!isIntervention) {
    DisciplineTemplate = ABADiscipline;
  }

  const isAssessmentNote =
    [
      SessionTypes.IndirectAssessment,
      SessionTypes.DirectAssessment,
      SessionTypes.FeedbackAssessment,
    ].includes(sessionType) && !!assessmentNote;

  return (
    <>
      <S.Root
        round
        isOpen={isOpen}
        isSelected={isSelected}
        direction="column"
        bg="neutral0"
        onClick={onClick}
      >
        <>
          <S.HeaderInfo direction="column">
            <Flex>
              {isIntervention && (
                <S.BadgeContainer>
                  <Badge bg="purple100" color="neutral0" key={id}>
                    {discipline.toUpperCase()}
                  </Badge>

                  {isCancelled && <CanceledBadge key={discipline} />}
                </S.BadgeContainer>
              )}

              {isOpen && <S.SessionAuthor>{sessionTitle}</S.SessionAuthor>}
            </Flex>

            <Typography variant={isOpen ? 'h4' : 'h5'} weight="bold" color="neutral500">
              Sessão de {sessionTypeDescription || 'Flanders'} -{' '}
              {formatSessionDate(startScheduledAt)}
            </Typography>
          </S.HeaderInfo>
        </>

        <S.BodyContainer>
          {isOpen ? (
            <S.SessionDetail data-testid={`session-detail-opened-${id}`}>
              {isCancelled && (
                <>
                  {cancellation && (
                    <Cancellation cancellation={cancellation} observation={observation} />
                  )}
                </>
              )}

              {!isCancelled && (
                <>
                  {isAssessmentNote ? (
                    <AssessmentDetail note={assessmentNote} />
                  ) : (
                    <>
                      <DisciplineTemplate
                        note={{
                          ...note!,
                          body: noteBody,
                        }}
                      />
                      <ClinicalQuestions
                        guidances={clinicalGuidances}
                        handleGetClinicalGuidance={handleGetClinicalGuidance}
                      />
                    </>
                  )}
                </>
              )}
            </S.SessionDetail>
          ) : (
            <S.ResumeContainer data-testid={`session-detail-closed-${id}`} direction="column">
              <S.SessionResume dangerouslySetInnerHTML={{ __html: cardResume() }} />

              <S.SessionAuthor color="neutral300">{sessionTitle}</S.SessionAuthor>
            </S.ResumeContainer>
          )}
        </S.BodyContainer>
      </S.Root>

      {isIntervention && isOpen && !isCancelled && (
        <SkillAcquisitionNotes
          data-testId="tweets-component"
          interventionSessionId={id}
          notes={skillAcquisitionNotes}
        />
      )}
    </>
  );
};
