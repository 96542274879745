import { gql } from '@apollo/client';

export const GET_CLINICAL_GUIDANCE_REGISTRIES = gql`
  query getClinicalGuidanceRegistries(
    $clinicalCaseId: ID!
    $status: ClinicalGuidanceRegistryStatus
    $startCreatedAt: String
    $endCreatedAt: String
    $clinicians: [ClinicianFilterInput]
    $limit: Int
  ) {
    clinicalGuidanceRegistries(
      clinicalCaseId: $clinicalCaseId
      status: $status
      startCreatedAt: $startCreatedAt
      endCreatedAt: $endCreatedAt
      clinicians: $clinicians
      limit: $limit
    ) {
      id
      status
      statusDescription
      session {
        discipline
        startScheduledAt
        finalStartedAt
        clinicians {
          id
          name
        }
      }
      tasks {
        id
        status
      }
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
    clinicalCase(id: $clinicalCaseId) {
      clinicians {
        id
        name
      }
    }
  }
`;
