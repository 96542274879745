import { Flex, Typography, pxToRem, styled, NavigationLocation } from '@genialcare/atipico-react';

export const Root = styled(Flex, {});

export const Text = styled(Typography, {
  variants: {
    variant: {
      body2: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
      },
    },
  },
});

export const Icon = styled(NavigationLocation, {
  width: pxToRem(20),
  height: pxToRem(20),
  fill: '$purple500',
  marginRight: pxToRem(6),
});
