import React from 'react';
import GenialCareLogoSmallSvg from 'assets/images/genial-care-logo-small.svg';
import GenialCareLogoSvg from 'assets/images/genial-care-logo.svg';
import { Logo, IconMenu, IconMenuClose } from './MenuHeader.styles';

export const MenuHeader = ({ isOpen = false }: { isOpen: boolean }) => {
  const MenuIconSmallScreen = isOpen ? IconMenuClose : IconMenu;
  const MenuIconBigScreen = isOpen ? GenialCareLogoSvg : GenialCareLogoSmallSvg;

  return (
    <>
      <Logo isOpen={isOpen} as="img" src={MenuIconBigScreen} />
      <MenuIconSmallScreen />
    </>
  );
};
