import { graphql } from 'msw';

export default graphql.query('getClinicalGuidance', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(3000),
    ctx.data({
      user: {
        id: '8221414a-d0a7-4be7-a648-91ff2546a0dd',
      },
      clinicalGuidance: {
        id,
        status: 'OPEN',
        priority: 'HIGH',
        category: 'DISCUSSION',
        title: 'Arthur ainda parece estar com piolho.',
        content:
          'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique. Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique. Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique. Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique. Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique.',
        createdAt: '2023-02-08T21:59:51.416Z',
        updatedAt: '2023-02-08T21:59:51.416Z',
        createdBy: {
          id: '8221414a-d0a7-4be7-a648-91ff2546a0dd',
          name: 'Caio Menezes',
        },
        context: {
          id: '7f2e7992-32f0-46a1-9f73-516af6a55210',
          type: 'INTERVENTION_SESSION',
          clinicalCase: {
            id: 'ed62af10-5272-41a6-b530-1f0de21e251d',
          },
        },
        comments: [
          {
            id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
            content:
              'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique, Amet eu adipiscing massa diam arcu ultrices molestie.',
            createdAt: '2023-02-08T21:59:51.416Z',
            updatedAt: '2023-02-08T21:59:51.416Z',
            createdBy: {
              id: '8221414a-d0a7-4be7-a648-91ff2546a0dd',
              name: 'Caroline Batina',
            },
          },
          {
            id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
            content:
              'Sodales amet auctor rhoncus pellentesque. Amet eu adipiscing massa diam arcu ultrices molestie. Diam ligula facilisi interdum venenatis nisi feugiat tristique, Amet eu adipiscing massa diam arcu ultrices molestie.',
            createdAt: '2023-02-09T21:59:51.416Z',
            updatedAt: '2023-02-09T21:59:51.416Z',
            createdBy: {
              id: '520df4b1-5352-4cc5-afca-41248cdc266a',
              name: 'Caio Menezes',
            },
          },
          {
            id: '44ce8443-a1d1-4920-a1e6-8dd3a8cce92f',
            content:
              'Lorem ipsum dolor sit amet consectetur. Consequat sed faucibus enim suscipit arcu morbi nam. Vitae feugiat fringilla massa sed enim metus. Vehicula blandit adipiscing faucibus et bibendum.',
            createdAt: '2023-02-11T21:59:51.416Z',
            updatedAt: '2023-02-11T21:59:51.416Z',
            createdBy: {
              id: '520df4b1-5352-4cc5-afca-41248cdc266a',
              name: 'Maria Luiza',
            },
          },
        ],
        tasks: [
          {
            id: '57eee60d-1955-48f5-ae50-e0ecc5be4168',
            title: 'Criar novo programa no Catalyst',
            description:
              'O terapeuta deverá cadastrar o novo programa de Estrutura Linguística no Catalyst até a próxima sessão com',
            dueDate: '2023-04-01T00:00:00.000Z',
            status: 'OPEN',
            createdAt: '2023-01-01T00:00:00',
            updatedAt: '2023-01-01T00:00:00',
            assignee: {
              id: 'ffdbfbd2-9e29-474f-819d-eef64be368d2',
              name: 'Caroline Batina',
            },
            createdBy: {
              id: 'e17b345b-3942-4f31-bdf7-624ff2d3cb72',
              name: 'Geninho',
            },
            context: {
              id: 'd580e024-5a46-496a-bb83-89f4f8b32ad6',
              type: 'CLINICAL_GUIDANCE',
              clinicalCase: {
                id: '1114e203-68c9-4305-b428-15da4d8bd789',
              },
            },
          },
          {
            id: '3d74f8d3-941a-4fa4-a9ba-133df6cb9c82',
            title: 'Solicitar compra de novo brinquedo',
            status: 'OPEN',
            createdAt: '2023-01-01T00:00:00',
            updatedAt: '2023-01-01T00:00:00',
            assignee: {
              id: 'ffdbfbd2-9e29-474f-819d-eef64be368d2',
              name: 'Caroline Batina',
            },
            createdBy: {
              id: 'e17b345b-3942-4f31-bdf7-624ff2d3cb72',
              name: 'Geninho',
            },
            context: {
              id: 'd580e024-5a46-496a-bb83-89f4f8b32ad6',
              type: 'CLINICAL_GUIDANCE',
              clinicalCase: {
                id: '1114e203-68c9-4305-b428-15da4d8bd789',
              },
            },
          },
          {
            id: '39f2e3ec-1c99-4885-8e1d-54806d643d7c',
            title: 'Confeccionar novo material para a sessão',
            description:
              'Para a próxima sessão, o terapeuta deverá construir um material de histórias sociais contendo: rotina visual, regras visuais, ir ao banheiro e prancha de escolhas.',
            dueDate: '2023-04-02T00:00:00.000Z',
            status: 'RESOLVED',
            createdAt: '2023-01-01T00:00:00',
            updatedAt: '2023-01-01T00:00:00',
            assignee: {
              id: 'ffdbfbd2-9e29-474f-819d-eef64be368d2',
              name: 'Caroline Batina',
            },
            createdBy: {
              id: 'e17b345b-3942-4f31-bdf7-624ff2d3cb72',
              name: 'Geninho',
            },
            context: {
              id: 'd580e024-5a46-496a-bb83-89f4f8b32ad6',
              type: 'CLINICAL_GUIDANCE',
              clinicalCase: {
                id: '1114e203-68c9-4305-b428-15da4d8bd789',
              },
            },
          },
        ],
      },
    }),
  );
});
