import { gql } from '@apollo/client';

export const GET_USER_TASKS_SUM = gql`
  query getUserTasksSum(
    $clinicalCaseId: ID
    $contextType: ContextType
    $subjectTypes: [SubjectType]
  ) {
    user {
      id
      __typename
      totalTasks(
        clinicalCaseId: $clinicalCaseId
        contextType: $contextType
        subjectTypes: $subjectTypes
      ) {
        total
        totalOpen
        totalToBeOverdue
        totalOverdue
        totalResolved
      }
    }
  }
`;
