import React, { useState, useEffect, PropsWithChildren, MutableRefObject } from 'react';
import type { ThemeColors } from '@genialcare/atipico-react';
import * as S from './Content.styles';

type ContentProps = PropsWithChildren & {
  open: boolean;
  colorScheme: ThemeColors;
  rootRef: MutableRefObject<HTMLDivElement | null>;
};

export const Content: React.FC<ContentProps> = ({ open, rootRef, colorScheme, children }) => {
  const [contentStyle, setContentStyle] = useState('');

  useEffect(() => {
    if (rootRef.current) {
      const { height } = rootRef.current.getBoundingClientRect();
      const style = S.baseStyle({ height, colorScheme });

      setContentStyle(style);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!contentStyle) return null;

  return (
    <S.Root
      direction="column"
      role="tooltip"
      show={open}
      aria-hidden={!open}
      className={contentStyle}
    >
      {children}
    </S.Root>
  );
};
