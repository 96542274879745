import React from 'react';
import { Typography } from '@genialcare/atipico-react';
import * as S from './ResolveButton.styles';

type ResolveButtonProps = {
  isLoading: boolean;
  opened: boolean;
  handleOpenConfirm: () => void;
  handleCloseConfirm: () => void;
  handleResolveClinicalGuidance: () => void;
};

export const ResolveButton: React.FC<ResolveButtonProps> = ({
  opened,
  isLoading,
  handleOpenConfirm,
  handleCloseConfirm,
  handleResolveClinicalGuidance,
  ...props
}) => {
  if (opened) {
    const handleResolveAction = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      handleResolveClinicalGuidance();
      handleCloseConfirm();
    };

    return (
      <S.ConfirmSection
        align={{
          '@initial': 'start',
          '@md': 'center',
        }}
        direction={{
          '@initial': 'column',
          '@md': 'row',
        }}
      >
        <Typography variant="body2" color="neutral400">
          Tem certeza que deseja resolver?
        </Typography>
        <S.ConfirmButton onClick={handleResolveAction}>Sim, quero resolver</S.ConfirmButton>
        <S.ConfirmButton variant="outline" onClick={handleCloseConfirm}>
          Cancelar
        </S.ConfirmButton>
      </S.ConfirmSection>
    );
  }

  return (
    <S.ResolveButton
      {...props}
      isDisabled={isLoading}
      isLoading={isLoading}
      onClick={handleOpenConfirm}
    >
      Resolver
    </S.ResolveButton>
  );
};
