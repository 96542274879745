import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_PEIS = gql`
  query getClinicalCasePEIs($id: ID!, $status: String) {
    clinicalCase(id: $id) {
      id
      peis(status: $status) {
        id
        version
        objectives {
          id
          description
          skill {
            id
            name
          }
          program {
            id
            name
            instruction
            stimulusDiscrimination
            consequence
            correctionProcedure
            trialsPerSession
            registerType
            activities {
              name
            }
            targets {
              id
              name
              status
              completeTrials @client
              data {
                __typename
                previousRegisters @client
                ... on TrialTargetData {
                  lastPromptId @client
                  currentPromptId
                }
              }
            }
            targetCategory {
              name
            }
            configuration {
              __typename
              ... on TrialConfiguration {
                promptSchedule {
                  id
                  name
                  promotion
                  demotion
                  sessionInARowToConsider
                  prompts {
                    id
                    name
                    abbreviation
                  }
                }
              }
              ... on TaskAnalysisConfiguration {
                steps {
                  id
                  name
                  level
                }
              }
            }
          }
        }
      }
    }
  }
`;
