import React, { PropsWithChildren } from 'react';
import * as S from './CardFooter.styles';
import type { CSS } from '@genialcare/atipico-react';

type CardFooterProps = PropsWithChildren & {
  justify?: 'start' | 'end' | 'center' | 'space-between' | 'space-around';
  css?: CSS;
};

export const CardFooter: React.FC<CardFooterProps> = ({
  children,
  css,
  justify = 'space-between',
}) => (
  <S.Root align="end" justify={justify} css={css}>
    {children}
  </S.Root>
);
