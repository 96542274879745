import { Flex } from '@genialcare/atipico-react';
import CalendarWithX from 'assets/images/calendar-with-x.svg';
import React from 'react';
import { OfficialSessionScheduleDeletedNotification } from 'types';
import { ComposeNotificationProps, Notification } from '../Notification';
import { getVerboseWeekdayTranslation } from 'utils/weekdays';
import { convertStrFormatToPtBrDate } from 'utils/date';

type OfficialSessionScheduleDeletedProps =
  ComposeNotificationProps<OfficialSessionScheduleDeletedNotification>;

export const OfficialSessionScheduleDeleted: React.FC<OfficialSessionScheduleDeletedProps> = ({
  clinicalCaseName,
  startScheduledAt,
  endScheduledAt,
  weekday,
  cancellationStartDate,
  sentAt,
  ...props
}) => {
  return (
    <Notification href={`/panel/users/sessions`} icon={<img src={CalendarWithX} />} {...props}>
      <Notification.Title>
        A <Notification.TitleHighlight>agenda</Notification.TitleHighlight> do caso{' '}
        {clinicalCaseName} não estará mais disponível para você a partir de{' '}
        {convertStrFormatToPtBrDate(cancellationStartDate, 'DD.MM.YYYY', 'DD/MM')}.
      </Notification.Title>

      <Notification.Subtitle>
        'As sessões de {getVerboseWeekdayTranslation(weekday)}, das {startScheduledAt} às{' '}
        {endScheduledAt} horas, serão retiradas da sua agenda.'
      </Notification.Subtitle>

      <Flex>
        <Notification.Date date={sentAt} />
      </Flex>
    </Notification>
  );
};
