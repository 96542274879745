import { styled, Flex } from 'styled-system/jsx';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';

export const TriggerContainer = styled(Flex, {
  base: {
    position: 'relative',
  },
});

export const Content = styled(DropdownMenuRoot.Content, {
  base: {
    display: 'flex',
    flexDir: 'column',
    w: '100vw',
    h: '100vh',
    bg: 'neutral.0',
    borderRadius: '16px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    animationDuration: '400ms',
    animationName: 'slideUpAndFade',
    willChange: 'transform, opacity',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',

    lg: {
      h: 'auto',
      w: '464px',
    },
  },
});

export const Header = styled(Flex, {
  base: {
    gap: '12px',
    p: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'neutral.200',
  },
});

export const NotificationsContainer = styled(Flex, {
  base: {
    p: '0px 0px 180px 8px',
    minH: '100%',
    maxH: '100%',
    overflowY: 'auto',
    scrollBehavior: 'smooth',

    lg: {
      p: '0 8px',
      minH: '120px',
      maxH: '480px',
    },
  },
});
