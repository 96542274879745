import { VariantProps } from '@stitches/react';
import { styled, pxToRem, generateColorPaletteVariants, Box } from '@genialcare/atipico-react';

export const Root = styled(Box, {
  padding: `${pxToRem(4)} ${pxToRem(12)}`,
  display: 'flex',
  height: pxToRem(24),
  alignItems: 'center',
  textAlign: 'center',
  fontSize: pxToRem(12),
  fontWeight: '$semibold',
  borderRadius: pxToRem(16),
  variants: {
    color: generateColorPaletteVariants(['color']),
    bg: generateColorPaletteVariants(['backgroundColor']),
  },
  defaultVariants: {
    color: 'purple500',
    bg: 'neutral200',
  },
});

export type LabelVariants = VariantProps<typeof Root>;
