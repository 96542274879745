import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_PROGRAMS_BY_STATUS = gql`
  query getClinicalCaseProgramsByStatus($id: ID!, $objectiveStatuses: [String]) {
    clinicalCase(id: $id) {
      id
      name
      peis(objectiveStatuses: $objectiveStatuses) {
        id
        objectives {
          id
          status
          program {
            id
            name
            registerType
          }
        }
      }
    }
  }
`;
