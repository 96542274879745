import React, { useContext, createContext, PropsWithChildren, useState, useEffect } from 'react';
import { queueLink } from '../api/apollo-client';

type NetworkAvailabilityType = {
  isOnline: boolean;
  isOffline: boolean;
};

export const NetworkAvailability = createContext<NetworkAvailabilityType>({
  isOnline: navigator.onLine,
  isOffline: !navigator.onLine,
});

export const NetworkAvailabilityProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const setOnline = () => {
    setIsOnline(true);
    queueLink.open();
  };
  const setOffline = () => {
    setIsOnline(false);
    queueLink.close();
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    if (navigator.onLine) {
      setOnline();
    } else {
      setOffline();
    }
    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return (
    <NetworkAvailability.Provider
      value={{
        isOnline,
        isOffline: !isOnline,
      }}
    >
      {children}
    </NetworkAvailability.Provider>
  );
};

export const useNetworkAvailability = () => {
  const context = useContext(NetworkAvailability);

  if (!context) {
    throw new Error('useNetworkAvailability must be used within an NetworkAvailabilityProvider');
  }

  return context;
};
