import React from 'react';
import { Typography, StatusNotificationOn } from '@genialcare/atipico-react';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';
import { Flex } from 'styled-system/jsx';
import { NotificationData, NotificationType } from 'types';
import { Trigger } from './Trigger';
import { Badge } from './Badge';
import { Filter } from './Filter';
import {
  TaskResolved,
  TaskCreated,
  TaskDue,
  ClinicalGuidanceCreated,
  ClinicalGuidanceCommentsAdded,
  NoticeClinicalGuidanceCreated,
  NoticeClinicalGuidanceCommentAdded,
  UncompletedExpiredSession,
} from './Templates';
import { EmptyList } from 'components/EmptyList';
import { UseNotificationHookProps } from 'hooks/useNotifications';
import * as S from './Notifications.styles';
import { SessionCancelled } from './Templates/SessionCancelled';
import { SessionScheduleMadeOfficial } from './Templates/SessionScheduleMadeOfficial';
import { OfficialSessionScheduleDeleted } from './Templates/OfficialSessionScheduleDeleted';
import { FamilySupportMessagesCreated } from './Templates/FamilySupportMessagesCreated';

type NotificationHookProps = Omit<
  UseNotificationHookProps,
  'notifications' | 'handleReadNotification'
>;

type NotificationsProps = NotificationHookProps & {
  data?: NotificationData[];
  handleOnSelect?: (notificationId: string) => Promise<void>;
};

const EXTENDED_NOTIFICATIONS_SIZE = 100;

const isExtendedBadge = (count: number) => count >= EXTENDED_NOTIFICATIONS_SIZE;

const notificationComponents = {
  [NotificationType.TaskResolved]: TaskResolved,
  [NotificationType.TaskCreated]: TaskCreated,
  [NotificationType.TaskDue]: TaskDue,
  [NotificationType.ClinicalGuidanceCreated]: ClinicalGuidanceCreated,
  [NotificationType.ClinicalGuidanceCommentAdded]: ClinicalGuidanceCommentsAdded,
  [NotificationType.HighPriorityClinicalGuidanceCreated]: ClinicalGuidanceCreated,
  [NotificationType.HighPriorityClinicalGuidanceCommentAdded]: ClinicalGuidanceCommentsAdded,
  [NotificationType.NoticeClinicalGuidanceCreated]: NoticeClinicalGuidanceCreated,
  [NotificationType.NoticeClinicalGuidanceCommentAdded]: NoticeClinicalGuidanceCommentAdded,
  [NotificationType.UncompletedExpiredSession]: UncompletedExpiredSession,
  [NotificationType.SessionCancelled]: SessionCancelled,
  [NotificationType.SessionScheduleMadeOfficial]: SessionScheduleMadeOfficial,
  [NotificationType.OfficialSessionScheduleDeleted]: OfficialSessionScheduleDeleted,
  [NotificationType.FamilySupportConversationMessageCreated]: FamilySupportMessagesCreated,
  [NotificationType.HighPriorityFamilySupportConversationMessageCreated]:
    FamilySupportMessagesCreated,
} as unknown as Record<NotificationType, React.FC<NotificationData>>;

export const Notifications: React.FC<NotificationsProps> = ({
  data,
  handleOnSelect,
  handleSetNotificationTypes,
  selectedNotificationTypes = [],
}) => {
  const count = data?.length || 0;
  const hasNotifications = count > 0 || false;
  const unreadNotificationsCount = data?.filter(({ read }) => !read).length || 0;

  const emptyMessage =
    'Não se preocupe em perder nada! Novas discussões e comentários sempre vão aparecer aqui para você.';
  const emptyFilterMessage =
    'Não encontramos nenhum resultado. Tente novamente usando outro filtro.';

  return (
    <DropdownMenuRoot.Root modal={false}>
      <S.TriggerContainer>
        <Trigger />

        {unreadNotificationsCount > 0 && (
          <Badge
            color="neutral0"
            bg="red500"
            data-testid="unread-badge-notifications"
            extended={isExtendedBadge(unreadNotificationsCount)}
            fixed
          >
            {unreadNotificationsCount}
          </Badge>
        )}
      </S.TriggerContainer>

      <S.Content align="end" sideOffset={6}>
        <S.Header>
          <Flex gap="12px" alignItems="center">
            <Typography variant="h5" weight="bold" color="neutral500">
              Notificações
            </Typography>
            <Badge
              color="purple500"
              bg="neutral100"
              extended={isExtendedBadge(count)}
              data-testid="count-badge-notifications"
            >
              {count}
            </Badge>
          </Flex>

          <Filter
            selectedNotificationTypes={selectedNotificationTypes}
            handleSetNotificationTypes={handleSetNotificationTypes}
          />
        </S.Header>

        {!hasNotifications && (
          <EmptyList
            message={Boolean(selectedNotificationTypes.length) ? emptyFilterMessage : emptyMessage}
            Icon={StatusNotificationOn}
            iconColor="light"
            iconSize="md"
          />
        )}

        {hasNotifications && (
          <>
            <S.NotificationsContainer flexDir="column" data-testid="notifications-container">
              {data?.map(({ id, type, notifications, ...props }) => {
                const NotificationComponent = notificationComponents[type];

                if (!NotificationComponent) return null;

                const notificationProps = {
                  ...props,
                  id,
                  type,
                  notifications,
                  key: `notification-${id}`,
                  onSelect: handleOnSelect,
                };

                return <NotificationComponent {...notificationProps} />;
              })}
            </S.NotificationsContainer>
          </>
        )}
      </S.Content>
    </DropdownMenuRoot.Root>
  );
};
