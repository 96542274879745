import { styled, generateColorPaletteVariants } from '@genialcare/atipico-react';
import { VariantProps } from '@stitches/react';

export const Root = styled('a', {
  fontWeight: '$bold',
  textDecoration: 'underline',
  variants: {
    color: generateColorPaletteVariants(['color']),
  },
});

export type LinkPropsVariants = VariantProps<typeof Root>;
