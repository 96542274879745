import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';

import 'dayjs/locale/pt-br';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isoWeek);

dayjs.locale('pt-br');
dayjs.tz.setDefault('America/Sao_Paulo');

export { dayjs, Dayjs };

export const convertDateToPtBr = (dateString: string, options?: Intl.DateTimeFormatOptions) => {
  const date = new Date(dateString);

  if (date.toString() === 'Invalid Date') return dateString;

  return formatPtBrDate(date, options);
};

export const formatPtBrDate = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  return date.toLocaleString('pt-BR', {
    timeZone: options?.timeZone || 'America/Sao_Paulo',
    ...(options ?? {}),
  });
};

export const convertTimeToISODuration = (time: number) => {
  const timeAsDuration = dayjs.duration(time);

  return dayjs
    .duration({
      minutes: timeAsDuration.minutes(),
      seconds: timeAsDuration.seconds(),
    })
    .toISOString();
};

export const convertIsoTimeToCounter = (isoTime?: string): string => {
  if (!isoTime) return '00:00';

  return dayjs.duration(isoTime).format('mm:ss');
};

export const sumDurationTime = (isoTime: string, addTime: string): string => {
  return dayjs.duration(isoTime).add(addTime).toISOString();
};

export const convertDateStrToFormat = (format: string, dateString: string) => {
  return dayjs.utc(dateString).tz('UTC').format(format);
};

export const formatTime = (time: string) => dayjs.utc(time).format('HH:mm');

export const convertStrFormatToPtBrDate = (
  dateString: string,
  fromFormat: string,
  toFormat: string,
) => {
  return dayjs(dateString, fromFormat).format(toFormat);
};

const today = () => new Date().toString();

export const PT_BR_DATE_FORMAT = 'DD/MM/YYYY';
export const BASE_DATE_FORMAT = 'YYYY-MM-DD';
const dateFormat = BASE_DATE_FORMAT;

const calculateRange = (daysAgo: number, daysAhead: number, baseDate = today()) => {
  const endDate = dayjs(baseDate).add(daysAhead, 'day');
  const startDate = dayjs(baseDate).subtract(daysAgo, 'day');

  return { startDate: startDate, endDate: endDate };
};

export const createDateTimeRange = ({
  daysAgo,
  baseDate,
  daysAhead = 0,
}: {
  daysAgo: number;
  baseDate?: string;
  daysAhead?: number;
}) => {
  const { startDate, endDate } = calculateRange(daysAgo, daysAhead, baseDate);

  return {
    startDate: `${startDate.format(dateFormat)}T00:00:00`,
    endDate: `${endDate.format(dateFormat)}T23:59:59`,
  };
};

export const createDateRange = ({
  daysAgo,
  baseDate,
  daysAhead = 0,
}: {
  daysAgo: number;
  baseDate?: string;
  daysAhead?: number;
}) => {
  const { startDate, endDate } = calculateRange(daysAgo, daysAhead, baseDate);

  return {
    startDate: startDate.format(dateFormat),
    endDate: endDate.format(dateFormat),
  };
};

export const lastWeekRange = () => {
  const now = dayjs().utc();
  const startOfWeek = now.startOf('isoWeek');
  const startOfLastWeek = startOfWeek.subtract(1, 'week');
  const endOfLastWeek = startOfLastWeek.endOf('isoWeek');

  return {
    start: startOfLastWeek,
    end: endOfLastWeek,
  };
};
