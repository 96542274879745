import React, { useState } from 'react';
import { css } from 'styled-system/css';
import { CommunicationUser } from '@genialcare/atipico-react';
import { RadioGroup } from 'components/Form/Radio';
import { useSessionFilters } from 'contexts/filterSessions';
import * as S from './DisciplineFilter.styles';

type Discipline = {
  label: string;
  value: string;
};

const allDisciplines: Discipline[] = [
  {
    label: 'Análise do Comportamento (ABA)',
    value: 'ABA',
  },
  {
    label: 'Terapia Ocupacional (TO)',
    value: 'TO',
  },
  {
    label: 'Fonoaudiologia (FONO)',
    value: 'Fono',
  },
];

export const DisciplineFilter = () => {
  const { setFilters, filters } = useSessionFilters();
  const [discipline, setDiscipline] = useState<string>();

  const handleFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setFilters({
      discipline,
    });
  };

  return (
    <S.Filter
      icon={<CommunicationUser data-testid="icon" />}
      title="Ver por disciplina"
      onFilter={handleFilter}
      onClear={() => setFilters({ discipline: null })}
    >
      <S.Label variant="body2">Selecione a disciplina:</S.Label>
      <RadioGroup
        className={css({
          flexDir: 'column',
          mt: '12px',
          gap: { md: '12px' },
        })}
        defaultValue={filters?.discipline || ''}
        value={discipline}
      >
        {allDisciplines.map(({ label, value }, index) => (
          <S.Radio key={index} label={label} value={value} onClick={() => setDiscipline(value)} />
        ))}
      </RadioGroup>
    </S.Filter>
  );
};
