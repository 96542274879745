import { pxToRem, styled, Flex, Typography } from '@genialcare/atipico-react';

export const Header = styled(Flex, {
  gap: pxToRem(16),
  marginTop: pxToRem(56),
});

export const Root = styled('div', {
  borderRadius: '16px',
  minHeight: pxToRem(480),
});

export const Divider = styled('hr', {
  marginTop: pxToRem(8),
  borderTop: '1px solid #F1F1F1',
});

export const AttributeTitle = styled(Typography, {
  marginTop: pxToRem(32),
});

export const Text = styled(Typography, {
  margin: `${pxToRem(8)} ${pxToRem(8)} 0 ${pxToRem(8)}`,
  variants: {
    variant: {
      body1: {
        fontSize: pxToRem(16),
      },
    },
  },
});

export const TemplateForOldNotes = styled('div', {
  padding: `${pxToRem(32)} ${pxToRem(0)} `,
  fontSize: pxToRem(20),
  strong: {
    fontWeight: '$semibold',
  },
  li: {
    marginTop: pxToRem(10),
  },
});
