import { gql } from '@apollo/client';

export const TASK_FRAGMENT = gql`
  fragment TaskFields on Task {
    id
    status
    title
    description
    dueDate
    assignee {
      id
      name
    }
    createdBy {
      id
      name
    }
    context {
      id
      type
      clinicalCase {
        id
        name
      }
      details {
        ... on ClinicalGuidance {
          title
        }
      }
    }
    __typename
  }
`;
