import React, { useState } from 'react';
import * as S from './Filter.styles';

export type FilterProps = {
  'data-testid'?: string;
  title: string;
  onFilter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode | React.ReactNode[];
  icon: React.ReactNode | React.ReactNode[];
  shouldCloseOnClear?: boolean;
};

export const Filter: React.FC<FilterProps> = ({
  icon,
  title,
  onFilter,
  onClear,
  children,
  shouldCloseOnClear = true,
  'data-testid': dataTestId,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => setOpen((isOpen) => !isOpen);

  const handleClose = () => setOpen(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (onFilter?.(e)) handleClose();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (shouldCloseOnClear) setOpen(false);

    if (onClear?.(e) && shouldCloseOnClear) handleClose();
  };

  return (
    <S.Wrapper isOpen={open}>
      <S.Trigger isOpen={open} onClick={toggleOpen} data-testid={dataTestId}>
        {icon}
      </S.Trigger>
      {open && (
        <S.Background isOpen={open}>
          <S.Box {...rest}>
            <S.Header>
              <S.Title variant="body2" color="neutral0">
                <S.FilterIcon />
                {title}
                <S.CloseButton onClick={handleClose} />
              </S.Title>
            </S.Header>
            <S.Body>{children}</S.Body>
            <S.Footer>
              <S.Button onClick={handleSubmit}>Aplicar</S.Button>
              <S.Button variant="outline" onClick={handleClear}>
                Limpar
              </S.Button>
            </S.Footer>
          </S.Box>
        </S.Background>
      )}
    </S.Wrapper>
  );
};
