import React from 'react';
import { Typography } from '@genialcare/atipico-react';
import * as S from './Item.styles';
import { useList } from '../List.context';

type ListItemProps = React.PropsWithChildren & {
  name: string;
};

const itemStyles: Record<string, Record<string, unknown>> = {
  true: {
    color: 'neutral400',
    css: {
      fontFamily: '$dm-sans',
    },
  },
  false: {
    color: 'neutral500',
    weight: 'bold',
  },
};

export const Item: React.FC<ListItemProps> = ({ name, children, ...props }) => {
  const { sublist } = useList();
  let itemElement: JSX.Element;

  if (typeof children === 'string') {
    itemElement = (
      <Typography variant="body2" color="neutral400" css={S.descriptionStyle}>
        {children}
      </Typography>
    );
  } else {
    itemElement = <>{children}</>;
  }

  return (
    <S.Root {...props}>
      <Typography {...itemStyles[String(sublist)]} variant="body1">
        {name}
      </Typography>

      {itemElement}
    </S.Root>
  );
};
