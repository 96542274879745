import { graphql } from 'msw';

export const mockObjective1 = {
  id: '9bc0d809-654c-4559-8d70-c21185e8c2f4',
  description: 'Mando para continuação de uma atividade',
  skill: {
    id: 'f2b99d83-3050-48d4-9c13-c4dee41b94ad',
    name: 'Mando',
  },
  program: {
    id: 'a0f158b6-b747-4213-8865-eeea446c8607',
    name: 'Mando para continuação de uma atividade',
    instruction: "Pedir por 'MAIS' verbo|substantivo",
    stimulusDiscrimination: "Pedir por 'MAIS' verbo|substantivo",
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 2,
    registerType: 'trial',
    activities: [],
    targets: [
      {
        id: 'a0f158b6-b747-4213-8865-eeea446c8609',
        name: "Pedir por 'MAIS' verbo|substantivo",
        status: 'ready',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
    ],
    targetCategory: {
      name: 'Para continuação de uma atividade',
    },
    configuration: {
      __typename: 'TrialConfiguration',
      promptSchedule: {
        id: '1',
        name: 'Esquema de dicas relativo',
        promotion: null,
        demotion: null,
        sessionInARowToConsider: null,
        prompts: [
          {
            id: 'e0560fbb-1d05-4037-8c1e-dbcf330d2674',
            name: 'Erro',
            abbreviation: 'Erro',
          },
          {
            id: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
            name: 'Ajuda Física Total',
            abbreviation: 'AFT',
          },
          {
            id: '8a2d6ea1-029c-4688-85d1-40846f39958d',
            name: 'Ajuda Física Leve',
            abbreviation: 'AFL',
          },
          {
            id: 'f21a663a-7789-4b60-b73f-c8a87d80b53b',
            name: 'Modelo',
            abbreviation: 'Mod',
          },
          {
            id: 'b1fe20d0-c5e7-4da6-b1d2-f0430ef6c665',
            name: 'Ajuda Gestual',
            abbreviation: 'AG',
          },
          {
            id: '829d9ff0-1082-4941-960f-11006587824e',
            name: 'Independente',
            abbreviation: 'Independente',
          },
        ],
      },
    },
  },
};
export const mockObjective2 = {
  id: 'adc5a620-0784-45ad-8b40-4b6806d6c35b',
  description: 'Mando para obtenção de itens/atividades',
  skill: {
    id: 'f2b99d83-3050-48d4-9c13-c4dee41b94ad',
    name: 'Mando',
  },
  program: {
    id: 'a0f158b4-b747-4213-8865-eeea446c8603',
    name: 'Mando para obtenção de itens/atividades',
    instruction: 'OM da criança',
    stimulusDiscrimination: 'OM da criança',
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 2,
    registerType: 'trial',
    activities: [],
    targets: [
      {
        id: '26f21e0c-24bc-43f1-9aa9-a764273a4eb8',
        name: 'Eu quero + item',
        status: 'ready',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
    ],
    targetCategory: {
      name: 'Para obtenção de itens/atividades',
    },
    configuration: {
      __typename: 'TrialConfiguration',
      promptSchedule: {
        id: '1',
        name: 'Esquema de dicas relativo',
        promotion: null,
        demotion: null,
        sessionInARowToConsider: null,
        prompts: [
          {
            id: 'e0560fbb-1d05-4037-8c1e-dbcf330d2674',
            name: 'Erro',
            abbreviation: 'Erro',
          },
          {
            id: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
            name: 'Ajuda Física Total',
            abbreviation: 'AFT',
          },
          {
            id: '8a2d6ea1-029c-4688-85d1-40846f39958d',
            name: 'Ajuda Física Leve',
            abbreviation: 'AFL',
          },
          {
            id: 'f21a663a-7789-4b60-b73f-c8a87d80b53b',
            name: 'Modelo',
            abbreviation: 'Mod',
          },
          {
            id: 'b1fe20d0-c5e7-4da6-b1d2-f0430ef6c665',
            name: 'Ajuda Gestual',
            abbreviation: 'AG',
          },
          {
            id: '829d9ff0-1082-4941-960f-11006587824e',
            name: 'Independente',
            abbreviation: 'Independente',
          },
        ],
      },
    },
  },
};
export const mockObjective3 = {
  id: '992f4c9b-5a5c-4e84-876e-19d04ee3ca47',
  description: 'Mando para remoção de itens/atividades',
  skill: {
    id: 'f2b99d83-3050-48d4-9c13-c4dee41b94ad',
    name: 'Mando',
  },
  program: {
    id: 'a0f358b6-b747-4213-8865-ee3a446c8607',
    name: 'Mando para remoção de itens/atividades',
    instruction: 'OM da criança (quando identificado motivação para recusar algo)',
    stimulusDiscrimination: 'OM da criança (quando identificado motivação para recusar algo)',
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 3,
    registerType: 'trial',
    activities: [],
    targets: [
      {
        id: '35f9607d-c894-4c1c-8406-68f45cff7bb3',
        name: "'Não' gestual com o dedo",
        status: 'ready',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
      {
        id: 'c1e18324-4611-4111-abc5-87f427e13406',
        name: ' Pedir para trocar de atividade',
        status: 'ready',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
    ],
    targetCategory: {
      name: 'Para remoção de itens/atividades',
    },
    configuration: {
      __typename: 'TrialConfiguration',
      promptSchedule: {
        id: '1',
        name: 'Esquema de dicas relativo',
        promotion: null,
        demotion: null,
        sessionInARowToConsider: null,
        prompts: [
          {
            id: 'e0560fbb-1d05-4037-8c1e-dbcf330d2674',
            name: 'Erro',
            abbreviation: 'Erro',
          },
          {
            id: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
            name: 'Ajuda Física Total',
            abbreviation: 'AFT',
          },
          {
            id: '8a2d6ea1-029c-4688-85d1-40846f39958d',
            name: 'Ajuda Física Leve',
            abbreviation: 'AFL',
          },
          {
            id: 'f21a663a-7789-4b60-b73f-c8a87d80b53b',
            name: 'Modelo',
            abbreviation: 'Mod',
          },
          {
            id: 'b1fe20d0-c5e7-4da6-b1d2-f0430ef6c665',
            name: 'Ajuda Gestual',
            abbreviation: 'AG',
          },
          {
            id: '829d9ff0-1082-4941-960f-11006587824e',
            name: 'Independente',
            abbreviation: 'Independente',
          },
        ],
      },
    },
  },
};
export const mockObjective4 = {
  id: '43dce5c2-14d5-4809-807f-1a05529c6a5a',
  description: 'Tato por ações',
  skill: {
    id: 'f2b99d83-3050-48d4-9c13-c4dee41b94ad',
    name: 'Mando',
  },
  program: {
    id: 'a0f1e8b6-b747-4213-8865-ee2a446c8607',
    name: 'Tato por ações',
    instruction: 'O que ___ está fazendo?',
    stimulusDiscrimination: 'O que ___ está fazendo?',
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 2,
    registerType: 'trial',
    activities: [],
    targets: [
      {
        id: 'dda89532-89b8-49d5-abaa-d9852fd9fad4',
        name: 'Comendo',
        status: 'in_treatment',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
      {
        id: 'b8f3bbcd-2337-4b49-a0f5-71e433709c9d',
        name: 'Dormindo',
        status: 'in_treatment',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
      {
        id: '719c3c47-f3e6-4005-9c2d-f1742a4819c5',
        name: 'Pulando',
        status: 'in_treatment',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
      {
        id: 'a1c3de73-8787-45de-be82-e0a2bb33e02f',
        name: 'Bebendo',
        status: 'in_treatment',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
      {
        id: '01f62671-4055-4170-afaa-d27213b913c0',
        name: 'Pintando',
        status: 'in_treatment',
        data: {
          __typename: 'TrialTargetData',
          currentPromptId: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
        },
      },
    ],
    targetCategory: {
      name: 'Ações',
    },
    configuration: {
      __typename: 'TrialConfiguration',
      promptSchedule: {
        id: '1',
        name: 'Esquema de dicas relativo',
        promotion: null,
        demotion: null,
        sessionInARowToConsider: null,
        prompts: [
          {
            id: 'e0560fbb-1d05-4037-8c1e-dbcf330d2674',
            name: 'Erro',
            abbreviation: 'Erro',
          },
          {
            id: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
            name: 'Ajuda Física Total',
            abbreviation: 'AFT',
          },
          {
            id: '8a2d6ea1-029c-4688-85d1-40846f39958d',
            name: 'Ajuda Física Leve',
            abbreviation: 'AFL',
          },
          {
            id: 'f21a663a-7789-4b60-b73f-c8a87d80b53b',
            name: 'Modelo',
            abbreviation: 'Mod',
          },
          {
            id: 'b1fe20d0-c5e7-4da6-b1d2-f0430ef6c665',
            name: 'Ajuda Gestual',
            abbreviation: 'AG',
          },
          {
            id: '829d9ff0-1082-4941-960f-11006587824e',
            name: 'Independente',
            abbreviation: 'Independente',
          },
        ],
      },
    },
  },
};
export const mockObjectiveWithoutTargets = {
  id: '43dce5c2-14d5-4809-807f-1a05529c6a5a',
  description: 'Tato por ações',
  skill: {
    id: 'f2b99d83-3050-48d4-9c13-c4dee41b94ad',
    name: 'Mando',
  },
  program: {
    id: 'a0f158bf-b747-4213-8865-eeea446c860f',
    name: 'Mando - Por Ajuda',
    instruction: 'O que ___ está fazendo?',
    stimulusDiscrimination: 'O que ___ está fazendo?',
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 2,
    registerType: 'trial',
    activities: [],
    targets: [],
    targetCategory: {
      name: 'Ações',
    },
    configuration: {
      __typename: 'TrialConfiguration',
      promptSchedule: {
        id: '1',
        name: 'Esquema de dicas relativo',
        promotion: null,
        demotion: null,
        sessionInARowToConsider: null,
        prompts: [
          {
            id: 'e0560fbb-1d05-4037-8c1e-dbcf330d2674',
            name: 'Erro',
            abbreviation: 'Erro',
          },
          {
            id: 'a58f2667-7c74-466c-869d-eb00c546dd0e',
            name: 'Ajuda Física Total',
            abbreviation: 'AFT',
          },
          {
            id: '8a2d6ea1-029c-4688-85d1-40846f39958d',
            name: 'Ajuda Física Leve',
            abbreviation: 'AFL',
          },
          {
            id: 'f21a663a-7789-4b60-b73f-c8a87d80b53b',
            name: 'Modelo',
            abbreviation: 'Mod',
          },
          {
            id: 'b1fe20d0-c5e7-4da6-b1d2-f0430ef6c665',
            name: 'Ajuda Gestual',
            abbreviation: 'AG',
          },
          {
            id: '829d9ff0-1082-4941-960f-11006587824e',
            name: 'Independente',
            abbreviation: 'Independente',
          },
        ],
      },
    },
  },
};
export const mockObjectiveFrequency = {
  id: '147911f7-e1c9-4f1d-978b-19c2a5ed550d',
  description: 'Comportamento social no contato visual',
  skill: {
    id: 'd3a7f8ed-47f6-45a3-9aa4-01d77ebbcf57',
    name: 'Comportamento social',
  },
  program: {
    id: 'v0f158b6-b747-4213-8865-eeea446c8602',
    name: 'Comportamento social no contato visual',
    instruction: 'Interação com a criança',
    stimulusDiscrimination: 'Interação com a criança',
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 5,
    registerType: 'frequency',
    activities: [],
    targets: [
      {
        id: 'b1fe20d0-c5e7-4da6-b1d2-f0430df6c665',
        name: 'Freq. CV Com ajuda',
        status: 'ready',
        data: null,
      },
    ],
    targetCategory: {
      name: 'Contato visual',
    },
    configuration: null,
  },
};
export const mockObjectiveAnecdotal = {
  id: 'a6a0a9ba-8e7f-46cc-9d1d-e3d95f3bf57d',
  description: 'Estrutura linguistica',
  skill: {
    id: '5799eb15-daec-4f29-880e-b1dac80d3f92',
    name: 'Mando',
  },
  program: {
    id: 'a0f158b6-b747-4213-8865-eeea446c860n',
    name: 'Estrutura linguistica',
    instruction: 'OM da criança (quando identificado motivação para obter/remover algo)',
    stimulusDiscrimination: null,
    consequence: null,
    correctionProcedure: null,
    trialsPerSession: 5,
    registerType: 'anecdotal',
    activities: [],
    targets: [
      {
        id: 'b1fe20d0-c3e1-4da6-b1d2-f0430df6c622',
        name: 'Mando qualitativo',
        status: 'in_treatment',
        data: null,
      },
      {
        id: 'b1fe20d0-c3e1-5eb7-b1d2-f0430df6c613',
        name: 'Mando quantitativo',
        status: 'in_treatment',
        data: null,
      },
    ],
    targetCategory: {
      name: 'Mando qualitativo',
    },
    configuration: null,
  },
};

const mockObjectiveTaskAnlysis = {
  id: '1ac5670c-ab3d-4f89-a3d7-7381714df95a',
  description: 'Criança aprendera a se lavar',
  discipline: 'aba',
  skill: {
    id: '106229da-850a-458b-ac76-f168c39be45f',
    name: 'Comportamento Social',
  },
  program: {
    id: 't0f158b6-b747-4213-8865-eeea446c860a',
    name: 'Criança aprendera a se lavar',
    instruction: 'Ensinar a criança a se lavar',
    stimulusDiscrimination: 'Interação com a criança durante o banho',
    consequence: '',
    correctionProcedure: '',
    trialsPerSession: 3,
    registerType: 'taskAnalysis',
    activities: [
      {
        name: 'Brincando com agua',
      },
    ],
    targets: [
      {
        id: 'b2fe21d5-c1e1-5eb7-b1d2-f0430df6c701',
        name: 'CV com ajuda',
        data: null,
        status: 'in_treatment',
      },
    ],
    targetCategory: {
      name: 'Domésticos',
    },
    configuration: {
      __typename: 'TaskAnalysisConfiguration',
      steps: [
        {
          id: '5ab30bb4-d024-489f-b396-686dfc324db0',
          name: 'Entrar no banheiro',
          level: 1,
        },
        {
          id: 'c1a7444e-f6e1-44cc-9a36-615d920f14f1',
          name: 'Abrir a torneira',
          level: 2,
        },
      ],
    },
  },
};

export default graphql.query('getClinicalCasePEIs', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        id,
        peis: [
          {
            id: '96c00a05-1a7e-46f9-8eda-0155f1243643',
            version: 1,
            objectives: [
              mockObjective1,
              mockObjective2,
              mockObjective3,
              mockObjective4,
              mockObjectiveFrequency,
              mockObjectiveAnecdotal,
              mockObjectiveTaskAnlysis,
            ],
          },
        ],
      },
    }),
  );
});
