import { gql } from '@apollo/client';
import { PLANNING_FRAGMENT } from 'queries/planning';

export const GET_USER_PLANNING = gql`
  ${PLANNING_FRAGMENT}

  query getUserPlanning($id: ID!) {
    user {
      id
      firstName
      lastName
      planning(id: $id) {
        ...PlanningFields
        __typename
      }
    }
  }
`;
