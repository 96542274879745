import { pxToRem, styled } from '@genialcare/atipico-react';

export const Root = styled('details', {
  position: 'relative',
  '&[open]:not(aria-disabled="true")': {
    '&>summary': {
      outline: '1px solid $purple500',
      borderColor: 'transparent',
      color: '$purple500',
    },
  },
  '::-webkit-scrollbar': {
    width: 6,
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(63, 67, 80, 0.24)',
    height: 83,
    borderRadius: 40,
  },
});

export const Trigger = styled('summary', {
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontFamily: '$open-sans',
  fontSize: pxToRem(16),
  borderRadius: pxToRem(8),
  padding: pxToRem(16),
  backgroundColor: '$neutral0',
  border: '1px solid $neutral300',
  position: 'relative',
  cursor: 'pointer',
  '&[aria-disabled="true"]': {
    backgroundColor: '#f0f0f0',
    borderColor: '#DDD',
    color: '#999',
    cursor: 'default',
  },
  '&[aria-expanded="true"]': {
    outline: '1px solid $purple500',
    borderColor: 'transparent',
    '&[data-placeholder]': {
      color: '$purple500',
    },
    '&>div': {
      rotate: '180deg',
    },
  },
  '&[data-placeholder]': {
    color: '$neutral300',
  },
});

export const TriggerText = styled('span', {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Icon = styled('span', {
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: `${pxToRem(6)} ${pxToRem(6)} 0 ${pxToRem(6)}`,
  borderColor: '$purple500 transparent transparent transparent',
  color: '$purple500',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: pxToRem(16),
  margin: 'auto',
  '&[aria-disabled="true"]': {
    borderTopColor: '#999',
  },
});

export const Content = styled('div', {
  width: '100%',
  maxHeight: 246,
  color: '$neutral400',
  overflow: 'hidden auto',
  backgroundColor: 'white',
  borderRadius: pxToRem(6),
  padding: pxToRem(8),
  border: '1px solid $neutral100',
  position: 'absolute',
  top: pxToRem(68),
  zIndex: 5,
});

export const Item = styled('span', {
  width: '100%',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  padding: `${pxToRem(12)} ${pxToRem(8)}`,
  borderRadius: pxToRem(8),
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$neutral100',
    fontWeight: 700,
  },
  '&:focus-visible': {
    outline: 'none',
    backgroundColor: '$neutral100',
    fontWeight: 800,
  },
  '&:[data-disabled]': {
    color: 'red',
    pointerEvents: 'none',
  },
  variants: {
    isActive: {
      true: {
        backgroundColor: '$neutral100',
        fontWeight: 700,
      },
    },
  },
});
