import { gql } from '@apollo/client';

export const GET_CLINICAL_GUIDANCE = gql`
  query getClinicalGuidance($id: ID!) {
    clinicalGuidance(id: $id) {
      id
      title
      content
      status
      priority
      category
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      context {
        id
        type
        clinicalCase {
          id
        }
      }
      comments {
        id
        content
        createdAt
        updatedAt
        createdBy {
          id
          name
        }
      }
      tasks {
        id
        title
        description
        dueDate
        status
        createdAt
        updatedAt
        assignee {
          id
          name
        }
        createdBy {
          id
          name
        }
        context {
          id
          type
          clinicalCase {
            id
          }
        }
      }
    }
  }
`;
