import { TaskContextData, TaskNotificationData } from 'types/notification';
import { buildURL } from 'utils/build-urls';
import { dayjs } from 'utils/date';

export const getTaskHref = ({
  clinicalCaseId,
  clinicalGuidanceId,
  registryId,
}: Partial<TaskContextData>) => {
  let href = 'panel/home';

  if (clinicalCaseId) {
    href = buildURL.toClinicalCase(clinicalCaseId);
  }

  if (clinicalGuidanceId && clinicalCaseId) {
    href = buildURL.toClinicalGuidance(clinicalCaseId, clinicalGuidanceId);
  }

  if (registryId && clinicalCaseId) {
    href = buildURL.toClinicalGuidanceRegistry(clinicalCaseId, registryId);
  }

  return href;
};

export const getTaskContext = ({
  clinicalCaseName,
  clinicalGuidanceTitle,
  registryId,
  sessionDate,
}: Partial<TaskNotificationData>) => {
  let titleComplement = 'em Tarefas Pessoais';

  if (clinicalCaseName) {
    titleComplement = `no caso '${clinicalCaseName}'`;
  }

  if (clinicalGuidanceTitle) {
    titleComplement = `na discussão '${clinicalGuidanceTitle}'`;
  }

  if (registryId && sessionDate) {
    const formattedSessionDate = dayjs(sessionDate.toDate()).format('DD/MM/YYYY');
    titleComplement = `no registro de OC da 'sessão ${formattedSessionDate}'`;
  }

  return titleComplement;
};
