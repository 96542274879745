import { FirebaseApp } from 'firebase/app';
import { User } from 'types';
import useAuthorizedQuery from './useAuthorizedQuery';
import { GET_FIREBASE_CUSTOM_TOKEN } from 'queries';
import { UserCredential, getAuth, signInWithCustomToken } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { logError } from '../utils/logger';

type Data = {
  user: User;
};

type Variables = Record<string, unknown>;

export const useFirebaseAuth = (firebaseApp: FirebaseApp) => {
  const [userCredential, setUserCredential] = useState<UserCredential | null>();
  const auth = getAuth(firebaseApp);
  const { loading, error, data } = useAuthorizedQuery<Data, Variables>(GET_FIREBASE_CUSTOM_TOKEN);

  useEffect(() => {
    if (!data) return;

    const getSignInWithCustomToken = async () => {
      try {
        const response = await signInWithCustomToken(auth, data.user.firebase.token);
        setUserCredential(response);
      } catch (e) {
        if ((e as Error)?.message === 'network-request-failed') return;
        logError(e as Error);
      }
    };

    getSignInWithCustomToken();
  }, [data, auth, loading]);

  if (error) {
    logError(error, { component: 'useFirebaseAuth', query: 'GET_FIREBASE_CUSTOM_TOKEN' });
    return { auth, userCredential: null };
  }

  return { auth, userCredential };
};
