import React, { useState, useEffect } from 'react';
import { pxToRem, Typography, Flex, Button } from '@genialcare/atipico-react';
import { ClinicalGuidanceComment } from 'types';
import { convertDateToPtBr } from 'utils/date';
import { useUpdateClinicalGuidanceComment } from 'hooks/useUpdateClinicalGuidanceComment';
import * as S from './CommentItem.styles';
import { Form } from 'components/Form/Form';
import { useClinicalGuidanceCommentForm } from 'hooks/useClinicalGuidanceCommentForm';

type CommentItemProps = {
  userId: string;
  clinicalGuidanceId: string;
  data: ClinicalGuidanceComment;
};

const showCommentDate = (createdAt: string, updatedAt: string) => {
  const suffix = createdAt !== updatedAt ? '(editado)' : '';

  return `${convertDateToPtBr(createdAt, { dateStyle: 'short' })} ${suffix}`;
};

export const CommentItem: React.FC<CommentItemProps> = ({ userId, clinicalGuidanceId, data }) => {
  const [showForm, setShowForm] = useState(false);
  const {
    mutation: updateComment,
    loading: isUpdating,
    data: updatedCommentData,
  } = useUpdateClinicalGuidanceComment();

  const comment = updatedCommentData ?? data;
  const {
    handleSubmit,
    getContentInputProps,
    formState: { isValid, isSubmitting },
    formMethods,
  } = useClinicalGuidanceCommentForm({
    value: comment.content,
    onSubmit: ({ content }) => {
      const input = {
        content,
        clinicalGuidanceId,
        commentId: data.id,
      };

      return updateComment(input);
    },
  });

  const isOwner = userId === data.createdBy.id;
  const isLoading = isSubmitting || isUpdating;
  const isDisabled = !isValid || isLoading;

  const handleShowForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowForm(true);
  };

  const handleHideForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowForm(false);
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const el = event.target;
    const contentLength = comment.content.length;

    el.setSelectionRange(contentLength, contentLength);
  };

  useEffect(() => {
    if (updatedCommentData) {
      setShowForm(false);
    }
  }, [updatedCommentData]);

  return (
    <Form
      formMethods={formMethods}
      onSave={handleSubmit}
      name={{
        subject: 'clinical_guidance_comment',
        action: 'updated',
      }}
    >
      <S.Card direction="row" align="start" bg="neutral100">
        <S.CardBody direction="column">
          <Typography variant="body2" weight="bold" color="purple500">
            {data.createdBy.name}
          </Typography>

          {showForm ? (
            <S.TextArea
              {...getContentInputProps()}
              autoFocus
              rows={3}
              placeholder="Descreva aqui"
              onFocus={handleOnFocus}
            />
          ) : (
            <Typography
              variant="body2"
              color="neutral400"
              css={{ fontSize: pxToRem(12), whiteSpace: 'pre-line', wordBreak: 'break-all' }}
            >
              {comment.content}
            </Typography>
          )}

          <Flex
            align={{ '@initial': 'start', '@md': 'center' }}
            direction={{ '@initial': 'column', '@md': 'row' }}
            css={{ gap: 12 }}
          >
            <Flex css={{ gap: 12 }}>
              {isOwner && !showForm && (
                <Button variant="link" onClick={handleShowForm} css={{ fontSize: 12 }}>
                  Editar
                </Button>
              )}

              <Typography variant="quote" color="neutral300" css={{ fontSize: pxToRem(10) }}>
                Comentário criado em {showCommentDate(comment.createdAt, comment.updatedAt)}
              </Typography>
            </Flex>

            {showForm && (
              <S.FormActions justify="end" direction={{ '@initial': 'column', '@md': 'row' }}>
                <S.FormActionButton
                  variant="outline"
                  onClick={handleHideForm}
                  css={{ fontSize: 12 }}
                >
                  Cancelar
                </S.FormActionButton>
                <S.FormActionButton isDisabled={isDisabled} isLoading={isLoading}>
                  Salvar
                </S.FormActionButton>
              </S.FormActions>
            )}
          </Flex>
        </S.CardBody>
      </S.Card>
    </Form>
  );
};
