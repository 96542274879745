import React, { useState, createContext, PropsWithChildren, useContext } from 'react';

type FiltersData = {
  startDate?: string | null;
  endDate?: string | null;
  discipline?: string | null;
  user?: string | null;
};
type FiltersSessionsContextType = {
  filters: FiltersData | null;
  setFilters: (data: FiltersData) => void;
};

export const FiltersSessionsContext = createContext<FiltersSessionsContextType | null>(null);

export const FiltersSessionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [allFilters, setAllFilters] = useState<FiltersData>({});

  const setFilters = (newFilters: FiltersData) => {
    setAllFilters((oldFilters: FiltersData) => ({
      ...oldFilters,
      ...newFilters,
    }));
  };

  return (
    <FiltersSessionsContext.Provider value={{ filters: allFilters, setFilters }}>
      {children}
    </FiltersSessionsContext.Provider>
  );
};

export const useSessionFilters = (): FiltersSessionsContextType => {
  const context = useContext(FiltersSessionsContext);

  if (!context) {
    throw new Error('useSessionFilters must be used within an FilterProvider');
  }

  return context;
};
