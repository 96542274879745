import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CardBody, FileFolderBlock, Typography } from '@genialcare/atipico-react';
import type { ThemeColors } from '@genialcare/atipico-react';
import { convertDateToPtBr } from 'utils/date';
import { InterventionSession, SkillAcquisition } from 'types';
import { useDevice } from 'hooks/useDevice';
import { FormValues, useCreateSkillAcquisitionNote } from 'hooks/useCreateSkillAcquisitionNote';
import { TextAreaErrorMessage } from 'components/Form/TextArea';
import { Tooltip } from 'components/Tooltip';
import { AlertDialog, AlertDialogTitle } from 'components/AlertDialog';
import * as S from './Form.styles';
import { logError } from 'utils/logger';
import { REQUIRED_ERROR_MESSAGE } from 'constants/validation-messages';
import { buildMinLengthValidationMessage, buildMaxLengthValidationMessage } from 'utils/validation';
import { Toast } from 'components/Toast';
import { Banner } from 'components/Banner';
import { buildErrorMessage } from 'utils/build-error-message';
import { Form } from 'components/Form/Form';

interface FormProps {
  skillAcquisitions: SkillAcquisition[];
  interventionSession?: InterventionSession;
}

type TooltipTextProps = {
  textColor?: ThemeColors;
  linkColor?: ThemeColors;
};

const TooltipText: React.FC<TooltipTextProps> = ({ textColor, linkColor }) => (
  <Tooltip.Text color={textColor}>
    Acesse nossa documentação no{' '}
    <Tooltip.Link
      color={linkColor}
      href="https://www.notion.so/Tweets-sobre-habilidades-760ee95e5df94cf88bb9aa4bb41be6a4"
      target="_blank"
      rel="noopener noreferrer"
    >
      Notion
    </Tooltip.Link>{' '}
    para te auxiliar! 😀
  </Tooltip.Text>
);

export const TweetsForm: React.FC<FormProps> = ({ skillAcquisitions, interventionSession }) => {
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [openTip, setOpenTip] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const navigate = useNavigate();
  const { id = '', interventionId = '' } = useParams();
  const { isBigScreen } = useDevice();

  const methods = useForm<FormValues>({
    mode: 'onBlur',
  });
  const { register, reset, setValue, formState } = methods;

  const { mutate, loading, error: mutationError } = useCreateSkillAcquisitionNote();

  useEffect(() => {
    const hasErrors = Object.keys(formState.errors).length > 0;
    setEnableSave(!formState.isValidating && formState.isValid && !hasErrors);
  }, [formState]);

  useEffect(() => {
    if (mutationError) {
      setShowError(true);
    }
  }, [mutationError]);

  const errors = formState.errors;

  const options = skillAcquisitions?.map((skill) => ({
    value: skill.id,
    children: `${skill.skillName} - ${skill.technicalName} / ${skill.name}`,
  }));

  const tweets = interventionSession?.skillAcquisitionNotes || [];

  const onSubmit = async (data: FormValues) => {
    try {
      await mutate({
        values: {
          ...data,
          clinicalCaseId: id,
          interventionId,
        },
        onSuccess: () =>
          navigate(`/panel/clinical-cases/${id}/interventions/${interventionId}/tweets/success`),
      });
    } catch (err) {
      logError(err as Error, {
        component: 'Interventions/Tweets',
        mutation: 'CREATE_SKILL_ACQUISITION_NOTE',
      });
    }
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    reset();
    navigate(`/panel/clinical-cases/${id}/overview`);
  };

  return (
    <>
      {!isBigScreen && (
        <AlertDialog open={openTip} onOpenChange={setOpenTip} closable>
          <AlertDialogTitle>Dúvidas em como escrever um tweet?</AlertDialogTitle>
          <TooltipText textColor="neutral400" linkColor="purple500" />
        </AlertDialog>
      )}

      <S.Title variant="h4" weight="bold">
        Criar novo tweet da sessão
      </S.Title>
      <S.Wrapper>
        <Toast open={showError} duration={1000}>
          <Banner
            type="error"
            Icon={FileFolderBlock}
            closable
            handleClose={() => setShowError(false)}
          >
            Ocorreu um erro ao enviar seus dados. Por favor, tente novamente.
            <br />
            Erro: {buildErrorMessage(mutationError)}
          </Banner>
        </Toast>
        <Form
          formMethods={methods}
          onSave={onSubmit}
          name={{
            subject: 'intervention_tweets',
          }}
        >
          <S.FieldGroup>
            <Typography variant="body2" weight="bold">
              Escolha a habilidade que foi trabalhada durante a sessão.*
            </Typography>
            <S.Select
              options={options}
              {...register('targetGroupId', {
                required: REQUIRED_ERROR_MESSAGE,
              })}
              onSelect={(value) => setValue('targetGroupId', value, { shouldValidate: true })}
            />
          </S.FieldGroup>
          <S.FieldGroup>
            <S.Tip>
              <Typography variant="body2" weight="bold">
                Faça o tweet sobre a sessão.*
              </Typography>
              <Tooltip isOpen={openTip && isBigScreen} onOpenChange={setOpenTip}>
                <Tooltip.Text variant="title">Dúvidas em como escrever um tweet?</Tooltip.Text>
                <TooltipText />
              </Tooltip>
            </S.Tip>
            <S.HelperText variant="body2">
              Escreva um resumo descritivo da evolução dessa habilidade, por exemplo: Geninho
              conseguiu pedir para o terapeuta pegá-lo no colo sem precisar de ajuda.
            </S.HelperText>
            <S.TextArea
              placeholder="Descreva aqui"
              isInvalid={!!errors.body}
              maxLength={500}
              showCounter
              {...register('body', {
                required: REQUIRED_ERROR_MESSAGE,
                maxLength: buildMaxLengthValidationMessage(500),
                minLength: buildMinLengthValidationMessage(100),
              })}
            />
            <TextAreaErrorMessage>{errors.body?.message}</TextAreaErrorMessage>
          </S.FieldGroup>
          <S.Flex justify="end" wrap={{ '@xs': 'wrap', '@md': 'no-wrap' }}>
            <S.Button
              colorScheme="purple"
              variant="outline"
              onClick={handleCancel}
              isDisabled={loading}
            >
              Cancelar
            </S.Button>
            <S.Button colorScheme="purple" isDisabled={!enableSave} isLoading={loading}>
              Salvar
            </S.Button>
          </S.Flex>
        </Form>
      </S.Wrapper>
      {tweets?.length > 0 && (
        <>
          <S.Title variant="h4" weight="bold">
            Tweets sobre a evolução da criança
          </S.Title>
          {tweets?.map((tweet) => (
            <S.Tweet key={tweet.id} direction="column">
              <CardBody>
                <S.TweetTitle variant="h5" weight="bold">
                  {tweet?.targetGroup?.name}
                </S.TweetTitle>
                <S.TweetBody variant="body2">{tweet.body}</S.TweetBody>
                <S.TweetAuthor>
                  Por {tweet?.clinician?.name} ({tweet?.clinician.professionalRegistrationNumber})
                  em {convertDateToPtBr(tweet.createdAt, { dateStyle: 'short' })}
                </S.TweetAuthor>
              </CardBody>
            </S.Tweet>
          ))}
        </>
      )}
    </>
  );
};
