import {
  styled as atStyled,
  pxToRem,
  CSS,
  Button,
  IconButton,
  InterfaceOther2,
  InterfaceEditAlt,
  FileFolderOpen,
} from '@genialcare/atipico-react';
import { styled } from 'styled-system/jsx';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';

export const TriggerMenuButtonIcon = atStyled(InterfaceOther2, {
  width: '35px',
  height: '35px',
  fill: '$purple500',
});

export const triggerMenuButtonOpenStyle: CSS = {
  '@md': {
    '&[data-state="open"]': {
      backgroundColor: '$purple100',
    },
    [`&[data-state="open"] ${TriggerMenuButtonIcon} path`]: {
      stroke: '$neutral0',
    },
  },
};

export const TriggerMenuButton = atStyled(IconButton, {
  width: pxToRem(48),
  height: pxToRem(48),
});

export const Content = styled(DropdownMenuRoot.Content, {
  base: {
    display: 'flex',
    flexDir: 'column',
    p: '12px',
    gap: '12px',
    bg: 'neutral.0',
    borderRadius: '16px',
    animationDuration: '400ms',
    animationName: 'slideUpAndFade',
    willChange: 'transform, opacity',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  },
});

export const MenuItemButton = atStyled(Button, {
  width: '176px',
  height: '42px',
  minWidth: 'auto',
  variants: {
    colorScheme: {
      default: {
        fontSize: '16px',
        color: '$purple500',
        backgroundColor: '$neutral0',

        '&:hover': {
          backgroundColor: '$neutral100',
        },
      },
    },
  },
  defaultVariants: {
    colorScheme: 'default',
  },
});

export const EditIcon = atStyled(InterfaceEditAlt, {
  width: '25px',
  height: '25px',
  fill: '$purple500',
});

export const FolderIcon = atStyled(FileFolderOpen, {
  width: '20px',
  height: '20px',
  fill: '$purple500',
});
