import { gql } from '@apollo/client';

export const UPDATE_SUBJECT = gql`
  mutation updateSubject($subjectInput: UpdateSubjectInput!) {
    updateSubject(subjectInput: $subjectInput) {
      id
      text
      status
      statusDescription
      createdAt
      updatedAt
      clinicalDecision {
        id
        text
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
