import { gql } from '@apollo/client';

export const UPDATE_PEI_OBJECTIVE_STATUS = gql`
  mutation updatePEIObjectiveStatus(
    $objective: UpdateObjectiveStatusInput!
    $clinicalCaseId: String!
  ) {
    updatePEIObjectiveStatus(objective: $objective, clinicalCaseId: $clinicalCaseId) {
      id
    }
  }
`;
