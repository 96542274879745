import { gql } from '@apollo/client';

export const GET_USER_CLINICAL_CASES = gql`
  query getUserClinicalCases {
    user {
      clinicalCases {
        id
        name
      }
    }
  }
`;
