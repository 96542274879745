import { pxToRem, styled } from '@genialcare/atipico-react';
import { Typography, Button as ButtonDefault } from '@genialcare/atipico-react';
import { Wrapper as WrapperDefault } from 'components/Wrapper';

export const Wrapper = styled(WrapperDefault, {
  marginTop: pxToRem(56),
  borderRadius: pxToRem(16),
});

export const Image = styled('img', {
  width: pxToRem(250),
  maxWidth: '100%',
  maxHeight: pxToRem(250),
  margin: `${pxToRem(8)} auto 0`,
  display: 'block',
  aspectRatio: 1 / 1,
});

export const Title = styled(Typography, {
  textAlign: 'center',
  margin: `${pxToRem(8)} auto ${pxToRem(20)}`,
});

export const Text = styled(Typography, {
  width: '100%',
  maxWidth: pxToRem(380),
  margin: 'auto',
  textAlign: 'center',
});

export const Button = styled(ButtonDefault, {
  display: 'block',
  textAlign: 'center',
  margin: `${pxToRem(24)} auto 0`,
});
