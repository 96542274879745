import React, { Suspense } from 'react';
import { Routes } from './routes/Routes';
import { FirebaseProvider } from './contexts/firebase';
import { UserProvider } from './contexts/user';
import { SplitIOProvider } from './contexts/split-io';
import { setupMock } from './mocks/browser';
import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

if (process.env.REACT_APP_MOCK_GRAPHQL === 'true') {
  setupMock();
}

const App: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const cubejsApi = cubejs(async () => await getAccessTokenSilently(), {
    apiUrl: `${process.env.REACT_APP_CUBEJS_API_URL}/cubejs-api/v1`,
  });

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <FirebaseProvider>
        <SplitIOProvider>
          <UserProvider>
            <ErrorBoundary key={location.pathname}>
              <Suspense fallback={<h1> Carregando </h1>}>
                <Routes />
              </Suspense>
            </ErrorBoundary>
          </UserProvider>
        </SplitIOProvider>
      </FirebaseProvider>
    </CubeProvider>
  );
};

export default App;
