import { gql } from '@apollo/client';

export const CREATE_CUSTOM_TARGET_LIST = gql`
  mutation createCustomTargetList(
    $clinicalCaseId: String!
    $customTargetList: CustomTargetListInput!
  ) {
    createCustomTargetList(clinicalCaseId: $clinicalCaseId, customTargetList: $customTargetList) {
      id
    }
  }
`;
