import { Card, CardBody, CardHeader, pxToRem, Button, styled } from '@genialcare/atipico-react';

export const Root = styled(Card, {
  marginTop: pxToRem(1),
  marginBottom: pxToRem(30),
  boxShadow: 'none',

  variants: {
    round: {
      true: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
      },
    },
  },
});

export const Note = styled(Card, {
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: pxToRem(12),
});

export const NoteHeader = styled(CardHeader, {
  paddingBottom: pxToRem(1),
});

export const NoteBody = styled(CardBody, {
  paddingBottom: pxToRem(1),
});

export const CreateNoteButton = styled(Button, {
  marginTop: pxToRem(12),
  paddingLeft: pxToRem(42),
  width: '100%',

  '@lg': {
    width: 'auto',
    alignSelf: 'flex-end',
    marginLeft: pxToRem(12),
    marginTop: 0,
    minWidth: pxToRem(228),
  },
});
