import React from 'react';
import * as AlertDialogDefault from '@radix-ui/react-alert-dialog';
import * as S from './AlertDialog.styles';

type AlertDialogCancelButtonProps = {
  children: string | React.ReactNode | React.ReactNode[];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const AlertDialogCancelButton: React.FC<AlertDialogCancelButtonProps> = ({
  children,
  onClick,
  ...rest
}) => (
  <AlertDialogDefault.Cancel asChild {...rest}>
    <S.Button onClick={onClick} variant="outline">
      {children}
    </S.Button>
  </AlertDialogDefault.Cancel>
);
