import React from 'react';
import * as S from './AlertDialog.styles';

type AlertDialogTitleProps = {
  children: string;
};

export const AlertDialogTitle: React.FC<AlertDialogTitleProps> = ({ children, ...rest }) => (
  <S.Title {...rest}>{children}</S.Title>
);
