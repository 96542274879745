import { gql } from '@apollo/client';

export const GET_CAREGIVERS_AND_DEPENDENTS = gql`
  query getCaregiversAndDependents($id: ID!) {
    clinicalCase(id: $id) {
      name
      id
      caregivers {
        id
        name
        cellphoneNumber
      }
      dependents {
        id
        name
        avatarImage
        birthDate
        age
      }
    }
  }
`;
