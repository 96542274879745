import { gql } from '@apollo/client';

export const INTERVENTION_NOTES_SUMMARY_FRAGMENT = gql`
  fragment InterventionNotesSummaryFields on InterventionNoteSummary {
    id
    key
    promptVersion
    clinicalCaseId
    startDate
    endDate
    structuredSummary
    createdAt
    updatedAt
  }
`;
