import React from 'react';
import * as AlertDialogDefault from '@radix-ui/react-alert-dialog';
import * as S from './AlertDialog.styles';

type AlertDialogProps = {
  children: string | React.ReactNode | React.ReactNode[];
  open?: boolean;
  onOpenChange?(open: boolean): void;
  defaultOpen?: boolean;
  closable?: boolean;
  className?: string;
};

export const AlertDialog = React.forwardRef<HTMLDivElement, AlertDialogProps>(
  (
    { children, className, onOpenChange, open, defaultOpen = false, closable = false, ...rest },
    ref,
  ) => {
    const handleClose = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
      onOpenChange?.(false);
    };

    return (
      <AlertDialogDefault.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        {...rest}
      >
        <AlertDialogDefault.Portal>
          <S.Overlay />
          <S.Content ref={ref} className={className}>
            {closable && (
              <S.CloseButton
                data-alert-close-button
                data-testid="alert-dialog-close-button"
                onClick={handleClose}
              />
            )}
            {children}
          </S.Content>
        </AlertDialogDefault.Portal>
      </AlertDialogDefault.Root>
    );
  },
);
