import { gql } from '@apollo/client';

export const PARENTAL_TRAINING_NOTE_FIELDS = gql`
  fragment ParentalTrainingNoteFields on ParentalTrainingNote {
    id
    sessionId
    sessionGoals
    body
    nextSessionAgreements
    createdAt
    updatedAt
  }
`;

export const PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS = gql`
  fragment ParentalTrainingNoteAuthorFields on ParentalTrainingNote {
    createdBy {
      id
      clinician {
        id
        name
        professionalRegistrationNumber
      }
    }
  }
`;
