import React from 'react';
import { useNavigate } from 'react-router-dom';
import { pxToRem, Flex, Typography, FileClipboardAlt } from '@genialcare/atipico-react';
import { Task } from 'types';
import { ClinicalGuidanceTaskCard } from 'components/TaskCard';
import { EmptyList } from 'components/EmptyList';
import * as S from './TaskList.styles';

type TaskListProps = {
  tasks: Task[];
  userId: string;
  clinicalCaseId: string;
  clinicalGuidanceId: string;
  onDeleted?: (taskId: string) => void;
};

export const TaskList: React.FC<TaskListProps> = ({
  userId,
  tasks,
  clinicalCaseId,
  clinicalGuidanceId,
  onDeleted,
}) => {
  const navigate = useNavigate();
  const tasksCount = tasks.length;
  const hasTasks = tasksCount > 0;

  const handleRedirectToNewTaskPage = (e: React.MouseEvent) => {
    e.preventDefault();

    const url = `/panel/clinical-cases/${clinicalCaseId}/clinical-guidances/${clinicalGuidanceId}/tasks/new`;
    navigate(url);
  };

  const emptyMessage =
    'Ainda não foi adicionada nenhuma tarefa relacionada a esta discussão. Para começar, clique em criar nova tarefa.';

  return (
    <S.Root direction="column" data-testid="task-list">
      <Flex align="center" justify="space-between">
        <S.Heading align="center">
          <Typography variant="h5" weight="bold" color="neutral500">
            Tarefas
          </Typography>
          <S.Counter justify="center" align="center" data-testid="tasks-counter">
            <Typography
              variant="body2"
              weight="bold"
              color="purple500"
              css={{ fontSize: pxToRem(14) }}
            >
              {tasksCount}
            </Typography>
          </S.Counter>
        </S.Heading>

        <S.NewTaskButton
          colorScheme="purple"
          onClick={handleRedirectToNewTaskPage}
          leftIcon={<S.InterfacePlus color="white" />}
          data-testid="new-task-button"
        >
          Criar nova tarefa
        </S.NewTaskButton>
      </Flex>

      {!hasTasks && (
        <EmptyList
          message={emptyMessage}
          Icon={FileClipboardAlt}
          iconColor="light"
          css={{
            '@md': { width: pxToRem(406) },
            margin: `${pxToRem(42)} auto`,
          }}
        />
      )}

      {hasTasks && (
        <S.ListContainer direction="column" data-testid="task-list-container">
          {tasks.map((task) => (
            <ClinicalGuidanceTaskCard
              {...task}
              userId={userId}
              key={`task-card-${task.id}`}
              onDeleted={onDeleted}
            />
          ))}
        </S.ListContainer>
      )}
    </S.Root>
  );
};
