import { Typography, pxToRem, styled } from '@genialcare/atipico-react';
import { Radio as RadioDefault } from 'components/Form/Radio';
import { Select as SelectDefault } from 'components/Form/Select';
import { Filter as FilterDefault } from 'components/Filter';

export const Filter = styled(FilterDefault, {
  width: pxToRem(380),
});

export const Label = styled(Typography, {
  marginTop: pxToRem(12),
});

export const Radio = styled(RadioDefault, {
  height: 'fit-content',
  marginTop: pxToRem(12),
});

export const Select = styled(SelectDefault, {
  marginTop: pxToRem(12),
});
