import React, { FormEvent, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn, useFormContext } from 'react-hook-form';
import { useAnalyticsEvents } from 'hooks/useAnalyticsEvents';
import {
  AlertDialog,
  AlertDialogCancelButton,
  AlertDialogConfirmButton,
} from 'components/AlertDialog';
import { Flex } from '@genialcare/atipico-react';
import ThinkingImage from 'assets/images/illustrations/thinking.svg';
import * as S from './Form.styles';

export type FormProps<T extends Record<string, unknown>, E extends Error> = {
  name: {
    subject: string;
    action?: string;
  };
  onSave: (data: T) => Promise<unknown> | unknown | void;
  onError?: (error: E) => Promise<void>;
  formMethods: UseFormReturn<T>;
  children: React.ReactNode;
  showConfirmation?: boolean;
  alertText?: string;
  titleText?: string;
  customEventProps?: Record<string, unknown>;
};

const FormWrapper = <T extends Record<string, unknown>>({
  children,
  onSubmit,
  openAlert,
  toConfirm,
  setOpenAlert,
  alertText = 'Após clicar em salvar não será possível editar ou excluir esse registro.',
  titleText = 'Tem certeza que deseja salvar?',
}: {
  children: React.ReactNode;
  onSubmit: SubmitHandler<T>;
  openAlert: boolean;
  toConfirm: boolean;
  setOpenAlert: (open: boolean) => void;
  alertText?: string;
  titleText?: string;
}) => {
  const { handleSubmit, trigger } = useFormContext<T>();

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    if (toConfirm) {
      const isValid = await trigger(undefined, { shouldFocus: true });

      if (!isValid) return;

      setOpenAlert(true);
      return;
    }

    return handleSubmit(onSubmit)();
  };

  return (
    <>
      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <S.Image src={ThinkingImage} />
        <S.Title>{titleText}</S.Title>
        <S.Text>{alertText}</S.Text>
        <Flex justify="end" css={{ gap: 24 }}>
          <AlertDialogCancelButton>Cancelar</AlertDialogCancelButton>
          <AlertDialogConfirmButton
            onClick={handleSubmit(onSubmit)}
            data-testid="save-modal-button"
          >
            Salvar
          </AlertDialogConfirmButton>
        </Flex>
      </AlertDialog>
      <form data-testid="form" onSubmit={(e) => onSubmitForm(e)}>
        {children}
      </form>
    </>
  );
};

export const Form = <T extends Record<string, unknown>, E extends Error>({
  children,
  name,
  showConfirmation,
  alertText,
  titleText,
  onSave,
  onError = async () => {
    return;
  },
  formMethods,
  customEventProps = {},
}: FormProps<T, E>) => {
  const { sendAnalyticEvent } = useAnalyticsEvents();
  const [startRegister, setStartRegister] = useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const showAlert = showConfirmation || alertText;

  useEffect(() => {
    setStartRegister(new Date().toISOString());
  }, []);

  const handleSave = async (data: T) => {
    setOpenAlert(false);

    try {
      const response = (await onSave(data as T)) as Record<string, unknown>;

      const otherProps = response ? { id: response.id } : {};
      const eventName = `${name.subject}_${name.action || 'created'}`;

      sendAnalyticEvent(eventName, {
        start_register: startRegister,
        end_register: new Date().toISOString(),
        ...otherProps,
        ...customEventProps,
      });
    } catch (error) {
      onError(error as E);
    }
  };

  const toConfirm: boolean = !!showAlert && !openAlert;

  const onSubmit: SubmitHandler<T> = async (data) => {
    if (toConfirm) return setOpenAlert(true);

    return handleSave(data as T);
  };

  return (
    <FormProvider {...formMethods}>
      <FormWrapper<T>
        onSubmit={onSubmit}
        openAlert={openAlert}
        alertText={alertText}
        titleText={titleText}
        setOpenAlert={setOpenAlert}
        toConfirm={toConfirm}
      >
        {children}
      </FormWrapper>
    </FormProvider>
  );
};
