import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_COMPLEXITY_SCORES = gql`
  query ($clinicalCaseId: ID!) {
    complexityScores(clinicalCaseId: $clinicalCaseId) {
      id
      score
      reason
      createdBy {
        id
        name
      }
      createdAt
    }
  }
`;
