import { gql } from '@apollo/client';

export const ADD_ATTACHMENTS_TO_REGISTRY_MUTATION = gql`
  mutation addAttachmentsToRegistry($registryId: ID!, $attachments: [AttachmentsInput]) {
    addAttachmentsToRegistry(registryId: $registryId, attachments: $attachments) {
      key
      filename
      contentType
      checksum
      serviceName
      byteSize
      url
      createdAt
    }
  }
`;
