import { gql } from '@apollo/client';

export const AUTHENTICATED_USER = gql`
  query authenticatedUser {
    user {
      id
      partner {
        legalName
      }
    }
  }
`;
