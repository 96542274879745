import React, { useState, useEffect, useContext, createContext, PropsWithChildren } from 'react';
import { Toast } from 'components/Toast';

const DEFAULT_TOAST_DURATION_TIME = 5000;

type ToastVariants = 'success' | 'error' | 'warning' | 'info';

type ToastContent = React.ReactNode;

type ToastData = {
  open: boolean;
  content: ToastContent;
  duration?: number;
  variant: ToastVariants;
  Icon?: React.FC;
  onClose?: () => void;
};

type ToastHandlerProps = Omit<ToastData, 'open' | 'content' | 'variant'>;

export type ToastHandler = (content: ToastContent, props?: ToastHandlerProps) => void;

type TriggerInterface = {
  success: ToastHandler;
  error: ToastHandler;
  warning: ToastHandler;
  info: ToastHandler;
};

export type ToastContextType = {
  data?: ToastData;
  onClose?: () => void;
  trigger: TriggerInterface;
};

export const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<ToastData | undefined>();

  const handleOnClose = () => {
    setData(undefined);
    data?.onClose?.();
  };

  const trigger = (variant: ToastVariants, content: ToastContent, props?: ToastHandlerProps) => {
    const Icon = props?.Icon;
    const onClose = props?.onClose;
    const duration = props?.duration ?? DEFAULT_TOAST_DURATION_TIME;

    setData({
      Icon,
      onClose,
      content,
      variant,
      duration,
      open: true,
    });
  };

  const contextProps = {
    data,
    onClose: handleOnClose,
    trigger: {
      success: (content: ToastContent, props?: ToastHandlerProps) =>
        trigger('success', content, props),
      error: (content: ToastContent, props?: ToastHandlerProps) => trigger('error', content, props),
      warning: (content: ToastContent, props?: ToastHandlerProps) =>
        trigger('warning', content, props),
      info: (content: ToastContent, props?: ToastHandlerProps) => trigger('info', content, props),
    },
  };

  useEffect(() => {
    const duration = data?.duration ?? DEFAULT_TOAST_DURATION_TIME;

    if (data?.open) {
      const timeout = setTimeout(() => {
        handleOnClose();
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [data?.open, data?.duration]);

  return (
    <ToastContext.Provider value={contextProps}>
      <Toast />

      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastContext');
  }

  return context.trigger;
};

export const useToastData = () => {
  const context = useContext(ToastContext);

  return {
    ...context?.data,
    onClose: context?.onClose,
  };
};
