import { gql } from '@apollo/client';

export const GET_DISCIPLINE_EDIT_FORM_DATA = gql`
  query getDisciplineEditFormData($id: ID!, $clinicalCaseId: ID!) {
    objective(id: $id, clinicalCaseId: $clinicalCaseId) {
      programs {
        ... on OccupationalTherapyProgram {
          id
          name
          description
          strategies {
            name
            id: strategyId
          }
          sensorialFunctions {
            id
            name
          }
        }
        ... on SpeechTherapyProgram {
          id
          name
          discipline
          description
          skills {
            id
            name
          }
          strategies {
            id: strategyId
            name
          }
        }
      }
    }
    strategies {
      id
      name
    }
    sensorialFunctions {
      id
      name
    }
    skills {
      id
      name
    }
  }
`;
