import { styled, pxToRem, Flex, CommunicationUser } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  gap: pxToRem(6),
});

export const UserIcon = styled(CommunicationUser, {
  width: pxToRem(25),
  height: pxToRem(25),
  fill: '$purple500',
});
