import React from 'react';
import { match } from 'ts-pattern';
import { InterfaceEditAlt } from '@genialcare/atipico-react';
import { SessionCancelledNotification, SessionTypes } from 'types';
import { Notification, ComposeNotificationProps } from '../Notification';
import { buildURL } from 'utils/build-urls';
import { formatPtBrDate } from 'utils/date';

type SessionCancelledProps = ComposeNotificationProps<SessionCancelledNotification>;

const sessionNameWithPrefix = (sessionName: string) => (sessionName ? `de ${sessionName}` : '');

const getSessionTypeName = (sessionType: SessionTypes) => {
  const type = sessionType.toUpperCase();
  const sessionName = match(type)
    .with(SessionTypes.Intervention, () => 'intervenção')
    .with(SessionTypes.ParentalTraining, () => 'orientação parental')
    .otherwise(() => '');

  return sessionNameWithPrefix(sessionName);
};

export const SessionCancelled: React.FC<SessionCancelledProps> = ({
  sentAt,
  sessionId,
  sessionType,
  clinicalCaseId,
  clinicalCaseName,
  startScheduledAt,
  ...props
}) => {
  const sessionName = getSessionTypeName(sessionType);
  const href = buildURL.toClinicalSession(clinicalCaseId, sessionId, true);
  const formatedScheduledAt = formatPtBrDate(startScheduledAt.toDate(), { dateStyle: 'short' });

  return (
    <Notification
      {...props}
      date={sentAt}
      showIconBadgeCounter
      href={href}
      icon={<InterfaceEditAlt />}
    >
      <Notification.Title>
        A família do caso{' '}
        <Notification.TitleHighlight>{clinicalCaseName}</Notification.TitleHighlight> cancelou a
        sessão <Notification.TitleHighlight>{sessionName}</Notification.TitleHighlight> do dia{' '}
        <Notification.TitleHighlight>{formatedScheduledAt}</Notification.TitleHighlight>.
      </Notification.Title>

      <Notification.Subtitle>
        Por favor verifique os detalhes do cancelamento.
      </Notification.Subtitle>
    </Notification>
  );
};
