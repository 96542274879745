import createClinicalGuidance from './create-clinical-guidance';
import updateClinicalGuidance from './update-clinical-guidance';
import createClinicalGuidanceComment from './create-clinical-guidance-comment';
import updateClinicalGuidanceComment from './update-clinical-guidance-comment';
import createClinicalCaseSupportLink from './create-clinical-case-support-link';
import deleteClinicalCaseSupportLink from './delete-clinical-case-support-link';
import registerUserDevice from './register-user-device';
import createTask from './create-task';
import updateTask from './update-task';
import deleteTask from './delete-task';
import createSubject from './create-subject';
import updateSubject from './update-subject';
import deleteSubject from './delete-subject';
import selectAvailableHour from './select-available-hour';

export const mutations = [
  createClinicalGuidance,
  updateClinicalGuidance,
  createClinicalGuidanceComment,
  updateClinicalGuidanceComment,
  createClinicalCaseSupportLink,
  deleteClinicalCaseSupportLink,
  registerUserDevice,
  createTask,
  updateTask,
  deleteTask,
  createSubject,
  updateSubject,
  deleteSubject,
  selectAvailableHour,
];
