import React from 'react';
import { Flex, Typography, pxToRem } from '@genialcare/atipico-react';
import { Badge } from 'components/Badge';
import { Session, SessionStatuses, GeneralSessionClinician } from 'types';
import { convertDateToPtBr } from 'utils/date';
import * as S from './SessionCard.styles';
import { Cancellation } from 'components/SessionCard/Cancellation';
import { NoteTemplate } from './Template';

export interface SessionCard extends Session {
  isOpen?: boolean;
  isSelected?: boolean;
  showResume?: boolean;
  onClick?: () => void;
}

const CanceledBadge = () => (
  <Badge
    bg="orange200"
    color="purple500"
    css={{
      height: pxToRem(24),
      minWidth: pxToRem(84),
      fontSize: '12px',
      padding: `${pxToRem(5)} ${pxToRem(8)}`,
      borderRadius: `16px`,
    }}
  >
    Cancelada
  </Badge>
);

const formatSessionDate = (date?: string, onlyDate = true) => {
  if (!date) return '-';

  const replacedDate = date.replace('Z', '-0300');

  return convertDateToPtBr(replacedDate, onlyDate ? { dateStyle: 'short' } : {});
};

const formatSessionTitle = (date?: string) => {
  const formattedDate = formatSessionDate(date, false);

  return `Sessão de orientação parental - ${formattedDate}`;
};

const formatSessionFooter = (clinician: GeneralSessionClinician, date?: string) => {
  const formattedDate = formatSessionDate(date, false);

  return `Por ${clinician.name} (${
    clinician.professionalRegistrationNumber || 'XXX'
  }) em ${formattedDate}`;
};

export const SessionCard: React.FC<SessionCard> = ({
  id,
  clinicians,
  status,
  discipline,
  finalStartedAt,
  parentalTrainingNote,
  cancellation,
  observation,
  isOpen,
  isSelected,
  onClick,
}) => {
  const clinician = parentalTrainingNote?.createdBy?.clinician || clinicians[0];
  const isCancelled = status === SessionStatuses.Cancelled;
  const sessionTitle = formatSessionTitle(finalStartedAt);
  const sessionFooter = formatSessionFooter(clinician, finalStartedAt);
  const noteBody = isCancelled
    ? observation ?? 'Sem observações operacionais'
    : parentalTrainingNote?.body || '-';

  return (
    <>
      <S.Root
        round
        isOpen={isOpen}
        isSelected={isSelected}
        direction="column"
        bg="neutral0"
        onClick={onClick}
      >
        <>
          <S.HeaderInfo direction="column">
            <Flex css={{ padding: `0 ${pxToRem(12)}` }}>
              {isOpen && (
                <>
                  {isCancelled && (
                    <S.BadgeContainer>
                      <CanceledBadge key={discipline} />
                    </S.BadgeContainer>
                  )}
                  <S.SessionAuthor>{sessionFooter}</S.SessionAuthor>
                </>
              )}
            </Flex>

            {isOpen && (
              <Typography
                variant={isOpen ? 'h4' : 'h5'}
                weight="bold"
                color="neutral500"
                css={{ padding: `0 ${pxToRem(16)}` }}
              >
                {sessionTitle}
              </Typography>
            )}
          </S.HeaderInfo>
        </>

        <S.BodyContainer>
          {isOpen ? (
            <S.SessionDetail data-testid={`session-detail-opened-${id}`}>
              <S.Divider />
              {isCancelled && (
                <>
                  {cancellation && (
                    <S.CancellationTemplate>
                      <Cancellation cancellation={cancellation} observation={observation} />
                    </S.CancellationTemplate>
                  )}
                </>
              )}

              {!isCancelled && (
                <>
                  {parentalTrainingNote && (
                    <NoteTemplate parentalTrainingNote={parentalTrainingNote} />
                  )}
                </>
              )}
            </S.SessionDetail>
          ) : (
            <S.ResumeContainer direction="column" data-testid={`session-detail-closed-${id}`}>
              {isCancelled && (
                <S.BadgeContainer>
                  <CanceledBadge key={discipline} />
                </S.BadgeContainer>
              )}
              {!isOpen && (
                <Typography variant={isOpen ? 'h4' : 'h5'} weight="bold" color="neutral500">
                  {sessionTitle}
                </Typography>
              )}
              <S.SessionResume dangerouslySetInnerHTML={{ __html: noteBody }} />

              <S.SessionAuthor color="neutral300">{sessionFooter}</S.SessionAuthor>
            </S.ResumeContainer>
          )}
        </S.BodyContainer>
      </S.Root>
    </>
  );
};
