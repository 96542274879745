import { graphql } from 'msw';
import { v4 as uuidV4 } from 'uuid';

export default graphql.query('getUserAvailableHours', (_req, res, ctx) => {
  return res(
    ctx.delay(1500),
    ctx.data({
      user: {
        __typename: 'User',
        id: uuidV4(),
        availableHours: [
          {
            id: uuidV4(),
            __typename: 'AvailableHour',
            weekday: 'monday',
            startAt: '2024-01-01T08:00:00Z',
            endAt: '2024-01-01T09:00:00Z',
            discipline: 'ABA',
            status: 'REJECTED',
            preApprovedFamily: true,
            statusDescription: null,
            allocatedInClinicalCase: true,
            createdAt: '2024-01-01T08:00:00Z',
            updatedAt: '2024-01-01T08:00:00Z',
            location: {
              id: uuidV4(),
              __typename: 'Location',
              category: 'clinic',
              name: 'Casa Genial - Morumbi',
            },
            child: {
              id: uuidV4(),
              __typename: 'AvailableHourChild',
            },
            selectedAvailableHours: [
              {
                id: uuidV4(),
                status: 'REJECTED',
                rejectedReason: 'THERAPIST_HAS_EXISTING_PROPOSAL',
                rejectedReasonDescription: 'Terapeuta já possui proposta',
                createdAt: '2024-01-01T08:00:00Z',
                updatedAt: '2024-01-01T08:00:00Z',
              },
            ],
          },
          {
            id: uuidV4(),
            __typename: 'AvailableHour',
            weekday: 'thursday',
            startAt: '2024-01-01T10:00:00Z',
            endAt: '2024-01-01T11:00:00Z',
            discipline: 'FONO',
            status: 'AVAILABLE',
            statusDescription: null,
            allocatedInClinicalCase: true,
            preApprovedFamily: false,
            createdAt: '2024-01-01T08:00:00Z',
            updatedAt: '2024-01-01T08:00:00Z',
            location: {
              id: uuidV4(),
              __typename: 'Location',
              category: 'clinic',
              name: 'Casa Genial - Morumbi',
            },
            child: {
              id: uuidV4(),
              __typename: 'AvailableHourChild',
            },
            selectedAvailableHours: [
              {
                id: uuidV4(),
                status: 'PENDING',
                rejectedReason: null,
                rejectedReasonDescription: null,
                createdAt: '2024-01-01T08:00:00Z',
                updatedAt: '2024-01-01T08:00:00Z',
              },
            ],
          },
        ],
      },
    }),
  );
});
