import { graphql } from 'msw';

export default graphql.query('getTaskFormData', (_, res, ctx) =>
  res(
    ctx.delay(1500),
    ctx.data({
      clinicalCase: {
        clinicians: [
          {
            id: 'f286b723-9964-4ed7-a536-55d1fcbed11e',
            name: 'Marcelo Silva',
            user: {
              id: 'fad2ef86-a5a1-429d-b34c-a0f3a9c6c232',
            },
          },
          {
            id: '8b7108c8-5d75-4757-a4f5-98471fc22517',
            name: 'Camila Campos',
            user: {
              id: '9eee86ba-5f9d-4f68-b57b-f3fa4a0a00f7',
            },
          },
          {
            id: 'bf06dbf0-603a-46e9-b309-793ee6a61af3',
            name: 'Juliana Wenzel',
            user: {
              id: '32e067fa-153a-4c87-bb01-317928736051',
            },
          },
          {
            id: '38632791-91bd-4052-8e21-df8d5e5dc698',
            name: 'Matheus Caceres',
            user: {
              id: '259e2bc4-f8e1-4bd8-be29-468b872d9108',
            },
          },
        ],
      },
      clinicalGuidance: {
        status: 'OPEN',
        category: 'DISCUSSION',
        title: 'Arthur ainda parece estar com piolho.',
        createdAt: '2023-02-08T21:59:51.416Z',
        createdBy: {
          id: 'created-by-user-fake-id',
          name: 'Caio Menezes',
        },
      },
    }),
  ),
);
