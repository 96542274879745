import { SkillAcquisitionNotes } from './interventionSession';
import { ParentalTrainingNote } from './parentalTrainingNote';
import { Disciplines } from './pei';
import { ClinicalCase } from './clinicalCase';
import { Pendency } from './pendency';
import { EvolutionCheckSession } from './evolutionCheck';
import { AssessmentNote } from './assessments';
import { Registry } from './clinicalGuidance';

export type AbaNoteDetail = {
  discipline: string;
  usedReinforcers?: string;
  challengingBehaviors?: string;
};

export type OccupationalTherapyMeasurementNoteDetail = {
  strategy: string;
  response: string;
};

export type OccupationalTherapyNoteDetail = {
  discipline: string;
  usedReinforcers?: string;
  challengingBehaviors?: string;
  observation?: string;
  measurements: OccupationalTherapyMeasurementNoteDetail[];
};

export type SpeechTherapyMeasurementNoteDetail = {
  prompt: string;
  response: string;
  skillId?: string;
  skill?: {
    id: string;
    name: string;
  };
};

export type SpeechTherapyNoteDetail = {
  discipline: string;
  usedReinforcers?: string;
  challengingBehaviors?: string;
  observation?: string;
  measurements: SpeechTherapyMeasurementNoteDetail[];
};

export type InterventionNoteDetail =
  | AbaNoteDetail
  | OccupationalTherapyNoteDetail
  | SpeechTherapyNoteDetail;

type NoteCreatedBy = {
  id: string;
  clinician: GeneralSessionClinician;
};
export type InterventionNote = {
  id: string;
  body?: string;
  healthChecks?: string[];
  healthCheck?: string;
  detail: InterventionNoteDetail;
  createdBy?: NoteCreatedBy;
  createdAt: string;
  updatedAt: string;
};

export type ClinicalGuidanceSessionable = {
  registry: Registry;
};

export type ParentalTrainingSessionable = {
  noteId: string;
};

export type SuggestedNote = {
  healthChecks?: string[];
  usedReinforcers?: string[];
  challengingBehaviors?: string[];
  summary?: string;
  observation?: string;
};

export type SuggestedChildProgressionNote = {
  body: string;
};

export type InterventionSessionable = {
  suggestedNote: SuggestedNote;
  suggestedChildProgressionNote: SuggestedChildProgressionNote;
  evolutionCheck?: EvolutionCheckSession | null;
  clinicalGuidanceRegistry?: Registry;
};

export type Sessionable =
  | ClinicalGuidanceSessionable
  | ParentalTrainingSessionable
  | InterventionSessionable;

export type SessionClinicalGuidance = {
  id: string;
  title: string;
};

export type InterventionSessionPerformanceRegistry = {
  status: string;
  completedById: string;
  completedAt: string;
};

export type Session = {
  id: string;
  clinicalCaseId: string;
  clinicalCase: ClinicalCase;
  discipline: keyof typeof Disciplines;
  participants?: string[];
  startScheduledAt: string;
  endScheduledAt: string;
  finished: boolean;
  finalStartedAt: string;
  finalEndedAt: string;
  status: SessionStatuses;
  statusDescription: string;
  sessionType: SessionTypes;
  sessionTypeDescription: string;
  observation?: string;
  cancellationReason?: string;
  cancellationReasonDescription?: string;
  clinicalGuidances?: SessionClinicalGuidance[];
  cancellation?: SessionCancellation;
  createdAt: string;
  updatedAt: string;
  location: SessionLocation;
  clinicians: GeneralSessionClinician[];
  createdBy: SessionUser;
  updatedBy?: SessionUser;
  sessionable?: Sessionable;
  note?: InterventionNote;
  assessmentNote?: AssessmentNote | null;
  skillAcquisitionNotes?: SkillAcquisitionNotes[];
  parentalTrainingNote?: ParentalTrainingNote;
  performanceRegistry?: InterventionSessionPerformanceRegistry | null;
  pendencies?: Pendency[];
};

export type SessionCancellation = {
  reason?: keyof typeof SessionCancellationReasons;
  reasonDescription?: string;
  comment?: string;
  requestedByRoleDescription: string;
  inAdvance: boolean;
};

export type GeneralSessionClinician = {
  id: string;
  name?: string;
  professionalRegistrationNumber?: string;
};

export type SessionLocation = {
  label: string;
  labelDescription: string;
};

export enum SessionCancellationReasons {
  'personal_matters' = 'Assuntos pessoais',
  'caregiver_did_not_attend' = 'Cuidador não compareceu',
  'physical_health_problems' = 'Problemas de saúde física',
  'mental_health_problems' = 'Problemas de saúde mental',
  'displacement_problems' = 'Problemas no deslocamento',
  'reassessment' = 'Reavaliação',
  'without_justification' = 'Sem justificativa',
  'schedule_deleted' = 'Exclusão de programação do SAG',
  'time_off' = 'Férias/Folga',
  'external_trainings' = 'Treinamentos Externos',
  'operational_error' = 'Erro operacional',
  'clinical_error' = 'Erro de preenchimento de anotação',
  'others' = 'Outros',
}

export enum SessionStatuses {
  Scheduled = 'SCHEDULED',
  Rescheduled = 'RESCHEDULED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Held = 'HELD',
  Completed = 'COMPLETED',
}

export enum SessionTypes {
  Intervention = 'INTERVENTION',
  Baseline = 'BASELINE',
  DirectAssessment = 'DIRECT_ASSESSMENT',
  IndirectAssessment = 'INDIRECT_ASSESSMENT',
  FeedbackAssessment = 'FEEDBACK_ASSESSMENT',
  Reassessment = 'REASSESSMENT',
  ClinicalGuidance = 'CLINICAL_GUIDANCE',
  ParentalTraining = 'PARENTAL_TRAINING',
  ExternalSchoolMeeting = 'EXTERNAL_SCHOOL_MEETING',
  ExternalTeamMeeting = 'EXTERNAL_TEAM_MEETING',
  ClinicalCaseSyncUpMeeting = 'CLINICAL_CASE_SYNC_UP_MEETING',
}

export const AssessementsSessionType = [
  SessionTypes.DirectAssessment,
  SessionTypes.IndirectAssessment,
  SessionTypes.FeedbackAssessment,
  SessionTypes.Reassessment,
];

export type SessionUser = {
  id: string;
  name?: string;
  email?: string;
};

export enum RequestCancellationRoles {
  Genial = 'GENIAL',
  Caregiver = 'CAREGIVER',
  Clinician = 'CLINICIAN',
}

export type CompleteSessionRequestInput = {
  cancelled: boolean;
  completion?: SessionCompletionInput;
  cancellation?: SessionCancellationInput;
};

export type SessionCompletionInput = {
  startedAt: string;
  endedAt: string;
  clinicianIds?: string[];
  clinicianEmails?: string[];
  participants?: string[];
};

export type SessionCancellationInput = {
  reason: string;
  comment: string;
  inAdvance: boolean;
  requestedByRole: RequestCancellationRoles;
};
