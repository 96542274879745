import React from 'react';
import { CommunicationBullhorn } from '@genialcare/atipico-react';
import { NoticeClinicalGuidanceCreatedNotification } from 'types';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type NoticeClinicalGuidanceCreatedProps =
  ComposeNotificationProps<NoticeClinicalGuidanceCreatedNotification>;

export const NoticeClinicalGuidanceCreated: React.FC<NoticeClinicalGuidanceCreatedProps> = ({
  clinicalCaseId,
  clinicalCaseName,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  createdAt,
  createdByName,
  ...props
}) => {
  const href = buildURL.toClinicalGuidance(clinicalCaseId, clinicalGuidanceId);

  return (
    <Notification {...props} date={createdAt} href={href} icon={<CommunicationBullhorn />}>
      <Notification.Title>
        {createdByName} <Notification.TitleHighlight>publicou um aviso</Notification.TitleHighlight>{' '}
        no caso {clinicalCaseName}:
      </Notification.Title>
      <Notification.Subtitle>{clinicalGuidanceTitle}</Notification.Subtitle>
    </Notification>
  );
};
