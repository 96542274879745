import React from 'react';
import { CommunicationComment } from '@genialcare/atipico-react';
import { ClinicalGuidanceCreatedNotification } from 'types';
import { buildURL } from 'utils/build-urls';
import { Notification, ComposeNotificationProps } from '../Notification';

type ClinicalGuidanceCreatedProps = ComposeNotificationProps<ClinicalGuidanceCreatedNotification>;

export const ClinicalGuidanceCreated: React.FC<ClinicalGuidanceCreatedProps> = ({
  clinicalCaseId,
  clinicalCaseName,
  clinicalGuidanceId,
  clinicalGuidanceTitle,
  createdAt,
  createdByName,
  ...props
}) => {
  const href = buildURL.toClinicalGuidance(clinicalCaseId, clinicalGuidanceId);

  return (
    <Notification {...props} date={createdAt} href={href} icon={<CommunicationComment />}>
      <Notification.Title>
        {createdByName} iniciou uma{' '}
        <Notification.TitleHighlight>nova discussão</Notification.TitleHighlight> no caso{' '}
        {clinicalCaseName}:
      </Notification.Title>
      <Notification.Subtitle>{clinicalGuidanceTitle}</Notification.Subtitle>
    </Notification>
  );
};
