import React from 'react';
import { pxToRem, Typography } from '@genialcare/atipico-react';
import * as S from './CardAssignee.styles';

type CardAssigneeProps = {
  name: string;
};

export const CardAssignee: React.FC<CardAssigneeProps> = ({ name }) => (
  <S.Root align="center">
    <S.UserIcon data-testid="card-assignee-icon" />
    <Typography variant="body2" color="purple500" css={{ fontSize: pxToRem(14) }}>
      {name}
    </Typography>
  </S.Root>
);
