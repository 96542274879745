import { styled as atStyled, IconButton, CSS } from '@genialcare/atipico-react';
import { styled, Flex } from 'styled-system/jsx';

export const Container = styled(Flex, {
  base: {
    gap: '12px',
    p: '8px 20px 18px 20px',
  },
});

export const Label = styled(Flex, {
  base: {
    flex: '0 0 auto',
    py: '4px',
    px: '12px',
    cursor: 'pointer',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'neutral.400',
    bg: 'neutral.100',
    borderRadius: '12px',

    _hover: {
      color: 'neutral.0',
      bg: 'purple.100',
    },
  },

  variants: {
    active: {
      true: {
        color: 'neutral.0',
        bg: 'purple.100',

        _hover: {
          color: 'neutral.0',
          bg: 'purple.100',
        },
      },
    },
  },
});

const buttonActivatedStyle: CSS = {
  backgroundColor: '$neutral0',
  boxShadow: '0px 8px 26px 0px rgba(0, 0, 0, 0.1)',

  '& svg': {
    fill: '$purple500',
  },
};

export const FilterButton = atStyled(IconButton, {
  variants: {
    open: {
      true: {
        ...buttonActivatedStyle,
      },
      false: {
        '& svg': {
          fill: '$neutral400',
        },
      },
    },
    active: {
      true: {
        '& svg': {
          fill: '$purple500',
        },
      },
      false: {
        '& svg': {
          fill: '$neutral400',
        },
      },
    },
  },
});

export const filterButtonBaseStyle: CSS = {
  width: '38px',
  height: '38px',
  border: 'none',

  '& svg': {
    width: '26px',
    height: '26px',
  },

  '&:hover': {
    backgroundColor: '$neutral0',
    boxShadow: '0px 8px 26px 0px rgba(0, 0, 0, 0.1)',

    '& svg': {
      fill: '$purple500',
    },
  },

  '@md': {
    width: '38px',
    height: '38px',
  },
};

export const FilterContent = styled(Flex, {
  base: {
    position: 'absolute',
    top: '45px',
    right: 0,
    zIndex: 1,
    minW: '220px',
    flexDir: 'column',
    p: '12px',
    bg: 'neutral.0',
    borderRadius: '16px',
    boxShadow: '0px 8px 26px 0px rgba(0, 0, 0, 0.1)',
  },
});
