import { useContext, createContext } from 'react';

export type AuthenticatedUserContextType = {
  id: string;
  roles: string[];
  isClinicalCaseOwner: boolean;
  isClinicalCaseReference: boolean;
  partnerInfo: PartnerInfo | undefined;
};

export type PartnerInfo = {
  legalName: string;
};

export const AuthenticatedUserContext = createContext<AuthenticatedUserContextType | null>(null);

export const useAuthenticatedUser = () => {
  const context = useContext(AuthenticatedUserContext);

  if (!context) {
    throw new Error('useAuthenticatedUser must be used within an AuthenticatedUserContext');
  }

  return context;
};
