import { gql } from '@apollo/client';
import {
  PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS,
  PARENTAL_TRAINING_NOTE_FIELDS,
} from './fragments/parentalTrainingNoteFragments';
import { PARENTAL_TRAINING_SESSION_FIELDS } from './fragments/parentalTrainingSessionFragments';

export const GET_PARENTAL_TRAINING_SESSION_AND_NOTE_BY_SESSION_ID = gql`
  ${PARENTAL_TRAINING_NOTE_FIELDS}
  ${PARENTAL_TRAINING_NOTE_AUTHOR_FIELDS}
  ${PARENTAL_TRAINING_SESSION_FIELDS}

  query getParentalTrainingSessionAndNoteById($id: ID!) {
    session(id: $id) {
      ...ParentalTrainingSessionFields
      parentalTrainingNote {
        ...ParentalTrainingNoteFields
        ...ParentalTrainingNoteAuthorFields
      }
    }
  }
`;
