import { gql } from '@apollo/client';

export const DEMAND_FIELDS = gql`
  fragment DemandFields on Demand {
    id
    status
    statusDescription
    title
    description
    requestedAt
    createdAt
    updatedAt
    createdBy {
      id
      clinician {
        id
        name
        professionalRegistrationNumber
      }
    }
    updatedBy {
      id
      clinician {
        id
        name
        professionalRegistrationNumber
      }
    }
  }
`;
