import { gql } from '@apollo/client';

export const CREATE_SUBJECT = gql`
  mutation createSubject($subjectInput: CreateSubjectInput!) {
    createSubject(subjectInput: $subjectInput) {
      id
      text
      status
      statusDescription
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
    }
  }
`;
