import { VariantProps } from '@stitches/react';
import { styled, pxToRem, generateColorPaletteVariants } from '@genialcare/atipico-react';

export const Root = styled('div', {
  display: 'grid',
  placeItems: 'center',
  width: pxToRem(26),
  height: pxToRem(26),
  borderRadius: '100%',
  fontWeight: '$semibold',
  fontSize: pxToRem(12),
  variants: {
    color: generateColorPaletteVariants(['color']),
    bg: generateColorPaletteVariants(['backgroundColor']),
    fixed: {
      true: {
        position: 'absolute',
        top: pxToRem(-5),
        right: pxToRem(-10),
        border: `${pxToRem(2)} solid $neutral0`,
        '@lg': {
          top: pxToRem(-10),
        },
      },
    },
    extended: {
      true: {
        padding: pxToRem(8),
        width: 'auto',
        top: pxToRem(-12),
        right: pxToRem(-24),
        borderRadius: pxToRem(16),
        border: `${pxToRem(2)} solid $neutral0`,
      },
    },
  },
});

export type BadgeVariants = VariantProps<typeof Root>;
