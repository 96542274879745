import React from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import * as S from './Radio.styles';

type RadioProps = {
  labelValue?: string;
  label: string;
  value?: string;
  id?: string;
  className?: string;
  defaultSelected?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isInvalid?: boolean;
  dataTestId?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Radio: React.FC<RadioProps> = React.forwardRef<HTMLButtonElement, RadioProps>(
  (props, ref) => {
    const {
      value,
      id,
      label,
      labelValue,
      isDisabled,
      isReadOnly,
      isInvalid,
      dataTestId,
      onClick,
      ...rest
    } = props;

    return (
      <S.FieldGroup alignItems="center" justifyContent="flex-start">
        <S.Label htmlFor={id} disabled={isDisabled}>
          <S.Item
            value={value || label}
            id={id ?? label}
            onClick={onClick}
            disabled={isDisabled}
            aria-invalid={isInvalid}
            aria-readonly={isReadOnly}
            data-testid={dataTestId}
          >
            <>
              <RadioGroup.Indicator forceMount ref={ref} asChild {...rest} />
              {labelValue && <S.LabelValue>{labelValue}</S.LabelValue>}
              <S.LabelText>{label}</S.LabelText>
            </>
          </S.Item>
        </S.Label>
      </S.FieldGroup>
    );
  },
);
