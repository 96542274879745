import { useTreatments } from '@splitsoftware/splitio-react';

type FeatureFlagAttributes = {
  attributes?: SplitIO.Attributes;
  treatmentHandler?: (treatment: string) => unknown;
};

const defaultTreatment = (treatment: string) => treatment === 'on';

export const useFeatureFlag = <T = boolean>(flag: string, params?: FeatureFlagAttributes) => {
  const { treatmentHandler = defaultTreatment, attributes } = params ?? {};
  const { [flag]: feature } = useTreatments([flag], attributes);

  return treatmentHandler(feature.treatment) as T;
};
