import React from 'react';
import * as DropdownMenuRoot from '@radix-ui/react-dropdown-menu';
import * as S from './Trigger.styles';

export const Trigger = () => (
  <DropdownMenuRoot.Trigger asChild>
    <S.Triggerbutton
      icon={<S.Icon />}
      css={S.triggerToggleStatus}
      data-testid="notifications-trigger"
    />
  </DropdownMenuRoot.Trigger>
);
