import React from 'react';
import { pxToRem, Typography } from '@genialcare/atipico-react';
import { InterventionNote } from 'types';
import { List } from '../../components';

type ABAProps = {
  note: InterventionNote;
};

export const ABA: React.FC<ABAProps> = ({
  note: {
    body = '',
    healthChecks = [],
    detail: { usedReinforcers = '', challengingBehaviors = '' },
  },
}) => {
  return (
    <List css={{ marginBottom: pxToRem(24) }} data-testid="aba-discipline-template">
      {healthChecks?.length > 0 && (
        <List.Item
          data-testid="discipline-healthcheck"
          name="Checagem de saúde da criança para essa sessão"
        >
          <List sublist>
            {healthChecks.map((healthCheck, index) => (
              <List.Item key={index} name={healthCheck} />
            ))}
          </List>
        </List.Item>
      )}

      {usedReinforcers && (
        <List.Item data-testid="discipline-used-reinforcers" name="Reforçadores utilizados">
          {usedReinforcers}
        </List.Item>
      )}

      {challengingBehaviors && (
        <List.Item
          data-testid="discipline-challenging-behaviors"
          name="Comportamentos desafiadores/barreiras de aprendizagem"
        >
          {challengingBehaviors}
        </List.Item>
      )}
      <List.Item name="Relato da sessão">
        <Typography as="div" variant="body2" color="neutral400" css={{ marginTop: pxToRem(8) }}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </List.Item>
    </List>
  );
};
