import { DefaultContext, MutationHookOptions } from '@apollo/client';
import { UPDATE_CLINICAL_GUIDANCE } from 'queries';
import { ClinicalGuidance, UpdateClinicalGuidanceInput } from 'types';
import { useAuthorizedMutation } from './useAuthorizedMutation';

type Data = {
  updateClinicalGuidance: ClinicalGuidance;
};

type Variables = {
  clinicalGuidanceId: string;
  clinicalGuidance: UpdateClinicalGuidanceInput;
};

export const useUpdateClinicalGuidance = (
  options?: MutationHookOptions<Data, Variables, DefaultContext>,
) => {
  const { mutate, loading, error, data } = useAuthorizedMutation<Data, Variables>(
    UPDATE_CLINICAL_GUIDANCE,
    options,
  );

  const mutation = (clinicalGuidanceId: string, clinicalGuidance: UpdateClinicalGuidanceInput) => {
    return mutate({
      variables: {
        clinicalGuidance,
        clinicalGuidanceId,
      },
    });
  };

  return {
    data: data?.updateClinicalGuidance,
    mutation,
    loading,
    error,
  };
};
