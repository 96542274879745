import { gql } from '@apollo/client';
import { OBSERVATION_FIELDS } from './fragments';

export const ADD_DEMAND_OBSERVATION = gql`
  ${OBSERVATION_FIELDS}

  mutation ($clinicalCaseId: ID!, $demandId: ID!, $request: AddObservationInput!) {
    addObservation(clinicalCaseId: $clinicalCaseId, demandId: $demandId, request: $request) {
      ...ObservationFields
    }
  }
`;
