import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HuggingSvg from 'assets/images/illustrations/hugging.svg';
import * as S from './Success.styles';

export const TweetsSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { id, interventionId } = useParams();

  const goToInterventionNotes = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/panel/clinical-cases/${id}/interventions`);
  };

  const goToTweets = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/panel/clinical-cases/${id}/interventions/${interventionId}/tweets`);
  };

  return (
    <S.Wrapper>
      <S.Image src={HuggingSvg} />
      <S.Title variant="h4" color="purple500" weight="bold">
        Deu tudo certo!
      </S.Title>
      <S.Text variant="body2" color="neutral400">
        A anotação e os tweets da sessão foram registrados com sucesso. Para visualizar a anotação
        completa, basta clicar no botão abaixo.
      </S.Text>
      <S.Button colorScheme="purple" onClick={goToInterventionNotes}>
        Ver todas as anotações
      </S.Button>
      <S.Button colorScheme="purple" variant="outline" onClick={goToTweets}>
        Criar novo tweet
      </S.Button>
    </S.Wrapper>
  );
};

export default TweetsSuccess;
