import React from 'react';
import { pxToRem } from '@genialcare/atipico-react';
import { InterventionNote, OccupationalTherapyNoteDetail } from 'types';
import * as S from './TO.styles';
import { List } from '../../components';

type TODisciplineProps = {
  note: InterventionNote;
};

export const TO: React.FC<TODisciplineProps> = ({ note: { healthChecks = [], detail } }) => {
  const { measurements, observation } = detail as OccupationalTherapyNoteDetail;

  return (
    <List css={{ marginBottom: pxToRem(24) }} data-testid="to-discipline-template">
      <List.Item name="Checagem de saúde da criança para essa sessão">
        <List sublist>
          {healthChecks.map((healthCheck, index) => (
            <List.Item key={index} name={healthCheck} />
          ))}
        </List>
      </List.Item>

      <List.Item name="Estratégia(s) utilizada(s) e resposta da criança">
        {measurements.map((measurement, index) => (
          <S.Sublist key={`measurement-sublist-${index}`}>
            <List.Item name="Estratégia utilizada">{measurement.strategy}</List.Item>

            <List.Item name="Resposta da criança">{measurement.response}</List.Item>
          </S.Sublist>
        ))}
      </List.Item>

      {observation && <List.Item name="Observações complementares">{observation}</List.Item>}
    </List>
  );
};
