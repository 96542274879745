import { gql } from '@apollo/client';

export const GET_CLINICAL_CASE_GUIDANCES = gql`
  query getClinicalCaseGuidances($id: ID!) {
    clinicalCase(id: $id) {
      id
      clinicalGuidances {
        id
        context {
          type
          id
          details {
            ... on InterventionSessionDetails {
              title
            }
          }
        }
        createdBy {
          name
        }
        title
        content
        status
        priority
        category
        createdAt
        updatedAt
        comments {
          content
          createdBy {
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
