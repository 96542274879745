import { useEffect, MutableRefObject } from 'react';

type Callback = (e: Event) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: MutableRefObject<T | null>,
  callback: Callback,
) {
  useEffect(() => {
    const listener = (e: Event) => {
      const el = ref.current;
      const target = e.target as Node;

      if (!el || el.contains(target)) {
        return;
      }

      callback(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, callback]);
}
