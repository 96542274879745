import { createContext, useContext } from 'react';
import { Caregiver, Dependent } from 'types';
import { BaseComplexityScore } from 'types/complexityScores';

type ClinicalCaseContextType = {
  caregivers?: Caregiver[];
  dependents?: Dependent[];
  name?: string;
  currentComplexityScore?: BaseComplexityScore;
};

export const ClinicalCaseContext = createContext<ClinicalCaseContextType | null>(null);

export const useClinicalCase = () => {
  const context = useContext(ClinicalCaseContext);

  if (!context) {
    throw new Error('useClinicalCase must be used within an ClinicalCaseProvider');
  }

  return context;
};
